import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import ProgressBar from '@ramonak/react-progress-bar';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Grid, Typography } from '@material-ui/core';
import { SuccessfulPurchaseModal } from 'components/Modals/SuccessfulPurchaseModal';
import { Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  fetchClientContribution,
  rescheduleSessionForClient,
  fetchClientContributionAfterInterval,
} from 'actions/contributions';
import { PaymentStatus } from 'helpers/constants';
import { useAccount, useApplicationStatus, useHttp, useRouter, useVideoChat, useClientPreview } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import { JOIN_CONTRIBUTION_STATUS } from '../../../../constants';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import CustomPurchaseModal from './CustomPurchaseModal';
import JoinSessionTime from 'components/SessionButtons/JoinSessionTime';
import Modal from 'components/UI/Modal';
import { BodyText } from 'components/UI/Text/TextStyles';
import TextField from 'components/UI/TextField';
import styled from 'styled-components';
import { TIMEZONES } from 'constants.js';
import {
  determineColorToUse,
  getOneToOneSelfBookClient,
  getThemedColors,
  updateTimeforSelfBook,
} from 'services/contributions.service';

import LaunchRecordedSessionVideo from 'components/SessionButtons/LaunchRecordedSessionVideo';
import SessionsContainer from 'pages/ContributionView/ContributionSessions/OneToOneSessions/SessionsContainer/SessionsContainer';
import CalendarContributionView from '../CalendarContributionView/CalendarContributionView';
import CalendarContributionViewForPaymentCard from '../CalendarContributionViewForPaymentCard/CalendarContributionViewForPaymentCard';
import BookModal from '../BookModal';
import BookModalContainerForPaymentCard from './BookModalForPaymentCard';
import PurchaseModal from './PurchaseModal';
import ProceedModal from '../ProceedModal';
import RefuseModal from '../RefuseModal';
import JoinSessionTimeModal from 'pages/ContributionView/ContributionSessions/OneToOneSessions/ClientOneToOneSessions/JoinSessionTimeModal';
import SlotsModalForPaymentCard from './SlotsModalForPaymentCard';
import '../../ContributionSessions/OneToOneSessions/ClientOneToOneSessions/ClientOneToOneSessions.scss';
import SlotsModal from '../SlotsModal';
import { FILTER_TABS_OPTIONS } from 'pages/ContributionView/ContributionSessions/constants';
import TabSelector from 'components/UI/TabSelector';
import CustomPurchaseBlock from 'pages/ContributionView/components/PurchaseBlock/CustomPurchaseBlock';
import PurchaseModalForPaymentCard from './PurchaseModalForPaymentCard';
import { toRem } from 'utils/styles';
import * as contributionActions from 'actions/contributions';
import { isNil } from 'ramda';
import Loader from 'components/UI/Loader';
import { getIpGlobal, lightOrDark } from 'utils/utils';
import BookModalView from '../BookModal/BookModalView';
import Input from 'components/FormUI/Input';
import Button from 'components/FormUI/Button';
import PurchaseClientInvitationFrom from './PurchaseClientInvitationForm';
import { redirectTo } from 'services/links';
import { useStripe } from '@stripe/react-stripe-js';
import { getOneToOneEvents, isOneToOnePastDate } from 'utils/oneToOneSession';
import { setEasyBookingData } from 'actions/easyBooking';
import { setEasyBookingThankYouData } from 'actions/easyBookingThankYou';
import CustomApplyCoupon from './CustomApplyCoupon';
import { getOnToOnePaymentInfo } from 'services/purchase.service';
import momenttz from 'moment-timezone';
import { getItem, removeItem } from 'services/localStorage.service';
import { useDefaultTimezone } from 'hooks';

const scrollToCalendar = ref => window.scrollTo(0, ref.current.offsetTop);

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${({ mobileView }) => (mobileView ? '24px' : '5px')};
`;

const PriceTagContainer = styled.div`
  background-color: ${({ colorToUse }) => colorToUse?.PrimaryColorCode};
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  font-size: 18px;
  color: white;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const PriceTag = styled.p`
  font-family: 'Brandon Text';
  font-style: normal;
  margin: 0px;
  font-size: 15px;
  padding: 2px;
  font-weight: 600;
`;
const StyledToggleButtonGroupContainer = styled.div`
  display: block;
  text-align: center;

  ${({ isSlotModalOpen }) => !isSlotModalOpen && `  margin-bottom: 10px;`}
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    border-radius: 6px;
  }
`;
const StyledTitle = styled.div`
  color: ${({ color }) => color || 'var(--Cohere-Primary-Blue, #215C73)'};
  font-family: Avenir;
  font-size: 20px;
  font-weight: 800;
  line-height: 31.24px;
  padding: 0px 16px 16px 16px;
  ${({ isSlotModalOpen }) => isSlotModalOpen && `padding: 0px 16px 16px 24px;`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;
const StyledToggleButton = styled(ToggleButton)`
  width: 162px;
  align-items: center;

  &.MuiToggleButton-root {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 24.5px;
    letter-spacing: 0.4px;
    word-wrap: break-word;
    padding-top: 6.1px;
    padding-bottom: 7.4px;
    padding-left: 13.92px;
    padding-right: 13.93px;
    color: #0000008a !important;
    background-color: #dfe3e4 !important;
  }
  &.Mui-selected {
    color: white !important;
    ${({ background }) => background && `background-color: ${background} !important;`}
  }

  svg {
    height: 20px;
  }
`;
const PriceValue = styled.div`
  font-size: 36px;
  font-weight: 700;
  // margin-bottom: 10px;
`;

const LabelText = styled.span`
  font-size: ${toRem(18)};
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.12px;
  color: #000000;

  ${({ mobileView }) => mobileView && `font-size: ${toRem(16)};`}
`;

const PriceContainer = styled.div`
  background-color: #fafafa;
  padding: 2px 0px;
`;
const ClientOneToOneSessionsForPaymentCard = ({
  user,
  contribution,
  contribution: {
    id,
    activeTemplate,
    title,
    availabilityTimes = [],
    durations,
    serviceProviderName,
    paymentInfo,
    packagePurchases,
    isElectronicSignatureActive,
    isPriceHidden,
    progressBarPackagePurchases,
  },
  contributionTimes,
  rescheduleChosenSession,
  isCustomBrandingColorsActive,
  isBrandingColor,
  singleSessionData,
  anyPaymentOptionEnabled,
  easyBooking = false,
  rescheduleSession = false,
  setRescheduleSession,
  packageSessionBooking = false,
  setShowModal = () => {},
  setRescheduleBookingmodal = () => {},
  isModal = false,
  isCoach = false,
}) => {
  const dispatch = useDispatch();
  const [toggleAvailabilitySlots, setToggleAvailabilitySlots] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const { newThemedTextColor } = getThemedColors(contribution);
  let totalSessionNumbers = 0;
  let totalBookedSessionNumbers = 0;
  const clientPackage = progressBarPackagePurchases?.filter(p => p.userId === user?.id);
  clientPackage.forEach(item => {
    totalSessionNumbers += item.sessionNumbers;
    totalBookedSessionNumbers += item.bookedSessionNumbers;
  });
  const [couponError, setCouponError] = useState(false);
  const [showButtons, setshowButtons] = useState(true);
  const { sessionNumbers, freeSessionNumbers, bookedSessionNumbers } = clientPackage[clientPackage.length - 1] || {};
  const [isBookModalShowed, setBookModalShowed] = useState(false);
  const [isPurchaseModalShowed, setPurchaseModalShowed] = useState(false);
  const [isProceedModalShowed, setProceedModalShowed] = useState(false);
  const [isRefuseModalShowed, setRefuseModalShowed] = useState(false);
  const [isOnlyPackageBookingAllowedModalShowed, setIsOnlyPackageBookingAllowedModalShowed] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [sessionPeriodSelected, setSessionPeriodSelected] = useState({});
  const [isPackage, setIsPackage] = useState(false);
  const [isOpenSuccessPurchaseModal, setSuccessPurchaseModal] = useState(false);
  const [isNotLoggedUser, setIsNotLoggedUser] = useState(false);
  const [isSubmitPurchaseModalNow, setIsSubmitPurchaseModalNow] = useState(false);
  const [rescheduleMode, setRescheduleMode] = useState(false);
  const [isCalendarRescheduleModalOpen, setCalendarRescheduleModal] = useState(false);
  const [currentRescheduleSession, setCurrentRescheduleSession] = useState(null);
  const [selectedTimeZone, setSelectedTimeZone] = useState('Select Timezone');
  const [messageText, setMessageText] = useState('');
  const [rescheduleTo, setRescheduleTo] = useState(null);
  const [isSlotModalOpen, setSlotModalShowed] = useState(false);
  const [calendarRescheduleModalStatus, setCalendarRescheduleModalStatus] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const easyBookingThankYou = useSelector(state => state.easyBookingThankYou);
  const formerBookingData = useSelector(state => state?.contributions.formerBookingData);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [alreadyPuchased, isAlreadyPuchased] = useState(false);
  const [loading, setLoading] = useState(false);
  const { history, query, pathname } = useRouter();
  const isMasterCalendar = pathname.includes('calendar/master');
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const [paymentDetails, setPaymentDetails] = useState(null);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const myAvailabilityTime = useSelector(state => state?.contributions?.myAvailabilityTime);
  const { applicationRequiredButNotApproved } = useApplicationStatus({ contribution, user });
  const [coupon, setCoupon] = useState('');
  const [couponId, setCouponId] = useState(null);
  const isInviteToJoin = useSelector(state => state?.contributions?.inviteCode);
  const easyBookingData = useSelector(state => state?.easyBooking);
  const isCodeToJoin = useSelector(state => state?.contributions?.singleCode);
  const priceId = useSelector(state => state?.contributions?.priceCode);
  const pickAnotherTime = useSelector(state => state.contributions.pickAnotherTime);
  const guestTimeZone = useSelector(state => state.contributions.selectedGuestTimeZone);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [freeAccessCode, setfreeAccessCode] = useState(null);
  const [showClientInvitedView, setShowClientInvitedView] = useState(isCodeToJoin != null);
  const [singleSessionInvite, setSingleSessionInvite] = useState(null);
  const [slotExpired, setSlotExpired] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const { clientPreviewMode } = useClientPreview();
  const firstRender = useRef(true);
  const { timeZones } = useSelector(state => state.timeZone);
  const { fetchUserTimezone } = useDefaultTimezone();
  const { clientData } = useSelector(state => state?.invitedClient);
  const currentDate = moment();
  const startTimes = contributionTimes?.map(item => item.startTime);
  const filteredDates = startTimes?.filter(date => {
    const dateObj = moment(date);
    return dateObj.isSameOrAfter(currentDate, 'day');
  });
  const stripe = useStripe();

  useEffect(() => {
    if (applicationRequiredButNotApproved === false) {
      dispatch(contributionActions.setHideApplyButton(true));
    }
  }, [applicationRequiredButNotApproved]);

  useEffect(() => {
    dispatch(contributionActions.setClientCalendarDate(null));
  }, []);

  useEffect(() => {
    if (isCoach) {
      if (myAvailabilityTime === false) {
        dispatch(
          contributionActions.fetchContributionAllTimes(
            id,
            true,
            contribution.sessionIncrements,
            selectedDate != null
              ? moment(selectedDate).format('YYYY-MM-DD')
              : moment(filteredDates[0]).format('YYYY-MM-DD'),
            selectedTimeZone,
            contribution,
          ),
        ).then(() => {
          setLoading(false);
        });
      }
    }
  }, [myAvailabilityTime, selectedTimeZone, selectedDate]);

  useEffect(() => {
    if (pathname.includes('session') && !isSlotModalOpen && isInviteToJoin === null) {
      getOnToOnePaymentInfo(contribution.id, priceId, 'PerSession').then(res => {
        setPaymentDetails(res);
      });
    }
  }, []);

  useEffect(() => {
    if (isCodeToJoin) {
      getSelfBookData();
    }
    getOnToOnePaymentInfo(contribution.id, priceId, 'PerSession').then(res => {
      setPaymentDetails(res);
    });
  }, []);

  const fetchData = async () => {
    if (timeZones.length > 0 && selectedTimeZone === 'Select Timezone' && contribution) {
      const finalTimeZone = await fetchUserTimezone(user?.timeZoneId, contribution?.timeZoneId, timeZones);
      setSelectedTimeZone(finalTimeZone);
    }
  };
  useEffect(() => {
    if (timeZones.length > 0 && selectedTimeZone === 'Select Timezone') {
      fetchData();
    }

    if (easyBookingData.timeZone && selectedTimeZone !== easyBookingData.timeZone) {
      setSelectedTimeZone(easyBookingData.timeZone);
    }
  }, [timeZones, contribution]);

  useEffect(() => {
    if (selectedTimeZone && !firstRender.current) {
      setSelectedTimeZone(selectedTimeZone);
      dispatch(
        setEasyBookingData.setData({
          ...easyBookingData,
          timeZone: selectedTimeZone,
          timeZoneThankYou: TIMEZONES[selectedTimeZone],
        }),
      );
    }
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [selectedTimeZone]);
  const sessionDateFormat = 'MMMM Do YYYY hh:mm A';
  const sessionTimeFormat = 'hh:mm A';
  const reload = () => window.location.reload();

  let availableToRescheduleGuest = moment(
    easyBookingThankYou?.selectedSlot?.startTime ||
      formerBookingData.selectedSlot?.startTime ||
      easyBookingThankYou?.selectedSlot?.start ||
      formerBookingData.selectedSlot?.start,
  ).isAfter(moment().add(24, 'h'));
  const { sessionTimeId } = useSelector(state => state.contributions);
  useEffect(() => {
    availableToRescheduleGuest = moment(
      easyBookingThankYou?.selectedSlot?.startTime ||
        formerBookingData.selectedSlot?.startTime ||
        easyBookingThankYou?.selectedSlot?.start ||
        formerBookingData.selectedSlot?.start,
    ).isAfter(moment().add(24, 'h'));
  }, [sessionTimeId]);

  const getSelfBookData = async () => {
    await getOneToOneSelfBookClient(isCodeToJoin.slice(2))
      .then(response => {
        setSingleSessionInvite(response);
      })
      .catch(err => {
        setSlotExpired(true);
        setLoading(false);
        setErrorState(err.response.data);
      });
  };

  const toggleSlotPopup = () => {
    setSlotExpired(!slotExpired);
    setLoading(false);
    dispatch(contributionActions.setSingleCode(null));
    window.location.replace(`/contribution-view/${id}/about`);
  };
  const calendarEl = useRef(null);

  const { token } = useVideoChat();
  const { request } = useHttp();

  const calendarRef = useRef(null);
  const dateToShow = currentRescheduleSession?.startTime;
  const executeScroll = () => {
    scrollToCalendar(calendarRef);
    dispatch(contributionActions.setRescheduleModal(true));
  };
  const handleCalendarRescheduleSubmit = () => {
    const rescheduleData = {
      rescheduleFromId: currentRescheduleSession.id,
      rescheduleToId: rescheduleTo.id,
      contributionId: id,
      note: messageText,
      offset: rescheduleTo.offset,
    };
    rescheduleChosenSession(rescheduleData).then(() => {
      setCalendarRescheduleModal(false);
      setCurrentRescheduleSession(null);
      dispatch(contributionActions.setRescheduleModal(false));
      setMessageText(null);
      setCalendarRescheduleModalStatus(rescheduleTo);
    });
  };

  const handleCalendarRescheduleCancel = () => {
    setCalendarRescheduleModal(false);
    setRescheduleMode(false);
    setRescheduleTo(null);
    dispatch(contributionActions.setRescheduleModal(false));
  };

  useEffect(() => {
    if (isCoach === false) {
      const sessionsRefresher = setInterval(async () => {
        const userIp = await getIpGlobal();
        return !token && !clientPreviewMode && dispatch(fetchClientContributionAfterInterval(id, userIp));
      }, 10000);

      return () => {
        clearInterval(sessionsRefresher);
      };
    }
  }, [token, dispatch, id]);

  useEffect(() => {
    if (isInviteToJoin) {
      setIsOnlyPackageBookingAllowedModalShowed(false);
    }
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      const cod = isInviteToJoin || queryCode || isCodeToJoin;
      if (shouldRedirect && cod) {
        let userIp = null;
        try {
          userIp = await getIpGlobal();
        } catch (e) {
          console.info(e);
        }
        dispatch(fetchClientContribution(id, userIp));
        setTimeout(() => {
          setLoading(false);
          if (contribution?.clientRedirectLink) {
            return history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            return history.push(`/contribution-view/${id}/sessions`);
          }
          // history.push(`/contribution-view/${id}/${cod}/sessions`);
        }, 2000);
      }
    }, 1000);
  }, [shouldRedirect]);

  const duration = durations && durations.length ? durations[0] : 60; // temporary becauce there is null
  const endDay = contribution?.oneToOneSessionDataUi?.endDay;
  const endDate = endDay ? moment(endDay) : moment().add(60, 'days');
  const times = Array.from({ length: endDate.diff(moment(), 'days') + 1 }, (_, i) => ({
    start: moment().add(i, 'days').set({ hour: 14, minute: 0, second: 0 }).toISOString(),
    isBooked: undefined,
  }));
  const events = myAvailabilityTime
    ? getOneToOneEvents({ contributionTimes, duration, title, serviceProviderName }).filter(item =>
        moment(item.start).isSameOrAfter(moment()),
      )
    : times;

  const toggleSuccessPurchaseModal = () => setSuccessPurchaseModal(prevState => !prevState);

  // const handlePayment = useCallback(
  //   // ({ status }) => {
  //   () => {
  //     // if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
  //     // if(token) {
  //     if (isNotLoggedUser) {
  //       setIsNotLoggedUser(false);
  //       return setIsSubmitPurchaseModalNow(true);
  //     }
  //     setPurchaseModalShowed(true);
  //     // }

  //     // if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
  //     //   setProceedModalShowed(true);
  //     // }

  //     // if (!status) {
  //     // if (!token) {
  //     //   dispatch(fetchClientContribution(id));
  //     // }
  //   },
  //   [setProceedModalShowed, dispatch, id, isNotLoggedUser],
  // );

  const handlePayment = useCallback(
    async (sessionId, { status }, code) => {
      if (sessionId && typeof sessionId === 'string') {
        if (isNotLoggedUser) {
          setIsNotLoggedUser(false);
          return setIsSubmitPurchaseModalNow(true);
        }
        if (!code) {
          setPurchaseModalShowed(true);
        }
      } else {
        if ([PaymentStatus.requiresPaymentMethod, PaymentStatus.unpurchased].includes(status)) {
          if (isNotLoggedUser) {
            setIsNotLoggedUser(false);
            return setIsSubmitPurchaseModalNow(true);
          }
          setPurchaseModalShowed(true);
        }

        if ([PaymentStatus.requiresAction, PaymentStatus.requiresConfirmation].includes(status)) {
          setProceedModalShowed(true);
        }

        if (!status) {
          const userIp = await getIpGlobal();
          dispatch(fetchClientContribution(id, userIp));
        }
      }
    },
    [setProceedModalShowed, dispatch, id, isNotLoggedUser],
  );

  const handleBookModalOpen = useCallback(
    event => {
      setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
      setBookModalShowed(true);
    },
    [setBookModalShowed, setSessionPeriodSelected, sessionPeriodSelected],
  );

  const getIsGuest = () => {
    if (user?.isSecondaryExisting || user?.isSecondaryAccount) {
      return true;
    }
    return false;
  };

  const handleBookModalSubmit = useCallback(
    async code => {
      if (isEmpty(user)) {
        setIsNotLoggedUser(true);
        setPurchaseModalShowed(true);
        setBookModalShowed(false);
        return;
      }
      setBookModalShowed(false);
      const { id: availiableId, offset, start, end, accessCode, priceOption } = sessionPeriodSelected;
      let option = priceOption || code?.priceOption;
      if (isCodeToJoin && sessionPeriodSelected && isEmpty(user) === false) {
        updateTimeforSelfBook({
          availabilityTimeId: singleSessionInvite.availabilityTimeId,
          id: isCodeToJoin.slice(2),
          contributionId: id,
          clientId: user?.id,
          selectedSlotStartDateTime: selectedSlot.start,
          selectedSlotEndDateTime: selectedSlot.end,
          updatedAvailabilityTimeId: availiableId,
          accessCode: singleSessionInvite.accessCode,
          clientName: singleSessionInvite.clientName,
          priceId: priceId,
          isNewUser: false,
          otherTimePicked: true,
        }).then(async () => {
          try {
            let reqData;
            // if (isCodeToJoin) {

            reqData = {
              contributionId: id,
              AvailabilityTimeId: isCodeToJoin ? code?.id || availiableId : availiableId,
              offset,
              accessCode: isCodeToJoin ? code?.accessCode || accessCode : queryCode,
              priceId: priceId,
              paymentOption: 'Free',
              createSingleSession: true,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              couponId: couponCode,
              isGuest: getIsGuest(),
              firstName: getItem('guestFirstName'),
              lastName: getItem('guestLastName'),
              BookingDoneWithSecondaryAccount: getIsGuest(),
              HelpingMaterial: getItem('helpingMaterial'),
              isSecondaryExisting: user?.isSecondaryExisting,
              scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
            };
            let reqDataPaid = {
              contributionId: id,
              AvailabilityTimeId: isCodeToJoin ? code?.id || availiableId : availiableId,
              offset,
              createSingleSession: true,
              couponId: couponCode,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              priceId: priceId,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              isGuest: getIsGuest(),
              firstName: getItem('guestFirstName'),
              lastName: getItem('guestLastName'),
              BookingDoneWithSecondaryAccount: getIsGuest(),
              HelpingMaterial: getItem('helpingMaterial'),
              isSecondaryExisting: user?.isSecondaryExisting,
              scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
            };
            let reqDataCredit = {
              contributionId: id,
              AvailabilityTimeId: isCodeToJoin ? code?.id || availiableId : availiableId,
              offset,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              priceId: priceId,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              isGuest: getIsGuest(),
              firstName: getItem('guestFirstName'),
              lastName: getItem('guestLastName'),
              BookingDoneWithSecondaryAccount: getIsGuest(),
              HelpingMaterial: getItem('helpingMaterial'),
              isSecondaryExisting: user?.isSecondaryExisting,
              scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
            };
            const paymentDataResponse = await request(
              '/api/purchase/one-to-one',
              'POST',
              option === 'Credit' ? reqDataCredit : option === 'Free' || isInviteToJoin ? reqData : reqDataPaid,
            );
            dispatch(
              setEasyBookingThankYouData.setData({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
                helpingMaterial: getItem('helpingMaterial'),
              }),
            );
            dispatch(
              contributionActions.setRescheduleFormerSessionDetail({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
              }),
            );
            removeItem('helpingMaterial');
            removeItem('guestFirstName');
            removeItem('guestLastName');
            removeItem('existingUser');
            if (paymentDataResponse.includes('cs_') && paymentDataResponse.includes('checkout.stripe') === false) {
              return stripe.redirectToCheckout({
                sessionId: paymentDataResponse,
              });
            }
            if (paymentDataResponse.includes('checkout.stripe')) {
              redirectTo(paymentDataResponse);
            }
            if (
              paymentDataResponse?.bookedSessionNumbers ||
              paymentDataResponse?.sessionNumbers ||
              paymentDataResponse?.freeSessionNumbers
            ) {
              window.location.replace(`/contribution-view/${id}/sessions`);
              dispatch(contributionActions.setSingleCode(null));
            }
            const userIP = await getIpGlobal();
            dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
            setBookModalShowed(false);
            setShowModal(false);
            setRescheduleBookingmodal(false);
            // handlePayment();
            handlePayment(paymentDataResponse, paymentDataResponse, code);
            dispatch(contributionActions.setSingleCode(null));
          } catch (e) {
            if (e?.response?.data.includes('You are not booked for this session or this session is already booked.')) {
              setErrorModal(true);
            }
            setLoading(false);
            setErrorMessage(e?.response?.data);
            if (e?.response?.data === 'Unable to book one to one time. You have unconfirmed session package payment') {
              setRefuseModalShowed(true);
            }
          }
        });
      } else {
        try {
          let reqData;
          if (code || queryCode) {
            setLoading(true);
            reqData = {
              contributionId: id,
              AvailabilityTimeId: availiableId,
              offset,
              accessCode: code || queryCode,
              paymentOption: 'Free',
              createSingleSession: true,
              priceId: priceId,
              couponId: couponCode,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              isGuest: getIsGuest(),
              firstName: getItem('guestFirstName'),
              lastName: getItem('guestLastName'),
              BookingDoneWithSecondaryAccount: getIsGuest(),
              HelpingMaterial: getItem('helpingMaterial'),
              isSecondaryExisting: user?.isSecondaryExisting,
              guestTimeZone: guestTimeZone || contribution.timeZoneId,
              scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
            };
          } else {
            reqData = {
              contributionId: id,
              AvailabilityTimeId: availiableId,
              offset,
              priceId: priceId,
              isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
              isPhoneNumberAdded: contribution.isPhoneNumberAdded,
              isCoachNumberAdded: contribution.isCoachNumberAdded,
              createSingleSession: true,
              couponId: couponCode,
              isGuest: getIsGuest(),
              firstName: getItem('guestFirstName'),
              lastName: getItem('guestLastName'),
              BookingDoneWithSecondaryAccount: getIsGuest(),
              HelpingMaterial: getItem('helpingMaterial'),
              isSecondaryExisting: user?.isSecondaryExisting,
              guestTimeZone: guestTimeZone || contribution.timeZoneId,
              scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
            };
          }
          const paymentDataResponse = await request('/api/purchase/one-to-one', 'POST', reqData);
          dispatch(
            setEasyBookingData.setData({
              ...easyBookingData,
              timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                easyBookingData.selectedSlot?.end,
              ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                easyBookingData.selectedSlot?.end,
              ).format('MMMM DD, yyyy')}`,
              helpingMaterial: getItem('helpingMaterial'),
            }),
          );
          dispatch(
            contributionActions.setRescheduleFormerSessionDetail({
              ...easyBookingData,
              timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                easyBookingData.selectedSlot?.end,
              ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                easyBookingData.selectedSlot?.end,
              ).format('MMMM DD, yyyy')}`,
            }),
          );
          dispatch(contributionActions.setRedirectToSessions(true));
          removeItem('helpingMaterial');
          removeItem('guestFirstName');
          removeItem('guestLastName');
          removeItem('existingUser');
          const userIP = await getIpGlobal();
          dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
          setShowModal(false);
          setRescheduleBookingmodal(false);
          dispatch(contributionActions.setInviteCode(null));
          if (paymentDataResponse === 'test' && code) {
            isAlreadyPuchased(true);
            setPaymentData(reqData);
          } else if (paymentDataResponse === 'test' && !code) {
            setPaymentData(reqData);
          } else if (
            paymentDataResponse === 'Unable to book one to one time. You have unconfirmed session package payment'
          ) {
            setRefuseModalShowed(true);
          } else if (paymentDataResponse === 'Free session joined successfully.') {
            if (code || queryCode || isInviteToJoin != null) {
              dispatch(contributionActions.setInviteCode(null));

              if (contribution?.clientRedirectLink) {
                if (contribution?.clientRedirectLink.includes('Community')) {
                  history.push(`/contribution-view/${id}/community/social`);
                } else {
                  history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
                }
              } else {
                history.push(`/contribution-view/${contribution.id}/sessions`);
              }
            }
            return setShouldRedirect(true);
          } else if (paymentDataResponse?.includes('cs_') && !paymentDataResponse?.includes('checkout.stripe')) {
            return stripe.redirectToCheckout({
              sessionId: paymentDataResponse,
            });
          } else if (paymentDataResponse.includes('checkout.stripe')) {
            redirectTo(paymentDataResponse);
          } else {
            setPaymentData(paymentDataResponse);
          }
          setBookModalShowed(false);
          // handlePayment();
          handlePayment(paymentDataResponse, paymentDataResponse, code);
        } catch (e) {
          setErrorMessage(e?.response?.data);
          if (e?.response?.data === 'Unable to book one to one time. You have unconfirmed session package payment') {
            setRefuseModalShowed(true);
          }
        }
      }
    },
    [user, sessionPeriodSelected, id, request, handlePayment, isInviteToJoin, couponCode],
  );

  const proseedHandlePurchase = useCallback(() => {
    handleBookModalSubmit();
  }, [handleBookModalSubmit]);

  const handleBookModalCancel = useCallback(() => {
    setBookModalShowed(false);
  }, [setBookModalShowed]);

  const handlePurchaseProceedModalCancel = useCallback(
    (releasePaymentIntent = true) => {
      setPurchaseModalShowed(false);
      setProceedModalShowed(false);
      if (isPackage) {
        setIsPackage(false);
      }

      if (!isPackage && paymentData && paymentData.bookedTimeIds && paymentData.bookedTimeIds.length > 0) {
        if (releasePaymentIntent) {
          request('/api/purchase/cancelOneToOneReservation', 'POST', {
            contributionId: id,
            bookedTimeId: paymentData.bookedTimeIds[0],
            created: paymentData.created,
          }).catch(console.dir);
        }
      }
      setSlotModalShowed(true);
    },
    [id, paymentData, setPurchaseModalShowed, setProceedModalShowed, isPackage, setIsPackage, dispatch],
  );

  const handlePurchaseProceedModalSubmit = useCallback(() => {
    setPurchaseModalShowed(false);
    setProceedModalShowed(false);
    toggleSuccessPurchaseModal();
    if (isPackage) {
      setIsPackage(false);
    }
    setTimeout(async () => {
      const userIp = await getIpGlobal();
      dispatch(fetchClientContribution(id, userIp));
    }, 1500);
  }, [dispatch, isPackage, id]);

  const handleRefuseModal = useCallback(() => {
    setRefuseModalShowed(false);
  }, [setRefuseModalShowed]);

  const [selectedSessionTime, setSelectedSessionTime] = useState(null);

  const OnlyPackageBookingAllowedModalBody = useMemo(() => {
    return `${title} is available as a package of sessions. Please purchase a package prior to selecting your session time(s).`;
  }, [title]);

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));

  const closeModalHandler = useCallback(() => {
    setSlotModalShowed(false);
    setshowButtons(true);
    if (rescheduleSession) {
      dispatch(setEasyBookingThankYouData.setData({ ...formerBookingData }));
      dispatch(setEasyBookingData.setData({ ...formerBookingData }));
    } else {
      dispatch(setEasyBookingThankYouData.setData({ selectedSlot: null, selectedDate: selectedDate || moment() }));
      dispatch(setEasyBookingData.setData({ selectedSlot: null, selectedDate: selectedDate || moment() }));
    }
  }, []);

  useEffect(() => {
    dispatch(
      setEasyBookingData.setData({
        ...easyBookingData,
        selectedDate: selectedDate || moment(),
      }),
    );
  }, [selectedDate]);

  const onCalendarDrillDownHandler = useCallback(day => {
    const todayDate = moment(new Date()).startOf('day');
    const selectedDate = moment(day).startOf('day');
    const isPastDate = selectedDate.isBefore(todayDate);
    if (!isPastDate) {
      setSelectedDate(moment(day));
      dispatch(contributionActions.setClientCalendarDate(moment(day)));
      setSlotModalShowed(true);
    }
  }, []);
  const onCalendarEventSelectHandler = useCallback(event => {
    setSelectedDate(moment(moment(event.start)));

    setSlotModalShowed(true);
  }, []);
  const handleRedeem = () => {
    setCouponError(false);
    request(`/Coupons/ValidateByName/${coupon}/${contribution.id}/PerSession`, 'GET')
      .then(res => {
        if (res?.id === undefined) {
          setCouponError(true);
        }
        if (res?.percentAmount > 0 || res?.discountAmount > 0) {
          setCouponId(res.id);
          dispatch(contributionActions.setCouponCode(res.id));
          getOnToOnePaymentInfo(contribution.id, priceId, 'PerSession', res.id).then(res => {
            setPaymentDetails(res);
          });
        }
      })
      .catch(() => {
        setCouponError(true);
      });
  };
  const onSelectEvent = useCallback(
    async event => {
      if (isEmpty(user) && event) {
        setIsNotLoggedUser(true);
        setPurchaseModalShowed(true);
        dispatch(
          contributionActions.setPickAnotherTime({
            updatedTimeId: event.id,
            ...singleSessionInvite,
            ...event,
          }),
        );
      }
      const { isBooked, participantId, videoRoomInfo, start, bookedTimeId, offset } = event;
      const availableToReschedule = moment(start).isAfter(moment().add(24, 'h'));
      if (isCodeToJoin != null && isEmpty(user) === false) {
        let option = singleSessionInvite?.priceOption;
        let reqData;
        reqData = {
          contributionId: id,
          AvailabilityTimeId: event.id,
          offset: event.offset,
          accessCode: singleSessionInvite?.accessCode,
          paymentOption: 'Free',
          createSingleSession: true,
          couponId: couponCode,
          priceId: priceId,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          isGuest: getIsGuest(),
          firstName: getItem('guestFirstName'),
          lastName: getItem('guestLastName'),
          BookingDoneWithSecondaryAccount: getIsGuest(),
          HelpingMaterial: getItem('helpingMaterial'),
          isSecondaryExisting: user?.isSecondaryExisting,
          scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
        };
        let reqDataPaid = {
          contributionId: id,
          AvailabilityTimeId: event.id,
          offset: event.offset,
          createSingleSession: true,
          priceId: priceId,
          couponId: couponCode,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          isGuest: getIsGuest(),
          firstName: getItem('guestFirstName'),
          lastName: getItem('guestLastName'),
          BookingDoneWithSecondaryAccount: getIsGuest(),
          HelpingMaterial: getItem('helpingMaterial'),
          isSecondaryExisting: user?.isSecondaryExisting,
          scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
        };

        let reqDataCredit = {
          contributionId: id,
          AvailabilityTimeId: event.id,
          offset: event.offset,
          priceId: priceId,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          isGuest: getIsGuest(),
          firstName: getItem('guestFirstName'),
          lastName: getItem('guestLastName'),
          BookingDoneWithSecondaryAccount: getIsGuest(),
          HelpingMaterial: getItem('helpingMaterial'),
          isSecondaryExisting: user?.isSecondaryExisting,
          scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
        };
        await request(
          '/api/purchase/one-to-one',
          'POST',
          option === 'Credit' ? reqDataCredit : option === 'Free' || isInviteToJoin ? reqData : reqDataPaid,
        )
          .then(async res => {
            const userIP = await getIpGlobal();
            dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
            setShowModal(false);
            setRescheduleBookingmodal(false);
            dispatch(
              setEasyBookingThankYouData.setData({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mma')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mma')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
                helpingMaterial: getItem('helpingMaterial'),
              }),
            );
            dispatch(
              contributionActions.setRescheduleFormerSessionDetail({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
              }),
            );
            removeItem('helpingMaterial');
            removeItem('guestFirstName');
            removeItem('guestLastName');
            removeItem('existingUser');
            if (res?.bookedSessionNumbers || res?.sessionNumbers || res?.freeSessionNumbers) {
              window.location.replace(`/contribution-view/${id}/sessions`);
              // dispatch(contributionActions.setSingleCode(null));
            } else if (res?.includes('cs_') && !res?.includes('checkout.stripe')) {
              return stripe.redirectToCheckout({
                sessionId: res,
              });
            } else if (res.includes('checkout.stripe')) {
              redirectTo(res);
            } else if (res === 'Free session joined successfully.') {
              // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
              window.location.replace(`/contribution-view/${id}/sessions`);
            }
          })
          .catch(e => {
            if (e?.response?.data.includes('You are not booked for this session or this session is already booked.')) {
              setErrorModal(true);
            }
            setLoading(false);
            setErrorMessage(e?.response?.data);
            if (e?.response?.data === 'Unable to book one to one time. You have unconfirmed session package payment') {
              setRefuseModalShowed(true);
            }
          });
        setBookModalShowed(false);
        // dispatch(contributionActions.setSingleCode(null));
      }
      if (
        isCodeToJoin === null &&
        isEmpty(user) === false &&
        singleSessionInvite === null &&
        !pathname?.includes('sessions')
        // && singleSessionInvite != null
      ) {
        let reqData;
        reqData = {
          contributionId: id,
          AvailabilityTimeId: event.id,
          offset: event.offset,
          accessCode: singleSessionInvite?.accessCode,
          paymentOption: 'Free',
          createSingleSession: true,
          couponId: couponCode,
          priceId: priceId,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
        };
        let reqDataPaid = {
          contributionId: id,
          AvailabilityTimeId: event.id,
          offset: event.offset,
          createSingleSession: true,
          priceId: priceId,
          isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
          isPhoneNumberAdded: contribution.isPhoneNumberAdded,
          isCoachNumberAdded: contribution.isCoachNumberAdded,
          couponId: couponCode,
        };

        await request('/api/purchase/one-to-one', 'POST', isInviteToJoin ? reqData : reqDataPaid)
          .then(res => {
            dispatch(
              setEasyBookingThankYouData.setData({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mma')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mma')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
                helpingMaterial: getItem('helpingMaterial'),
              }),
            );
            dispatch(
              contributionActions.setRescheduleFormerSessionDetail({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
              }),
            );
            removeItem('helpingMaterial');
            removeItem('guestFirstName');
            removeItem('guestLastName');
            removeItem('existingUser');
            if (res?.bookedSessionNumbers || res?.sessionNumbers || res?.freeSessionNumbers) {
              window.location.replace(`/contribution-view/${id}/sessions`);
              // dispatch(contributionActions.setSingleCode(null));
            } else if (res?.includes('cs_') && !res?.includes('checkout.stripe')) {
              return stripe.redirectToCheckout({
                sessionId: res,
              });
            } else if (res.includes('checkout.stripe')) {
              redirectTo(res);
            } else if (res === 'Free session joined successfully.') {
              // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
              window.location.replace(`/contribution-view/${id}/sessions`);
            }
          })
          .catch(e => {
            if (e?.response?.data.includes('You are not booked for this session or this session is already booked.')) {
              setErrorModal(true);
            }
            setLoading(false);
            setErrorMessage(e?.response?.data);
            if (e?.response?.data === 'Unable to book one to one time. You have unconfirmed session package payment') {
              setRefuseModalShowed(true);
            }
          });
        setBookModalShowed(false);
        // dispatch(contributionActions.setSingleCode(null));
      }
      if (isCodeToJoin != null && isEmpty(user) && pickAnotherTime === null) {
        let option = singleSessionInvite?.priceOption;
        try {
          let reqData;
          reqData = {
            contributionId: id,
            AvailabilityTimeId: event.id,
            offset: event.offset,
            accessCode: singleSessionInvite?.accessCode,
            paymentOption: 'Free',
            createSingleSession: true,
            priceId: priceId,
            isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
            isPhoneNumberAdded: contribution.isPhoneNumberAdded,
            isCoachNumberAdded: contribution.isCoachNumberAdded,
            isGuest: getIsGuest(),
            firstName: getItem('guestFirstName'),
            lastName: getItem('guestLastName'),
            BookingDoneWithSecondaryAccount: getIsGuest(),
            HelpingMaterial: getItem('helpingMaterial'),
            isSecondaryExisting: user?.isSecondaryExisting,
            scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
          };
          let reqDataPaid = {
            contributionId: id,
            AvailabilityTimeId: event.id,
            offset: event.offset,
            priceId: priceId,
            createSingleSession: true,
            couponId: couponCode,
            isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
            isPhoneNumberAdded: contribution.isPhoneNumberAdded,
            isCoachNumberAdded: contribution.isCoachNumberAdded,
            isGuest: getIsGuest(),
            firstName: getItem('guestFirstName'),
            lastName: getItem('guestLastName'),
            BookingDoneWithSecondaryAccount: getIsGuest(),
            HelpingMaterial: getItem('helpingMaterial'),
            isSecondaryExisting: user?.isSecondaryExisting,
            scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
          };
          let reqDataCredit = {
            contributionId: id,
            AvailabilityTimeId: event.id,
            offset: event.offset,
            priceId: priceId,
            isPhoneNumberEnabledSession: contribution.isPhoneNumberAdded,
            isPhoneNumberAdded: contribution.isPhoneNumberAdded,
            isCoachNumberAdded: contribution.isCoachNumberAdded,
            isGuest: getIsGuest(),
            firstName: getItem('guestFirstName'),
            lastName: getItem('guestLastName'),
            BookingDoneWithSecondaryAccount: getIsGuest(),
            HelpingMaterial: getItem('helpingMaterial'),
            isSecondaryExisting: user?.isSecondaryExisting,
            scheduledClientName: getItem('existingUser') && clientData ? clientData.clientName : null,
          };
          request(
            '/api/purchase/one-to-one',
            'POST',
            option === 'Credit'
              ? reqDataCredit
              : option === 'Free' || isInviteToJoin || queryCode
              ? reqData
              : reqDataPaid,
          ).then(async res => {
            const userIP = await getIpGlobal();
            dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
            setShowModal(false);
            setRescheduleBookingmodal(false);
            dispatch(
              setEasyBookingThankYouData.setData({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mma')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mma')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
                helpingMaterial: getItem('helpingMaterial'),
              }),
            );
            dispatch(
              contributionActions.setRescheduleFormerSessionDetail({
                ...easyBookingData,
                timeSlot: `${moment(easyBookingData.selectedSlot?.start).format('h:mmA')} - ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('h:mmA')}, ${moment(easyBookingData.selectedSlot?.end).format('dddd')}, ${moment(
                  easyBookingData.selectedSlot?.end,
                ).format('MMMM DD, yyyy')}`,
              }),
            );
            const userIp = await getIpGlobal();
            dispatch(fetchClientContribution(id, userIp));
            if (res.includes('cs_') && res.includes('checkout.stripe') === false) {
              return stripe.redirectToCheckout({
                sessionId: res,
              });
            }
            if (res.includes('checkout.stripe')) {
              redirectTo(res);
            }
            if (res === 'Free session joined successfully.') {
              // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
              window.location.replace(`/contribution-view/${id}/sessions`);
            }
            if (res?.bookedSessionNumbers || res?.sessionNumbers || res?.freeSessionNumbers) {
              window.location.replace(`/contribution-view/${id}/sessions`);
              // dispatch(contributionActions.setSingleCode(null));
            }
            if (res?.bookedSessionNumbers || res?.sessionNumbers || res?.freeSessionNumbers) {
              window.location.replace(`/contribution-view/${id}/sessions`);
              // dispatch(contributionActions.setSingleCode(null));
            }
          });
          const userIP = await getIpGlobal();
          dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
          setBookModalShowed(false);
          setShowModal(false);
          setRescheduleBookingmodal(false);
          // dispatch(contributionActions.setSingleCode(null));
        } catch (e) {
          if (e?.response?.data.includes('You are not booked for this session or this session is already booked.')) {
            setErrorModal(true);
          }
          setLoading(false);
          setErrorMessage(e?.response?.data);
          if (e?.response?.data === 'Unable to book one to one time. You have unconfirmed session package payment') {
            setRefuseModalShowed(true);
          }
        }
      }
      if (rescheduleMode && availableToReschedule && !isBooked) {
        setRescheduleMode(false);
        setCalendarRescheduleModal(true);
        setSlotModalShowed(false);
        setRescheduleTo(event);
        return;
      }
      if (!isBooked) {
        setSlotModalShowed(false);
        const isSessionPurchaseAllowed =
          paymentInfo &&
          paymentInfo.paymentOptions &&
          (paymentInfo.paymentOptions.includes('PerSession') ||
            paymentInfo.paymentOptions.includes('MonthlySessionSubscription'));
        const isPackageSessionsAvailable =
          packagePurchases && packagePurchases[0] && packagePurchases[0].freeSessionNumbers;

        if (isSessionPurchaseAllowed || isPackageSessionsAvailable) {
          // handleBookModalOpen(event);
          setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
          handleBookModalSubmit(isInviteToJoin || queryCode);
        } else {
          if (isInviteToJoin || queryCode) {
            setSessionPeriodSelected(Object.assign(sessionPeriodSelected, event));
            handleBookModalSubmit(isInviteToJoin || queryCode);
            // setIsOnlyPackageBookingAllowedModalShowed(false);
          }
          //  else {
          //   setIsOnlyPackageBookingAllowedModalShowed(true);
          // }
        }
        return;
      }
      const isBookedByMe = participantId === user.id;
      const isLaunched = !!videoRoomInfo && videoRoomInfo.isRunning;
      if (!isBookedByMe || !isLaunched) {
        setRefuseModalShowed(true);

        return;
      }

      setSelectedSessionTime({
        id: bookedTimeId,
        title: event.title,
        start: event.start,
        end: event.end,
        serviceProviderName: event.serviceProviderName,
        offset,
      });
    },
    [paymentInfo, packagePurchases, rescheduleMode, singleSessionInvite, pickAnotherTime, couponCode],
  );
  const shouldHidePriceSection = isInviteToJoin || queryCode;
  const [selectedFilterTab, setSelectedFilterTab] = useState(FILTER_TABS_OPTIONS[0].value);
  const colorToUse = determineColorToUse(isMasterCalendar ? null : contribution);
  const TextColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';

  const showingPricingOption =
    !(shouldHidePriceSection && shouldHidePriceSection != 'clientpreview') &&
    ((anyPaymentOptionEnabled && isModal === false) ||
      (isEmpty(clientPackage) && paymentInfo.paymentOptions.includes('PerSession')) ||
      (freeSessionNumbers === 0 && paymentInfo.paymentOptions.includes('PerSession'))) &&
    !isPriceHidden;
  const changeInvitedView = () => {
    setShowClientInvitedView(!showClientInvitedView);
  };
  if (isCoach) {
    return (
      <div
        className="main-container-session-8-for-card"
        style={{
          pointerEvents: applicationRequiredButNotApproved ? 'none' : '',
          opacity: applicationRequiredButNotApproved ? '0.5' : '1',
        }}
      >
        {loading && <Loader />}
        <Grid md={12} xs={12} className="">
          <div id="contribution-view-calendar" className="contribution-view-calendar" ref={calendarEl}>
            {!showClientInvitedView && isModal && !isSlotModalOpen && showButtons && (
              <StyledTitle
                isSlotModalOpen={isSlotModalOpen}
                {...{ color: colorToUse.AccentColorCode, textAlign: isModal ? 'left' : 'center' }}
              >
                {' Select Date and Time'}
              </StyledTitle>
            )}
            {isSlotModalOpen && !showClientInvitedView && isModal && showButtons && (
              <StyledTitle
                isSlotModalOpen={isSlotModalOpen}
                {...{ color: colorToUse.AccentColorCode, textAlign: isModal ? 'left' : 'center' }}
              >
                {' Select Your Session Time'}
              </StyledTitle>
            )}
            {showButtons && (
              <StyledToggleButtonGroupContainer isSlotModalOpen={isSlotModalOpen}>
                <StyledToggleButtonGroup
                  color="primary"
                  value={toggleAvailabilitySlots}
                  exclusive
                  onChange={(e, newVal) => {
                    if (newVal !== null) {
                      setToggleAvailabilitySlots(newVal);
                      dispatch(contributionActions.setMyAvailabilityTime(newVal));
                    }
                  }}
                >
                  <StyledToggleButton value background={colorToUse?.AccentColorCode} textColor={newThemedTextColor}>
                    <span>My Availability</span>
                    <Tooltip
                      title="Only the specific time slots you’ve chosen for your one-on-one availability will be displayed here. If you have the 'Prevent Double Booking' setting enabled, these slots will automatically account for any conflicts with other appointments."
                      arrow
                    >
                      <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
                    </Tooltip>
                  </StyledToggleButton>
                  <StyledToggleButton
                    value={false}
                    background={colorToUse?.AccentColorCode}
                    textColor={newThemedTextColor}
                  >
                    <span>All Times</span>
                    <Tooltip
                      title="If you need to make an exception to the availability you’ve set for this service and want to override those settings, use this feature to pick any time on your calendar."
                      arrow
                    >
                      <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
                    </Tooltip>
                  </StyledToggleButton>
                </StyledToggleButtonGroup>
              </StyledToggleButtonGroupContainer>
            )}
            {!isSlotModalOpen && !isBookModalShowed && !isPurchaseModalShowed && (
              <>
                {!showClientInvitedView && (
                  <CalendarContributionViewForPaymentCard
                    isCodeToJoin={isCodeToJoin}
                    events={events}
                    onDrillDown={onCalendarDrillDownHandler}
                    onSelectEvent={onCalendarEventSelectHandler}
                    dateToShow={dateToShow}
                    calendarRef={calendarRef}
                    rescheduleMode={rescheduleMode}
                    timeZoneId={contribution.timeZoneId}
                    selectedTimeZone={selectedTimeZone}
                    selectTimeZone={setSelectedTimeZone}
                    packageSessionBooking={packageSessionBooking}
                    setShowModal={setShowModal}
                    isModal={isModal}
                  />
                )}
              </>
            )}
          </div>
          {isSlotModalOpen && !showClientInvitedView && (
            <SlotsModalForPaymentCard
              contribution={contribution}
              onClose={closeModalHandler}
              selectedDate={selectedDate}
              contributionId={id}
              contributionTimeZoneId={contribution.timeZoneId}
              title={title}
              serviceProviderName={serviceProviderName}
              duration={duration}
              onSubmit={onSelectEvent}
              colorToUse={colorToUse}
              easyBooking={easyBooking}
              setshowButtons={setshowButtons}
              selectedTimeZone={selectedTimeZone => {
                console.log('selected Timezone', selectedTimeZone);
                setSelectedTimeZone(selectedTimeZone);
              }}
              anyPaymentOptionEnabled={anyPaymentOptionEnabled}
              activeTemplate={activeTemplate}
              shouldHidePriceSection={shouldHidePriceSection}
              userSelectedTimeZone={selectedTimeZone}
              rescheduleSession={rescheduleSession}
              setRescheduleSession={setRescheduleSession}
              packageSessionBooking={packageSessionBooking}
              setShowModal={setShowModal}
              setRescheduleBookingmodal={setRescheduleBookingmodal}
              isModal={isModal}
            />
          )}
        </Grid>
      </div>
    );
  }
  console.log('applicationRequiredButNotApproved', applicationRequiredButNotApproved);

  return (
    <div
      className="main-container-session-8-for-card"
      style={{
        pointerEvents:
          applicationRequiredButNotApproved || (!availableToRescheduleGuest && query.code === 'GuestUserReschedule')
            ? 'none'
            : '',
        opacity:
          applicationRequiredButNotApproved || (!availableToRescheduleGuest && query.code === 'GuestUserReschedule')
            ? '0.5'
            : '1',
      }}
    >
      {loading && <Loader />}
      <Grid md={12} xs={12} className="">
        <div id="contribution-view-calendar" className="contribution-view-calendar" ref={calendarEl}>
          {(pathname.includes('session') || !isSlotModalOpen) &&
            !isBookModalShowed &&
            !isPurchaseModalShowed &&
            rescheduleSession === false && (
              <>
                {!(shouldHidePriceSection && shouldHidePriceSection != 'clientpreview') &&
                  ((anyPaymentOptionEnabled && isModal === false) ||
                    (isEmpty(clientPackage) && paymentInfo.paymentOptions.includes('PerSession')) ||
                    (freeSessionNumbers === 0 && paymentInfo.paymentOptions.includes('PerSession'))) &&
                  !isPriceHidden &&
                  !showClientInvitedView && (
                    <>
                      <PriceSection mobileView={mobileView}>
                        <PriceTagContainer
                          colorToUse={colorToUse}
                          style={pathname.includes('sessions') ? { marginTop: 0 } : {}}
                        >
                          <PriceTag style={{ color: TextColor }}>Session Price</PriceTag>
                        </PriceTagContainer>
                        <PriceValue style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
                          {singleSessionData?.defaultSymbol}
                          {paymentDetails
                            ? formatMoney(paymentDetails?.price)
                            : formatMoney(singleSessionData?.summary?.price)}{' '}
                          <span style={{ fontWeight: '300' }}> {singleSessionData?.defaultCurrency.toUpperCase()}</span>
                        </PriceValue>
                      </PriceSection>
                      {isModal === true && isSlotModalOpen && singleSessionData.summary?.platformFee > 0 && (
                        <div style={{ padding: '0px 20px' }}>
                          <PriceContainer className="price-container">
                            <Grid container justify="space-between">
                              <Grid item>
                                <Typography
                                  style={{
                                    color: contribution?.isDarkModeEnabled ? 'white' : 'black',
                                    lineHeight: 2.1,
                                  }}
                                >
                                  Processing Fee:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="h6"
                                  style={{
                                    color: contribution?.isDarkModeEnabled ? 'white' : 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {singleSessionData?.defaultSymbol}
                                  {formatMoney(singleSessionData.summary?.platformFee)}{' '}
                                  <span style={{ fontWeight: '300' }}>
                                    {' '}
                                    {singleSessionData?.defaultCurrency.toUpperCase()}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </PriceContainer>

                          <PriceContainer className="price-container">
                            <Grid container justify="space-between">
                              <Grid item>
                                <Typography
                                  style={{
                                    color: contribution?.isDarkModeEnabled ? 'white' : 'black',
                                    lineHeight: 2.1,
                                  }}
                                >
                                  Due Now:
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  variant="h6"
                                  style={{
                                    color: contribution?.isDarkModeEnabled ? 'white' : 'black',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {singleSessionData?.defaultSymbol}
                                  {formatMoney(singleSessionData.summary?.dueNowWithCouponDiscountAmount)}{' '}
                                  <span style={{ fontWeight: '300' }}>
                                    {' '}
                                    {singleSessionData?.defaultCurrency.toUpperCase()}
                                  </span>
                                </Typography>
                              </Grid>
                            </Grid>
                          </PriceContainer>
                        </div>
                      )}
                      <hr
                        style={{
                          borderColor: contribution?.isDarkModeEnabled ? 'white' : '#F5F5F5',
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    </>
                  )}
              </>
            )}
          {contribution.isCouponAvailable &&
          isInviteToJoin === null &&
          !isEmpty(user) &&
          !isSlotModalOpen &&
          !isBookModalShowed &&
          !isPurchaseModalShowed &&
          !showClientInvitedView &&
          !isModal &&
          !rescheduleSession ? (
            <Grid item md={12} xs={12} style={{ padding: '5px 15px' }}>
              <CustomApplyCoupon
                handleChange={() => {}}
                couponField={true}
                setCoupon={setCoupon}
                activeTemplate={contribution.activeTemplate}
                colorToUse={colorToUse}
                handleRedeem={handleRedeem}
                couponError={couponError}
              />
            </Grid>
          ) : (
            contribution.isCouponAvailable &&
            isInviteToJoin === null &&
            !isEmpty(user) &&
            isSlotModalOpen &&
            !isBookModalShowed &&
            !isPurchaseModalShowed &&
            !showClientInvitedView &&
            isModal &&
            contribution.isPurchased === true &&
            !rescheduleSession &&
            totalSessionNumbers === totalBookedSessionNumbers && (
              <Grid item md={12} xs={12} style={{ padding: '5px 15px' }}>
                <CustomApplyCoupon
                  handleChange={() => {}}
                  couponField={true}
                  setCoupon={setCoupon}
                  activeTemplate={contribution.activeTemplate}
                  colorToUse={colorToUse}
                  handleRedeem={handleRedeem}
                  couponError={couponError}
                />
              </Grid>
            )
          )}

          {!isSlotModalOpen && !isBookModalShowed && !isPurchaseModalShowed && (
            <>
              {!showClientInvitedView && (
                <CalendarContributionViewForPaymentCard
                  isCodeToJoin={isCodeToJoin}
                  events={events}
                  onDrillDown={onCalendarDrillDownHandler}
                  onSelectEvent={onCalendarEventSelectHandler}
                  dateToShow={dateToShow}
                  calendarRef={calendarRef}
                  rescheduleMode={rescheduleMode}
                  timeZoneId={contribution.timeZoneId}
                  selectedTimeZone={selectedTimeZone}
                  selectTimeZone={setSelectedTimeZone}
                  packageSessionBooking={packageSessionBooking}
                  setShowModal={setShowModal}
                  isModal={isModal}
                  showingPricingOption={showingPricingOption}
                />
              )}
            </>
          )}
        </div>
        {isSlotModalOpen && !showClientInvitedView && (
          <SlotsModalForPaymentCard
            contribution={contribution}
            onClose={closeModalHandler}
            selectedDate={selectedDate}
            contributionId={id}
            contributionTimeZoneId={contribution.timeZoneId}
            title={title}
            serviceProviderName={serviceProviderName}
            duration={duration}
            onSubmit={onSelectEvent}
            colorToUse={colorToUse}
            easyBooking={easyBooking}
            selectedTimeZone={selectedTimeZone => {
              console.log('selected Timezone', selectedTimeZone);
              setSelectedTimeZone(selectedTimeZone);
            }}
            anyPaymentOptionEnabled={anyPaymentOptionEnabled}
            activeTemplate={activeTemplate}
            shouldHidePriceSection={shouldHidePriceSection}
            userSelectedTimeZone={selectedTimeZone}
            rescheduleSession={rescheduleSession}
            setRescheduleSession={setRescheduleSession}
            packageSessionBooking={packageSessionBooking}
            setShowModal={setShowModal}
            setRescheduleBookingmodal={setRescheduleBookingmodal}
            isModal={isModal}
          />
        )}
        {isBookModalShowed && !showClientInvitedView && (
          <BookModalContainerForPaymentCard
            handleModalCancel={handleBookModalCancel}
            handleModalSubmit={handleBookModalSubmit}
            sessionInfo={sessionPeriodSelected}
            timeZoneId={contribution.timeZoneId}
          />
        )}
        {showClientInvitedView && (
          <PurchaseClientInvitationFrom
            colorToUse={colorToUse}
            singleSessionData={singleSessionInvite}
            formatMoney={formatMoney}
            toRem={toRem}
            TextColor={TextColor}
            changeInvitedView={changeInvitedView}
          />
        )}
        {(isPurchaseModalShowed || showClientInvitedView) && (
          <PurchaseModalForPaymentCard
            onClose={handlePurchaseProceedModalCancel}
            onSubmit={handlePurchaseProceedModalSubmit}
            isPackage={isPackage}
            oneToOne
            paymentDataOneToOne={paymentData}
            proseedHandlePurchase={isNotLoggedUser && proseedHandlePurchase}
            submitNow={isSubmitPurchaseModalNow}
            selectedTimeZone={selectedTimeZone}
            selectedDate={selectedSlot}
            isElectronicSignatureActive={isElectronicSignatureActive}
            sessionInfo={sessionPeriodSelected}
            timeZoneId={contribution?.timeZoneId}
            isCouponAvailable={contribution?.isCouponAvailable}
            showClientInvitedView={showClientInvitedView}
            isCodeToJoin={isCodeToJoin}
            singleSessionInvite={singleSessionInvite}
            setfreeAccessCode={setfreeAccessCode}
          />
        )}

        {isProceedModalShowed && (
          <ProceedModal
            isOpen={isProceedModalShowed}
            onClose={handlePurchaseProceedModalCancel}
            onSubmit={handlePurchaseProceedModalSubmit}
          />
        )}

        {isRefuseModalShowed && (
          <RefuseModal
            isOpen={isRefuseModalShowed}
            onClose={handleRefuseModal}
            onSubmit={handleRefuseModal}
            message={errorMessage}
          />
        )}

        {isOnlyPackageBookingAllowedModalShowed && (
          <Modal
            hiddenCancel
            isOpen={isOnlyPackageBookingAllowedModalShowed}
            onCancel={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
            onSubmit={() => setIsOnlyPackageBookingAllowedModalShowed(false)}
            title="Please purchase package"
          >
            <BodyText>{OnlyPackageBookingAllowedModalBody}</BodyText>
          </Modal>
        )}

        {selectedSessionTime && (
          <JoinSessionTimeModal
            user={user}
            contribution={contribution}
            sessionTime={selectedSessionTime}
            open={!!selectedSessionTime}
            onOpenChange={open => setSelectedSessionTime(session => (open ? session : null))}
          />
        )}

        {isOpenSuccessPurchaseModal && (
          <SuccessfulPurchaseModal
            isOpen={isOpenSuccessPurchaseModal}
            handleOpen={toggleSuccessPurchaseModal}
            handleClose={toggleSuccessPurchaseModal}
          />
        )}
        {isCalendarRescheduleModalOpen && (
          <Modal
            isOpen={isCalendarRescheduleModalOpen}
            onCancel={handleCalendarRescheduleCancel}
            onSubmit={handleCalendarRescheduleSubmit}
            title="Confirm your new session time"
            hiddenCancel
            submitTitle="Confirm Reschedule"
          >
            <BodyText>
              By clicking Confirm, you will be updating the session time with {serviceProviderName}. It’s your
              responsibility to confirm with {serviceProviderName} that this new time also works for them.
            </BodyText>
            <StyledTextField
              placeholder="Optional. What is the reason for rescheduling? This information will be shared with your coach"
              multiline
              rowsMax={6}
              value={messageText}
              onChange={e => setMessageText(e.target.value)}
            />
          </Modal>
        )}
        {calendarRescheduleModalStatus && (
          <Modal
            isOpen={calendarRescheduleModalStatus}
            onCancel={() => {
              setCalendarRescheduleModalStatus(null);
            }}
            onSubmit={() => {
              setCalendarRescheduleModalStatus(null);
            }}
            title="Reschedule status"
            hiddenCancel
            submitTitle="Ok"
          >
            <BodyText>
              Your new session time with {serviceProviderName} on{' '}
              {moment(calendarRescheduleModalStatus?.start).format('LL [at] LT z')}
              {TIMEZONES[user?.timeZoneId]} is confirmed.
            </BodyText>
          </Modal>
        )}
        {alreadyPuchased && (
          <Modal
            isOpen
            onCancel={() => {
              isAlreadyPuchased(false);
            }}
            onSubmit={() => {
              isAlreadyPuchased(false);
            }}
            title="Purchased service"
            hiddenCancel
            // PrimaryColor={PrimaryColor}
          >
            <LabelText>You have already purchased this service </LabelText>
          </Modal>
        )}
        {slotExpired && (
          <Modal
            title="Time Slot Expired"
            isOpen={true}
            onCloseText="OK"
            hiddenCancel
            onSubmit={toggleSlotPopup}
            onCancel={toggleSlotPopup}
            disableFooter
          >
            <div style={{ fontSize: '16px', marginTop: '13px', fontWeight: 450, fontFamily: 'Avenir' }}>
              This session is already booked or link has been expired.
            </div>
          </Modal>
        )}
        {errorModal && (
          <Modal
            isOpen={errorModal}
            onCancel={() => {
              setErrorModal(false);
            }}
            onSubmit={() => {
              setErrorModal(false);
            }}
            title=""
            hiddenCancel
            submitTitle="Ok"
          >
            <LabelText style={{ color: contribution?.isDarkModeEnabled ? 'white' : 'black' }}>
              You are not booked for this session or this session is already booked.
            </LabelText>
          </Modal>
        )}
      </Grid>
    </div>
  );
};

ClientOneToOneSessionsForPaymentCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    isSecondaryExisting: PropTypes.bool,
    isSecondaryAccount: PropTypes.bool,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    availabilityTimes: PropTypes.arrayOf(PropTypes.object),
    durations: PropTypes.arrayOf(PropTypes.number),
    serviceProviderName: PropTypes.string,
    purchaseStatus: PropTypes.string.isRequired,
  }).isRequired,
  rescheduleChosenSession: PropTypes.func.isRequired,
  contributionTimes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
  isCustomBrandingColorsActive: contributions?.activeContribution?.isCustomBrandingColorsActive,
  isBrandingColor: contributions?.activeContribution?.brandingColors,
});

const actions = {
  rescheduleChosenSession: rescheduleSessionForClient,
};

export default connect(mapStateToProps, actions)(ClientOneToOneSessionsForPaymentCard);
