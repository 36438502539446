import React, { Children, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import { useScreenView } from 'hooks';
import { useSelector, useDispatch, connect } from 'react-redux';
import styled from 'styled-components';
import Loader from 'components/UI/Loader';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarView.scss';
import { getThemedColors } from 'services/contributions.service';
import { SvgIcon, Select as Select2, Tooltip } from '@material-ui/core';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';

const localizer = momentLocalizer(moment);
const formats = {
  weekdayFormat: (date, culture, localizer1) => localizer1.format(date, 'dd', culture),
};
const allViews = [Views.MONTH];
const StyledTitle = styled.div`
  color: ${({ color }) => color || 'var(--Cohere-Primary-Blue, #215C73)'};
  font-style: normal;
  font-family: Brandon Text;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  text-align: left;
`;
const StyledTimezoneDropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  margin-top: 1.25rem;
`;
const StyledToggleButtonGroupContainer = styled.div`
  display: block;
  text-align: center;
`;

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  &.MuiToggleButtonGroup-root {
    border-radius: 6px;
  }
`;

const StyledToggleButton = styled(ToggleButton)`
  width: 162px;
  align-items: center;

  &.MuiToggleButton-root {
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 24.5px;
    letter-spacing: 0.4px;
    word-wrap: break-word;
    padding-top: 6.1px;
    padding-bottom: 7.4px;
    padding-left: 13.92px;
    padding-right: 13.93px;
    color: #0000008a !important;
    background-color: #dfe3e4 !important;
  }
  &.Mui-selected {
    color: white !important;
    ${({ background }) => background && `background-color: ${background} !important;`}
  }

  svg {
    height: 20px;
  }
`;
const StyledSelectTz = styled(Select2)`
  position: relative;
  border-radius: 0.375rem;
  border: 1px solid var(--Cohere-Greys-Grey-Tint, #dfe3e4);
  .MuiSelect-select {
    padding: 0.63rem 0.95rem;
  }

  .MuiSelect-icon {
    color: ${({ iconColor }) => iconColor};
  }
`;

const CalendarView = ({
  availableTimes,
  onSelectEvent,
  onDrillDown,
  calendarRef,
  loadingTimes,
  colorToUse,
  contribution,
  selectedTimeZone,
  selectTimeZone,
  scheduleData,
  fromMasterCalendar,
  isDarkModeEnabled,
  toggleAvailabilitySlots,
  setToggleAvailabilitySlots,
  isLoading = false,
}) => {
  const { mobileView } = useScreenView();
  const currentContribution = useContribution();
  const [showLoader, setShowLoader] = useState(true);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { newThemedTextColor, themedBackgroundColor, newThemedBackgroundColor } = getThemedColors(
    fromMasterCalendar ? null : currentContribution,
  );

  useEffect(() => {
    if (availableTimes.length > 0) {
      setShowLoader(false);
    } else {
      setTimeout(() => {
        setShowLoader(false);
      }, 3000);
    }
  }, [availableTimes]);

  const CustomToolbarCalendly = toolbar => {
    const goToBack = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      toolbar.onNavigate('prev');
    };

    const goToNext = () => {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      toolbar.onNavigate('next');
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.date.setMonth(now.getMonth());
      toolbar.date.setYear(now.getFullYear());
      toolbar.onNavigate('current');
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span style={{ fontFamily: 'Brandon Text', fontSize: '20px' }}>
          {date.format('MMMM')}
          <span> {date.format('YYYY')}</span>
        </span>
      );
    };

    return (
      <div className="toolbar-container calendly">
        <div className="navigation-buttons">
          <div>
            <SvgIcon onClick={goToBack} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
          <button
            type="button"
            className="btn-current"
            style={{
              backgroundColor: isDarkModeEnabled ? '#252728' : 'white',
              color: newThemedTextColor,
            }}
            onClick={goToCurrent}
          >
            {label()}
          </button>
          <div>
            <SvgIcon onClick={goToNext} style={{ width: '29px', height: '29px' }} viewBox="0 0 29 29">
              <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                <path
                  d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                  fill={newThemedTextColor}
                />
              </svg>
            </SvgIcon>
          </div>
        </div>
      </div>
    );
  };

  const ColoredDateCellWrapper = ({ children, value }) => {
    const selectedDate = new Date(scheduleData?.selectedDate);
    const momentSelected = moment(selectedDate).format('MMM Do YY');
    const today = new Date();
    const momentValue = moment(value).format('MMM Do YY');
    const momentToday = moment(today).format('MMM Do YY');

    const eventDates = [];
    for (let availableTime of availableTimes) {
      const day = moment(availableTime).format('MMM Do YY');
      if (!eventDates.includes(day)) eventDates.push(day);
    }
    let bgColor = !fromMasterCalendar && themedBackgroundColor;
    let opacity = '100%';
    if (eventDates.includes(momentValue)) {
      bgColor = colorToUse?.AccentColorCode;
      opacity = '30%';
    }
    if (momentValue === momentToday && eventDates.includes(momentToday)) {
      bgColor = colorToUse?.AccentColorCode;
      opacity = '30%';
    } else if (momentValue === momentToday && eventDates.includes(momentToday) === false) {
      bgColor = themedBackgroundColor;
      opacity = '100%';
    }
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        border: 'none',
        borderRadius: '25px',
        maxWidth: '33px',
        maxHeight: '33px',
        backgroundColor: bgColor,
        fontWeight: '900',
        opacity,
      },
    });
  };

  return (
    <div
      className="calendar-container-custom"
      style={{ color: newThemedTextColor, padding: mobileView ? '5px' : '0px 0px 16px 5px' }}
      ref={calendarRef}
    >
      <div
        className={`calendar-container-custom ${isDarkModeEnabled ? 'cohere-dark-mode' : ''}`}
        style={{ color: newThemedTextColor, padding: mobileView ? '5px' : '0px 0px 16px 5px' }}
      >
        <p className="booking-title" style={{ color: colorToUse?.AccentColorCode, marginBottom: '10px' }}>
          Select Date and Time
        </p>
        <StyledToggleButtonGroupContainer>
          <StyledToggleButtonGroup
            color="primary"
            value={toggleAvailabilitySlots}
            exclusive
            onChange={(e, newVal) => {
              if (newVal !== null) setToggleAvailabilitySlots(newVal);
            }}
          >
            <StyledToggleButton value background={colorToUse?.AccentColorCode} textColor={newThemedTextColor}>
              <span>My Availability</span>
              <Tooltip
                title="Only the specific time slots you’ve chosen for your one-on-one availability will be displayed here. If you have the 'Prevent Double Booking' setting enabled, these slots will automatically account for any conflicts with other appointments."
                arrow
              >
                <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
              </Tooltip>
            </StyledToggleButton>
            <StyledToggleButton value={false} background={colorToUse?.AccentColorCode} textColor={newThemedTextColor}>
              <span>All Times</span>
              <Tooltip
                title="If you need to make an exception to the availability you’ve set for this service and want to override those settings, use this feature to pick any time on your calendar."
                arrow
              >
                <InfoIcon style={{ marginLeft: '10px' }} htmlColor="" />
              </Tooltip>
            </StyledToggleButton>
          </StyledToggleButtonGroup>
        </StyledToggleButtonGroupContainer>
        <StyledTimezoneDropdownContainer>
          <StyledTitle {...{ color: colorToUse?.AccentColorCode }}>Time Zone</StyledTitle>
          <StyledSelectTz
            required
            native
            iconColor={isDarkModeEnabled ? 'white' : 'black'}
            onChange={e => {
              selectTimeZone(e.target.value);
            }}
            disableUnderline
            style={{
              backgroundColor: !fromMasterCalendar && newThemedBackgroundColor,
              color: !fromMasterCalendar && newThemedTextColor,
            }}
            value={selectedTimeZone}
          >
            <option
              aria-label="Time Zone"
              value={null}
              disabled
              style={{
                backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                color: isDarkModeEnabled ? 'white' : 'black',
              }}
            >
              Select Timezone
            </option>
            {timeZones?.length > 0 &&
              timeZones.map(timeZone => (
                <option
                  style={{
                    backgroundColor: isDarkModeEnabled ? '#696969' : 'white',
                    color: isDarkModeEnabled ? 'white' : 'black',
                  }}
                  value={timeZone?.countryName}
                >
                  {timeZone?.name}
                </option>
              ))}
          </StyledSelectTz>
        </StyledTimezoneDropdownContainer>
        {(loadingTimes || isLoading || availableTimes?.length === 0 || showLoader) && <Loader />}
        <BigCalendar
          className={`custom-calendar ${isDarkModeEnabled ? 'dark-theme' : ''}`}
          style={{ color: 'black', position: 'relative', marginTop: '10px' }}
          localizer={localizer}
          events={[]}
          views={allViews}
          defaultView={Views.MONTH}
          formats={formats}
          components={{
            toolbar: CustomToolbarCalendly,
            dateCellWrapper: ColoredDateCellWrapper,
          }}
          step={30}
          showMultiDayTimes
          titleAccessor={event => `${event.title.substring(0, 15)}...`}
          defaultDate={new Date(scheduleData?.selectedDate)}
          date={new Date(scheduleData?.selectedDate)}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onDrillDown={onDrillDown}
        />
      </div>
    </div>
  );
};

CalendarView.propTypes = {
  availableTimes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectEvent: PropTypes.func.isRequired,
  calendarRef: PropTypes.shape({ current: PropTypes.any }),
  loadingTimes: PropTypes.bool,
  timeZoneId: PropTypes.string.isRequired,
  scheduleData: PropTypes.shape({
    selectedDate: PropTypes.string.isRequired,
  }).isRequired,
  isDarkModeEnabled: PropTypes.bool.isRequired,
  colorToUse: PropTypes.shape({
    PrimaryColorCode: PropTypes.string.isRequired,
    AccentColorCode: PropTypes.string.isRequired,
  }).isRequired,
};

CalendarView.defaultProps = {
  calendarRef: null,
  loadingTimes: false,
};

const mapStateToProps = ({ contributions }) => ({
  loadingTimes: contributions?.loadingTimes,
  contribution: contributions,
});

export default connect(mapStateToProps, null)(memo(CalendarView));
