import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControlLabel, useTheme, useMediaQuery } from '@material-ui/core';
import Item from 'components/UI/SidebarDropDown/List/Item';
import Button from 'components/FormUI/Button';
import styled from 'styled-components';
import primaryPlusIcon from '../../../assets/primary-plus-icon.png';
import settingIcon from '../../../assets/setting-icon.png';
import crossIcon from '../../../assets/cross-icon.png';
import Checkbox from '@material-ui/core/Checkbox';
import classes from './AttachedContribution.module.scss';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import {
  addToCalendarAction,
  extraCalendarSettingAction,
  previewMasterCalendarEventAction,
  removeMasterCalendarEmailAction,
} from 'actions/masterCalendarSidebar.actions';
import { getCalendarColor } from 'utils/calendar';
import { getOneToOneContributionsWithBookedSession } from 'services/contributions.service';
import CircleSvg from 'pages/MasterCalendarPage/components/CircleIcon';
import useRouter from 'hooks/useRouter';
import { setMasterCalendarArray } from 'actions/contributions';

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
  opacity: 45%;
  font-size: 18px !important;
`;
const StyledOutlinedPrimaryButton = styled(Button)`
  background-color: white;
  color: #215c73;
  padding: 12px 16px 12px 0px;
  font-size: 14px;
  min-width: 182px;
  display: flex;
  gap: 8px;
  white-space: nowrap;
  align-items: center;
  &:hover {
    background-color: white;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  width: 100%;
  margin-bottom: 0px;
`;

const AttachedContributions = () => {
  const [fetchedContributions, setfetchedContributions] = useState([]);
  const { history } = useRouter();
  const dispatch = useDispatch();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  useEffect(() => {
    getOneToOneContributionsWithBookedSession().then(res => {
      setfetchedContributions(res);
    });
  }, []);

  function truncateString(str) {
    if (mobileView) {
      if (str.length > 30) {
        return str.slice(0, 30) + '...';
      }
      return str;
    } else {
      if (str.length > 25) {
        return str.slice(0, 20) + '...';
      }
      return str;
    }
  }

  return (
    <>
      <Box className={classes.container} paddingTop="20px">
        <Item
          title="My Services"
          id="coach-connected-calendars-list"
          isList
          titleFontWeight={700}
          listPadding="0px 0px 20px 0px"
          defaultExpanded={true}
        >
          {!!fetchedContributions?.length &&
            fetchedContributions?.map(calendar => {
              const colors = getCalendarColor(calendar?.emailAddress);
              return (
                <div className={classes.form_control_container}>
                  <div
                    // onClick={() => {
                    //   dispatch(setMasterCalendarArray([{ label: calendar.name, id: calendar.id }]));
                    // }}
                    className={classes.form_control_left_block}
                  >
                    <CircleSvg fill={calendar?.colorCode} isContributions={true} />
                    <Tooltip title={calendar.name} arrow>
                      <div style={{ fontFamily: 'Avenir', fontSize: '14px', fontWeight: 400 }}>
                        {' '}
                        {truncateString(calendar.name)}
                      </div>
                    </Tooltip>
                  </div>
                  <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
                    <div
                      onClick={() => {
                        history.push(`/contribution-view/${calendar.id}/sessions`);
                      }}
                      className={classes.setting_icon}
                    >
                      <StyledOpenInNewOutlinedIcon />
                    </div>
                  </Box>
                </div>
              );
            })}
        </Item>
      </Box>
    </>
  );
};

export default AttachedContributions;
