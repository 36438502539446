import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import { colors, toRem } from '../../../../../utils/styles';
import Button from '../../../../../components/FormUI/Button';

export const StyledContainer = styled(Grid)`
  border-radius: 14px;
  max-width: 530px !important;
  padding: 16px 0px 0px 0px;
  @media screen and (max-width: 767px) {
    max-width: 100% !important;
  }

  ${({ isUnifiedCommunity }) =>
    isUnifiedCommunity &&
    `
  border-radius: 5px;
  width: 100%;
  // box-shadow: 0 0px 10px 0px rgba(64, 60, 67, 0.16);
  `}
`;

export const StyledMetaData = styled(Grid).attrs({
  direction: 'row',
})`
  justify-content: space-between;
  margin-top: 10px;
  ${({ isComment }) => {
    return isComment && `font-size: .7rem;`;
  }}
  ${({ isComment }) => {
    return isComment && `margin-top: 0px;`;
  }}
  ${({ marginLeft }) => {
    return marginLeft && marginLeft > 0 && `margin-left: ${toRem(marginLeft)};`;
  }};
`;
export const StyledName = styled.div`
  font-size: 16px;
  font-weight: 800;
  display: flex;
  align-items: flex-end;
`;
export const StyledCommentName = styled.span`
  font-size: 16px;
  font-weight: 900;
`;
export const StyledDate = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 350;
  opacity: 70%;
`;
export const StyledClickDropDown = styled.span`
  max-width: 10%;
  ${({ noMarginLeft }) => {
    return (
      noMarginLeft &&
      `
      margin-left: 0px;
      `
    );
  }}
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
      margin-top: 8px;
      `
    );
  }};
`;
export const StyledMessage = styled.div`
  cursor: pointer;
  display: flex;
`;
export const StyledText = styled.div`
  color: black;
  font-weight: 400;
  font-size: 16px;
  word-break: break-word;
  margin-top: 20px;
  margin-bottom: 16px;
  ${({ isComment }) => {
    return isComment && `margin-top: 0px;`;
  }}
  ${({ isComment }) => {
    return isComment && `margin-bottom: 0px;`;
  }}
`;
export const StyledLikeInfo = styled.div`
  margin-top: 16px;
  color: #060606;
  cursor: pointer;
`;

export const AvatarComponent = styled(Avatar)`
  width: 44px;
  height: 44px;

  ${({ small }) => {
    return (
      small &&
      `
      width: 30px;
      height: 30px;
      font-size: 1rem;
      `
    );
  }}

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        font-size: 0.7rem;
      `
    );
  }}
`;

export const StyledContainerComment = styled(Grid)`
  ${({ additionalHeight }) => {
    return `height: calc(0 + ${additionalHeight}px);`;
  }}
  flex-wrap: nowrap !important;
  width: 600px !important;
`;

export const AvatarComponentComment = styled(Avatar)`
  width: 44px !important;
  height: 44px !important;
  margin: 15px;
  margin-top: 20px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        font-size: 0.7rem;
      `
    );
  }}
`;
export const StyledInputWrapper = styled(Grid)`
  ${({ additionalHeight }) => {
    return `height: calc(46px + ${additionalHeight}px);`;
  }}

  border-radius: 46px;
  background: ${colors.lightGray};
  width: calc(100% - 65px) !important;
  margin-top: 15px;
`;
export const StyledTextArea = styled(Field)`
  border: none;
  border-radius: 40px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.24;
  letter-spacing: 0.25px;
  resize: none;
  padding: 0.85rem;
  font-family: 'Avenir';
  font-weight: normal;
  outline: none;
  background: ${colors.lightGray};
  width: 100%;
  height: 100%;
`;
export const StyledButton = styled(Button)`
  height: 24px;
  width: 24px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100px;
  background-color: ${({ btnColor }) => btnColor};

  &.disabled {
    background-color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? '#9b9b9b' : '')};
  }
`;
export const StyledTextAreaWrap = styled(Grid)`
  display: flex;
  align-items: flex-end;
`;
export const StyledCommentsToggle = styled.div`
  color: #a9a9a9;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const StyledCommentWrap = styled.div`
  word-break: break-word;
  ${({ ident }) => {
    return ident && ident > 0 && `margin-left: ${toRem(ident * 20)};`;
  }}
`;

export const StyleTextLink = styled.a`
  color: ${colors.darkOceanBlue};
  font-weight: 600;
  &:hover {
    color: ${colors.darkOceanBlue};
  }
`;

export const StyledProfileLink = styled(Link)`
  cursor: pointer;
  max-width: 90%;
  &:hover {
    text-decoration: none;
    color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : colors.darkOceanBlue)};

    ${({ isMyPost, isDarkThemeEnabled }) => {
      return (
        isMyPost &&
        `
       cursor: default;
       color: ${isDarkThemeEnabled ? 'white' : colors.black}`
      );
    }}
  }
`;
export const StyledRemoveComment = styled.span`
  cursor: pointer;
`;
export const StyledTextPost = styled.p`
  min-height: 1rem;
  margin-bottom: 0;
`;
export const StyledTextError = styled.span`
  color: ${colors.fadedPurple} !important;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1px;
  padding-left: 30px;
  ${({ mobileView }) => {
    return (
      mobileView &&
      ` 
         font-size: .8rem;
         padding-left: 20px;`
    );
  }}
`;

export const StyledCheckoutHeading = styled.p`
  font-size: 12px;
  padding: 12px 0px;
`;
export const StyledPostImg = styled.img`
  width: 100%;
  height: 250px;
`;
export const StyledReplyMainDiv = styled.div`
  display: flex;
  align-items: center;
`;
