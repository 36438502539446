import Modal from 'components/UI/Modal';
import React, { useState, useCallback, useEffect } from 'react';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import * as Yup from 'yup';
import {
  createAccessCode,
  determineColorToUse,
  getSingleDaySlotsForScheduleClient,
  contributionParticipants,
} from 'services/contributions.service';
import { useFormik } from 'formik';
import Form from 'antd/lib/form/Form';
import { uniqBy, cloneDeep } from 'lodash';
import {
  getCoachContributionTimes,
  getCoachClientsWithCredits,
  saveOneToOneSelfBookClient,
  sendScheduleSessionEmailToClient,
  getScheduleSessionLink,
} from 'services/contributions.service';
import copyContributionLinkToClipboard from 'utils/copyContributionLinkToClipboard';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import Loader from 'components/UI/Loader';
import BookingSummary from '../BookingSummary/BookingSummary';
import CalendarView from '../CalendarView/CalendarView';
import SlotsView from '../SlotsView/SlotsView';
import ClientsView from '../ClientsView/ClientsView';
import InviteTypeView from '../InviteTypeView/InviteTypeView';
import ShareInviteView from '../ShareInviteView/ShareInviteView';
import { TIMEZONES } from 'constants.js';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { fetchTimeZones } from 'actions/timeZone';
import SessionBookingThankyou from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/components/SessionBookingThankyou';
import styled from 'styled-components';
import { useShallowEqualSelector, useAccount, useDefaultTimezone } from 'hooks';
import {
  fetchCohealerContribution,
  setRescheduleFormerSessionDetail,
  setRescheduleParticipantInfo,
  fetchContributionActions,
  setLoadingParticipants,
} from 'actions/contributions';
import { setEasyBookingData } from 'actions/easyBooking';

const FORM_INITIAL_VALUES = {
  isSessionCompleted: false,
  isInviteSent: false,
  client: '',
  email: '',
  name: '',
  date: '',
  timeSlot: '',
  price: '',
  contribution: '',
};

const StyledModal = styled(Modal)`
  padding: ${({ mobileView }) => (mobileView ? '20px 5px !important' : '')};

  .cross-icon-hide {
    padding-top: 0px !important;
  }

  .body {
    padding: ${({ mobileView, isContribStep }) =>
      isContribStep ? '0px !important' : mobileView ? '0px 4px !important' : '0px 16px !important'};
    max-height: 85vh !important;
  }
`;

function ScheduleMeetingModal({
  isVisible,
  closeModal,
  selectOptions,
  paymentInfo,
  setIsScheduleMeetingVisible,
  selectedCalDate,
  title,
  fromMasterCalendar,
  isMasterCalendar = false,
  isResendFlow = false,
  resendSession = null,
  setRescheduleSession,
  isCalendarFlow = false,
  onMasterCalendarComplete = null,
}) {
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const contribution = useContribution();
  const [isExistingClient, setIsExistingClient] = useState(false);
  const [isAuto, setIsAuto] = useState(!isResendFlow);
  const [selectedDate, setSelectedDate] = useState();
  const [toggleAvailabilitySlots, setToggleAvailabilitySlots] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [meetingForm, setMeetingForm] = useState(FORM_INITIAL_VALUES);
  const [pricingOptions, setPricingOptions] = useState([]);
  const [autoAddPricingOptions, setAutoAddPricingOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const request = toggleAvailabilitySlots ? getCoachContributionTimes : getSingleDaySlotsForScheduleClient;
  const [tempTimeSlots, setTempTimeSlots] = useState([]);
  const [clientsWithCredits, setClientsWithCredits] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState('');
  const [clientsArray, setclientsArray] = useState([]);
  const [code, setCode] = useState(null);
  const [packageClient, setpackageClient] = useState([]);
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [pricingOption, setpricingOption] = useState(null);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [inviteLink, setInviteLink] = useState(null);
  const [step, setStep] = useState(isResendFlow ? 5 : isCalendarFlow ? 2 : 1);
  const [selectedTimeZone, setSelectedTimeZone] = useState('Select Timezone');
  const [disableContributionSelection, setDisableContributionSelection] = useState(false);
  const [availableTimes, setAvailableTimes] = useState(undefined);
  const [contributionTimesTz, setContributionTimesTz] = useState([]);
  const [clientData, setClientData] = useState(null);
  const { serviceProviderName = '', durations = [], coachAvatarUrl = '' } = activeContribution || {};
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [sendingInvite, setSendingInvite] = useState(false);
  const duration = durations.length > 0 ? durations[0] : 60;
  const dispatch = useDispatch();
  const { user } = useAccount();
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { fetchUserTimezone } = useDefaultTimezone();
  const [scheduleData, setScheduleData] = useState({
    selectedDate: selectedCalDate ? new Date(selectedCalDate) : new Date(),
  });
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const participantInfo = useShallowEqualSelector(
    state =>
      (state?.contributions?.activeContribution?.participants || []).find(
        client => client?.id === resendSession?.participantId,
      ) || {},
  );
  const onOptionSelect = useCallback(val => {
    setCloseDropDown(val);
  }, []);

  const clickAnywhere = useCallback(() => {
    setCloseDropDown(true);
  }, []);

  const fetchFinalTimeZone = async () => {
    setIsLoading(true);
    const finalTimezone = await fetchUserTimezone(
      user.timeZoneId,
      contribution?.activeContribution?.timeZoneId,
      timeZones,
    );
    setSelectedTimeZone(finalTimezone);
    setIsLoading(false);
  };

  useEffect(() => {
    if (user && timeZones.length > 0 && selectedTimeZone === 'Select Timezone') fetchFinalTimeZone();
  }, [user, timeZones]);

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones, timeZoneLoading]);

  useEffect(() => {
    if (isResendFlow) {
      setIsLoading(true);
      getScheduleSessionLink(resendSession.timeId)
        .then(response => {
          setCode(response);
          setInviteLink(`${window.location.origin}contribution-view/${activeContribution.id}/${response}/about`);
          setIsLoading(false);
          if (response.split('s_').length > 1) setCurrentSessionId(response.split('s_')[1]);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    formik.setErrors({});
    formik.setFieldValue('date', selectedCalDate);
  }, []);

  const getClientName = () => {
    if (isResendFlow) {
      if (resendSession.isGuestUserSession) return resendSession?.guestUserName;
      return `${participantInfo?.firstName === undefined ? '' : participantInfo?.firstName} ${
        participantInfo?.lastName === undefined ? '' : participantInfo?.lastName
      }`;
    }
    return formik?.values?.client?.name ?? formik?.values?.name;
  };

  const currentDate = moment();
  const onChangeClient = useCallback(
    clientId => {
      filterArrayforPackageCredit(clientId);
      setSelectedClient(clientId);
      formik.setFieldValue('client', clientId);
      setMeetingForm({
        ...meetingForm,
        clientName: getSelectedClient(clientId),
      });
    },
    [selectedClient, pricingOption],
  );

  const colorToUse = determineColorToUse(isMasterCalendar ? null : activeContribution);
  const formik = useFormik({
    initialValues: {
      client: '',
      name: '',
      email: '',
      date: moment(),
      coachSessionTitle: '',
      price: '',
    },
    validationSchema: Yup.object().shape({
      client: isExistingClient ? Yup.string().required('Please Select a Client') : Yup.string(),
      name: !isExistingClient ? Yup.string().required('Please Enter Client Name') : Yup.string(),
      email: !isExistingClient && Yup.string().email('Invalid Email').required('Please Enter Client Email'),
      price: Yup.string().required('Please Select a ' + isAuto ? 'Booking Method' : 'Price'),
    }),
  });

  const mapClientsList = (clientsList = []) => {
    const selectOptions = [];
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = paymentInfo;
    if (paymentOptions.length === 1 && paymentOptions.includes('FreeSessionsPackage')) {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        if (participant.creditsRemaining > 0) {
          selectOptions.push({
            title: (
              <>
                <p style={{ margin: 0 }}>
                  {clientName}{' '}
                  {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
                </p>
              </>
            ),
            clientName: clientName,
            name: participant.clientName.split('(')[0],
            clientEmail: participant.clientEmail,
            creditsRemaining: participant.creditsRemaining,
            value: participant.clientId,
            key: participant.clientId,
          });
        }
      });
    } else {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        selectOptions.push({
          title: (
            <>
              <p style={{ margin: 0 }}>
                {clientName}{' '}
                {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
              </p>
            </>
          ),
          clientName: clientName,
          name: participant.clientName.split('(')[0],
          clientEmail: participant.clientEmail,
          creditsRemaining: participant.creditsRemaining,
          value: participant.clientId,
          key: participant.clientId,
        });
      });
    }
    setClientsWithCredits(selectOptions);
    setclientsArray(clientsList);
  };

  const mapClientsListMasterCalendar = (clientsList = [], val) => {
    const selectOptions = [];
    const { cost, paymentOptions, packageSessionNumbers, monthlySessionSubscriptionInfo } = val;
    if (paymentOptions.length === 1 && paymentOptions.includes('FreeSessionsPackage')) {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        if (participant.creditsRemaining > 0) {
          selectOptions.push({
            title: (
              <>
                <p style={{ margin: 0 }}>
                  {clientName}{' '}
                  {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
                </p>
              </>
            ),
            clientName: clientName,
            name: participant.clientName.split('(')[0],
            clientEmail: participant.clientEmail,
            creditsRemaining: participant.creditsRemaining,
            value: participant.clientId,
            key: participant.clientId,
          });
        }
      });
    } else {
      clientsList.map(participant => {
        const clientName = participant.clientName;
        const creditsRemaining = participant.clientName.split('(')[1];
        selectOptions.push({
          title: (
            <>
              <p style={{ margin: 0 }}>
                {clientName}{' '}
                {participant.creditsRemaining > 0 && <span style={{ color: 'gray' }}>({creditsRemaining}</span>}
              </p>
            </>
          ),
          clientName: clientName,
          name: participant.clientName.split('(')[0],
          clientEmail: participant.clientEmail,
          creditsRemaining: participant.creditsRemaining,
          value: participant.clientId,
          key: participant.clientId,
        });
      });
    }
    setClientsWithCredits(selectOptions);
    setclientsArray([{ creditsRemaining: selectedClient?.creditsRemaining, clientId: selectedClient?.value }]);
    calculatePricingsforMasterCalendar(pricingOption.paymentInfo);
  };
  const filterClient = (clientsList = []) => {
    if (isMasterCalendar === false) {
      calculatePricings(paymentInfo);
    }
  };
  const handleCopyToClipboard = () => {
    copyContributionLinkToClipboard({ contributionId: contribution.id, code: code });
    setIsTooltipOpen(true);
    setTimeout(() => setIsTooltipOpen(false), 2000);
  };

  const onSubmitSentInviteForm = values => {
    setSendingInvite(true);
    setIsLoading(true);
    values.timeSlot = scheduleData?.selectedSlot?.completeTime;
    const { value, name } = selectedClient;
    const selectedTimeSlot = scheduleData?.selectedSlot;

    const commonValues = {
      clientId: isExistingClient ? value : null,
      availabilityTimeId: selectedTimeSlot?.id,
      offset: selectedTimeSlot?.offset,
      selectedSlotEndDateTime: selectedTimeSlot?.end,
      selectedSlotStartDateTime: selectedTimeSlot?.start,
      email: isExistingClient ? null : formik?.values?.email,
      priceOption: `${formik.values?.price?.value}`,
      clientName: isExistingClient ? name : meetingForm.name,
      contributionId: activeContribution.id,
      sendViaEmail: !isExistingClient,
      sharedViaLink: true,
      sessionName: formik?.values?.coachSessionTitle,
      accessCode: null,
      autoAddEnabled: isAuto,
    };

    if (formik?.values?.price?.value?.toString() === 'Free') {
      createAccessCode(activeContribution.id).then(data => {
        commonValues.accessCode = data.code;
        onSubmitInvitationForm(commonValues);
      });
    } else {
      onSubmitInvitationForm(commonValues);
    }

    setMeetingForm({
      ...meetingForm,
      ...values,
      isInviteSent: true,
    });

    setIsLoading(false);
    setSendingInvite(false);
    return true;
  };

  const getSelectedClient = client => {
    setClientData(client);
    const selectedClient = clientsWithCredits?.find(option => option?.value === client);
    return selectedClient?.clientName;
  };

  const getClientsList = () => {
    getCoachClientsWithCredits(activeContribution.id).then(clientsList => mapClientsList(clientsList));
  };

  const mapTimeSlots = timeSlots => {
    if (timeSlots?.length > 0) {
      const newTimeSlots = timeSlots.map((time, index) => {
        return {
          id: time.id,
          completeTime: time.title,
          startTime: moment(time.start).format('h:mm AM'),
          endTime: time.end,
          start: moment(time.start),
          end: moment(time.end),
          isSelected: index === 0 ? true : false,
          offset: time.offset,
          time: time.start,
        };
      });
      return newTimeSlots;
    }
    return [];
  };

  const timeSlotsOnAvailbleDate = val => {
    if (!activeContribution?.id || !scheduleData?.timeZone) {
      return;
    }
    const selectedDate = val ? val : formik.values.date;
    const duration = activeContribution?.durations.length > 0 ? activeContribution?.durations : [60];
    const serviceProviderName = activeContribution?.serviceProviderName;
    setIsModalOpen(true);
    setIsLoading(true);
    const startTimeIncrementDuration = activeContribution?.sessionIncrements?.[0] || 0;
    const increments = [startTimeIncrementDuration];
    if (moment(selectedDate).isSameOrAfter(moment(), 'day')) {
      Promise.allSettled(
        increments.map(k =>
          request(activeContribution.id, k, moment(selectedDate).format('YYYY-MM-DD'), scheduleData?.timeZone),
        ),
      ).then(_slots => {
        const zeroSlots = _slots.reduce(
          (acc, curr) => (acc.some(k => k.startTime === curr.value?.startTime) ? acc : [...acc, ...curr.value]),
          [],
        );
        const formattedSlots = zeroSlots
          .filter(slot => moment(slot.startTime).isSame(selectedDate, 'day'))
          .sort((a, b) => moment(a.startTime).diff(moment(b.startTime), 'minutes'));

        const newArray = formattedSlots.filter(function (el) {
          return el.bookedTimes.length === 0;
        });
        const filteredData = newArray.filter(item => {
          const startTime = moment(item.startTime);
          if (startTime.isSame(currentDate, 'day')) {
            return startTime.isSame(currentDate, 'day') && !startTime.isBefore(currentDate);
          }
          return startTime;
        });
        const event = getTimePeriodsForAvailability({
          availabilityPeriods: uniqBy(filteredData, 'id'),
          duration,
          serviceProviderName,
        });
        if (event.length === 0) {
          setTempTimeSlots([]);
          setIsLoading(false);
          setLoadingSlots(false);
        }
        if (event.length > 0) {
          const timeSlotsOption = mapTimeSlots(event);
          setTempTimeSlots(timeSlotsOption);
          setIsLoading(false);
          setLoadingSlots(false);
        }
      });
    } else {
      setTempTimeSlots([]);
      setIsLoading(false);
      setLoadingSlots(false);
    }
  };

  const calculateAvailableTimes = (res = []) => {
    const endDay = activeContribution?.oneToOneSessionDataUi?.endDay;
    const endDate = endDay ? moment(endDay) : moment().add(60, 'days');
    const times = toggleAvailabilitySlots
      ? res
          .filter(item => item.bookedTimes.length === 0 && moment(item.startTime).isSameOrAfter(moment()))
          .map(item => item.startTime)
      : Array.from({ length: endDate.diff(moment(), 'days') + 1 }, (_, i) =>
          moment().add(i, 'days').set({ hour: 14, minute: 0, second: 0 }).toISOString(),
        );
    return times;
  };

  useEffect(() => {
    setIsLoading(true);
    getCoachContributionTimes(
      activeContribution?.id,
      activeContribution?.sessionIncrements?.[0],
      undefined,
      scheduleData?.timeZone,
    )
      .then(res => {
        setContributionTimesTz(res);
        const times = calculateAvailableTimes(res);
        setAvailableTimes(times);
        setIsLoading(false);
        if (step === 1 && isMasterCalendar) {
          setScheduleData({ ...scheduleData, selectedDate: moment(res[0].startTime) });
          setSelectedDate(moment(moment(res[0].startTime)));
        }
      })
      .catch(() => setIsLoading(false));
  }, [scheduleData?.timeZone, activeContribution]);

  useEffect(() => {
    const times = calculateAvailableTimes(contributionTimesTz);
    setAvailableTimes(times);
  }, [toggleAvailabilitySlots]);

  useEffect(() => {
    if (!isMasterCalendar) {
      setLoadingSlots(true);
      timeSlotsOnAvailbleDate(scheduleData?.selectedDate || selectedCalDate || formik.values.date);
    } else {
      setLoadingSlots(true);
      setIsLoading(true);
      getCoachContributionTimes(activeContribution?.id, activeContribution?.sessionIncrements?.[0])
        .then(res => {
          const startTimes = res.map(item => item.startTime);
          setIsLoading(false);
          const filteredDates = startTimes?.filter(date => {
            const dateObj = moment(date);
            return dateObj.isSameOrAfter(currentDate, 'day');
          });
          timeSlotsOnAvailbleDate(scheduleData?.selectedDate || formik.values.date);
        })
        .catch(() => setIsLoading(false));
    }
  }, [toggleAvailabilitySlots, scheduleData?.selectedDate, scheduleData?.timeZone]);

  useEffect(() => {
    filterClient();
  }, [selectedClient]);

  useEffect(() => {
    if (isMasterCalendar) {
      setIsLoading(true);
      getCoachContributionTimes(activeContribution?.id, activeContribution?.sessionIncrements?.[0])
        .then(res => {
          const startTimes = res.map(item => item.startTime);
          const filteredDates = startTimes?.filter(date => {
            const dateObj = moment(date);
            return dateObj.isSameOrAfter(currentDate, 'day');
          });
          timeSlotsOnAvailbleDate(filteredDates[0]);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
    if (!activeContribution) setIsModalOpen(true);
    if (isVisible && activeContribution && !isMasterCalendar) {
      timeSlotsOnAvailbleDate(selectedCalDate);
    }
    if (!clientsArray?.length && !isMasterCalendar) {
      getClientsList();
    }
  }, [isVisible, activeContribution?.id]);

  const handleSendViaEmail = () => {
    setIsLoading(true);
    sendScheduleSessionEmailToClient(currentSessionId)
      .then(response => {
        setIsLoading(false);
        setStep(6);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onSubmitInvitationForm = data => {
    setSendingInvite(true);
    setIsLoading(true);
    saveOneToOneSelfBookClient(data)
      .then(response => {
        setCode(response.id);
        if (isMasterCalendar && onMasterCalendarComplete) onMasterCalendarComplete();
        setInviteLink(`${window.location.origin}contribution-view/${activeContribution.id}/${response.id}/about`);
        setIsLoading(false);
        setSendingInvite(false);
        if (response.id.split('s_').length > 1) setCurrentSessionId(response.id.split('s_')[1]);
        setStep(isAuto ? 6 : 5);
        if (!isMasterCalendar) {
          dispatch(setLoadingParticipants(true));
          contributionParticipants(activeContribution.id).then(res => {
            let contrib = {
              ...contribution,
              participants: res,
            };
            dispatch(fetchContributionActions.success(contrib));
            dispatch(setLoadingParticipants(false));
          });
          dispatch(fetchCohealerContribution(activeContribution.id));
        }
        const updatedScheduleData = cloneDeep(scheduleData?.selectedSlot);
        updatedScheduleData.id = response.bookTimeId;
        if (isAuto) {
          dispatch(
            setEasyBookingData.setData({
              selectedSlot: updatedScheduleData,
              timeSlot: `${moment(response?.selectedSlotStartDateTime).format('h:mmA')} - ${moment(
                response?.selectedSlotEndDateTime,
              ).format('h:mmA')}, ${moment(response?.selectedSlotEndDateTime).format('dddd')}, ${moment(
                response?.selectedSlotEndDateTime,
              ).format('MMMM DD, yyyy')}`,
              isBookedTime: true,
            }),
          );
          dispatch(
            setRescheduleFormerSessionDetail({
              selectedSlot: updatedScheduleData,
              timeSlot: `${moment(response?.selectedSlotStartDateTime).format('h:mmA')} - ${moment(
                response?.selectedSlotEndDateTime,
              ).format('h:mmA')}, ${moment(response?.selectedSlotEndDateTime).format('dddd')}, ${moment(
                response?.selectedSlotEndDateTime,
              ).format('MMMM DD, yyyy')}`,
              isBookedTime: true,
            }),
          );
          dispatch(
            setRescheduleParticipantInfo(
              isResendFlow
                ? participantInfo
                : { firstName: isExistingClient ? formik?.values?.client?.name : formik?.values?.name },
            ),
          );
        }
      })
      .catch(() => {
        setSendingInvite(false);
        setIsLoading(false);
      });
  };

  const onCloseModal = () => {
    setIsScheduleMeetingVisible(false);
    setIsModalOpen(false);
    setSelectedClient('');
    formik.resetForm();
    setMeetingForm(FORM_INITIAL_VALUES);
  };

  const filterArrayforPackageCredit = clientId => {
    let filter = clientsArray.filter(
      participant => clientId?.value === participant?.clientId && participant?.creditsRemaining > 0,
    );

    setpackageClient(filter);
  };

  const calculatePricings = () => {
    const { cost, paymentOptions } = paymentInfo;
    const priceSelectOptions = [];
    const autoAddPriceSelectOptions = [];

    const paymentTitle = {
      Free: 'Single Session (Free)',
      PerSession: 'Single Session',
    };

    autoAddPriceSelectOptions.push({
      value: 'Free',
      price: 0,
      title: paymentTitle.Free,
    });

    priceSelectOptions.push({
      value: 'Free',
      price: 0,
      title: paymentTitle.Free,
    });
    if (
      paymentOptions.includes('PerSession') &&
      (selectedClient?.creditsRemaining === 0 || isExistingClient === false)
    ) {
      priceSelectOptions.push({
        value: cost,
        price: cost,
        title: `${paymentTitle.PerSession} ($${cost} USD)`,
      });
    }

    if (selectedClient?.creditsRemaining > 0 && isExistingClient) {
      priceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Single Session (Free/Package Credit)`,
      });
      autoAddPriceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Session Package Credits`,
      });
    }
    setPricingOptions(priceSelectOptions);
    setAutoAddPricingOptions(autoAddPriceSelectOptions);
  };

  const calculatePricingsforMasterCalendar = val => {
    const { cost, paymentOptions } = val;
    const priceSelectOptions = [];
    const autoAddPriceSelectOptions = [];

    const paymentTitle = {
      Free: 'Single Session (Free)',
      PerSession: 'Single Session',
    };

    autoAddPriceSelectOptions.push({
      value: 'Free',
      price: 0,
      title: paymentTitle.Free,
    });

    priceSelectOptions.push({
      value: 'Free',
      price: 0,
      title: paymentTitle.Free,
    });
    if (paymentOptions.includes('PerSession') && selectedClient?.creditsRemaining === 0) {
      priceSelectOptions.push({
        value: cost,
        price: cost,
        title: `${paymentTitle.PerSession} ($${cost} USD)`,
      });
    }

    if (selectedClient?.creditsRemaining > 0 && isExistingClient) {
      priceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Single Session (Free/Package Credit)`,
      });
      autoAddPriceSelectOptions.push({
        value: 'Credit',
        price: 'Free',
        title: `Session Package Credits`,
      });
    }
    setPricingOptions(priceSelectOptions);
    setAutoAddPricingOptions(autoAddPriceSelectOptions);
  };

  const onCalendarDrillDownHandler = useCallback(
    day => {
      const todayDate = moment(new Date()).startOf('day');
      const dateSelected = moment(day).startOf('day');
      const isPastDate = dateSelected.isBefore(todayDate);
      if (!isPastDate) {
        setScheduleData({ ...scheduleData, selectedDate: moment(day) });
        setSelectedDate(moment(day));
        setStep(2);
      }
    },
    [scheduleData],
  );

  const onCalendarEventSelectHandler = useCallback(
    event => {
      setScheduleData({ ...scheduleData, selectedDate: moment(event.start) });
      setSelectedDate(moment(moment(event.start)));
      setStep(2);
    },
    [scheduleData],
  );

  const slotsViewBackHandler = () => {
    const { selectedSlot, ...rest } = scheduleData;
    setScheduleData({ ...rest });
    setStep(1);
  };

  const slotsViewNextHandler = () => {
    setDisableContributionSelection(true);
    setStep(3);
  };

  const clientsViewBackHandler = () => {
    setDisableContributionSelection(false);
    setStep(2);
  };

  const clientsViewNextHandler = () => {
    formik.validateForm().then(errors => {
      if (!errors.name && !errors.email && !errors.client) {
        setStep(4);
      } else {
        formik.setTouched(
          {
            name: true,
            email: true,
            client: true,
          },
          true,
        );
      }
    });
  };

  const inviteTypeViewBackHandler = () => {
    setStep(3);
  };

  const inviteTypeViewNextHandler = () => {
    formik.validateForm().then(errors => {
      if (!errors.price) {
        onSubmitSentInviteForm(formik?.values, true);
      } else {
        formik.setTouched(
          {
            price: true,
          },
          true,
        );
      }
    });
  };

  const shareInviteViewBackHandler = () => {
    setStep(4);
  };

  useEffect(() => {
    if (fromMasterCalendar) {
      if (pricingOption != null) {
        calculatePricingsforMasterCalendar(pricingOption.paymentInfo);
        filterArrayforPackageCredit();
        getCoachClientsWithCredits(pricingOption.id).then(clientsList =>
          mapClientsListMasterCalendar(clientsList, pricingOption.paymentInfo),
        );
      }
    }
  }, [pricingOption, onChangeClient]);

  useEffect(() => {
    if (activeContribution) {
      const clientName = formik?.values?.client?.name;
      const name = formik?.values?.name;
      const hasName = clientName || name;
      const sessionTitle = `${activeContribution.title}`.trim() + (hasName ? `, ${clientName || name}` : '');
      formik.setFieldValue('coachSessionTitle', sessionTitle);
    }
  }, [formik?.values?.client, formik?.values?.name, activeContribution]);

  useEffect(() => {
    if (selectedTimeZone !== 'Select Timezone') {
      setScheduleData({ ...scheduleData, timeZone: selectedTimeZone });
    }
  }, [selectedTimeZone]);

  return isLoading && !isModalOpen && availableTimes === undefined ? (
    <Loader />
  ) : (
    <StyledModal
      isOpen={isModalOpen}
      isContribStep={!activeContribution?.id}
      title={<div style={{ margin: mobileView ? '0px 15px' : '' }}>Schedule Client</div>}
      CrossIconHide={step > 4}
      isCreatingContribution={fromMasterCalendar || isMasterCalendar}
      onCancel={() => onCloseModal()}
      onClickAnyWhere={clickAnywhere}
      brandingColor={!isMasterCalendar}
      isScheduleMeetingModal
      minHeight={'160px'}
      disableFooter
      mobileView={mobileView}
      disableOverlayClick
      isDarkModeSelected={contribution?.isDarkModeEnabled}
    >
      <div
        className="schedule-meeting-form-modal"
        onClick={() => {
          setCloseDropDown(true);
        }}
      >
        <div
          className={`easy-booking-page ${
            (isMasterCalendar ? false : contribution?.isDarkModeEnabled) ? 'cohere-dark-mode' : ''
          }`}
        >
          <Form id="meetingForm">
            <div
              className={`easy-booking-container ${!activeContribution?.id ? 'no-active-contribution' : ''}`}
              style={{ backgroundColor: 'transparent', width: '100%' }}
            >
              {!meetingForm?.isSessionCompleted && step < 5 && (
                <BookingSummary
                  coachAvatarUrl={coachAvatarUrl}
                  client={formik?.values?.client ?? ''}
                  name={formik?.values?.name}
                  serviceProviderName={serviceProviderName}
                  duration={duration}
                  contributionTitle={activeContribution?.title}
                  purpose={activeContribution?.purpose}
                  viewPurpose={activeContribution?.viewPurpose}
                  scheduleData={scheduleData}
                  fromMasterCalendar={fromMasterCalendar}
                  setpricingOption={setpricingOption}
                  pricingOption={pricingOption}
                  calculatePricings={calculatePricings}
                  contributionSelectionDisable={disableContributionSelection}
                  colorToUse={colorToUse}
                  activeContribution={activeContribution}
                  isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                />
              )}
              {!meetingForm?.isSessionCompleted && activeContribution?.id && step < 5 && (
                <div
                  className="calendar-side"
                  style={{ padding: '0px 5px 0px 5px', minWidth: mobileView ? 'unset' : '' }}
                >
                  {step === 1 && (
                    <CalendarView
                      onDrillDown={onCalendarDrillDownHandler}
                      isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                      onSelectEvent={onCalendarEventSelectHandler}
                      availableTimes={availableTimes}
                      scheduleData={scheduleData}
                      setScheduleData={setScheduleData}
                      colorToUse={colorToUse}
                      selectedTimeZone={selectedTimeZone}
                      selectTimeZone={setSelectedTimeZone}
                      fromMasterCalendar={fromMasterCalendar}
                      toggleAvailabilitySlots={toggleAvailabilitySlots}
                      setToggleAvailabilitySlots={setToggleAvailabilitySlots}
                      isMasterCalendar={isMasterCalendar}
                      isLoading={loadingSlots}
                    />
                  )}
                  {step === 2 && (
                    <SlotsView
                      contribution={contribution}
                      isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                      onClose={slotsViewBackHandler}
                      selectedDate={selectedDate}
                      contributionId={contribution?.id}
                      contributionTimeZoneId={contribution.timeZoneId}
                      title={title}
                      serviceProviderName={serviceProviderName}
                      duration={duration}
                      onSubmit={slotsViewNextHandler}
                      colorToUse={colorToUse}
                      selectedTimeZone={tz => {
                        setSelectedTimeZone(tz);
                      }}
                      isLoading={loadingSlots}
                      tempTimeSlots={tempTimeSlots}
                      toggleAvailabilitySlots={toggleAvailabilitySlots}
                      setToggleAvailabilitySlots={setToggleAvailabilitySlots}
                      userSelectedTimeZone={selectedTimeZone}
                      scheduleData={scheduleData}
                      setScheduleData={setScheduleData}
                      fromMasterCalendar={fromMasterCalendar}
                    />
                  )}
                  {step === 3 && (
                    <ClientsView
                      isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                      onClose={clientsViewBackHandler}
                      onSubmit={clientsViewNextHandler}
                      setClientData={setClientData}
                      colorToUse={colorToUse}
                      clientData={clientData}
                      formik={formik}
                      meetingForm={meetingForm}
                      paymentInfo={paymentInfo}
                      selectOptions={clientsWithCredits}
                      onOptionSelect={onOptionSelect}
                      closeDropDown={closeDropDown}
                      setMeetingForm={setMeetingForm}
                      onChangeClient={onChangeClient}
                      setIsExistingClient={setIsExistingClient}
                      isExistingClient={isExistingClient}
                      filterClient={filterClient}
                      fromMasterCalendar={fromMasterCalendar}
                    />
                  )}
                  {step === 4 && (
                    <InviteTypeView
                      isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                      onClose={inviteTypeViewBackHandler}
                      onSubmit={inviteTypeViewNextHandler}
                      colorToUse={colorToUse}
                      setIsAuto={setIsAuto}
                      isAuto={isAuto}
                      formik={formik}
                      meetingForm={meetingForm}
                      selectedClient={selectedClient}
                      paymentInfo={paymentInfo}
                      autoAddSelectOptions={autoAddPricingOptions}
                      selectOptions={pricingOptions}
                      onOptionSelect={onOptionSelect}
                      activeContribution={activeContribution}
                      isExistingClient={isExistingClient}
                      closeDropDown={closeDropDown}
                      setMeetingForm={setMeetingForm}
                      onChangeClient={onChangeClient}
                      filterClient={filterClient}
                      fromMasterCalendar={fromMasterCalendar}
                      sendingInvite={sendingInvite}
                    />
                  )}
                </div>
              )}
            </div>
            {step === 5 && (
              <ShareInviteView
                inviteLink={inviteLink}
                handleCopyLink={() => {
                  handleCopyToClipboard();
                }}
                handleSendEmail={() => {
                  handleSendViaEmail();
                  setStep(6);
                }}
                isDarkModeEnabled={isMasterCalendar ? false : contribution?.isDarkModeEnabled}
                onBack={shareInviteViewBackHandler}
                colorToUse={colorToUse}
                onCancel={() => onCloseModal()}
                isTooltipOpen={isTooltipOpen}
                setIsTooltipOpen={setIsTooltipOpen}
                isMasterCalendar={isMasterCalendar}
              />
            )}
            {step === 6 && (
              <SessionBookingThankyou
                isScheduleClientFlow
                isAutoAddFlow={isAuto}
                setRescheduleSession={setRescheduleSession}
                isMasterCalendar={isMasterCalendar}
                handleCancel={() => onCloseModal()}
                isModal
                scheduleClientAvatarUrl={isResendFlow ? participantInfo?.avatarUrl : null}
                modalTitle={isAuto ? 'The session is scheduled' : 'Invite sent successfully'}
                modalSubtitle={
                  isAuto
                    ? `A calendar invitation has been sent to ${
                        isExistingClient ? formik?.values?.client?.clientEmail : formik?.values?.email
                      }.\nNew clients will also receive an account activation email to access the session on Cohere.`
                    : 'A session invitation has been sent. Once your client confirms the session you will receive a calendar invite.'
                }
                clientName={getClientName()}
                timezone={TIMEZONES[isResendFlow ? contribution?.timeZoneId : selectedTimeZone] || selectedTimeZone}
                timeSlot={`${moment(isResendFlow ? resendSession.startTime : scheduleData?.selectedSlot?.start).format(
                  'h:mmA',
                )} - ${moment(isResendFlow ? resendSession.endTime : scheduleData?.selectedSlot?.end).format(
                  'h:mmA',
                )}, ${moment(isResendFlow ? resendSession.startTime : scheduleData?.selectedDate).format(
                  'dddd, MMMM DD, YYYY',
                )}`}
                selectedSlot={isResendFlow ? resendSession.selectedSlot : scheduleData?.selectedSlot}
              />
            )}
          </Form>
        </div>
      </div>
    </StyledModal>
  );
}

export default ScheduleMeetingModal;
