import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import useAccount from 'hooks/useAccount';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import { convertToPlainText } from 'utils/utils';
import moment from 'moment';
import * as R from 'ramda';
import * as contentService from 'services/content.service';
import {
  createMutiplePosts,
  removeAttachmentForUnifiedCommunity,
  post,
  notifyTaggedUsers,
} from 'services/community.service';
import useRouter from 'hooks/useRouter';
import filter from 'lodash/filter';
import { useMediaQuery, useTheme, Grid, Box, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import { CircularProgressWithLabel } from 'components/UI/CircularProgressWithLabel/CircularProgressWithLabel';
import { colors } from 'utils/styles';
import Modal from 'components/UI/Modal';
import { useSelector } from 'react-redux';
import { RxCrossCircled } from 'react-icons/rx';
import { useHttp } from 'hooks/';
import TextField from 'components/UI/TextField';
import { determineColorToUse, determineDarkThemedColorToUse, getAllParticipants } from 'services/contributions.service';
import { Formik, FieldArray } from 'formik';
import CommunityPhotoSvg from 'components/Icons/CommunityPhoto';
import CommunityVideoSvg from 'components/Icons/CommunityVideo';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { UserRoles } from 'helpers/constants';
import ButtonProgress from 'components/FormUI/ButtonProgress';
import InfoIcon from '@material-ui/icons/Info';
import { SearchBarComponent } from '.';
import { TOOLTIP } from '../../constants';
import CommunitiesAutoComplete from './Components/CommunitiesAutoComplete';
import { Attachments } from '../ContributionView/ContributionSocial/components/Attachments/Attachments';

const StyledProgressWrap = styled(Box)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  margin-top: -8px;
  margin-bottom: -8px;
`;

const StyledModal = styled(Modal)`
  .cohere-modal {
    .body {
      padding: 0;
      overflow: hidden;
      max-height: 75vh;

      .create-post-root-unified {
        padding: 0 20px;
        max-height: ${({ mobileView }) => (mobileView ? '58vh' : '62vh')};
        overflow-y: auto;
      }
    }
  }
`;

const StyledCross = styled(RxCrossCircled)``;
const StyledRemove = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  color: white;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`;

const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: 'space-between';
  gap: 16px;
  padding: 20px;
  border-top: 1px solid #dfe3e4;

  .Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .MuiFormControlLabel-label {
    height: 22px !important;
  }
`;

const StyledFooterButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 16px;
`;

const StyledHashtag = styled.div`
  height: 25px;
  background-color: ${({ color }) => color};
  color: white;
  display: flex;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  width: auto;
  word-wrap: break-word;
  position: relative;
  margin-right: 15px;
  min-width: 40px;
`;

const StyledContainer = styled.div`
  width: 100%;
  max-width: 530px !important;
  border-radius: 4px;
  margin-top: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

const AvatarComponent = styled(Avatar)`
  width: 44px !important;
  height: 44px !important;
  margin-right: 10px;

  ${({ mobileView }) => {
    return (
      mobileView &&
      `
        width: 40px;
        height: 40px;
        font-size: 0.7rem;
        margin-right: 5px;
      `
    );
  }}
`;

const StyledAttachmentsContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  gap: 16px;
  margin-bottom: 24px;
`;

const getInitialSymbol = R.compose(R.toUpper, R.head);

const MAX_FILES_SIZE_IN_BYTES = 5368706371;

const isFilesSizeLimitOrLess = R.compose(sum => sum <= MAX_FILES_SIZE_IN_BYTES, R.sum, R.map(R.prop('size')));

const CreatePostSection = ({ communities, reloadPostsOnUpload, hashtags, isUnifiedCommunity }) => {
  const podIdPrefix = 'podId-';
  const formRef = useRef(null);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [allContributionIds, setAllContributionIds] = useState([]);
  const fileRef = useRef(null);
  const [progress, setProgress] = useState(null);
  const { user, currentRole } = useAccount();
  const isCoach = currentRole === UserRoles.cohealer;
  const [isFileUpload, setIsFileUpload] = useState(false);
  const theme = useTheme();
  const { loading } = useHttp();
  const [image_video_Limit, setImageVideo_Limit] = useState(false);
  const [liveVideoSession, setliveVideoSession] = useState(false);
  const [message, setMessage] = useState('');
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [isPostModalOpened, setIsPostModalOpened] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const activeContribution = useSelector(state => state.contributions?.activeContribution);
  const [isHashtagModalOpen, setIsHashtagModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [hashtag, setHashtag] = useState('');
  const [triggerAttachment, setTriggerAttachment] = useState(false);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(isUnifiedCommunity ? {} : activeContribution);
  const [emailAllMembers, setEmailAllMembers] = useState(false);
  const [contributionParticipants, setContributionParticipants] = useState([]);
  const [userMentions, setUserMentions] = useState([]);
  const { domain } = useRouter();

  const closeHashtagModal = () => {
    setIsHashtagModalOpen(false);
  };

  const colorToUse = determineColorToUse(activeContribution);
  const currentDate = moment();
  const onClickClosePostModal = () => {
    if (formRef.current) {
      formRef.current.setFieldValue('contributions', []);
      formRef.current.setFieldValue('textmessage', '');
      formRef.current.setFieldValue('attachments', []);
      formRef.current.setFieldValue('shareOnFacebook', false);
      formRef.current.setFieldValue('shareOnInstagram', false);
      formRef.current.setFieldValue('hashTags', []);
    }
    setErrorMessage(false);
    setIsPostModalOpened(!isPostModalOpened);
  };

  useEffect(() => {
    let Ids = [];
    for (let c in communities) {
      Ids.push(communities[c].id);
    }
    setAllContributionIds(Ids);
  }, [communities]);

  const handleIsFileLoad = () => {
    setIsFileUpload(false);
    setProgress(null);
  };

  const handleUpdateProgress = (partNumber, totalParts) => progressData => {
    const percentPerChunk = 100 / totalParts;
    setProgress((progressData / 100) * percentPerChunk + percentPerChunk * (partNumber - 1));
  };

  const guid = () => {
    var d = new Date().getTime();
    var guid = 'xxxx-xxxx-xxxx-xxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : (r & 0x7) | 0x8).toString(16);
    });
    return guid;
  };

  const uploadPartFile = (
    chunk,
    partNumber,
    totalParts,
    isLastPart,
    documentId,
    fileName,
    fileType,
    uploadId,
    prevETags,
  ) =>
    new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', chunk);
      setIsFileUpload(true);
      contentService
        .addFileToPostForUnifiedCommunityPost(
          formData,
          partNumber,
          isLastPart,
          documentId,
          fileName.includes('#') ? fileName.replace(/#/g, '') : fileName,
          fileType,
          uploadId,
          prevETags,
          handleUpdateProgress(partNumber, totalParts),
        )
        .then(data => {
          resolve(data);
        })
        .then(() => {
          if (isLastPart) {
            handleIsFileLoad();
          }
        })
        .catch(data => {
          handleIsFileLoad();
          console.dir(data);
          reject(data);
        });
    });

  const handleUploadFile = async ({ target: { files } }) => {
    const chunkSize = 26214400;

    if (isFilesSizeLimitOrLess(files) && formRef.current) {
      const newUploadedFiles = [...formRef.current.values.attachments, ...files];
      const maxImages = 5; // Maximum number of images that can be selected
      const maxVideos = 1; // Maximum number of videos that can be selected
      let selectedImages = 0;
      let selectedVideos = 0;
      for (let j = 0; j < newUploadedFiles.length; j++) {
        const temp_file = newUploadedFiles[j];
        if (temp_file?.type?.startsWith('image/')) {
          selectedImages++;
        } else if (temp_file?.type?.startsWith('video/')) {
          selectedVideos++;
        }
      }

      if (selectedImages > maxImages || selectedVideos > maxVideos || (selectedImages >= 1 && selectedVideos >= 1)) {
        selectedImages = 0;
        selectedVideos = 0;
        setImageVideo_Limit(true);
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const documentId = guid();
        const fileName = file.name;
        const fileType = file.type;
        let uploadId = '';
        let prevETags = '';
        let partNumber = 1;
        for (let start = 0; start < file.size; start += chunkSize) {
          const isLastPart = start + chunkSize >= file.size;
          const chunk = file.slice(start, start + chunkSize);
          const totalParts = Math.ceil(file.size / chunkSize);
          const result = await uploadPartFile(
            chunk,
            partNumber,
            totalParts,
            isLastPart,
            documentId,
            fileName,
            fileType,
            uploadId,
            prevETags,
          );
          if (result) {
            if (isLastPart) {
              if (formRef.current) {
                const allAttachments = [...formRef.current.values.attachments, ...result];
                formRef.current.setFieldValue('attachments', allAttachments);
              }
            }
            uploadId = result.uploadId;
            prevETags = result.prevETags;
          }
          partNumber++;
        }
      }
    }
  };

  const fetchContributionParticipants = contributionId => {
    getAllParticipants(contributionId)
      .then(response => {
        const userss = [
          {
            imageUrl: null,
            display: '@channel',
            id: 'channelId',
          },
          ...(response?.map(x => ({
            imageUrl: x.avatarUrl,
            display: `@${x.firstName} ${x.lastName}`,
            id: x.id,
          })) || []),
          ...(response?.pods?.map(x => ({
            id: `${podIdPrefix}${x.id}`,
            display: x.name,
          })) || []),
        ];
        setContributionParticipants(userss);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const resetParticipantsList = resetEmailFlag => {
    setContributionParticipants([]);
    if (resetEmailFlag) setEmailAllMembers(false);
  };

  const handleRemoveAttachment = index => {
    if (formRef.current) {
      const linkId = formRef.current.values.attachments[index].id;
      const newAttachments = filter(formRef.current.values.attachments, attachment => attachment.id !== linkId);
      removeAttachmentForUnifiedCommunity(linkId)
        .then(() => {
          formRef.current.setFieldValue('attachments', newAttachments);
        })
        .catch(err => {});
    }
  };

  const handleEditorChange = useCallback((plainText, mentions) => {
    const editorContent = convertToPlainText(plainText.trim());
    if (editorContent.includes('@channel')) {
      setEmailAllMembers(true);
    }
    const filteredMentions = mentions.filter(obj => obj.id !== obj.display);
    setUserMentions(filteredMentions);
    const hash = editorContent.match(/#[^\s]+/g);
    formRef.current.setFieldValue('textmessage', editorContent);
    formRef.current.setFieldValue('hashTags', hash);
  });

  const submitPost = () => {
    let data = [];
    let contributionIds = R.pluck('id', formRef.current.values.contributions);
    if (formRef.current.values?.contributions?.length === 0) {
      setErrorMessage(true);
      document.getElementById('contributions-autocomplete').scrollIntoView({
        behavior: 'smooth',
      });
      return;
    } else if (
      formRef.current.values.contributions.length === 1 &&
      formRef.current.values.contributions.includes('All')
    ) {
      contributionIds = allContributionIds;
    }
    setIsPosting(true);
    if (contributionIds.length === 1 && contributionIds[0] !== 'All') {
      let mentionedUserIds = [];
      if (!_.isEmpty(userMentions) || emailAllMembers) {
        if (userMentions.some(e => e.id === 'channelId') || emailAllMembers) {
          mentionedUserIds = _.uniq(contributionParticipants.map(x => x.id).filter(id => id !== 'channelId'));
        } else {
          mentionedUserIds = _.uniq([
            ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
            ...userMentions.filter(x => x.id.includes(podIdPrefix)),
          ]).filter(x => !_.isEmpty(x));
        }
      }
      post({
        contributionId: contributionIds[0],
        text: formRef.current.values.textmessage,
        isScheduled: false,
        scheduledTime: currentDate,
        isDraft: false,
        isPrivate: false,
        attachments: formRef.current.values.attachments,
        hashTags: formRef.current.values.hashTags,
        taggedUserIds: mentionedUserIds,
      })
        .then(response => {
          notifyTaggedUsers({
            mentionAuthorUserName: `${user?.firstName} ${user?.lastName}`,
            message: formRef.current.values.textmessage,
            contributionName: formRef.current.values.contributions[0]?.title,
            mentionedUserIds,
            replyLink: `https://${domain}/contribution-view/${contributionIds[0]}/community/social/${response?.id}`,
            AuthorUserId: user?.id,
            ContributionId: contributionIds[0],
            PostId: response?.id,
          });
          if (formRef.current) {
            formRef.current.setFieldValue('contributions', []);
            formRef.current.setFieldValue('textmessage', '');
            formRef.current.setFieldValue('attachments', []);
            formRef.current.setFieldValue('shareOnFacebook', false);
            formRef.current.setFieldValue('shareOnInstagram', false);
            formRef.current.setFieldValue('hashTags', []);
            setErrorMessage(false);
          }
          setIsPosting(false);
          setIsPostModalOpened(!isPostModalOpened);
          reloadPostsOnUpload();
        })
        .catch(err => {});
    } else {
      data = contributionIds.map(id => ({
        contributionId: id,
        text: formRef.current.values.textmessage,
        isScheduled: false,
        scheduledTime: currentDate,
        isDraft: false,
        isPrivate: false,
        attachments: formRef.current.values.attachments,
        hashTags: formRef.current.values.hashTags,
      }));
      createMutiplePosts(data)
        .then(res => {
          if (res?.length) {
            if (formRef.current) {
              formRef.current.setFieldValue('contributions', []);
              formRef.current.setFieldValue('textmessage', '');
              formRef.current.setFieldValue('attachments', []);
              formRef.current.setFieldValue('shareOnFacebook', false);
              formRef.current.setFieldValue('shareOnInstagram', false);
              formRef.current.setFieldValue('hashTags', []);
              setErrorMessage(false);
            }
            setIsPosting(false);
            setIsPostModalOpened(!isPostModalOpened);
            reloadPostsOnUpload();
          }
        })
        .catch(err => {
          setIsPosting(false);
        });
    }
  };

  const getFooterComponent = (values, properties) => {
    return (
      <StyledFooter>
        {isCoach && (
          <div style={{ width: '100%' }}>
            <Tooltip
              title={
                values.contributions.length > 1 ? 'This option is available when a single service is selected' : ''
              }
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    disabled={values.contributions.length > 1}
                    checked={emailAllMembers}
                    onChange={event => {
                      setEmailAllMembers(event.target.checked);
                    }}
                    style={{
                      color: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black',
                      background: 'transparent',
                    }}
                  />
                }
                label="Email All Members"
                style={{ width: 'max-content', marginBottom: '0px' }}
              />
            </Tooltip>
            <Tooltip
              title="Check this box to email all members or type ‘@channel’ in your posts. This is an addition to your clients receiving notifications on Cohere mobile app."
              arrow
              enterTouchDelay={TOOLTIP.ENTER_DELAY}
              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
            >
              <InfoIcon
                htmlColor={isUnifiedCommunity ? colors.lightBrown : colorToUse.PrimaryColorCode}
                style={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </div>
        )}
        <StyledFooterButtons>
          <ButtonProgress
            style={{ height: '40px', padding: '0px 1.25rem' }}
            {...properties}
            autoWidth
            type="submit"
            id="post-create-form"
            disabled={isPosting || (values?.textmessage?.length === 0 && values?.attachments?.length === 0)}
            onClick={submitPost}
          >
            Post
          </ButtonProgress>
        </StyledFooterButtons>
      </StyledFooter>
    );
  };

  const getitems = () => {
    return [
      ...(communities?.map(item => ({ ...item, label: item.title })) || []),
      ...(isCoach ? [{ id: 'All', label: 'All My Communities', image: '' }] : []),
    ];
  };

  useEffect(() => {
    if (!isPostModalOpened) {
      setTriggerAttachment(false);
    }
  }, [isPostModalOpened]);

  useEffect(() => {
    if (triggerAttachment) {
      setTimeout(() => {
        fileRef.current.click();
      }, 300);
    }
  }, [triggerAttachment]);

  return (
    <StyledContainer>
      <div style={{ display: 'flex' }}>
        <AvatarComponent alt={`${user?.firstName} ${user?.lastName}`} src={user?.avatarUrl} mobileView={mobileView}>
          {`${user?.firstName && getInitialSymbol(user?.firstName)}${
            user?.lastName && getInitialSymbol(user?.lastName)
          }`}
        </AvatarComponent>
        <div
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={e => {
            e.stopPropagation();
            setIsPostModalOpened(!isPostModalOpened);
          }}
        >
          <SearchBarComponent
            darkPlaceHolder={true}
            placeholder="What would you like to share?"
            searchFilter={message}
            onSearchFilterChange={setMessage}
            NotForSearch
            disabled
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: mobileView ? 'space-around' : 'flex-end',
          marginTop: '8px',
        }}
      >
        <div
          style={{
            width: '50%',
            color: isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: '600',
            cursor: 'pointer',
            fontSize: mobileView ? '12px' : '14px',
            gap: '8px',
            height: '40px',
          }}
          onClick={() => {
            setIsPostModalOpened(!isPostModalOpened);
            setTriggerAttachment(true);
          }}
        >
          <CommunityPhotoSvg
            color={isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode}
          />
          Add Photo
        </div>
        <div
          style={{
            width: '50%',
            color: isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode,
            display: 'flex',
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: '600',
            fontSize: mobileView ? '12px' : '14px',
            gap: '8px',
            height: '40px',
          }}
          onClick={() => {
            setIsPostModalOpened(!isPostModalOpened);
            setTriggerAttachment(true);
          }}
        >
          <CommunityVideoSvg
            color={isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode}
          />
          Add Video/Attachment
        </div>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={{
          contributions: [],
          textmessage: message,
          attachments: [],
          shareOnFacebook: false,
          shareOnInstagram: false,
          hashTags: [],
        }}
      >
        {({ values, setFieldValue, handleSubmit }) => {
          return (
            <Grid item sm={12} xs={12} style={{ width: 400 }}>
              <StyledModal
                title="Create a post"
                isOpen={isPostModalOpened}
                hiddenCancel
                onCancel={() => {
                  onClickClosePostModal();
                }}
                minWidth={mobileView ? '94vw' : '620px'}
                maxWidth={mobileView ? '94vw' : '620px'}
                widthRequiredIs
                disableOverlayClick
                mobileView={mobileView}
                createPost={true}
                disableFooter
                disableConfirm
              >
                <div className="create-post-root-unified">
                  <Grid
                    style={{ marginBottom: '16px', position: 'relative' }}
                    item
                    sm={12}
                    xs={12}
                    id="contributions-autocomplete"
                  >
                    <div style={{ fontSize: '14px', fontWeight: '400', marginBottom: '8px' }}>
                      Select one or more communities to post in.
                    </div>
                    {/* {!values.contributions ||
                    (values.contributions?.length === 0 && (
                      <div
                        style={{
                          color: 'rgba(0, 0, 0, 0.87)',
                          opacity: '50%',
                          position: 'absolute',
                          marginTop: '7px',
                          marginLeft: '10px',
                        }}
                      >
                        Select communities
                      </div>
                    ))} */}
                    <CommunitiesAutoComplete
                      items={getitems()}
                      setFieldValue={setFieldValue}
                      mobileView={mobileView}
                      values={values}
                      fetchParticipants={fetchContributionParticipants}
                      resetParticipantsList={resetParticipantsList}
                    />
                    {/* {isCoach ? (
                    <StyledSelect
                      mobileView={mobileView}
                      style={mobileView ? { width: '320px' } : { width: '100%' }}
                      name="contributions"
                      value={values.contributions}
                      disabled={false}
                      variant="outlined"
                      multiple
                      fullWidth
                      items={communities
                        ?.map(item => ({
                          title: item.title,
                          value: item.id,
                        }))
                        .concat([{ id: 'All', title: 'All My Communities' }])}
                      onChange={e => {
                        let values = e.target.value;
                        if (values.length > 1 && values.includes('All')) {
                          const firstElement = values[0];
                          const lastElement = values[values.length - 1];
                          if (firstElement === 'All') {
                            values = values.filter(val => val != 'All');
                          } else if (lastElement === 'All') {
                            values = values.filter(val => val === 'All');
                          } else {
                            values = values;
                          }
                        }
                        setFieldValue('contributions', values);
                      }}
                    />
                  ) : (
                    <StyledSelect
                      style={mobileView ? { width: '320px' } : { width: '100%' }}
                      mobileView={mobileView}
                      name="contributions"
                      value={values.contributions}
                      disabled={false}
                      variant="outlined"
                      multiple
                      fullWidth
                      items={communities?.map(item => ({
                        title: item.title,
                        value: item.id,
                      }))}
                      onChange={e => {
                        let values = e.target.value;
                        if (values.length > 1 && values.includes('All')) {
                          const firstElement = values[0];
                          const lastElement = values[values.length - 1];
                          if (firstElement === 'All') {
                            values = values.filter(val => val != 'All');
                          } else if (lastElement === 'All') {
                            values = values.filter(val => val === 'All');
                          } else {
                            values = values;
                          }
                        }
                        setFieldValue('contributions', values);
                      }}
                    />
                  )} */}
                    {values.contributions.length === 0 && errorMessage && (
                      <CommonErrorMessage align="left" message="Please select contribution" />
                    )}
                  </Grid>
                  <Grid style={{ marginBottom: '16px' }} item sm={12} xs={12}>
                    <div
                      style={{
                        width: '100%',
                        minHeight: '130px',
                        border: '1px solid rgba(223, 227, 228, 1)',
                        borderRadius: '4px',
                      }}
                    >
                      <UserMentionInput
                        placeholder="What would you like to share?"
                        isCreate={true}
                        defaultValue={values?.textmessage?.trimStart()}
                        users={contributionParticipants}
                        onInputChange={handleEditorChange}
                        resetInputValue={resetUserMentionInputValue}
                        onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                        hashTags={hashtags}
                        minHeight="130px"
                      />
                    </div>
                  </Grid>
                  <Grid
                    style={{
                      marginBottom: '0px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: isFileUpload ? '10px' : '',
                    }}
                    item
                    sm={12}
                    xs={12}
                  >
                    {isFileUpload ? (
                      <StyledProgressWrap position="relative">
                        <CircularProgressWithLabel color={colorToUse?.AccentColorCode} value={progress} />
                      </StyledProgressWrap>
                    ) : (
                      <>
                        <input
                          style={{
                            width: '100%',
                            color: '#47627B',
                            textTransform: 'none',
                            borderRadius: '30px',
                            fontWeight: '600',
                            border: `1px solid #CDBA8F`,
                          }}
                          multiple
                          accept="*"
                          className="d-none"
                          id={`attach-media`}
                          type="file"
                          ref={fileRef}
                          onChange={event => {
                            event.preventDefault();
                            handleUploadFile(event);
                          }}
                        />

                        <StyledAttachmentsContainer>
                          <label
                            htmlFor={`attach-media`}
                            style={{
                              marginBottom: 0,
                              cursor: 'pointer',
                              fontWeight: '600',
                              fontSize: mobileView ? '12px' : '14px',
                              width: '100%',
                              color:
                                isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode,
                              textTransform: 'none',
                              borderRadius: '4px',
                              border: `1px solid #DFE3E4`,
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '10px',
                              alignItems: 'center',
                              gap: '8px',
                              height: '40px',
                            }}
                          >
                            <CommunityPhotoSvg
                              color={
                                isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode
                              }
                            />{' '}
                            Add Photo
                          </label>

                          <label
                            htmlFor={`attach-media`}
                            style={{
                              marginBottom: 0,
                              cursor: 'pointer',
                              fontSize: mobileView ? '12px' : '14px',
                              width: '100%',
                              color:
                                isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode,
                              textTransform: 'none',
                              borderRadius: '4px',
                              fontWeight: '600',
                              border: `1px solid #DFE3E4`,
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '10px',
                              alignItems: 'center',
                              gap: '8px',
                              height: '40px',
                            }}
                          >
                            <CommunityVideoSvg
                              color={
                                isUnifiedCommunity && !isDarkThemeEnabled ? '#215c73' : colorToUse?.AccentColorCode
                              }
                            />{' '}
                            Add Video/Attachment
                          </label>
                        </StyledAttachmentsContainer>
                      </>
                    )}
                  </Grid>
                  {values?.attachments && (
                    <Grid style={{ marginBottom: '2rpx' }} item sm={12} xs={12}>
                      <Attachments
                        pending={loading}
                        attachments={values?.attachments}
                        removeAttachment={handleRemoveAttachment}
                        createPost={true}
                        colorToUse={colorToUse}
                        unifiedCommunity
                      />
                    </Grid>
                  )}
                  {/* <Grid
                  style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  item
                  sm={12}
                  xs={12}
                >
                   <Button
                    style={{
                      background: 'white',
                      border: `1px solid ${colorToUse?.PrimaryColorCode}`,
                      marginTop: 10,
                      width: '100%',
                      borderRadius: '30px',
                      color: colorToUse?.PrimaryColorCode,
                      textTransform: 'none',
                    }}
                    onClick={() => {
                      setIsHashtagModalOpen(true);
                    }}
                  >
                    Add Hashtags
                  </Button> 
                </Grid> */}

                  {/* <Grid item sm={12} xs={12}>
                  <div
                    style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-start' }}>
                      <img src={FacebookIcon} alt={'Facebook-Icon'} />{' '}
                      <div
                        style={{
                          width: '250px',
                          alignItems: 'center',
                          display: 'flex',
                          paddingLeft: '5px',
                          fontWeight: '500',
                          fontFamily: 'Poppins',
                          color: '#47627B',
                        }}
                      >
                        Share on Facebook
                      </div>
                    </div>
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Checkbox
                        id="create-contribution-agreement"
                        color="primary"
                        checked={values.shareOnFacebook}
                        name="shareOnFacebook"
                        onChange={e => setFieldValue('shareOnFacebook', e.target.checked)}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid style={{ marginBottom: '19px' }} item sm={12} xs={12}>
                  <div
                    style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                  >
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-start' }}>
                      <img src={InstagramIcon} alt={'Instagram-Icon'} />{' '}
                      <div
                        style={{
                          width: '250px',
                          alignItems: 'center',
                          display: 'flex',
                          paddingLeft: '5px',
                          fontWeight: '500',
                          fontFamily: 'Poppins',
                          color: '#47627B',
                        }}
                      >
                        Share on Instagram
                      </div>
                    </div>
                    <div style={{ flex: 1, padding: '5px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Checkbox
                        id="create-contribution-agreement"
                        color="primary"
                        name="shareOnInstagram"
                        checked={values.shareOnInstagram}
                        onChange={e => setFieldValue('shareOnInstagram', e.target.checked)}
                      />
                    </div>
                  </div>
                </Grid> */}
                </div>
                {getFooterComponent(values, {
                  disabled: isPosting || (values?.textmessage?.length === 0 && values?.attachments?.length === 0),
                  pending: isPosting,
                })}
              </StyledModal>
              <FieldArray name="hashTags">
                {({ push, remove, form }) => {
                  const pushNewHashtag = newHashtag => {
                    push(newHashtag);
                    setHashtag('');
                  };
                  const removeHashtag = item => {
                    const index = form?.values?.hashTags?.indexOf(item);
                    remove(index);
                  };
                  return (
                    <>
                      <Modal
                        isOpen={isHashtagModalOpen}
                        onCancel={() => {
                          setIsHashtagModalOpen(false);
                        }}
                        submitTitle="Add"
                        onSubmit={() => {
                          if (hashtag?.length > 0) {
                            pushNewHashtag(hashtag);
                          } else {
                            closeHashtagModal();
                          }
                        }}
                        cancelTitle="Cancel"
                        title="Add Hashtags"
                        widthRequiredIs
                      >
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                          <div>
                            <TextField
                              className=""
                              variant="outlined"
                              placeholder="Write here..."
                              value={hashtag}
                              // name="hashTagInput"
                              onChange={e => {
                                setHashtag(e.target.value.trim());
                              }}
                              onFocus={e => e.stopPropagation()}
                              InputProps={
                                {
                                  // style: { color: themedColor },
                                }
                              }
                              fullWidth
                            />
                          </div>
                          <div style={{ marginTop: '20px', display: 'flex', flexWrap: 'wrap' }}>
                            {form?.values?.hashTags?.map((item, i) => (
                              <StyledHashtag color={colors.darkOceanBlue}>
                                <StyledRemove onClick={() => removeHashtag(item)}>
                                  <StyledCross />
                                </StyledRemove>
                                {item}
                              </StyledHashtag>
                            ))}
                          </div>
                        </div>
                      </Modal>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
          );
        }}
      </Formik>

      {image_video_Limit && (
        <>
          <Modal
            isOpen={image_video_Limit}
            onCancel={() => {
              setImageVideo_Limit(false);
            }}
            widthRequiredIs
            handleOnCancel
            disableConfirm
            cancelTitle="OK"
            title="File Uploading Restrictions"
          >
            <p> You are allowed to upload only 5 images OR 1 video per post. </p>
          </Modal>
        </>
      )}

      <Modal
        isOpen={liveVideoSession}
        onCancel={() => {
          setliveVideoSession(false);
        }}
        widthRequiredIs
        handleOnCancel
        disableConfirm
        cancelTitle="OK"
        title="Live Video"
      >
        <p style={{ justifyContent: 'center', display: 'flex' }}> Coming Soon... </p>
      </Modal>
      <hr style={{ width: '100%', marginTop: '8px', marginBottom: '0px' }} />
    </StyledContainer>
  );
};

export default CreatePostSection;
