import React, { useState } from 'react';
import '../../ViewAsCoachPage.scss';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { StyledSection, StyledButton } from './StyledElements';
import useRouter from 'hooks/useRouter';
import { lightOrDark, replaceBreakToBr, wrapText } from 'utils/utils';
import { openInNewWindow } from 'services/links';

function LetsWorkTogether({ theme, contributions, brandingColors, data, label = 'Let’s work together', accountId }) {
  const allContributions = contributions || [];
  const color = theme === 'dark' ? 'white' : 'black';
  const outline = theme === 'dark' ? '#2A2C2E' : 'black';
  const defaultPrimaryColor = theme === 'dark' ? '#2A2C2E' : 'white';
  const backgroundColor = brandingColors.PrimaryColorCode || defaultPrimaryColor;
  const accentColor = brandingColors.AccentColorCode || '#000000';
  const [isReadMore, setIsReadMore] = useState({});
  const muiTheme = useTheme();
  const isMobileSidebarNeeded = useMediaQuery(muiTheme.breakpoints.down(768));
  const dynamicCol = !isMobileSidebarNeeded && 'col-lg-6';
  const dynamicRow = !isMobileSidebarNeeded && 'row';
  const dynamicTextAlign = isMobileSidebarNeeded && 'text-center';
  const { domain } = useRouter();
  const textColor =
    brandingColors?.TextColorCode === 'Auto'
      ? lightOrDark(brandingColors?.PrimaryColorCode)
      : brandingColors?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  // const toggleReadMore = index => {
  //   let newIsReadMore = isReadMore;
  //   if (index in isReadMore) {
  //     delete newIsReadMore[index];
  //   } else {
  //     newIsReadMore = { ...newIsReadMore, [index]: true };
  //   }
  //   setIsReadMore({ ...newIsReadMore });
  // };
  return (
    <>
      {allContributions.length > 0 && (
        <StyledSection id="webView" className=" section about-new-text" color={color}>
          <div className="website-section-container">
            <h1 style={{ color }} className="section-header-text">
              {' '}
              {data?.contributionLabel?.length === 0 || data?.contributionLabel === null
                ? label
                : data?.contributionLabel}
            </h1>
            <div className="website-contributions">
              {allContributions
                .filter(contribution => {
                  if (contribution.isEnabled) {
                    return true;
                  }
                  return false; // skip
                })
                .map((record, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        // border: '1px solid',
                        backgroundColor: theme === 'dark' ? '#2A2C2E' : '#FAFAFA',
                        // borderColor: theme === 'dark' ? 'transparent' : '#DFE3E4',
                      }}
                      className={`${dynamicRow}  website-contribution-row`}
                    >
                      <div className={`${dynamicCol} image-container`}>
                        <picture>
                          <img src={record?.imageUrl} alt="" />
                        </picture>
                      </div>
                      <div className={`${dynamicCol} text-side`}>
                        <div className={`text ${dynamicTextAlign}`}>
                          <h1 style={{ color }} className="contribution-heading">
                            {record?.title ? record?.title : ''}
                          </h1>
                          {record?.description && (
                            <p className={`text ${dynamicTextAlign}`}>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    record?.description?.split(' ').length > 50
                                      ? `${record?.description.split(' ').slice(0, 50).join(' ')}...`
                                      : record?.description,
                                }}
                              />
                              {/* {record?.description.length > 100 && (
                                <span
                                  onClick={() => toggleReadMore(index)}
                                  className="read-or-hide"
                                  style={{ color: color }}
                                >
                                  {!isReadMore[index] ? ' ...read more' : ' show less'}
                                </span>
                              )} */}
                            </p>
                          )}
                          <StyledButton
                            bgColor={backgroundColor}
                            hoverBgColor={'transparent'}
                            hoverColor={theme === 'dark' ? 'white' : 'black'}
                            hoverBorder={`1px solid ${theme === 'dark' ? 'white' : 'black'} !important`}
                            // style={{
                            //   borderRadius: '5px',
                            //   padding: '8px 18px',
                            // }}
                            outline={outline}
                            color={textColor}
                            className="inline-btn btn-small"
                            onClick={() => {
                              if (record.contributionPaymentType === 'Free') {
                                record.isLeadMagnet
                                  ? openInNewWindow(`https://${domain}/lead-magnet-view/${record?.id}/landingpage`)
                                  : window
                                      .open(
                                        `https://${domain}/contribution-view/${record?.id}/${record?.accessCode}/about`,
                                        '_blank',
                                      )
                                      .focus();
                              } else if (record.contributionPaymentType === 'Free Application') {
                                record.isLeadMagnet
                                  ? openInNewWindow(`https://${domain}/lead-magnet-view/${record?.id}/landingpage`)
                                  : window
                                      .open(
                                        `https://${domain}/apply-contribution-view/${record?.id}/${record?.accessCode}/about`,
                                        '_blank',
                                      )
                                      .focus();
                              } else if (record.contributionPaymentType === 'Paid Application') {
                                if (record.priceId) {
                                  window
                                    .open(
                                      `https://${domain}/apply-contribution-view/${record?.id}/${record?.priceId}/about`,
                                      '_blank',
                                    )
                                    .focus();
                                } else {
                                  window
                                    .open(`https://${domain}/apply-contribution-view/${record?.id}/about`, '_blank')
                                    .focus();
                                }
                              } else {
                                if (record.priceId) {
                                  window
                                    .open(
                                      `https://${domain}/contribution-view/${record.id}/${record?.priceId}/about`,
                                      '_blank',
                                    )
                                    .focus();
                                } else {
                                  window
                                    .open(`https://${domain}/contribution-view/${record.id}/about`, '_blank')
                                    .focus();
                                }
                              }
                            }}
                          >
                            {accountId === '672a829340dd7a1ce7a46923' ? 'JOIN NOW' : 'Learn More'}
                          </StyledButton>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </StyledSection>
      )}
    </>
  );
}

export default LetsWorkTogether;
