import React from 'react';

const CommunityLikeSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9418 13.245L12.0063 21L5.07247 13.2468C2.39333 10.4473 2.50655 6.94235 4.30099 4.81065C5.19657 3.74675 6.50884 3.04097 8.03356 3.00212C9.33205 2.96904 10.693 3.42062 12.0041 4.4002C13.3111 3.42057 14.6691 2.96865 15.9659 3.00169C17.4888 3.04049 18.7997 3.7468 19.695 4.81006C21.4884 6.93997 21.6103 10.4444 18.9418 13.245ZM15.9134 4.94434C15.0257 4.92172 13.9048 5.28053 12.6875 6.38424L12.0066 7.00162L11.3245 6.38551C10.1022 5.2815 8.97707 4.92207 8.08598 4.94477C7.19427 4.96749 6.41037 5.3742 5.84903 6.04103C4.73245 7.36747 4.48645 9.78935 6.54681 11.9336L6.55898 11.9463L12.0063 18.0373L17.4538 11.9462L17.4652 11.9343C19.5142 9.79193 19.2656 7.36906 18.1471 6.04063C17.5851 5.37316 16.802 4.96698 15.9134 4.94434Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityLikeSvg;
