import { useTheme, useMediaQuery, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { useHeader, useRouter, useUnifiedCommunity } from 'hooks';
import NoImage from 'assets/NoImage.png';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { fetchCommunities, toggleConfirmationModal } from 'actions/community';
import { Skeleton } from '@mui/material';
import { formatPostTime } from 'utils/utils';
import useAccount from 'hooks/useAccount';
import { UserRoles } from 'helpers/constants';

const StyledMainSection = styled.div`
  height: ${({ containerHeight }) => `calc(100vh - ${containerHeight}px)`};
  max-width: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  gap: 8px;
  width: 100%;
  max-width: 100%;

  &:hover {
    cursor: pointer;
    background-color: #f3f3f3 !important;
    border-radius: 4px;
    overflow: hidden;
    .MuiSvgIcon-root {
      display: inline-block !important;
    }
  }
`;

const StyledInnerContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;
  align-items: center;

  div:first-child {
    width: 80%;
  }
  div:last-child {
    display: flex;
    gap: 5px;
  }
  ${({ mobileView }) =>
    mobileView &&
    `
    width: 55%;
  `}
`;

const StyledCommunityTag = styled.div`
  font-family: Avenir;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 8px;
  height: 22px;
  font-size: 10px;
  color: #a58b62;
  background-color: rgba(209, 185, 137, 0.1);
  width: fit-content !important;
  ${({ mobileView }) =>
    mobileView &&
    `
  padding: 2px 8px;
  place-content: center;
`}
`;

const StyledOpenInNewOutlinedIcon = styled(OpenInNewOutlinedIcon)`
  display: none !important;
  opacity: 45%;
  font-size: 18px !important;
`;

const CommunityList = ({ communityType, isCoach, containerHeight }) => {
  const selector = state => state?.communities;
  const { isUnifiedCommunity } = useUnifiedCommunity();
  useHeader(isUnifiedCommunity ? 'All Posts' : 'Communities');
  const { roles } = useAccount();

  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const { communities, loading, limitReached } = useSelector(selector);
  const [number, setNumber] = useState(0);
  const dispatch = useDispatch();
  const observerRef = useRef(null);
  const isCoachAndClientRolesAvailable = roles.includes(UserRoles.client) && roles.includes(UserRoles.cohealer);

  const getCommunities = pageNumber => {
    dispatch(fetchCommunities({ communityType, number: pageNumber, shouldRetain: isCoachAndClientRolesAvailable }));
  };

  useEffect(() => {
    setNumber(0);
    getCommunities(0);
  }, [communityType]);

  useEffect(() => {
    if (observerRef.current) observerRef.current.disconnect();

    const lastItem = document.querySelector('.community-item:last-child');

    if (lastItem) {
      observerRef.current = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !limitReached && communities.length > 14) {
            setNumber(prevNumber => prevNumber + 1);
            getCommunities(number + 1);
          }
        },
        { root: null, threshold: 1.0 }, // Adjust threshold as needed
      );

      observerRef.current.observe(lastItem);
    }
  }, [communities]);

  const getSkeletonLoading = () => {
    return (
      <StyledContainer>
        <Skeleton variant="rectangular" sx={{ width: '112px', height: '62px', borderRadius: '5px' }} />
        <StyledInnerContent mobileView={mobileView}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontWeight: '500', fontSize: '16px' }}>
              <Skeleton variant="text" />
            </span>
            <span style={{ fontWeight: '500', fontSize: '13px' }}>
              <Skeleton variant="text" />
            </span>
            <span style={{ fontWeight: '400', fontSize: '12px', opacity: '50%' }}>
              <Skeleton variant="text" />
            </span>
          </div>
        </StyledInnerContent>
      </StyledContainer>
    );
  };

  const handleOpenCommunity = (contributionId, contributionTitle, isMyCommunity) => {
    if ((isCoach && isMyCommunity) || (!isCoach && !isMyCommunity)) {
      window.open(`${window.location.origin}/contribution-view/${contributionId}/community`, '_blank');
    } else {
      dispatch(
        toggleConfirmationModal({
          showConfirmationModal: true,
          selectedContributionId: contributionId,
          selectedContributionName: contributionTitle,
        }),
      );
    }
  };

  return (
    <>
      <StyledMainSection
        mobileView={mobileView}
        className="communities-list"
        containerHeight={communityType === 'All' ? containerHeight : containerHeight - 55}
      >
        {communities?.map(({ title, id, postsCount, lastActivity, bannerImage, coachName, isMyCommunity }) => (
          <StyledContainer
            key={id}
            className="community-item"
            onClick={() => {
              handleOpenCommunity(id, title, isMyCommunity);
            }}
          >
            <img
              style={{
                width: '112px',
                height: '62px',
                borderRadius: '5px',
                objectFit: 'cover',
              }}
              src={bannerImage === null ? NoImage : bannerImage}
              alt={title}
            />
            <StyledInnerContent mobileView={mobileView} style={{ flexDirection: 'column' }}>
              <span
                style={{
                  fontWeight: '500',
                  fontSize: '16px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  width: '100%',
                }}
                title={title}
              >
                {title}
              </span>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span style={{ fontWeight: '500', fontSize: '13px' }}>
                    {isMyCommunity ? (
                      communityType === 'All' ? (
                        <StyledCommunityTag mobileView={mobileView}>MY COMMUNITY</StyledCommunityTag>
                      ) : (
                        ''
                      )
                    ) : (
                      coachName
                    )}
                  </span>
                  <span style={{ fontWeight: '400', fontSize: '12px', opacity: '50%' }}>
                    Last Activity {formatPostTime(lastActivity)}
                  </span>
                </div>
                <div>
                  {postsCount > 0 && (
                    <Typography
                      style={{
                        backgroundColor: '#215C73',
                        borderRadius: 10,
                        fontSize: '10px',
                        fontWeight: '900',
                        height: '16px',
                        color: 'white',
                        padding: '1px 5px',
                        textAlign: 'center',
                      }}
                    >
                      {postsCount}
                    </Typography>
                  )}
                  <StyledOpenInNewOutlinedIcon />
                </div>
              </div>
            </StyledInnerContent>
          </StyledContainer>
        ))}
        {loading && (
          <LoaderContainer>
            {Array.from({ length: !mobileView ? 8 : 4 }).map(() => (
              <>{getSkeletonLoading()}</>
            ))}
          </LoaderContainer>
        )}
      </StyledMainSection>
    </>
  );
};

CommunityList.propTypes = {
  communityType: PropTypes.string.isRequired,
  isCoach: PropTypes.bool.isRequired,
  containerHeight: PropTypes.number.isRequired,
};

export default CommunityList;
