import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getIn } from 'formik';
import { useRouter } from 'hooks';
import { Icon } from '@mdi/react';
import { mdiMinusCircleOutline, mdiPlus } from '@mdi/js';
import moment from 'moment';
import TimePickerField from 'components/FormUI/TimePickerField';
import Tooltip from '@material-ui/core/Tooltip';
import { useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import Modal from 'components/UI/Modal';
import { SessionMoreInfo } from 'components/Session/SessionMoreInfo/SessionMoreInfo';
import { SessionTimeSelfPaced } from 'components/Session/SessionTime/SessionTimeSelfPaced';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { MemberPodsToSessionTimeAssignment } from 'components/MemberPods';
import { UserRoles } from 'helpers/constants';
import useAccount from 'hooks/useAccount';
import usePaidTier from 'hooks/usePaidTier';
import { colors, toRem } from 'utils/styles';
import { getNextCurrentSessionTime } from 'utils/getSessionTime';
import orderBy from 'lodash/orderBy';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core';
import CustomSelect from 'components/FormUI/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@mui/material/Radio';
import '../../Profile/profilepage.scss';
import { TOOLTIP } from '../../../constants';
import InfoIcon from '@material-ui/icons/Info';
import { ConnectingAirportsOutlined } from '@mui/icons-material';
import UploadThumbnail from './UploadThumbnail';
import useShallowEqualSelector from '../../../hooks/useShallowEqualSelector';
import { PAID_TIER_TITLES } from '../../../constants';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import * as paidTierActions from 'actions/paidTierOptions';
import { setCohereAcademyStatus } from 'actions/update-user';
import * as paidTierService from 'services/paidTierOptions.service';
import { getBoughtByType } from 'services/contributions.service';
import * as paidTier from 'selectors/paidTier';
import { ContributionType } from 'helpers/constants';
const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
`;

const StyledInput = withStyles(() => ({
  underline: {
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: `none`,
      // borderBottom: props => (props.underLineColor ? `2px solid ${props.underLineColor}` : ''),
    },
    '&:after': {
      borderBottom: `none`,
    },
    '&:before': {
      borderColor: 'none',
      borderBottom: 'none !important',
    },
    border: 'none',
    borderRadius: 'none !important',
  },
}))(Input);
const StyledLink = styled.span`
  width: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-family: Avenir;
  font-weight: 800;
  font-size: ${toRem(16)};
  line-height: 21.06px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  // margin-top: 16px;
  &:hover {
    text-decoration: underline;
  }
`;
const StyledSelect = styled(Select)`
  width: calc(100% - 33px);
`;

const StyledCustomSelect = styled(CustomSelect)`
  .MuiInputBase-root {
    .MuiSelect-icon {
      right: 10px !important;
    }
  }
`;

export const SessionTime = ({
  isPrerecorded,
  enrollment,
  form,
  push,
  remove,
  sessionIdx,
  timesCount,
  computeDifference,
  isOneToOne,
  videoDuration,
  dragging,
  isSessionOpen,
}) => {
  const WeekValues = [
    { title: 'Monday', value: 'Monday' },
    { title: 'Tuesday', value: 'Tuesday' },
    { title: 'Wednesday', value: 'Wednesday' },
    { title: 'Thursday', value: 'Thursday' },
    { title: 'Friday', value: 'Friday' },
    { title: 'Saturday', value: 'Saturday' },
    { title: 'Sunday', value: 'Sunday' },
  ];
  const { sessions } = form.values;
  const currentSession = sessions[sessionIdx];
  const [recurringCheckBox, setRecurringCheckBox] = useState(false);
  const theme = useTheme();
  const todayDay = moment(moment(currentSession?.sessionTimes[0]?.startTime)).format('dddd');
  const todayDate = moment(moment(currentSession?.sessionTimes[0]?.startTime)).format('D');
  const digitsOnly = value => /^\d+$/.test(value);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [recuringConfirmation, setRecuringConfirmation] = useState(false);
  const { currentRole } = useAccount();
  // const { isScalePlan } = usePaidTier();
  const [addMoreTimeOptionModal, setaddMoreTimeOptionModal] = useState(false);
  const error = getIn(form.errors, `sessions[${sessionIdx}]`);
  const showError = getIn(form.touched, `sessions[${sessionIdx}]`)?.sessionTimes && !!error;
  const [supportPopup, setSupportPopup] = useState(true);
  const [recurringModal, setRecurringModal] = useState(false);
  const dispatch = useDispatch();

  const [isLoader, setIsLoader] = useState({
    paidTierPlans: false,
    activePaidTierPlan: false,
    academyMemberships: false,
    activeAcademyMemberships: false,
  });

  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const academyContributions = useSelector(paidTier.cohereAcademyContributionsSelector);
  const isScalePlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.scale;

  const getAllPaidTiers = useCallback(() => paidTierService.getPaidTierOptions(), []);
  const getActivePaidTier = useCallback(() => paidTierService.getCurrentPlan(), []);

  // Correct behavior - 100%
  const filterMembership = useCallback(
    res => {
      const filteredMemberships = [];

      academyContributions.forEach(item => {
        const matchedMembership = res.find(el => el.id === item.id);

        if (matchedMembership && matchedMembership.isPurchased) {
          filteredMemberships.push(item);
        }
        return true;
      });

      return filteredMemberships;
    },
    [academyContributions],
  );

  const updateActiveContributionsState = useCallback(() => {
    return getBoughtByType(ContributionType.contributionMembership)
      .then(filterMembership)
      .then(matchedMemberships => {
        dispatch(paidTierActions.setActivePaidTierContributions(matchedMemberships));
      });
  }, [filterMembership]);

  useEffect(() => {
    // this is a temp solution, we need to find a way to not repopulate it again if not needed
    // TODO: don't get paid tiers plans if the right version for existing user is already loaded
    // if (paidTierPlans.length) {
    //   return;
    // }
    setIsLoader(s => ({ ...s, paidTierPlans: true }));

    getAllPaidTiers().then(data => {
      dispatch(paidTierActions.setPaidTierPlans(data));
      setIsLoader(s => ({ ...s, paidTierPlans: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activePaidTierPlan: true }));

    getActivePaidTier().then(data => {
      dispatch(paidTierActions.setActivePaidTierPlan(data));
      setIsLoader(s => ({ ...s, activePaidTierPlan: false }));
    });
  }, []);

  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  useEffect(() => {
    setIsLoader(s => ({ ...s, academyMemberships: true }));

    paidTierService.getAcademy().then(data => {
      dispatch(paidTierActions.setPaidTierContribution(data));
      setIsLoader(s => ({ ...s, academyMemberships: false }));
    });
  }, []);

  useEffect(() => {
    setIsLoader(s => ({ ...s, activeAcademyMemberships: true }));

    updateActiveContributionsState().then(() => {
      setIsLoader(s => ({ ...s, activeAcademyMemberships: false }));
    });
  }, []);
  const formRef = useRef(null);
  const findCountOf = date => {
    const c = date / 7;
    if (c > 4) {
      return 5;
    } else if (c > 3 && (c < 4 || c === 4)) {
      return 4;
    } else if (c > 2 && (c < 3 || c === 3)) {
      return 3;
    } else if (c > 1 && (c < 2 || c === 2)) {
      return 2;
    } else {
      return 1;
    }
  };
  const addTimesHandler = useCallback(() => {
    const lastSessionTime =
      currentSession.sessionTimes.length > 0 ? currentSession.sessionTimes[currentSession.sessionTimes.length - 1] : {};
    const sessionTime = getNextCurrentSessionTime(isOneToOne, lastSessionTime, enrollment);
    push(sessionTime);
    if (currentSession.sessionTimes.length >= 0) {
      setaddMoreTimeOptionModal(true);
    }
  }, [isOneToOne, push, currentSession]);

  const removeTimesHandler = useCallback(i => () => remove(i), [remove]);

  const mdView = useMediaQuery(theme.breakpoints.down('md'));

  const handleTimeChange = useCallback(
    (value, sessionTimeIdx, timeType) => {
      if (value) {
        const { startTime } = form.values.sessions[sessionIdx].sessionTimes[sessionTimeIdx];
        const currentDate = moment(startTime).format('YYYY-MM-DD');
        const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss');
        form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${timeType}`, currentTime);
        if (timeType === 'startTime') {
          const addedTime = 60;
          const endTimeValue = value.add(addedTime, 'minutes');
          const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss');
          form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endTime`, newEndTime);
        }
      }
    },
    [form, sessionIdx],
  );

  const handleOnAssignPod = useCallback(
    (value, sessionTimeIndex) => {
      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIndex}].podId`, value);
    },
    [form, sessionIdx],
  );

  const handleDatePick = (choosedMomentDate, sessionTimeIdx, dateType, timeType) => {
    const choosedDate = choosedMomentDate.format();
    const currentSessionTime = form.values.sessions[sessionIdx].sessionTimes[sessionTimeIdx];
    const startTime = moment(
      `${choosedMomentDate.format('YYYY-MM-DD')} ${moment(currentSessionTime.startTime).format('hh:mm a')}`,
      'YYYY-MM-DD hh:mm a',
    ).format('YYYY-MM-DDTHH:mm:ss');
    const endTime = moment(
      `${choosedMomentDate.format('YYYY-MM-DD')} ${moment(currentSessionTime.endTime).format('hh:mm a')}`,
      'YYYY-MM-DD hh:mm a',
    ).format('YYYY-MM-DDTHH:mm:ss');
    // const startTime = choosedMomentDate.format('YYYY-MM-DDTHH:mm:ss');
    // const endTime = choosedMomentDate.add(60, 'minutes').format('YYYY-MM-DDTHH:mm:ss');

    if (formRef.current != null) {
      formRef.current.setFieldValue('endDate', moment(startTime).add(90, 'days'));
    }
    if (isOneToOne) {
      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${dateType}`, choosedDate);

      form.setFieldValue(
        `sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].${timeType}`,
        timeType === 'startTime' ? startTime : endTime,
      );
    } else {
      if (isPrerecorded) {
        form.setFieldValue(`sessions[${sessionIdx}].dateAvailable`, choosedDate);
      }
      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].startDate`, choosedDate);

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endDate`, choosedDate);

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].startTime`, startTime);

      form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[${sessionTimeIdx}].endTime`, endTime);
    }
  };

  const onChangeMoreInfo = useCallback(
    event => form.setFieldValue(`sessions[${sessionIdx}].moreInfo`, event?.target?.value),
    [form, sessionIdx],
  );

  const isMemberPods = !isPrerecorded && isScalePlan && currentRole === UserRoles.cohealer;

  function isLastWeekOfMonth(year, month, day) {
    // Create a new date object with the selected date
    var selectedDate = new Date(year, month - 1, day);
    // Get the total number of days in the month
    var lastDay = new Date(year, month, 0).getDate();
    // Calculate the day of the week for the last day of the month
    var lastDayOfWeek = new Date(year, month - 1, lastDay).getDay();
    // Calculate the day of the week for the selected date
    var selectedDayOfWeek = selectedDate.getDay();
    // Determine if the selected date falls in the last week
    return lastDay - day <= 6 && selectedDayOfWeek >= lastDayOfWeek;
  }
  // Example usage

  let highestFalseIndex = -1;

  for (let i = 0; i < sessions?.length; i++) {
    if (!sessions[i]?.isPrerecorded) {
      highestFalseIndex = i;
    }
  }

  return (
    <Grid style={{ marginBottom: isPrerecorded ? 24 : '16' }}>
      {isSessionOpen && !!sessions[sessionIdx]?.sessionTimes?.length && (
        <Grid alignItems="flex-start" container>
          {sessions[sessionIdx].sessionTimes.map((sessionTime, i) => {
            const momentStartTime = moment(sessionTime.startTime);
            const momentEndTime = moment(sessionTime.endTime);
            return (
              <Grid
                container
                key={i}
                justify="space-between"
                spacing={2}
                style={
                  isPrerecorded
                    ? {
                        backgroundColor: '#fafafa',
                        borderRadius: 4,
                        border: '1px solid #fafafa',
                        // padding: '0 20px',
                        padding: '0px 20px 10px 20px',
                        marginBottom: 20,
                        marginTop: 10,
                      }
                    : {
                        paddingBottom: '8px',
                      }
                }
              >
                {isOneToOne && !isPrerecorded && (
                  <>
                    <Grid item sm={2} xs={12}>
                      <DateTimePicker
                        label="Start Day"
                        type="date"
                        fullWidth
                        name={`sessions[${sessionIdx}].sessionTimes[${i}].startDate`}
                        value={sessionTime.startDate}
                        onChange={momentDate => handleDatePick(momentDate, i, 'startDate', 'startTime')}
                        format={mdView ? 'MMM Do' : 'MMMM Do'}
                      />
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <DateTimePicker
                        label="End Day"
                        type="date"
                        fullWidth
                        name={`sessions[${sessionIdx}].sessionTimes[${i}].endDate`}
                        value={sessionTime.endDate}
                        onChange={momentDate => handleDatePick(momentDate, i, 'endDate', 'endTime')}
                        format={mdView ? 'MMM Do' : 'MMMM Do'}
                      />
                    </Grid>
                  </>
                )}

                {!isOneToOne && !isPrerecorded && (
                  <Grid item sm={isPrerecorded ? 6 : 3} xs={12}>
                    {currentSession.id && moment(sessionTime.startTime).isBefore(new Date()) ? (
                      <>
                        <p style={{ marginBottom: 0 }}>{isPrerecorded ? 'Date Available' : 'Date'}</p>
                        <p style={{ marginBottom: 0 }}>{moment(sessionTime.startTime).format('DD MMMM')}</p>
                      </>
                    ) : (
                      <DateTimePicker
                        label={isPrerecorded ? 'Date Available' : 'Date'}
                        type="date"
                        fullWidth
                        name={`sessions[${sessionIdx}].sessionTimes[${i}].startDate`}
                        value={sessionTime.startTime}
                        onChange={momentDate => {
                          handleDatePick(momentDate, i, 'startDate');
                          form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[0].recurringSessionTime`, null);
                          if (formRef.current != null) {
                            formRef.current.setFieldValue('interval', 1);
                            formRef.current.setFieldValue('recurrencePattern', 'daily');
                            formRef.current.setFieldValue('repeatOn', []);
                            formRef.current.setFieldValue('repeatOnData', []);
                            formRef.current.setFieldValue('endson', true);
                            formRef.current.setFieldValue(
                              'endDate',
                              moment(currentSession?.sessionTimes[0]?.startTime).add(90, 'days'),
                            );
                            formRef.current.setFieldValue('occurrences', null);
                            formRef.current.setFieldValue('occurrence', false);
                            formRef.current.setFieldValue('daysOfWeek', []);
                            formRef.current.setFieldValue('isDayOfWeek', false);
                            formRef.current.setFieldValue('dayOfMonth', 1);
                            formRef.current.setFieldValue('weekOfMonth', 1);
                            formRef.current.setFieldValue('dayOfWeek', 'Sunday');
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            fontFamily: 'Avenir',
                            fontSize: '18px',
                            fontWeight: 400,
                            lineHeight: '16.85px',
                            color: '#000000DE',
                            paddingTop: '5px',
                          },
                        }}
                        inputProps={{
                          style: {
                            fontFamily: 'Avenir',
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#000000DE',
                            padding: '7.5px 0px',
                          },
                        }}
                      />
                    )}
                  </Grid>
                )}
                {!isPrerecorded && (
                  <>
                    <Grid item sm={3} xs={12}>
                      <div className="d-flex">
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel
                            htmlFor={`start-time-${i}`}
                            style={{
                              top: '-20px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '16.85px',
                              color: '#000000DE',
                            }}
                          >
                            From
                          </InputLabel>
                          <TimePickerField
                            onChange={value => handleTimeChange(value, i, 'startTime')}
                            name={`start-time-${i}`}
                            id={`tstart-time-${i}`}
                            classes={{
                              root: 'mt-4 mr-3',
                            }}
                            showSecond={false}
                            value={momentStartTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                            sessionTimeField={true}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <div className="d-flex">
                        <FormControl style={{ width: '100%' }}>
                          <InputLabel
                            htmlFor={`end-time-${i}`}
                            style={{
                              top: '-20px',
                              fontFamily: 'Avenir',
                              fontSize: '14px',
                              fontWeight: 400,
                              lineHeight: '16.85px',
                              color: '#000000DE',
                            }}
                          >
                            To
                          </InputLabel>
                          <TimePickerField
                            onChange={value => handleTimeChange(value, i, 'endTime')}
                            name={`end-time-${i}`}
                            id={`tend-time-${i}`}
                            classes={{
                              root: 'mt-4 mr-3',
                            }}
                            showSecond={false}
                            value={momentEndTime}
                            format="h:mm a"
                            use12Hours
                            minuteStep={15}
                            sessionTimeField={true}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    {(currentSession?.sessionTimes?.length === 1 && i === 0 && highestFalseIndex === sessionIdx) ===
                      false &&
                      (!isPrerecorded && isMemberPods) === false && <Grid item sm={3} xs={12}></Grid>}
                    {currentSession?.sessionTimes?.length === 1 && i === 0 && highestFalseIndex === sessionIdx && (
                      <Grid item sm={3} xs={12}>
                        <div className="d-flex">
                          <FormControl
                            style={{
                              width: '100%',
                              flexWrap: 'wrap',
                              alignContent: 'center',
                              marginTop: '25px',
                              flexDirection: 'row',
                            }}
                          >
                            <Checkbox
                              checked={
                                recurringCheckBox && currentSession?.sessionTimes[0].recurringSessionTime != null
                              }
                              onChange={() => {
                                if (recurringCheckBox === false) {
                                  setRecurringModal(true);
                                  setRecurringCheckBox(true);
                                } else {
                                  setRecurringModal(false);
                                  setRecurringCheckBox(false);
                                  form.setFieldValue(
                                    `sessions[${sessionIdx}].sessionTimes[0].recurringSessionTime`,
                                    null,
                                  );
                                  formRef.current.setFieldValue('interval', 1);
                                  formRef.current.setFieldValue('recurrencePattern', 'daily');
                                  formRef.current.setFieldValue('repeatOn', []);
                                  formRef.current.setFieldValue('repeatOnData', []);
                                  formRef.current.setFieldValue('endson', true);
                                  formRef.current.setFieldValue(
                                    'endDate',
                                    moment(currentSession?.sessionTimes[0]?.startTime).add(90, 'days'),
                                  );
                                  formRef.current.setFieldValue('occurrence', false);
                                  formRef.current.setFieldValue('occurrences', null);
                                  formRef.current.setFieldValue('daysOfWeek', []);
                                  formRef.current.setFieldValue('isDayOfWeek', false);
                                  formRef.current.setFieldValue('dayOfMonth', 1);
                                  formRef.current.setFieldValue('weekOfMonth', 1);
                                  formRef.current.setFieldValue('dayOfWeek', 'Sunday');
                                }
                              }}
                              color="primary"
                            />
                            <span
                              onClick={() => {
                                // if (recurringModal) {
                                // } else {
                                setRecurringModal(true);
                                // }
                              }}
                              style={{
                                fontSize: '1rem',
                                fontWeight: '400',
                                lineHeight: '1.5',
                                alignItems: 'center',
                                display: 'flex',
                              }}
                            >
                              Make Recurring
                            </span>
                          </FormControl>
                        </div>
                      </Grid>
                    )}
                  </>
                )}
                {!isPrerecorded && isMemberPods && (
                  <Grid
                    item
                    sm={isOneToOne ? 2 : 3}
                    xs={12}
                    style={{
                      textAlign: 'right',
                    }}
                    classes={{
                      root: 'd-flex align-items-center',
                    }}
                  >
                    {isMemberPods && (
                      <Grid item xs={12}>
                        <MemberPodsToSessionTimeAssignment
                          onAssignPod={value => handleOnAssignPod(value, i)}
                          value={sessions[sessionIdx].sessionTimes[i].podId}
                        />
                      </Grid>
                    )}
                    &nbsp;
                    {!isPrerecorded && sessions[sessionIdx].sessionTimes.length > 1 && (
                      <Tooltip
                        className="cursor-pointer"
                        title="Completed session cannot be deleted"
                        open={!!tooltipOpen[i]}
                        arrow
                        onClose={() => {
                          setTooltipOpen({ ...tooltipOpen, [i]: false });
                        }}
                      >
                        <span>
                          <StyledIcon
                            color="#215C73"
                            onClick={
                              sessionTime.isCompleted
                                ? () => {
                                    setTooltipOpen({ ...tooltipOpen, [i]: true });
                                  }
                                : removeTimesHandler(i)
                            }
                            path={mdiMinusCircleOutline}
                            size={1}
                          />
                        </span>
                      </Tooltip>
                    )}
                  </Grid>
                )}

                {isPrerecorded && (
                  <SessionTimeSelfPaced
                    dragging={dragging}
                    form={form}
                    session={sessions[sessionIdx]}
                    sessionIndex={sessionIdx}
                    sessionTimeIndex={i}
                    remove={remove}
                    isEditMode
                  />
                )}
                <div style={{ flexBasis: '100%' }} />
              </Grid>
            );
          })}
        </Grid>
      )}

      {!isPrerecorded && isSessionOpen && (
        <StyledLink
          onClick={addTimesHandler}
          style={{ marginTop: !isPrerecorded && sessions[sessionIdx].sessionTimes.length > 1 ? 10 : 10 }}
        >
          <Icon style={{ marginLeft: '-5px', marginRight: '5px' }} path={mdiPlus} size={1} />
          {isOneToOne ? 'Add Additional Booking Times' : 'Add more time options'}
        </StyledLink>
      )}
      {addMoreTimeOptionModal && !isPrerecorded && (
        <>
          <Modal
            brandingColor={false}
            CrossIconHide={true}
            // title="We are here for you!"
            isOpen={supportPopup}
            widthRequiredIs
            handleOnCancel
            submitTitle={'Cancel'}
            hiddenCancel={true}
            isCreatingContribution={true}
            onCancel={() => {
              setSupportPopup(false);
            }}
            onSubmit={() => {
              setSupportPopup(false);
            }}
          >
            <p>
              You have just added an additional session time. This is designed for large group cohorts where you may
              need multiple scheduling time windows. If you meant to add a new session, please click the gold button
              below “Create New Live Session.”
            </p>
          </Modal>
        </>
      )}

      {/* {isPrerecorded && (
        <SessionMoreInfo value={sessions[sessionIdx].moreInfo} title="About this module" onChange={onChangeMoreInfo} isSessionTime='false'/>
      )} */}

      {isPrerecorded && isSessionOpen && (
        <StyledLink onClick={addTimesHandler} style={{ marginBottom: '-10px' }}>
          <Icon path={mdiPlus} size={1} style={{ marginRight: '3px' }} />
          Add more content for this module
        </StyledLink>
      )}

      {showError && timesCount === 0 && (
        <>
          <br />
          <CommonErrorMessage align="left" message={error.sessionTimes} />
        </>
      )}
      {recurringModal && (
        <Formik
          innerRef={formRef}
          initialValues={{
            interval: currentSession?.recurringSessionTime?.interval || 1,
            recurrencePattern: currentSession?.recurringSessionTime?.recurrencePattern || 'daily',
            repeatOn: currentSession?.recurringSessionTime?.repeatOn || [],
            repeatOnData: currentSession?.recurringSessionTime?.repeatOnData || [],
            endson: currentSession?.recurringSessionTime?.endson || true,
            endDate:
              currentSession?.recurringSessionTime?.endDate ||
              moment(currentSession?.sessionTimes[0]?.startTime).add(90, 'days'),
            occurrences: currentSession?.recurringSessionTime?.occurrences || null,
            occurrence: currentSession?.recurringSessionTime?.occurrence || false,
            daysOfWeek: currentSession?.recurringSessionTime?.daysOfWeek || [],
            isDayOfWeek: currentSession?.recurringSessionTime?.isDayOfWeek || false,
            dayOfMonth: currentSession?.recurringSessionTime?.dayOfMonth || 1,
            weekOfMonth: currentSession?.recurringSessionTime?.weekOfMonth || 1,
            dayOfWeek: currentSession?.recurringSessionTime?.dayOfWeek || 'Sunday',
          }}
          validationSchema={Yup.object().shape({
            interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
            recurrencePattern: Yup.string().required('This is a required field'),
            daysOfWeek: Yup.array().when('recurrencePattern', {
              is: v => v === 'weekly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            repeatOn: Yup.array().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            dayOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            dayOfWeek: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            weekOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            endDate: Yup.date().when('endson', {
              is: true,
              then: Yup.date().required('This is a required field'),
              // otherwise: Yup.date().nullable(),
            }),
            occurrences: Yup.number().when('occurrence', {
              is: true,
              then: Yup.number()
                .integer()
                .required('This is a required field')
                .min(1, 'it must be 1 or more')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          })}
          enableReinitialize
          onSubmit={() => {
            form.setFieldValue(`sessions[${sessionIdx}].sessionTimes[0].recurringSessionTime`, formRef.current.values);
            setRecurringModal(false);
            setRecuringConfirmation(true);
            setRecurringCheckBox(true);
          }}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => {
            const changeRepeatOnValues = e => {
              if (e?.target?.value === 'daily') {
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'weekly') {
                setFieldValue('repeatOnData', WeekValues);
                setFieldValue('daysOfWeek', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else if (e?.target?.value === 'monthly') {
                const todayDate = moment(moment(currentSession?.sessionTimes[0]?.startTime)).format('D');
                const count = findCountOf(todayDate);
                var year = new Date(moment(currentSession?.sessionTimes[0]?.startTime)).getFullYear();
                var month = new Date(moment(currentSession?.sessionTimes[0]?.startTime)).getMonth();
                var day = todayDate;
                let lastWeek;
                if (isLastWeekOfMonth(year, month, day)) {
                  lastWeek = true;
                } else {
                  lastWeek = false;
                }
                setFieldValue('repeatOnData', [
                  { title: `Monthly on Day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                  {
                    title: `Monthly on ${
                      count === 1
                        ? '1st'
                        : count === 2
                        ? '2nd'
                        : count === 3
                        ? '3rd'
                        : count === 4 && lastWeek
                        ? 'Last'
                        : count === 5
                        ? 'Last'
                        : 'Last'
                    } ${todayDay}`,
                    value: `Monthly on ${count} ${todayDay}`,
                  },
                ]);
                setFieldValue('repeatOn', []);
                setFieldValue('dayOfWeek', todayDay);
                setFieldValue('weekOfMonth', count);
                setFieldValue('recurrencePattern', e?.target?.value);
              } else {
                setFieldValue('repeatOnData', null);
                setFieldValue('repeatOn', []);
                setFieldValue('recurrencePattern', e?.target?.value);
              }
            };
            return (
              <>
                <Modal
                  title={'Live Recurring Sessions'}
                  isOpen={recurringModal}
                  isCreatingContribution={true}
                  brandingColor={false}
                  handleOnCancel
                  submitTitle="Done"
                  widthRequiredIs
                  disableSubmitClick={errors?.length > 0 || (values?.endson === false && values?.occurrence === false)}
                  onCancel={() => {
                    setRecurringModal(false);
                  }}
                  onSubmit={handleSubmit}
                >
                  <>
                    <Grid container item xs={12} style={{ padding: '10px 0px', gap: '15px' }}>
                      <Grid
                        style={{
                          flexWrap: 'wrap',
                          display: 'flex',
                          fontSize: 16,
                          fontWeight: '350',
                          marginTop: '10px',
                        }}
                        item
                        xs={3}
                      >
                        Repeat Every
                      </Grid>
                      <Grid container xs={4}>
                        <div
                          style={{
                            height: '43px',
                            border: '1px solid #DFE3E4',
                            borderRadius: '4px',
                            padding: '5px 5px',
                          }}
                        >
                          <StyledInput
                            id="outlined-basic"
                            style={{ fontFamily: 'Brandon Text' }}
                            fullWidth
                            name="interval"
                            value={values?.interval}
                            onChange={e => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value.toString() === '') {
                                setFieldValue('interval', null);
                              } else if (re.test(e?.target?.value)) {
                                setFieldValue('interval', parseInt(e?.target?.value));
                              }
                            }}
                          />
                        </div>
                        {errors && errors?.interval && <CommonErrorMessage message={errors?.interval} align="left" />}
                      </Grid>
                      <Grid item xs={4}>
                        <StyledCustomSelect
                          variant="outlined"
                          roundedBorder={true}
                          style={{ height: '100%', fontFamily: 'Brandon Text' }}
                          name="recurrencePattern"
                          defaultValue={values?.recurrencePattern}
                          fullWidth
                          onChange={changeRepeatOnValues}
                          items={[
                            { value: 'daily', title: values?.interval > 1 ? 'Days' : 'Day' },
                            { value: 'weekly', title: values?.interval > 1 ? 'Weeks' : 'Week' },
                            { value: 'monthly', title: values?.interval > 1 ? 'Months' : 'Month' },
                            { value: 'yearly', title: values?.interval > 1 ? 'Years' : 'Year' },
                          ]}
                        />
                      </Grid>
                    </Grid>
                    {(values?.recurrencePattern === 'weekly' || values?.recurrencePattern === 'monthly') && (
                      <Grid style={{ padding: '0px 0px 10px 0px' }} item xs={12} container>
                        <Grid
                          style={{
                            flexWrap: 'wrap',
                            display: 'flex',
                            fontSize: 16,
                            fontWeight: '350',
                            alignItems: 'center',
                          }}
                          item
                          xs={3}
                        >
                          Repeat On
                        </Grid>
                        <Grid style={{ padding: '0px 8px 0px 15px' }} item xs={9}>
                          {values?.recurrencePattern === 'weekly' ? (
                            <StyledCustomSelect
                              name="daysOfWeek"
                              multiple
                              items={[
                                { title: 'Monday', value: 'Monday' },
                                { title: 'Tuesday', value: 'Tuesday' },
                                { title: 'Wednesday', value: 'Wednesday' },
                                { title: 'Thursday', value: 'Thursday' },
                                { title: 'Friday', value: 'Friday' },
                                { title: 'Saturday', value: 'Saturday' },
                                { title: 'Sunday', value: 'Sunday' },
                              ]}
                              fullWidth
                              variant="outlined"
                              roundedBorder={true}
                            />
                          ) : (
                            <>
                              <StyledCustomSelect
                                name="repeatOn"
                                variant="outlined"
                                roundedBorder={true}
                                fullWidth
                                value={values?.repeatOn[0]}
                                onChange={e => {
                                  if (e?.target?.value.includes('Monthly on day')) {
                                    setFieldValue('isDayOfWeek', false);
                                    setFieldValue('dayOfMonth', parseInt(todayDate));
                                  } else {
                                    const count = findCountOf(todayDate);
                                    setFieldValue('isDayOfWeek', true);
                                    setFieldValue('dayOfWeek', todayDay);
                                    setFieldValue('weekOfMonth', parseInt(count));
                                  }
                                  setFieldValue('repeatOn', [e?.target?.value]);
                                }}
                                items={values?.repeatOnData}
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <Grid className="profile-section" style={{ padding: '0px 0px 10px' }} container>
                      <Grid
                        style={{
                          flexWrap: 'wrap',
                          display: 'flex',
                          fontSize: 16,
                          fontWeight: '350',
                          alignItems: 'center',
                        }}
                        item
                        xs={3}
                      >
                        End Date
                        <Tooltip
                          title="You can do only for one year"
                          arrow
                          style={{ marginLeft: 8 }}
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} />
                        </Tooltip>
                      </Grid>
                      <Grid xs={9} style={{ padding: '0px 8px 0px 15px' }} justifyContent={'flex-end'}>
                        <div
                          style={{
                            height: '43px',
                            border: '1px solid rgb(223, 227, 228)',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            paddingLeft: '5px',
                          }}
                        >
                          <DateTimePicker
                            underline={false}
                            disabled={values.occurrence}
                            className="default-font-family"
                            type="date"
                            fullWidth
                            name="endDate"
                            value={values?.endDate}
                            maxDate={moment(currentSession?.sessionTimes[0]?.startTime).add(1, 'year')}
                            onChange={momentDate => {
                              setFieldValue('endDate', momentDate._d);
                              setFieldValue('occurrences', null);
                              const todayDay = moment(moment(momentDate._d)).format('dddd');
                              const todayDate = moment(moment(momentDate._d)).format('D');
                              const count = findCountOf(todayDate);
                              var year = new Date(moment(momentDate._d)).getFullYear();
                              var month = new Date(moment(momentDate._d)).getMonth() + 1;
                              var day = todayDate;
                              let lastWeek;
                              if (isLastWeekOfMonth(year, month, day)) {
                                lastWeek = true;
                              } else {
                                lastWeek = false;
                              }
                              // if (values.recurrencePattern === 'monthly') {
                              //   setFieldValue('repeatOnData', [
                              //     { name: `Monthly on day ${todayDate}`, value: `Monthly on day ${todayDate}` },
                              //     {
                              //       name: `Monthly on ${
                              //         count === 1
                              //           ? '1st'
                              //           : count === 2
                              //           ? '2nd'
                              //           : count === 3
                              //           ? '3rd'
                              //           : count === 4 && lastWeek
                              //           ? 'Last'
                              //           : count === 5
                              //           ? 'Last'
                              //           : '4th'
                              //       } ${todayDay}`,
                              //       value: `Monthly on ${count} ${todayDay}`,
                              //     },
                              //   ]);
                              // }
                            }}
                            format={'MMMM Do,yyyy'}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </>
                </Modal>
              </>
            );
          }}
        </Formik>
      )}
      {recuringConfirmation && (
        <>
          <Modal
            title="Live Recurring Added Sessions"
            isOpen={recuringConfirmation}
            hiddenCancel={true}
            isBackButton={false}
            onCancel={() => {
              setRecuringConfirmation(false);
            }}
            onSubmit={() => {
              setRecuringConfirmation(false);
            }}
            isCreatingContribution
            submitBtnStyle={{ backgroundColor: '#C9B382' }}
          >
            <p>
              The recurring sessions will be added to your session tab once you click SAVE & NEXT on this step and
              submit your service. You will be able to come back and edit any of the dates and titles.
            </p>
          </Modal>
        </>
      )}
    </Grid>
  );
};

SessionTime.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
  }).isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isOneToOne: PropTypes.bool,
  sessionIdx: PropTypes.number.isRequired,
  timesCount: PropTypes.number.isRequired,
  computeDifference: PropTypes.func.isRequired,
  isPrerecorded: PropTypes.bool,
};

SessionTime.defaultProps = {
  isOneToOne: false,
  isPrerecorded: false,
};
