import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useTheme, useMediaQuery, Button } from '@material-ui/core';
import { isEmptyOrNil } from 'utils/utils';
import CardSession from './CardSession/CardSession';
import CardSales from './CardSales/CardSales';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount, useShallowEqualSelector } from 'hooks/index';
import usePaidTier from 'hooks/usePaidTier';
import { SIGNUP_TYPES, UserRoles } from 'helpers/constants';
import './Dashboard.scss';
import useRouter from 'hooks/useRouter';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RenderCards from './renderCard';
import Loader from 'components/UI/Loader';
import IntroScreen from '../IntroScreen';
import * as paidTier from 'selectors/paidTier';
import { PAID_TIER_TITLES } from '../../../../../constants';
import { setCohereAcademyStatus } from 'actions/update-user';
import CreateQuizesModal from 'pages/CreateQuizes/Components/Modals/CreateModal/CreateModal';
import { setShowCreateModal } from 'actions/quizes';
import { fetchUpcomingCreated } from 'actions/contributions';

const renderSalesCards = cards => {
  let totals = {};
  for (let card of cards) {
    if (card.purchaseIncomeList) {
      for (let plist of card.purchaseIncomeList) {
        if (totals[plist.currency]) {
          totals[plist.currency].amount += plist.netIncomeAmount;
        } else {
          totals[plist.currency] = {
            amount: plist.netIncomeAmount,
            symbol: plist?.symbol,
            type: 'total',
          };
        }
      }
    }
  }

  const totalIncome = [];
  for (let [key, value] of Object.entries(totals)) {
    totalIncome.push({
      currency: key,
      netIncomeAmount: value.amount,
      symbol: value.symbol,
    });
  }

  const firstCard = {
    contributionType: 'Net Revenue',
    netIncomeAmount: totals,
    purchaseIncomeList: totalIncome,
    moreLink: '/account/payment',
  };
  return RenderCards([firstCard, ...cards], 'sales');
};

const Dashboard = ({ sessions, incomes }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const cardSize = mobileView ? 12 : 6;
  const user = useSelector(state => state.account);
  const { user: loggedInUser } = useAccount();
  const { showModal } = useSelector(state => state.quizes);
  const [sessionsArray, setSessionsArray] = useState([]);
  const { location, history } = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    currentPage: 0,
    perPage: 3,
    totalPages: Math.ceil(sessions.length / 3),
    totalItems: 0,
  });
  // const { isLaunchPlan } = usePaidTier();
  const activePaidTierCurrentPlan = useShallowEqualSelector(paidTier.activePaidTierCurrentPlanSelector);
  const isLaunchPlan = activePaidTierCurrentPlan?.displayName === PAID_TIER_TITLES.launch;

  // Correct behavior - 100%
  useEffect(() => {
    dispatch(fetchUpcomingCreated(loggedInUser.id));
  }, []);
  useEffect(() => {
    if (activePaidTierCurrentPlan?.default) {
      dispatch(setCohereAcademyStatus(true));
    } else {
      dispatch(setCohereAcademyStatus(false));
    }
  }, [activePaidTierCurrentPlan, dispatch]);

  const shouldBeDisabled =
    isLaunchPlan ||
    user.signupType === SIGNUP_TYPES.TYPE_A ||
    user.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_B ||
    loggedInUser.signupType === SIGNUP_TYPES.TYPE_A;
  useEffect(() => {
    getAdjacentThreeElements('first');
  }, [sessions]);

  const getAdjacentThreeElements = direction => {
    setSessionsArray([]);
    setIsLoading(true);
    setTimeout(() => {
      let adjacentThreeElements = [];
      let tempPagination = pagination;
      if (direction === 'next') {
        tempPagination.currentPage = tempPagination.currentPage + 1;
      } else if (direction === 'previous') {
        tempPagination.currentPage = tempPagination.currentPage - 1;
      }
      let skip = tempPagination.currentPage * tempPagination.perPage;
      for (let i = skip; i < sessions.length; i++) {
        if (i - skip < tempPagination.perPage) {
          adjacentThreeElements.push(sessions[i]);
        }
      }
      setPagination({
        ...tempPagination,
        totalItems: Math.floor(sessions.length / 3),
      });
      setSessionsArray(adjacentThreeElements);
      setIsLoading(false);
    }, 10);
  };

  return (
    <>
      <Grid
        container
        justify="center"
        classes={{
          root: 'grid',
        }}
        spacing={2}
      >
        <Grid
          container
          item
          xs={cardSize}
          classes={{
            root: 'grid_vertical',
          }}
        >
          <div className="column-title-container">
            <span className="column-title-container__text">Upcoming Sessions</span>
            <Link
              className={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? 'column-title-container__view-all'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'column-title-container__view-all-disabled'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? 'column-title-container__view-all'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? 'column-title-container__view-all-disabled'
                  : 'column-title-container__view-all'
              }
              to={
                user?.user?.isPartnerCoach === false && shouldBeDisabled === false
                  ? 'contributions/all'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? null
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === true
                  ? 'contributions/all'
                  : shouldBeDisabled == true && user?.user?.isPartnerCoach === false
                  ? null
                  : 'contributions/all'
              }
            >
              View All
            </Link>
          </div>
          {isEmptyOrNil(sessions) ? (
            <div className="mt-2">
              Sessions appear here once a group course with live sessions is created or a client books a 1:1 session.{' '}
              <span
                onClick={() => {
                  history.push('/create-contribution/basic');
                }}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                Click here to create a service.
              </span>
            </div>
          ) : (
            // sessions.length >3?
            // renderCards(sessions, 'sessions'):
            <div style={{ width: '100%' }}>
              {isLoading && (
                <Grid style={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Loader relative />
                </Grid>
              )}
              {!isLoading &&
                sessionsArray.length > 0 &&
                sessionsArray.map(card => {
                  return (
                    <Grid
                      key={card.id}
                      item
                      xs={12}
                      classes={{
                        root: 'grid_vertical_item',
                      }}
                    >
                      <CardSession card={card} />
                    </Grid>
                  );
                })}

              {sessionsArray?.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="text"
                    disabled={sessionsArray.length === 0 || pagination.currentPage === 0}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      getAdjacentThreeElements('previous');
                    }}
                    className="column-title-container__view-all"
                  >
                    <ArrowBackIosIcon fontSize="small" />
                    Previous
                  </Button>
                  <Button
                    variant="text"
                    disabled={
                      sessionsArray.length === 0 ||
                      pagination.currentPage === pagination.totalItems - 1 ||
                      sessionsArray.length < 3
                    }
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      getAdjacentThreeElements('next');
                    }}
                    className="column-title-container__view-all"
                  >
                    Next
                    <ArrowForwardIosIcon fontSize="small" />
                  </Button>
                </div>
              )}
            </div>
          )}
        </Grid>

        <Grid
          container
          item
          xs={cardSize}
          classes={{
            root: 'grid_vertical',
          }}
        >
          {!R.isEmpty(incomes) && (
            <>
              <div className="column-title-container">
                <span className="column-title-container__text">Sales</span>
              </div>
              {renderSalesCards(incomes)}
            </>
          )}
        </Grid>
      </Grid>
      <IntroScreen resources={true} />
    </>
  );
};

Dashboard.propTypes = {
  sessions: PropTypes.arrayOf(PropTypes.object).isRequired,
  incomes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Dashboard;
