import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { determineDarkThemedColorToUse } from 'services/contributions.service';

import ChatHeader from './ChatHeader';
import MessagesHistory from './MessagesHistory';
import MessageInput from './MessageInput';

const Chat = ({
  compact,
  title,
  iconUrl,
  members,
  myMember,
  typingMembers,
  memberProvider,
  messages,
  isLoadingMoreMessages,
  onLoadMoreMessages,
  onReadMessage,
  sendTextMessage,
  sendMediaMessage,
  onTyping,
  contribution,
  isCoach,
  channel,
}) => {
  const [participants, setParticipants] = useState([]);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  useEffect(() => {
    if (contribution?.participants?.length > 0) {
      let par = contribution.participants.map(participant => ({
        attributes: {
          IsFavorite: false,
          Name: `${participant.firstName} ${participant.lastName}`,
          PreviewImage: participant?.avatarUrl || null,
          UserId: participant.id,
        },
      }));
      setParticipants(par);
    } else {
      setParticipants(members);
    }
  }, [contribution, members]);

  return (
    <ExpansionPanel expanded className={isDarkThemeEnabled ? 'chat chat-dark' : 'chat'}>
      <ExpansionPanelSummary className="chat__header">
        <ChatHeader
          compact={compact}
          title={title}
          iconUrl={iconUrl}
          members={participants}
          contribution={contribution}
          isCoach={isCoach}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ height: '600px' }} className="chat__content chat-content">
        <MessagesHistory
          className="chat-content__messages-history"
          members={members}
          myMember={myMember}
          memberProvider={memberProvider}
          messages={messages}
          isLoadingMoreMessages={isLoadingMoreMessages}
          onLoadMoreMessages={onLoadMoreMessages}
          onReadMessage={onReadMessage}
          typingMembers={typingMembers}
        />
        <MessageInput
          channel={channel}
          contribution={contribution}
          members={members}
          onSendTextMessage={sendTextMessage}
          onSendMediaMessage={sendMediaMessage}
          onTyping={onTyping}
        />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

Chat.propTypes = {
  compact: PropTypes.bool,
  title: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  myMember: PropTypes.shape({
    sid: PropTypes.string,
  }).isRequired,
  typingMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  memberProvider: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoadingMoreMessages: PropTypes.bool.isRequired,
  onLoadMoreMessages: PropTypes.func.isRequired,
  onReadMessage: PropTypes.func.isRequired,
  sendTextMessage: PropTypes.func.isRequired,
  sendMediaMessage: PropTypes.func.isRequired,
  onTyping: PropTypes.func.isRequired,
};

Chat.defaultProps = {
  compact: false,
};

export default Chat;
