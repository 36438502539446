import * as R from 'ramda';

import { combineActions, handleActions } from 'redux-actions';

import * as actions from 'actions/contributions';
import {
  SET_INVITE_CODE,
  SET_PRICE_CODE,
  SET_POP_UP,
  SET_POP_UP_BANK,
  SET_SINGLE_CODE,
  SET_COUPON_CODE,
  SET_SHOW_THANK_YOU_PAGE,
  SET_RESCHEDULE_MODAL,
  SET_PICK_ANOTHER_TIME,
  SHOW_ERROR_POPUP,
  SET_REFETCH_CONTRIBUTION,
  SET_CONTRIBUTIONS_LOADER,
  SET_SESSION_CODE,
  SHOW_PRICE_ERROR_SHOW,
  SET_REPEATING_ERROR,
  SET_EDIT_MODE,
  SET_EDIT_MODE_DATA,
  SET_GET_START_SHOW,
  SET_LOADING_PARTICIPANTS,
  SET_MY_AVAILABILITY,
  SET_RESCHEDULE_HOST_SESSSION_DETAILS,
  SET_ERROR,
  SET_FORMER_BOOKING_DATA,
  SET_PREVIOUS_FREE_SESSION,
  SET_GUEST_TIMEZONE,
  SET_ONE_TO_ONE_RECURRING_AVAILABILITY,
  SET_RESCHEDULE_SESSION_TIME_ID,
  SET_RESCHEDULE_PARTICIPANT_INFO,
  SET_FETCH_SLOTS_CALENDAR,
  SET_NOTES_STATUS,
  SET_SHOW_SAVING_ANIMATION,
  SET_CONTRIBUTION_ARRAY_MASTER,
  SET_CLIENT_CALENDAR_DATE,
  MASTER_CALENDAR_AVAILABILITY_POP_UP_MODAL_ID,
  SET_REDIRECT_TO_SESSIONS,
  SET_PENDING_APPLY_BUTTON,
  SET_INVITE_GET_STARTED,
} from 'actions/contributions';
import { FILTERTYPES } from 'pages/ContributionView/ContributionSessions/constants';
import { cloneDeep } from 'lodash';
import { isEmpty } from 'lodash';

const initialState = {
  loading: false,
  error: null,
  firstLoad: true,
  contributionsCollection: {},
  activeContribution: null,
  adminContributions: null,
  inviteCode: null,
  priceCode: null,
  previosSessionNumber: null,
  singleCode: null,
  showPopUp: false,
  showPopUpBank: false,
  couponCode: null,
  showThankYouPage: false,
  reschedulePopUp: false,
  pickAnotherTime: null,
  showError: false,
  refetchContributons: false,
  loader: false,
  sessionId: null,
  repeatingError: false,
  editMode: false,
  editModeData: {},
  showPriceError: false,
  showCompletedGetStart: null,
  sessionsTabViewScreenUI: {
    loading: false,
    selectedSessionId: null,
    selectedSessionTimeOrContentId: null,
    isCenterColumnFullScreenMode: false,
    showMobileDetailedView: false,
    selectedFilter: FILTERTYPES.ALL,
  },
  loadingParticipants: false,
  errorState: false,
  showSessionFeedbackPopup: false,
  isNewlyCreated: false,
  rescheduleHostSessionDetails: {},
  formerBookingData: {},
  selectedGuestTimeZone: null,
  oneToOneRecurringAvailability: '',
  sessionTimeId: null,
  participantInfo: {},
  notesStatus: '',
  showSavingAnimation: false,
  closeDisabled: false,
  myAvailabilityTime: true,
  fetchSlotsCalendarLoader: false,
  masterCalendarArray: [],
  showAvailabilityPopUp: false,
  isAnyContributionJoinedOrCreated: false,
  fetchSlotsCalendarLoader: false,
  clientSelectedDate: new Date(),
  masterCalendarModalId: null,
  redirectToSessions: false,
  hideApplyButton: false,
  inviteGetStarted: false,
};

const reducerContribution = handleActions(
  new Map([
    [
      combineActions(actions.fetchContributionsActions.request, actions.fetchContributionAdminActions.request),
      state => ({
        ...state,
        error: null,
        loading: true,
        activeContribution: null,
      }),
    ],
    [
      combineActions(actions.createContributionActions.request, actions.fetchContributionActions.request),
      state => ({
        ...state,
        error: null,
        loading: true,
      }),
    ],
    [
      actions.fetchContributionsActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        contributionsCollection: action.payload,
      }),
    ],
    [
      combineActions(
        actions.fetchContributionActions.success,
        actions.getContributionFromLS,
        actions.saveContributionToLS,
        actions.saveContribution,
      ),
      (state, action) => {
        let contribution = action.payload;
        const prevParticipants = state?.activeContribution?.participants ?? [];
        const newParticipants = contribution?.participants ?? [];
        if (prevParticipants.length > 0 && newParticipants.length === 0) {
          contribution = {
            ...action.payload,
            participants: state.activeContribution.participants,
          };
        }
        return {
          ...state,
          loading: false,
          error: null,
          activeContribution: contribution,
        };
      },
    ],
    [
      actions.fetchContributionAdminActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        adminContributions: action.payload,
      }),
    ],
    [
      combineActions(
        actions.fetchContributionsActions.error,
        actions.fetchContributionActions.error,
        actions.fetchContributionAdminActions.error,
      ),
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
        contributionsCollection: [],
        activeContribution: null,
        adminContributions: [],
      }),
    ],
    [
      actions.fetchContributionActions.cancel,
      state => ({
        ...state,
        loading: false,
      }),
    ],
    [
      actions.resetContribution,
      state => ({
        ...state,
        activeContribution: null,
      }),
    ],
    [
      actions.createContributionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.createContributionActions.success,
      (state, action) => ({
        ...state,
        loading: false,
        error: null,
        activeContribution: null,
      }),
    ],
    [
      actions.rescheduleSessionActions.request,
      state => ({
        ...state,
      }),
    ],
    [
      actions.rescheduleSessionActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.rescheduleSessionActions.success,
      (state, action) => {
        let contribution = action.payload;
        if (state?.activeContribution?.participants && state?.activeContribution?.participants?.length > 0) {
          contribution = {
            ...action.payload,
            participants: state.activeContribution.participants,
          };
        }
        return {
          ...state,
          loading: false,
          error: null,
          activeContribution: contribution,
        };
      },
    ],
    [
      actions.updateSessionAttachments,
      (state, { payload }) => {
        const { sessionId, documentId } = payload;
        const {
          activeContribution: { sessions, timeZoneId },
        } = state;
        const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
        const currentSession = R.find(R.propEq('id', sessionId))(sessions);

        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            timeZoneId,
            sessions: R.update(
              currentSessionIdx,
              {
                ...currentSession,
                attachments: R.remove(
                  R.findIndex(R.propEq('id', documentId))(currentSession.attachments),
                  1,
                  currentSession.attachments,
                ),
              },
              sessions,
            ),
          },
        };
      },
    ],
    [
      actions.updateSessionTimeAttachments,
      (state, { payload }) => {
        const { sessionId, sessionTimeId, documentId } = payload;
        const {
          activeContribution: { sessions, timeZoneId },
        } = state;
        const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
        const currentSession = R.find(R.propEq('id', sessionId))(sessions);
        const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
        const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            timeZoneId,
            sessions: R.update(
              currentSessionIdx,
              {
                ...currentSession,
                sessionTimes: R.update(
                  currentSessionTimeIdx,
                  {
                    ...currentSessionTime,
                    attachments: R.remove(
                      R.findIndex(R.propEq('id', documentId))(currentSessionTime.attachments),
                      1,
                      currentSessionTime.attachments,
                    ),
                  },
                  currentSession.sessionTimes,
                ),
              },
              sessions,
            ),
          },
        };
      },
    ],
    [
      actions.updateSession,
      (state, { payload: { sessionId, sessionTimeId, userId } }) => {
        const {
          activeContribution: { sessions },
        } = state;
        const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
        const currentSession = R.find(R.propEq('id', sessionId))(sessions);
        const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
        const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
        let sessz = [...sessions];
        sessz[currentSessionIdx] = {
          ...currentSession,
          sessionTimes: [
            {
              ...currentSessionTime,
              usersWhoViewedRecording: currentSessionTime.usersWhoViewedRecording.includes(userId)
                ? currentSessionTime.usersWhoViewedRecording
                : [...currentSessionTime.usersWhoViewedRecording, userId],
            },
          ],
        };
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            sessions: sessz,
          },
        };
      },
    ],
    [
      actions.updateSessionCompletedList,
      (state, { payload: { sessionId, sessionTimeId, userId } }) => {
        const {
          activeContribution: { sessions },
        } = state;
        const currentSessionIdx = R.findIndex(R.propEq('id', sessionId))(sessions);
        const currentSession = R.find(R.propEq('id', sessionId))(sessions);
        const currentSessionTimeIdx = R.findIndex(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);
        const currentSessionTime = R.find(R.propEq('id', sessionTimeId))(currentSession.sessionTimes);

        let sessz = [...sessions];
        let times = [...currentSession.sessionTimes];
        times[currentSessionTimeIdx] = {
          ...currentSessionTime,
          completedSelfPacedParticipantIds: currentSessionTime.completedSelfPacedParticipantIds.includes(userId)
            ? currentSessionTime.completedSelfPacedParticipantIds
            : [...currentSessionTime.completedSelfPacedParticipantIds, userId],
        };
        sessz[currentSessionIdx] = {
          ...currentSession,
          sessionTimes: [...times],
        };

        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            sessions: sessz,
          },
        };
      },
    ],
    [
      actions.addSessionAttachments,
      (state, { payload: { sessions } }) => {
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            sessions,
          },
        };
      },
    ],
    [
      actions.updateOneToOneSessionAttachments,
      (state, { payload: { documentId } }) => {
        const {
          activeContribution: { availabilityTimes },
        } = state;

        const times = availabilityTimes.map(time => ({
          ...time,
          bookedTimes: time.bookedTimes.map(booked => ({
            ...booked,
            attachments: booked.attachments.filter(attach => attach.id !== documentId),
          })),
        }));

        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            availabilityTimes: times,
          },
        };
      },
    ],
    [
      actions.updateSubscriptionInfo,
      (state, { payload }) => {
        return {
          ...state,
          activeContribution: {
            ...state.activeContribution,
            subscriptionStatus: payload,
          },
        };
      },
    ],
    [
      combineActions(actions.showDuplicateLoading, actions.updateContributionActions.request),
      state => ({
        ...state,
        updating: true,
      }),
    ],
    [
      actions.hideDuplicateLoading,
      (state, action) => ({
        ...state,
        updating: false,
        error: null,
      }),
    ],
    [
      actions.updateContributionActions.success,
      (state, action) => ({
        ...state,
        updating: false,
        showError: false,
        activeContribution: {
          ...action.payload,
          serviceProviderName: state.activeContribution.serviceProviderName,
        },
      }),
    ],
    [
      actions.updateContributionActions.error,
      (state, action) => ({
        ...state,
        updating: false,
        showError: true,
        error: {
          ...action.payload,
        },
      }),
    ],
    [
      actions.fetchContributionTimesActions.request,
      state => ({
        ...state,
        loadingTimes: false,
      }),
    ],
    [
      actions.fetchContributionTimesActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        contributionTimes: action.payload,
      }),
    ],
    [
      actions.fetchContributionTimesActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.fetchCustomContributionTimesActions.request,
      state => ({
        ...state,
        loadingCustomTimes: false,
      }),
    ],
    [
      actions.fetchCustomContributionTimesActions.success,
      (state, action) => ({
        ...state,
        loadingCustomTimes: false,
        customContributionTimes: action.payload,
      }),
    ],
    [
      actions.fetchCustomContributionTimesActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingCustomTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.request,
      state => ({
        ...state,
        loadingTimes: true,
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        editContributionTimes: action.payload,
      }),
    ],
    [
      actions.fetchEditContributionTimesActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.calculateCreateSlotsActions.request,
      state => ({
        ...state,
        loadingTimes: true,
      }),
    ],
    [
      actions.calculateCreateSlotsActions.success,
      (state, action) => ({
        ...state,
        loadingTimes: false,
        calculatedSlots: action.payload,
      }),
    ],
    [
      SET_INVITE_CODE,
      (state, action) => ({
        ...state,
        inviteCode: action.payload,
      }),
    ],

    [
      MASTER_CALENDAR_AVAILABILITY_POP_UP_MODAL_ID,
      (state, action) => ({
        ...state,
        masterCalendarModalId: action.payload,
      }),
    ],
    [
      SET_POP_UP,
      (state, action) => ({
        ...state,
        showPopUp: action.payload,
      }),
    ],
    [
      SET_CLIENT_CALENDAR_DATE,
      (state, action) => ({
        ...state,
        clientSelectedDate: action.payload,
      }),
    ],
    [
      SET_POP_UP_BANK,
      (state, action) => ({
        ...state,
        showPopUpBank: action.payload,
      }),
    ],
    [
      SET_PRICE_CODE,
      (state, action) => ({
        ...state,
        priceCode: action.payload,
      }),
    ],
    [
      SET_PREVIOUS_FREE_SESSION,
      (state, action) => ({
        ...state,
        previosSessionNumber: action.payload,
      }),
    ],
    [
      SHOW_PRICE_ERROR_SHOW,
      (state, action) => ({
        ...state,
        showPriceError: action.payload,
      }),
    ],
    [
      SET_PENDING_APPLY_BUTTON,
      (state, action) => ({
        ...state,
        hideApplyButton: action.payload,
      }),
    ],

    [
      SHOW_ERROR_POPUP,
      (state, action) => ({
        ...state,
        showError: action.payload,
      }),
    ],
    [
      SET_SHOW_THANK_YOU_PAGE,
      (state, action) => ({
        ...state,
        showThankYouPage: action.payload,
      }),
    ],
    [
      SET_SINGLE_CODE,
      (state, action) => ({
        ...state,
        singleCode: action.payload,
      }),
    ],
    [
      SET_COUPON_CODE,
      (state, action) => ({
        ...state,
        couponCode: action.payload,
      }),
    ],
    [
      SET_CONTRIBUTIONS_LOADER,
      (state, action) => ({
        ...state,
        loader: action.payload,
      }),
    ],
    [
      SET_SESSION_CODE,
      (state, action) => ({
        ...state,
        sessionId: action.payload,
      }),
    ],
    [
      SET_EDIT_MODE,
      (state, action) => ({
        ...state,
        editMode: action.payload,
      }),
    ],
    [
      SET_EDIT_MODE_DATA,
      (state, action) => ({
        ...state,
        editModeData: action.payload,
      }),
    ],
    [
      SET_REPEATING_ERROR,
      (state, action) => ({
        ...state,
        repeatingError: action.payload,
      }),
    ],
    [
      SET_RESCHEDULE_HOST_SESSSION_DETAILS,
      (state, action) => ({
        ...state,
        rescheduleHostSessionDetails: action.payload,
      }),
    ],
    [
      SET_REFETCH_CONTRIBUTION,
      (state, action) => ({
        ...state,
        refetchContributons: action.payload,
      }),
    ],
    [
      SET_RESCHEDULE_SESSION_TIME_ID,
      (state, action) => ({
        ...state,
        sessionTimeId: action.payload,
      }),
    ],
    [
      SET_PICK_ANOTHER_TIME,
      (state, action) => ({
        ...state,
        pickAnotherTime: action.payload,
      }),
    ],
    [
      SET_FETCH_SLOTS_CALENDAR,
      (state, action) => ({
        ...state,
        fetchSlotsCalendarLoader: action.payload,
      }),
    ],
    [
      SET_RESCHEDULE_MODAL,
      (state, action) => ({
        ...state,
        reschedulePopUp: action.payload,
      }),
    ],
    [
      SET_FORMER_BOOKING_DATA,
      (state, action) => ({
        ...state,
        formerBookingData: action.payload,
      }),
    ],
    [
      SET_LOADING_PARTICIPANTS,
      (state, action) => ({
        ...state,
        loadingParticipants: action.payload,
      }),
    ],
    [
      SET_MY_AVAILABILITY,
      (state, action) => ({
        ...state,
        myAvailabilityTime: action.payload,
      }),
    ],
    [
      SET_CONTRIBUTION_ARRAY_MASTER,
      (state, action) => ({
        ...state,
        masterCalendarArray: action.payload,
      }),
    ],
    [
      SET_ERROR,
      (state, action) => ({
        ...state,
        errorState: action.payload,
      }),
    ],
    [
      SET_GET_START_SHOW,
      (state, action) => ({
        ...state,
        showCompletedGetStart: action.payload,
      }),
    ],
    [
      SET_ERROR,
      (state, action) => ({
        ...state,
        errorState: action.payload,
      }),
    ],
    [
      'UPDATE_FOR_FIRST_REDIRECT',
      (state, action) => ({
        ...state,
        firstLoad: false,
      }),
    ],
    [
      actions.calculateCreateSlotsActions.error,
      (state, { payload: { message } }) => ({
        ...state,
        loadingTimes: false,
        error: {
          message,
        },
      }),
    ],
    [
      actions.updateSessionsTabViewScreenUIActions.request,
      state => ({
        ...state,
        sessionsTabViewScreenUI: {
          ...state.sessionsTabViewScreenUI,
          // selectedSessionId: null,
          // selectedSessionTimeOrContentId: null,
          loading: true,
        },
      }),
    ],
    [
      actions.updateSessionsTabViewScreenUIActions.success,
      (state, { payload }) => ({
        ...state,
        sessionsTabViewScreenUI: {
          ...state.sessionsTabViewScreenUI,
          ...payload,
          loading: false,
        },
      }),
    ],
    [
      actions.updateSessionsTabViewScreenUIActions.complete,
      state => ({
        ...state,
        sessionsTabViewScreenUI: {
          ...state.sessionsTabViewScreenUI,
          loading: false,
        },
      }),
      // state => state,
    ],
    [
      actions.SHOW_SESSION_FEEDBACK_POPUP,
      (state, action) => ({
        ...state,
        showSessionFeedbackPopup: action.payload,
      }),
    ],
    [
      SET_FETCH_SLOTS_CALENDAR,
      (state, action) => ({
        ...state,
        fetchSlotsCalendarLoader: action.payload,
      }),
    ],
    [
      actions.SET_IS_NEWLY_CREATED,
      (state, action) => ({
        ...state,
        isNewlyCreated: action.payload,
      }),
    ],
    [
      actions.SET_RESCHEDULE_PARTICIPANT_INFO,
      (state, action) => ({
        ...state,
        participantInfo: action.payload,
      }),
    ],
    [
      actions.SET_GUEST_TIMEZONE,
      (state, action) => ({
        ...state,
        selectedGuestTimeZone: action.payload,
      }),
    ],
    [
      actions.SET_ONE_TO_ONE_RECURRING_AVAILABILITY,
      (state, action) => ({
        ...state,
        oneToOneRecurringAvailability: action.payload,
      }),
    ],
    [
      actions.SET_NOTES_STATUS,
      (state, action) => ({
        ...state,
        notesStatus: action.payload,
      }),
    ],
    [
      actions.SET_SHOW_SAVING_ANIMATION,
      (state, action) => ({
        ...state,
        showSavingAnimation: action.payload,
      }),
    ],
    [
      actions.SET_CLOSE_DISABLED,
      (state, action) => ({
        ...state,
        closeDisabled: action.payload,
      }),
    ],

    [
      actions.MASTER_CALENDAR_AVAILABILITY_POP_UP,
      (state, action) => ({
        ...state,
        showAvailabilityPopUp: action.payload,
      }),
    ],
    [
      actions.getIsAnyContributionCreatedOrJoinedActions.request,
      state => ({
        ...state,
        loading: true,
      }),
    ],
    [
      actions.getIsAnyContributionCreatedOrJoinedActions.error,
      (state, action) => ({
        ...state,
        loading: false,
        error: action.payload,
      }),
    ],
    [
      actions.getIsAnyContributionCreatedOrJoinedActions.success,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: null,
          isAnyContributionJoinedOrCreated: action.payload,
        };
      },
    ],
    [
      SET_REDIRECT_TO_SESSIONS,
      (state, action) => ({
        ...state,
        redirectToSessions: action.payload,
      }),
    ],
    [
      SET_INVITE_GET_STARTED,
      (state, action) => ({
        ...state,
        inviteGetStarted: action.payload,
      }),
    ],
  ]),
  initialState,
);

export default reducerContribution;
