import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core';

import {
  StyledMessage,
  StyledMetaData,
  StyledCommentWrap,
  AvatarComponent,
  StyledTextError,
  StyledButton,
  StyledClickDropDown,
  StyledName,
  StyledDate,
  StyledProfileLink,
  StyleTextLink,
  StyledText,
} from '../Post/Post.styled';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import moment from 'moment';
import Linkify from 'react-linkify';
import useContribution from '../../../hooks/useContribution';
import ClickDropDown, { StyledDropDownItem } from '../../../../../components/UI/ClickDropDown';
import { Grid, Box, CircularProgress, useMediaQuery, useTheme, ClickAwayListener } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { mdiDotsHorizontal } from '@mdi/js';
import some from 'lodash/some';
import { Formik } from 'formik';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { addComment, notifyTaggedUsers } from 'services/community.service';
import HappyEmoji from 'components/UI/chats/Chat/Icons/HappyEmoji';
import Picker from 'emoji-picker-react';
import EditCommentModal from '../EditModal/EditCommentModal';
import { removeComment } from 'services/community.service';
import { useRouter, useAccount, useUnifiedCommunity } from '../../../../../hooks';
import UserMentionInput from 'components/UI/mentions/UserMentionInput';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import * as _ from 'lodash';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { addhttp, lightOrDark } from 'utils/utils';
import CommunityLikeSvg from 'components/Icons/CommunityLike';
import CommunityLikeFilledSvg from 'components/Icons/CommunityLikeFilled';
import CommunityCommentSvg from 'components/Icons/CommunityComment';
import CommunityDeleteSvg from 'components/Icons/CommunityDelete';
import CommunityEditSvg from 'components/Icons/CommunityEdit';
import LikesModal from 'components/Modals/LikesModal';
import { formatPostTime } from 'utils/utils';
import { editComment as editCommentPost } from 'services/community.service';
import styled from 'styled-components';

const StyledCancelButton = styled.div`
  color: ${({ isDarkThemeEnabled }) => (isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.6)')};
  font-size: 14px;
  font-family: Avenir;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  cursor: pointer;
`;

const Comment = ({
  commentData,
  isCoach,
  handleToggleCommentLike,
  user,
  allComments,
  fetchPosts,
  generateHTMLText,
  pods = [],
  defaultTextareaValue = '',
  contributionData,
  contributionParticipants,
}) => {
  const { isUnifiedCommunity } = useUnifiedCommunity();
  const contrib = useContribution();
  const contribution = isUnifiedCommunity ? contributionData : contrib;
  const theme = useTheme();
  const [comment, setComment] = useState(commentData);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [commentPostPending, setCommentPostPending] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const isMyComment = user?.id === comment?.userInfo?.id;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showReplies, setShowReplies] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [emoji, setEmoji] = useState();
  const [addEmoji, setAddEmoji] = useState(false);
  const { history, domain } = useRouter();

  // const { partnerCoaches } = usePartnerCoach(contribution?.id);
  const podIdPrefix = 'podId-';
  const contributionAuthor = useSelector(state => state.cohealerInfo.info);
  const [userMentions, setUserMentions] = useState([]);
  const [resetUserMentionInputValue, setResetUserMentionInputValue] = useState(false);
  const [textareaValue, setTextareaValue] = useState(defaultTextareaValue);
  let colorToUse = determineColorToUse(isUnifiedCommunity ? {} : contribution);
  const [likesModalOpen, setLikesModalOpen] = useState(false);

  const isDarkThemeEnabled = determineDarkThemedColorToUse(isUnifiedCommunity ? {} : contribution);
  const { themedColor, themedCardBackgroundColor, themedBackgroundColor } = getThemedColors(
    isUnifiedCommunity ? {} : contribution,
  );

  const useStyles = makeStyles(theme => ({
    MainDivStyled: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: '#fafafa',
      borderRadius: '5px',
      paddingBottom: '8px',
    },
  }));
  const classes = useStyles();

  const btnColor = colorToUse.PrimaryColorCode;
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const users = [
    ...(contribution?.participants?.map(x => ({
      imageUrl: x.avatarUrl,
      display: `@${x.firstName} ${x.lastName}`,
      id: x.id,
    })) || []),
    ...(contribution?.contributionPartners?.map(
      x =>
        ({
          imageUrl: x.avatarUrl,
          display: `@${x.firstName} ${x.lastName}`,
          id: x.userId,
        } || []),
    ) || []),
    ...(pods?.map(x => ({ id: `${podIdPrefix}${x.id}`, display: x.name })) || []),
  ];
  if (user.id === contribution?.userId || contribution?.contributionPartners?.some(x => x.userId == user.id)) {
    users.push({
      imageUrl: null,
      display: `@channel`,
      id: 'channelId',
    });
  }
  if (contributionAuthor && !users.some(e => e.id === contributionAuthor.id)) {
    users.push({
      imageUrl: contributionAuthor.avatarUrl,
      display: `@${contributionAuthor.firstName} ${contributionAuthor.lastName}`,
      id: contributionAuthor.id,
    });
  }
  const handleKeyUp = useCallback((plainText, mentions, type = 'reply', formProps) => {
    if (type === 'reply') {
      setTextareaValue(plainText);
      setUserMentions(mentions);
    } else {
      formProps.setFieldValue('text', plainText);
    }
  });

  const onEmojiClick = (emojiObject, type = 'reply', formProps) => {
    if (type === 'reply') {
      setTextareaValue(prev => prev + emojiObject.emoji);
      setEmoji(emojiObject.emoji);
      setAddEmoji(true);
    } else {
      formProps.setFieldValue('text', formProps.values.text + emojiObject.emoji);
      setEmoji(emojiObject.emoji);
      setAddEmoji(true);
    }
  };

  const toggleReplies = () => {
    setShowReplies(!showReplies);
  };

  const handleToggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleCommentCreate = (e, { resetForm }) => {
    if (comment) {
      setCommentPostPending(true);
      addComment({
        text: textareaValue?.trim(),
        postId: comment?.postId,
        parentCommentId: comment?.id,
      })
        .then(data => {
          resetForm({});
          data.ident = comment?.ident + 1;
          comment.childComments.push(data);
          setCommentPostPending(false);
          setShowCommentInput(false);
          setShowReplies(true);
        })
        .then(async () => {
          if (!isEmpty(userMentions)) {
            let mentionedUserIds;

            if (userMentions.some(e => e.id === 'channelId')) {
              mentionedUserIds = isUnifiedCommunity
                ? _.uniq(contributionParticipants.map(x => x.id).filter(id => id !== 'channelId'))
                : (mentionedUserIds = uniq([
                    ...contribution?.participants?.map(x => x.id),
                    ...(contribution?.contributionPartners && contribution?.contributionPartners.map(x => x.userId)),
                    ...pods.flatMap(podItem => podItem.clientIds),
                    contribution?.userId,
                  ]));
            } else {
              mentionedUserIds = _.uniq([
                ...userMentions.filter(x => !x.id.includes(podIdPrefix)).map(x => x.id),
                ...userMentions.filter(x => x.id.includes(podIdPrefix)),
              ]).filter(x => !_.isEmpty(x));
            }
            await notifyTaggedUsers({
              mentionAuthorUserName: `${user.firstName} ${user.lastName}`,
              message: textareaValue,
              contributionName: contribution?.title,
              mentionedUserIds,
              replyLink: window.location.href,
              ContributionId: contribution?.id,
              PostId: comment?.postId,
              AuthorUserId: user?.id,
            });
          }
          setTextareaValue('');
          setResetUserMentionInputValue(true);
        });
    }
  };

  const handleClickEdit = useCallback(() => {
    setEditComment(true);
  }, [setEditComment]);

  const handleRemoveComment = () => {
    removeComment(comment?.id).then(() => {
      fetchPosts();
    });
  };

  const onStartDirectChat = useCallback(
    participantId => {
      const url = addhttp(domain?.concat(`/conversations/all?chatId=${participantId}`));
      window.open(url, '_blank');
    },
    [domain],
  );

  const handleCommentEdit = (e, { resetForm }) => {
    if (comment) {
      setCommentPostPending(true);
      editCommentPost({
        ...comment,
        text: e.text?.trim(),
        userId: comment.userInfo?.id,
      }).then(data => {
        resetForm({});
        const editedComment = {
          ...data,
          childComments: comment?.childComments,
          ident: comment?.ident,
        };
        setComment(editedComment);
        setTextareaValue('');
        setResetUserMentionInputValue(true);
        setCommentPostPending(false);
        setEditComment(false);
      });
    }
  };

  const getCommentInput = (type = 'reply') => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          text: type === 'reply' ? '' : comment.text || '',
        }}
        validateOnMount
        onSubmit={type === 'reply' ? handleCommentCreate : handleCommentEdit}
        id={`post-comment-form-${comment?.id}`}
      >
        {formProps => {
          return (
            <div className="create-post-root">
              <Grid container className="flex-flow-column">
                <Grid container className="flex-flow-row align-items-start">
                  <Grid container className="flex-flow-row align-items-start">
                    <div
                      style={{
                        position: 'relative',
                        width: '100%',
                        marginTop: '5px',
                        marginLeft: type === 'reply' ? '50px' : '',
                      }}
                    >
                      <Grid
                        className={` ${
                          !isUnifiedCommunity && isDarkThemeEnabled
                            ? `create-post-container-dark-themed `
                            : `create-post-container`
                        } flex-flow-row`}
                        container
                        style={{
                          width: '100%',
                          background:
                            !isUnifiedCommunity && isDarkThemeEnabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(250, 250, 250, 1)',
                          backgroundColor:
                            !isUnifiedCommunity && isDarkThemeEnabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(250, 250, 250, 1)',
                          padding: '5px 10px',
                          borderRadius: '10px',
                          paddingRight: '55px',
                          marginTop: '5px',
                        }}
                      >
                        <div
                          className={
                            !isUnifiedCommunity && isDarkThemeEnabled
                              ? 'create-comment-mention-input-container-dark-themed'
                              : 'create-comment-mention-input-container'
                          }
                        >
                          <UserMentionInput
                            isCreate={false}
                            placeholder={`Reply to ${comment?.userInfo?.firstName} ${comment?.userInfo?.lastName}`}
                            defaultValue={type === 'reply' ? textareaValue : formProps.values.text}
                            users={isUnifiedCommunity ? contributionParticipants : users}
                            type="comment"
                            onInputChange={(plainText, mentions) => {
                              handleKeyUp(plainText, mentions, type, formProps);
                            }}
                            resetInputValue={resetUserMentionInputValue}
                            onResetInputValueCallback={() => setResetUserMentionInputValue(false)}
                            addEmoji={addEmoji}
                            setAddEmoji={setAddEmoji}
                            emoji={emoji}
                          />
                        </div>
                      </Grid>
                      <div
                        style={{
                          position: 'absolute',
                          right: '5px',
                          bottom: '5px',
                          display: 'flex',
                        }}
                      >
                        <div style={{ marginLeft: '15px', position: 'relative', bottom: '1px' }}>
                          <HappyEmoji
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                            width="20px"
                            height="20px"
                            fill="#858585"
                          />
                        </div>
                        {showEmojiPicker && (
                          <ClickAwayListener
                            onClickAway={() => {
                              setShowEmojiPicker(false);
                            }}
                          >
                            <div style={{ position: 'absolute', bottom: '30px', right: '0px' }}>
                              <Picker
                                previewConfig={{
                                  showPreview: false,
                                }}
                                theme={!isUnifiedCommunity && isDarkThemeEnabled ? 'dark' : 'light'}
                                height={340}
                                width={300}
                                onEmojiClick={emojiObject => {
                                  onEmojiClick(emojiObject, type, formProps);
                                }}
                                disableAutoFocus={true}
                              />
                            </div>
                          </ClickAwayListener>
                        )}
                        <StyledButton
                          btnColor={btnColor}
                          disabled={
                            commentPostPending ||
                            (type === 'reply'
                              ? textareaValue?.trim()?.length <= 0
                              : formProps.values.text?.trim().length <= 0)
                          }
                          backgroundColor={colorToUse?.PrimaryColorCode}
                          autoWidth
                          type="submit"
                          id={`post-create-form-${comment?.id}`}
                          onClick={formProps.handleSubmit}
                        >
                          {commentPostPending ? (
                            <CircularProgress
                              size={24}
                              className="chat-messages-history-list__load-more-messages-progress"
                            />
                          ) : (
                            <SendIcon
                              style={{ color: textColor, height: '14px', width: '14px', marginLeft: '3px' }}
                              className="smoked-gary-color hoverable"
                            />
                          )}
                        </StyledButton>
                      </div>
                    </div>
                  </Grid>
                  {formProps.errors.text && formProps.dirty && (
                    <StyledTextError mobileView={mobileView}>{formProps.errors.text}</StyledTextError>
                  )}
                </Grid>
                {type === 'edit' && (
                  <StyledCancelButton
                    onClick={() => {
                      formProps.resetForm({});
                      setEditComment(false);
                    }}
                    isDarkThemeEnabled={isDarkThemeEnabled}
                  >
                    Cancel
                  </StyledCancelButton>
                )}
              </Grid>
            </div>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      {comment && (
        <StyledCommentWrap
          style={
            isUnifiedCommunity
              ? {
                  borderRadius: '14px',
                  background: 'white',
                  paddingTop: '20px',
                }
              : {
                  borderRadius: '14px',
                  background: isDarkThemeEnabled ? themedBackgroundColor : 'white',
                  paddingTop: '20px',
                }
          }
          ident={comment?.ident}
        >
          <Box component="div" display="flex">
            <Grid container style={{ flexWrap: 'nowrap' }}>
              <StyledProfileLink
                isMyPost={isMyComment}
                onClick={() => {
                  if (!isMyComment) onStartDirectChat(comment?.userInfo?.id);
                }}
                isDarkThemeEnabled={isDarkThemeEnabled}
                style={{ marginRight: '10px' }}
              >
                <AvatarComponent
                  alt={`${comment?.userInfo?.firstName}${comment?.userInfo?.lastName}`}
                  src={comment?.userInfo?.avatarUrl}
                  mobileView={mobileView}
                >
                  {comment?.userInfo?.firstName[0]}
                  {comment?.userInfo?.lastName[0]}
                </AvatarComponent>
              </StyledProfileLink>
              <Grid item style={{ width: '100%' }}>
                <Box
                  style={{
                    backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? '#252728' : '#FFFFFF',
                    color: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black',
                  }}
                  component="div"
                  className={classes.MainDivStyled}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    {' '}
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '6px', alignItems: 'center' }}>
                      <StyledProfileLink
                        isMyPost={isMyComment}
                        onClick={() => {
                          if (!isMyComment) onStartDirectChat(comment?.userInfo?.id);
                        }}
                        isDarkThemeEnabled={isDarkThemeEnabled}
                      >
                        <StyledName>
                          {comment?.userInfo?.firstName} {comment?.userInfo?.lastName}
                        </StyledName>
                      </StyledProfileLink>
                      <div
                        style={{
                          width: '4px',
                          height: '4px',
                          borderRadius: '50%',
                          backgroundColor: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'black',
                          display: 'inline-block',
                          opacity: '70%',
                        }}
                      />
                      <StyledDate
                        title={moment(comment?.createTime).format(DATE_FORMATS.DATE_TIME)}
                        style={{ color: themedColor }}
                      >
                        {formatPostTime(comment?.createTime)}
                      </StyledDate>
                    </div>
                    {editComment ? (
                      getCommentInput('edit')
                    ) : (
                      <StyledText
                        isComment={true}
                        style={isUnifiedCommunity ? { opacity: '90%' } : { color: themedColor, opacity: '90%' }}
                      >
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) => (
                            <StyleTextLink target="blank" href={decoratedHref} key={key}>
                              {decoratedText}
                            </StyleTextLink>
                          )}
                        >
                          {!isEmpty(comment?.text) &&
                            comment?.text
                              ?.split('\n')
                              .map(str => <div dangerouslySetInnerHTML={{ __html: generateHTMLText(str) }} />)}
                        </Linkify>
                      </StyledText>
                    )}
                  </div>
                  {!editComment && (
                    <div id="HHHHHHH">
                      <Box>
                        {(isMyComment || isCoach) && (
                          <StyledClickDropDown noMarginLeft={true}>
                            <ClickDropDown icon={mdiDotsHorizontal}>
                              {isMyComment && (
                                <StyledDropDownItem
                                  style={
                                    isUnifiedCommunity
                                      ? { backgroundColor: 'white' }
                                      : { color: themedColor, backgroundColor: themedBackgroundColor }
                                  }
                                  onClick={handleClickEdit}
                                >
                                  <CommunityEditSvg width="24px" height="24px" color={themedColor} />
                                  Edit
                                </StyledDropDownItem>
                              )}
                              {(isMyComment || isCoach) && (
                                <StyledDropDownItem
                                  style={
                                    isUnifiedCommunity
                                      ? { backgroundColor: 'white' }
                                      : { color: themedColor, backgroundColor: themedBackgroundColor }
                                  }
                                  onClick={handleRemoveComment}
                                >
                                  <CommunityDeleteSvg width="24px" height="24px" color={themedColor} />
                                  Delete
                                </StyledDropDownItem>
                              )}
                            </ClickDropDown>
                          </StyledClickDropDown>
                        )}
                      </Box>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Grid container>
            <Grid item md={12} xs={12}>
              <StyledMetaData
                style={{
                  position: 'relative',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
                isComment={true}
              >
                {!editComment && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      marginLeft: '50px',
                    }}
                  >
                    <StyledMessage
                      style={{ position: 'relative', bottom: '-1px' }}
                      className="inline-block"
                      onClick={() => handleToggleCommentLike(comment)}
                    >
                      {some(comment?.likes, { userInfo: { id: user?.id } }) && (
                        <CommunityLikeFilledSvg
                          style={{ cursor: 'pointer', opacity: '60%' }}
                          height="22px"
                          width="22px"
                          color={colorToUse?.AccentColorCode}
                        />
                      )}
                      {!some(comment?.likes, { userInfo: { id: user?.id } }) && (
                        <CommunityLikeSvg
                          style={{ cursor: 'pointer', opacity: '60%' }}
                          height="22px"
                          width="22px"
                          color={colorToUse?.AccentColorCode}
                        />
                      )}
                    </StyledMessage>
                    <StyledMessage style={{ gap: '4px' }} onClick={handleToggleCommentInput} className="inline-block">
                      <CommunityCommentSvg
                        style={{ cursor: 'pointer', opacity: '60%' }}
                        height="22px"
                        width="22px"
                        color={colorToUse?.AccentColorCode}
                      />
                      <span style={{ fontSize: '14px', fontWeight: '400', opacity: '60%' }}>Reply </span>
                    </StyledMessage>
                  </div>
                )}

                <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                  <StyledMessage
                    onClick={() => {
                      setLikesModalOpen(true);
                    }}
                  >
                    {comment?.likes?.length > 0 && (
                      <span style={{ fontSize: '14px', opacity: '60%' }}>
                        {comment?.likes?.length === 1 ? '1 like' : `${comment?.likes?.length} likes`}
                      </span>
                    )}
                  </StyledMessage>
                </div>
              </StyledMetaData>
            </Grid>
          </Grid>
          {showCommentInput && getCommentInput()}
          {likesModalOpen && (
            <LikesModal
              isOpen={likesModalOpen}
              handleClose={() => {
                setLikesModalOpen(false);
              }}
              isDarkModeEnabled={isUnifiedCommunity ? false : contribution?.isDarkModeEnabled}
              likes={comment?.likes}
            />
          )}
        </StyledCommentWrap>
      )}
      <div style={{ marginTop: '10px' }}>
        {comment?.childComments?.length > 0 && (
          <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '50px' }}>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid rgba(223, 227, 228, 1)',
                margin: '0px 10px 0px 0px',
                width: '20px',
                height: '100%',
                placeSelf: 'center',
              }}
            />
            <span
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: !isUnifiedCommunity && isDarkThemeEnabled ? 'white' : 'rgba(0, 0, 0, 0.6)',
                cursor: 'pointer',
                display: 'block',
              }}
              onClick={toggleReplies}
            >
              {showReplies
                ? comment.childComments.length === 1
                  ? 'Hide reply'
                  : 'Hide replies'
                : comment.childComments.length === 1
                ? 'View reply'
                : `View all ${comment.childComments.length} replies`}
            </span>
          </div>
        )}
        {showReplies &&
          comment.childComments.map(reply => (
            <Comment
              key={reply.id}
              themedColor={themedColor}
              themedCardBackgroundColor={themedCardBackgroundColor}
              themedBackgroundColor={themedBackgroundColor}
              commentData={reply}
              isCoach={isCoach}
              generateHTMLText={generateHTMLText}
              user={user}
              handleToggleCommentLike={handleToggleCommentLike}
              allComments={allComments}
              fetchPosts={fetchPosts}
              pods={pods}
              contributionParticipants={contributionParticipants}
            />
          ))}
      </div>
    </>
  );
};

export default Comment;
