import useContribution from 'pages/ContributionView/hooks/useContribution';
import React, { useState, useEffect, useSelector } from 'react';
import CalendarContributionViewForPaymentCard from '../CalendarContributionViewForPaymentCard/CalendarContributionViewForPaymentCard';
import { getTimePeriodsForAvailability } from 'utils/datesAndMoney';
import { TIMEZONES } from 'constants.js';
import { connect } from 'react-redux';

const FreeSingleView = ({ contributionTimes }) => {
  const contribution = useContribution();
  const [selectedTimeZone, setSelectedTimeZone] = useState(contribution?.timeZoneId ?? 'Select Timezone');

  return (
    <div style={{ pointerEvents: 'none' }}>
      <CalendarContributionViewForPaymentCard
        isCodeToJoin={false}
        events={[]}
        onDrillDown={() => {}}
        onSelectEvent={() => {}}
        // dateToShow={dateToShow}
        calendarRef={null}
        rescheduleMode={false}
        timeZoneId={contribution?.timeZoneId}
        selectedTimeZone={selectedTimeZone}
        selectTimeZone={setSelectedTimeZone}
      />
    </div>
  );
};

const mapStateToProps = ({ contributions }) => ({
  contributionTimes: contributions?.contributionTimes,
  isCustomBrandingColorsActive: contributions?.activeContribution?.isCustomBrandingColorsActive,
  isBrandingColor: contributions?.activeContribution?.brandingColors,
});

export default connect(mapStateToProps)(FreeSingleView);
