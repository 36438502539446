import React, { useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Select from 'components/FormUI/Select';
import * as countryActions from 'actions/country';
import { Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import SignatureCanvas from 'react-signature-canvas';
import PropTypes from 'prop-types';
import Popup from 'components/Popup/Popup';
import styled from 'styled-components';
import { colors } from 'utils/styles/styles';
import { useDispatch, useSelector } from 'react-redux';
import { LabelText, PageSubtitleCoach, PageTitleSecond } from 'components/UI/Text/TextStyles';
import { StyledText } from 'pages/Auth/SignInPage';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { FormikScrollToError } from 'components/FormikScrollToError';
import { useAccount } from 'hooks';
import { formatMoney } from 'utils/datesAndMoney';
import CreditCard from './CreditCard';
import AccountForm from './AccountForm/AccountForm';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import { PAYMENT_OPTIONS } from '../../../../constants.js';
import { ContributionType } from 'helpers/constants';
import ApplyCoupon from './ApplyCoupon';
import { PURCHASE_MODAL_STEPS } from './PurchaseModal.constants';
import { determineColorToUse, getThemedColors } from 'services/contributions.service';
import { ACCOUNT_FORM_FIELDS } from './PurchaseModal.constants';
import { Typography } from '@material-ui/core';
import { useStyles } from './AccountForm/AccountForm';
import { lightOrDark } from 'utils/utils';
const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;

export const PurchaseModalForm = ({
  typeOfPayment,
  setTypeOfPayment,
  loading,
  summary,
  isPackage,
  isMonthlySessionSubscription,
  handleChange,
  setFieldValue,
  handleRedeem,
  errors,
  touched,
  error,
  setShowTerms,
  step,
  formRef,
  paymentIntentCreated,
  sessionLifeTimeSeconds,
  onCheckoutSessionExpired,
  submitTitle,
  addButtons,
  setDisableValue,
  isCouponAvailable,
}) => {
  const theme = useTheme();
  const classNames = useStyles();
  const [disableBtn, setDisableBtn] = useState(true);
  const [sessionTimeExpired, setSessionTimeExpired] = useState(false);
  const mobileView = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAccount();
  const { serviceProviderName, customToS } = useContribution();
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const { states } = useSelector(state => state?.states);
  const [statesArray, setStatesArray] = useState([]);
  const [timeZoneSelected, settimeZoneSelected] = useState(null);
  const [showThreeFields, setshowThreeFields] = useState(false);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { countries } = useSelector(state => state?.country);
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const contribution = useContribution();
  const { themedColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
  }, [timeZones, timeZoneLoading]);
  useEffect(() => {
    checkCountry();
  }, [step, timeZones, states]);
  const {
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      membershipInfo,
    },
    type,
    title,
    defaultCurrency,
    defaultSymbol,
    isElectronicSignatureActive,
    isPurchased,
    customWaiverId,
    customWaiverTemplateName,
    isPriceHidden,
  } = contribution;
  const showDueLater = type !== ContributionType.contributionMembership;
  let colorToUse = determineColorToUse(contribution);

  const showPadForLoggedInClient = !isEmpty(user) && step === PURCHASE_MODAL_STEPS.loggedIn && !isPurchased;
  const PrimaryColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const clearSign = () => {
    if (canvasRef?.current) {
      canvasRef.current.clear();
      setDisableValue(true);
      setFieldValue(ACCOUNT_FORM_FIELDS.signature, null);
    }
  };

  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    // const currentCountry = { id: '60b8ddb57205057e7ce2b861' };
    const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
    const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
    setStatesArray(filteredObjects);
    formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
    setTimeZoneArray(filteredTimezone);
    if (filteredTimezone.length === 1) {
      setshowThreeFields(false);
    } else {
      if (currentCountry.id === user.countryId && filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        setshowThreeFields(true);
      }
    }
    if (!isEmpty(user)) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
    }
  };
  return (
    <>
      <FormikScrollToError>
        <Form id="credit-card-form">
          <>
            <Grid container spacing={2}>
              {!isInviteToJoin && (
                <>
                  <Grid item md={12} xs={12}>
                    <LabelText mobileView={mobileView}>Payment Summary</LabelText>

                    <RadioGroup value={typeOfPayment} onChange={e => setTypeOfPayment(e.target.value)}>
                      {/* {paymentOptions.includes('EntireCourse') && (
                      <FormControlLabel
                        disabled={loading}
                        value="EntireCourse"
                        control={<Radio color="primary" />}
                        label="Pay in Full"
                      />
                    )} */}
                      {paymentOptions.includes('SplitPayments') && (
                        <FormControlLabel
                          disabled={false || loading}
                          value="SplitPayments"
                          control={<Radio color="primary" />}
                          label={`Split: ${splitNumbers} Payments, Billed ${splitPeriod}`}
                        />
                      )}
                      {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                        <FormControlLabel
                          disabled={loading}
                          value={PAYMENT_OPTIONS.PER_MONTH}
                          control={<Radio color="primary" />}
                          label="Monthly subscription"
                        />
                      )}
                      {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                        <FormControlLabel
                          disabled={loading}
                          value={PAYMENT_OPTIONS.PER_YEAR}
                          control={<Radio color="primary" />}
                          label="Yearly subscription"
                        />
                      )}
                      {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                        <FormControlLabel
                          disabled={loading}
                          value={PAYMENT_OPTIONS.PACKAGE}
                          control={<Radio color="primary" />}
                          label={`${membershipInfo.membershipPackage?.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                        />
                      )}
                    </RadioGroup>
                    {error && <CommonErrorMessage message={error} />}
                  </Grid>
                  {!isPriceHidden && !isMonthlySessionSubscription && (
                    <Grid item md={12} xs={12}>
                      <div>
                        {summary && isPackage && (
                          <>
                            {!isPriceHidden && !isMonthlySessionSubscription && (
                              <PageSubtitleCoach>
                                {paymentOptions.includes('PerSession')
                                  ? isPackage
                                    ? `Price per ${packageSessionNumbers} Sessions Package`
                                    : 'Price per Session'
                                  : 'Price per Course'}
                                :{' '}
                                {defaultSymbol +
                                  formatMoney(summary.price /*- summary.platformFee*/) +
                                  ' ' +
                                  defaultCurrency.toUpperCase()}
                              </PageSubtitleCoach>
                            )}
                            <PageSubtitleCoach>
                              Processing Fee:{' '}
                              {defaultSymbol + formatMoney(summary.platformFee) + ' ' + defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                            {/* {summary.coupon?.discount && (
                          <PageSubtitleCoach>
                            Discount: ${formatMoney(Math.min(summary.coupon?.discount, summary.dueNow))}
                          </PageSubtitleCoach>
                        )} */}
                            {/* <PageSubtitleCoach>
                            Due Now:{' '}
                            {defaultSymbol +
                              formatMoney(summary.dueNowWithCouponDiscountAmount) +
                              ' ' +
                              defaultCurrency.toUpperCase()}
                          </PageSubtitleCoach>
                          {showDueLater && (
                            <PageSubtitleCoach>
                              Due Later:{' '}
                              {defaultSymbol + formatMoney(summary.dueLater) + ' ' + defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                          )} */}
                            {paymentIntentCreated && sessionLifeTimeSeconds && (
                              <PageSubtitleCoach>
                                Checkout time left:{' '}
                                <Countdown
                                  date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                                  onComplete={() => {
                                    setSessionTimeExpired(true);
                                  }}
                                  renderer={props => (
                                    <>
                                      {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                    </>
                                  )}
                                />
                              </PageSubtitleCoach>
                            )}
                          </>
                        )}
                        {summary && isPackage === false && (
                          <>
                            <PageSubtitleCoach>
                              {`Total Price - Includes ${monthlySessionSubscriptionInfo?.sessionCount} Session(s) Monthly, ${monthlySessionSubscriptionInfo?.duration} Months Total`}
                              :{' '}
                              {defaultSymbol +
                                formatMoney(summary.price - summary.platformFee) +
                                ' ' +
                                defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                            <PageSubtitleCoach>
                              Processing Fee:{' '}
                              {defaultSymbol + formatMoney(summary.platformFee) + ' ' + defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                            <PageSubtitleCoach>
                              Due Now:
                              {defaultSymbol +
                                formatMoney(summary.dueNowWithCouponDiscountAmount) +
                                ' ' +
                                defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                            <PageSubtitleCoach>
                              Due Later:{' '}
                              {defaultSymbol + formatMoney(summary.dueLater) + ' ' + defaultCurrency.toUpperCase()}
                            </PageSubtitleCoach>
                            {paymentIntentCreated && sessionLifeTimeSeconds && (
                              <PageSubtitleCoach>
                                Checkout time left:{' '}
                                <Countdown
                                  date={moment(paymentIntentCreated).add(sessionLifeTimeSeconds, 'seconds')}
                                  onComplete={() => {
                                    setSessionTimeExpired(true);
                                  }}
                                  renderer={props => (
                                    <>
                                      {zeroPad(props.minutes)}:{zeroPad(props.seconds)}
                                    </>
                                  )}
                                />
                              </PageSubtitleCoach>
                            )}
                          </>
                        )}
                      </div>
                    </Grid>
                  )}
                  {isCouponAvailable && (
                    <Grid item md={6} xs={12}>
                      <ApplyCoupon
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        handleRedeem={handleRedeem}
                      />
                    </Grid>
                  )}
                  {isMonthlySessionSubscription && (
                    <Grid item md={6} xs={12}>
                      <CreditCard
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        setShowTerms={setShowTerms}
                      />
                      {error && <CommonErrorMessage message={error} />}
                    </Grid>
                  )}
                </>
              )}
              {!isEmpty(user) && isInviteToJoin && (
                <Grid item xs={12}>
                  <PageTitleSecond>You joined {title} successfully</PageTitleSecond>
                </Grid>
              )}
              {!isEmpty(user) && showThreeFields && (
                <>
                  {contribution.paymentType === 'Simple' &&
                    contribution.taxType != 'No' &&
                    isInviteToJoin === null &&
                    !isMonthlySessionSubscription && (
                      <>
                        <Grid item md={6} xs={12}>
                          <Typography variant="h6" style={{ fontSize: '20px', color: themedColor }}>
                            Where are you located?
                          </Typography>
                          <Select
                            label="Country of Residence"
                            name={ACCOUNT_FORM_FIELDS.country}
                            labelId="country"
                            value={formRef?.current?.values?.countryId}
                            onChange={e => {
                              const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                              const filteredCountry = countries.find(obj => obj.id === e.target.value);
                              const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                              if (filteredObjects.length >= 1) {
                                setStatesArray(filteredObjects);
                                if (e.target.value != '60b8ddb57205057e7ce2b861') {
                                  setTimeZoneArray(filteredTimezone);
                                }
                              } else if (filteredObjects.length === 0) {
                                setStatesArray(filteredObjects);
                                if (filteredTimezone.length === 0) {
                                  setTimeZoneArray(timeZones);
                                } else if (
                                  filteredTimezone.length === 1 &&
                                  filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                ) {
                                  setTimeZoneArray(filteredTimezone);
                                  setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                                } else {
                                  setTimeZoneArray(filteredTimezone);
                                }
                              }
                              if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                                setTimeZoneArray(filteredTimezone);
                                setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
                                setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                              }
                              setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                            }}
                            fullWidth
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  color: themedColor,
                                  backgroundColor: themedCardBackgroundColor,
                                },
                              },
                            }}
                            items={
                              countries?.length > 0
                                ? countries.map(timeZone => {
                                    return { value: timeZone.id, title: timeZone.name };
                                  })
                                : []
                            }
                          />
                        </Grid>
                      </>
                    )}
                  {contribution.taxType != 'No' &&
                    isInviteToJoin === null &&
                    formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                    statesArray.length > 1 &&
                    !isMonthlySessionSubscription && (
                      <Grid style={{ display: 'flex', alignItems: 'flex-end' }} item md={6} xs={12}>
                        <Select
                          label="State"
                          name={ACCOUNT_FORM_FIELDS.state}
                          labelId="stateCode"
                          value={formRef?.current?.values?.stateCode}
                          onChange={e => {
                            const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                            const filteredObjects = timeZones.filter(obj => obj.statesIds.includes(filteredState.id));
                            if (filteredObjects.length >= 1) {
                              if (filteredObjects.length === 1) {
                                setTimeZoneArray(filteredObjects);
                                setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                              } else {
                                setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                setTimeZoneArray(filteredObjects);
                              }
                            } else {
                              setTimeZoneArray(timeZones);
                            }
                            setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                          }}
                          fullWidth
                          MenuProps={{
                            PaperProps: {
                              style: {
                                color: themedColor,
                                backgroundColor: themedCardBackgroundColor,
                              },
                            },
                          }}
                          items={
                            statesArray?.length > 0
                              ? statesArray.map(timeZone => {
                                  return { value: timeZone.alpha2Code, title: timeZone.name };
                                })
                              : []
                          }
                        />
                      </Grid>
                    )}
                  {contribution.paymentType === 'Simple' &&
                    contribution.taxType != 'No' &&
                    // isShowInput(step, ACCOUNT_FORM_FIELDS.country) &&
                    isInviteToJoin === null &&
                    timeZoneArray.length > 1 &&
                    formRef?.current?.values?.countryId != null && (
                      <>
                        <Grid style={{ display: 'flex', alignItems: 'flex-end' }} item md={6} xs={12}>
                          <Select
                            label="Timezone"
                            fullWidth
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  color: themedColor,
                                  backgroundColor: themedCardBackgroundColor,
                                },
                              },
                            }}
                            itemTextColor={themedColor}
                            name={ACCOUNT_FORM_FIELDS.timeZoneId}
                            onChange={e => {
                              setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                              const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                              // setColorForSelect(color);
                            }}
                            value={formRef?.current?.values?.TimeZoneId}
                            items={
                              timeZoneArray?.length > 0
                                ? timeZoneArray.map(timeZone => {
                                    return { value: timeZone.countryName, title: timeZone.name };
                                  })
                                : []
                            }
                          />
                        </Grid>
                      </>
                    )}
                </>
              )}
              {isEmpty(user) && (
                <AccountForm
                  colorToUse={colorToUse}
                  mobileView={mobileView}
                  handleChange={handleChange}
                  errors={errors}
                  formRef={formRef}
                  touched={touched}
                  isMonthlySessionSubscription={isMonthlySessionSubscription}
                  step={step}
                  isElectronicSignatureActive={isElectronicSignatureActive}
                  customWaiverId={customWaiverId}
                  setDisableValue={setDisableValue}
                  setFieldValue={setFieldValue}
                />
              )}
              {isElectronicSignatureActive && customWaiverId === null && showPadForLoggedInClient && (
                <Grid item md={12} xs={12}>
                  <div
                    style={{
                      position: 'relative',
                      width: mobileView ? '100%' : '48%',
                      border: '1px solid darkgray',
                      marginTop: mobileView ? '0px' : '50px',
                    }}
                  >
                    <SignatureCanvas
                      ref={canvasRef}
                      clearOnResize={false}
                      penColor="black"
                      canvasProps={{ className: classNames.signCanva }}
                      onEnd={() => {
                        setDisableValue(false);
                        if (canvasRef?.current) {
                          const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                          setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                        }
                      }}
                    />
                    <div
                      type="button"
                      onClick={clearSign}
                      style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: '5px',
                        right: '10px',
                        // color: colorToUse?.PrimaryColorCode,
                        fontWeight: 'bold',
                      }}
                    >
                      Clear
                    </div>
                  </div>
                  <StyledText style={{ color: PrimaryColor }}>Sign Here To Join</StyledText>
                </Grid>
              )}
            </Grid>
            {step !== PURCHASE_MODAL_STEPS.join && step !== PURCHASE_MODAL_STEPS.init && (
              // !isMonthlySessionSubscription &&
              // !isPackage && (
              <>
                <Grid item>
                  {customToS ? (
                    isElectronicSignatureActive ? (
                      <Typography variant="caption">
                        By signing and clicking join,I agree to the{' '}
                        <StyledLink
                          href="/"
                          color={colors.goldenColor}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </StyledLink>
                        , I also agree to {serviceProviderName}
                        {"'"}s{' '}
                        <StyledLink color={colors.goldenColor} href={customToS}>
                          Terms and Conditions
                        </StyledLink>
                        , and I'm at least 18 years old.
                      </Typography>
                    ) : (
                      <Typography variant="caption">
                        By clicking join,I agree to the{' '}
                        <StyledLink
                          href="/"
                          color={colors.goldenColor}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </StyledLink>
                        , I also agree to {serviceProviderName}
                        {"'"}s{' '}
                        <StyledLink color={colors.goldenColor} href={customToS}>
                          Terms and Conditions
                        </StyledLink>
                        , and I'm at least 18 years old.
                      </Typography>
                    )
                  ) : (
                    <>
                      <Typography variant="caption">
                        {isElectronicSignatureActive ? 'By signing and clicking join' : 'By clicking join'}, I agree to
                        the{' '}
                        <StyledLink
                          href="/"
                          color={colors.goldenColor}
                          onClick={e => {
                            e.preventDefault();
                            setShowTerms(true);
                          }}
                        >
                          Terms and Conditions
                        </StyledLink>
                        {customWaiverId != null ? (
                          <span>
                            {' '}
                            {/* , I also agree to {serviceProviderName}'s{' '}
                            <StyledDiv color={colorToUse?.PrimaryColorCode}>
                              {customWaiverTemplateName.trim()}
                            </StyledDiv> */}
                            , and I'm at least 18 years old.
                          </span>
                        ) : (
                          <span> and I'm at least 18 years old.</span>
                        )}
                      </Typography>
                      <br />
                    </>
                  )}
                </Grid>
              </>
            )}
          </>
        </Form>
      </FormikScrollToError>
      <Popup
        open={sessionTimeExpired}
        title="The checkout time has expired"
        text="The checkout time has expired. Please select your session time again to complete registration."
        onCloseText="Select Session"
        onClose={onCheckoutSessionExpired}
      />
    </>
  );
};

PurchaseModalForm.propTypes = {
  typeOfPayment: PropTypes.string.isRequired,
  setTypeOfPayment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  summary: PropTypes.shape({
    price: PropTypes.number,
    platformFee: PropTypes.number,
    dueNow: PropTypes.number,
    dueLater: PropTypes.number,
  }),
  isPackage: PropTypes.bool.isRequired,
  isMonthlySessionSubscription: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleRedeem: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(PropTypes.shape({})),
  touched: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  setShowTerms: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  paymentIntentCreated: PropTypes.string,
  sessionLifeTimeSeconds: PropTypes.number,
  monthlySessionSubscriptionInfo: PropTypes.shape({
    sessionCount: PropTypes.number,
    duration: PropTypes.number,
    monthlyPrice: PropTypes.number,
  }),
};

PurchaseModalForm.defaultProps = {
  summary: undefined,
  isInviteToJoin: null,
  errors: undefined,
  error: undefined,
  paymentIntentCreated: undefined,
  sessionLifeTimeSeconds: undefined,
};
