import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import Select from '@material-ui/core/Select';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import { Card as CardUI, CardHeader as CardHeaderUI } from 'components/UI/Card';
import { TemplateType } from 'helpers/constants/templateType';
import SignatureCanvas from 'react-signature-canvas';
import { dataURLtoFile, getIpGlobal } from 'utils/utils';
import { updateUser } from 'actions/update-user';
import { editUserProfile } from 'services/user.service';
import Modal from 'components/UI/Modal';
import { PhoneInput } from 'react-international-phone';
import Checkbox from '@material-ui/core/Checkbox';
import SignInWithOtpModal from 'components/Modals/SignInWithOtpModal';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import * as purchaseService from 'services/purchase.service';
import styled, { css } from 'styled-components';
import { colors } from 'utils/styles';
import { ModalTermsAndConditions } from 'components/Modals/TermsAndConditions';
import Loader from 'components/UI/Loader';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { darken } from 'polished';
import { formatMoney } from 'utils/datesAndMoney';
import { determineDarkThemedColorToUse } from 'services/contributions.service';
import { useDispatch, useSelector } from 'react-redux';
import { errorSelector } from 'selectors/user';
import {
  ACCOUNT_FORM_FIELDS,
  ACCOUNT_FORM_FIELDS_LABELS,
  DISCOUNT_CODE_FIELDS,
  PURCHASE_MODAL_STEPS,
  RESTORE_PASS,
} from 'pages/ContributionView/components/PurchaseModal/PurchaseModal.constants';
// import { StyledLink } from 'pages/Auth/SignInPage';
import { Link } from 'react-router-dom';
import { fetchTimeZones } from 'actions/timeZone';
import * as countryActions from 'actions/country';
import { If, mapTo } from 'utils/fp';
import Input from 'components/FormUI/Input';
import { FormikScrollToError } from 'components/FormikScrollToError';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import { useAccount, useApplicationStatus, useHttp, useRouter, useClientPreview, useDefaultTimezone } from 'hooks';
import { getPurchaseValidationSchema } from 'utils/validation';
import {
  determineColorToUse,
  joinContribution,
  saveSignoffData,
  getThemedColors,
  getSingleContract,
  setSignedContract,
} from 'services/contributions.service';
import {
  fetchClientContribution,
  fetchClientContributionAfterInterval,
  fetchContributionActions,
  setCouponCode,
  setInviteCode,
} from 'actions/contributions';
import {
  completeRegister,
  CONTINUE_REGISTER_FAILURE,
  LOG_IN_FAILURE,
  login,
  REGISTER_FAILURE,
  getOTP,
} from 'actions/user';
import { ContributionPurchaseType, ContributionType, UserRoles, ContributionThemedColors } from 'helpers/constants';
import { useIsEmailExist } from 'utils/useIsEmailExist';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { PageTitleSecond } from 'components/UI/Text/TextStyles';
import { PAYMENT_OPTIONS, JOIN_CONTRIBUTION_STATUS, ROUTES } from '../../../../constants';
import { useStyles } from './hooks/useStyles';
import { lightOrDark } from 'utils/utils';
import CountryModal from 'pages/Dashboard/components/CohealerDashboard/CountrySelectionModal';
import CreateCustomWaiver from 'pages/CreateContribution/components/CreateCustomWaiver';
import { redirectTo } from 'services/links';
import useApplyContributionView from 'hooks/useApplyContributionView';
import { PrivacyTip } from '@mui/icons-material';
/* styled components start */
const StyledLink = styled.a`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const StyledLink2 = styled.a`
  color: ${colors.lightBrown};
  margin: 1rem 1.25rem;
  font-size: 0.875rem;
  vertical-align: middle;
  display: inline-block;
`;
const StyledButton = styled.button`
  position: relative;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 2px;
  border: none;
  color: #fff;
  fill: #fff; /* for svg */
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 1.25px;
  min-width: 18rem;
  width: 100%;
  transition: background 0.2s;
  font-family: 'Avenir';

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 10px;
    `}

  ${({ variant, backgroundColor, textColor }) => {
    if (backgroundColor) {
      return css`
        background-color: ${backgroundColor};
        color: ${textColor};
      `;
    }
    return variant === 'primary'
      ? css`
          background-color: ${colors.darkOceanBlue};
        `
      : css`
          background-color: ${colors.lightBrown};
        `;
  }}

  ${({ invert }) =>
    invert &&
    css`
      color: rgba(0, 0, 0, 0.87);
      background-color: white;
      border: 1px solid rgba(0, 0, 0, 0.87);
    `}

  &:hover {
    ${({ variant, backgroundColor }) => {
      if (backgroundColor) {
        return css`
          background-color: ${darken(0.05, backgroundColor)};
        `;
      }
      return variant === 'primary'
        ? css`
            background-color: ${darken(0.05, colors.darkOceanBlue)};
          `
        : css`
            background-color: ${darken(0.05, colors.lightBrown)};
          `;
    }}

    ${({ invert }) =>
      invert &&
      css`
        background-color: ${darken(0.05, 'white')};
      `}
  }

  :disabled {
    background-color: #ededed;
    color: #9b9b9b;
    fill: #9b9b9b; /* for svg */
    cursor: not-allowed;
    border: none;
  }

  ${({ mobileView }) =>
    mobileView &&
    `
    font-size: 12px;
    padding: 8px 16px;
    min-width: 8rem;
  `}
`;
const PriceContainer = styled.div`
  background-color: #fafafa;
  padding: 20px 15px;
`;
const ApplyCouponContainer = styled.div`
  background-color: #fafafa;
  padding: 15px;
  margin-top: 20px;
`;
const StyledDiv = styled.span`
  color: ${props => (props.color ? props.color : colors.darkOceanBlue)};
  font-weight: 600;
`;
const ForgotPasswordLink = styled(Link)`
  color: ${props => props.color};
  font-family: 'Avenir';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  margin-top: 14px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledError = styled.div`
  color: ${({ template, PrimaryColor }) =>
    template === TemplateType.TemplateOne
      ? PrimaryColor
        ? PrimaryColor
        : '#6999CA'
      : PrimaryColor
      ? PrimaryColor
      : '#D08ACD'};
  font-size: 14px;
  margin-top: 2px;
  padding-left: 10px;
`;
export const SendOtpLink = styled(Link)`
  color: ${({ isDarkModeEnabled }) => (isDarkModeEnabled ? '#fff' : '#2b2b2b')};
  font-family: 'Avenir';
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.4px;
  line-height: 1.17;
  margin-top: 14px;
  margin-left: 5px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 360px) {
    font-size: 12px;
  }
  @media (max-width: 390px) {
    font-size: 14px;
  }
  @media (max-width: 375px) {
    font-size: 12px;
  }
`;
export const SendOtpLinkContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
/* styled components end */

const membershipPaymentOptions = [
  PAYMENT_OPTIONS.PER_DAY,
  PAYMENT_OPTIONS.PER_WEEK,
  PAYMENT_OPTIONS.PER_MONTH,
  PAYMENT_OPTIONS.PER_YEAR,
  PAYMENT_OPTIONS.PACKAGE,
];
function getCountrypopup(user) {
  if (user?.countryId === '60b8ddb57205057e7ce2b861' || user?.countryId === '60b8ddb57205057e7ce2b79d') {
    return user?.postalCode ?? null;
  }
  return user?.countryId;
}

function PurchaseBlock(props) {
  // TODO: moved from purchase modal so as not to break the previous functionality
  const { isPackage, onJoin } = props;
  const { history, query, domain, location } = useRouter();
  const [couponError, setCouponError] = useState(false);
  const queryCode = query?.code === undefined ? null : query?.code?.includes('p_') ? null : query?.code;
  const contribution = useContribution();
  const { clientPreviewMode } = useClientPreview();
  const [showPhoneNumberFields, setshowPhoneNumberFields] = useState(true);
  const [showUnableJoin, setshowUnableJoin] = useState(false);
  const isInviteToJoin = useSelector(state => state.contributions.inviteCode);
  const couponCode = useSelector(state => state?.contributions?.couponCode);
  const priceId = useSelector(state => state.contributions.priceCode);
  const { themedColor, themedBackgroundColor, themedCardBackgroundColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const [smsReminderCheckBox, setSmsReminderCheckBox] = useState(true);
  const [loginStep, setloginStep] = useState(false);
  const [showThreeFields, setshowThreeFields] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState();
  const { isApplyContributionView } = useApplyContributionView();
  const code = isInviteToJoin || priceId;

  const [statesArray, setStatesArray] = useState([]);
  const [phoneNumberField, setPhoneNumberField] = useState('');
  const { fetchUserCountryCode } = useDefaultTimezone();
  const [isShowWaiver, setIsShowWaiver] = useState(false);
  const styledProps = {
    cardBackgroundColor: themedCardBackgroundColor,
    color: themedColor,
    backgroundColor: themedBackgroundColor,
  };
  const classNames = useStyles(styledProps);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));
  const [allowpassword, setallowpassword] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [hide, setHide] = useState(false);
  const {
    customToS,
    isPurchased,
    paymentInfo: {
      paymentOptions,
      splitNumbers,
      splitPeriod,
      packageSessionNumbers,
      monthlySessionSubscriptionInfo,
      membershipInfo,
      multiplePriceList,
    },
    paymentType,
    serviceProviderName,
    type,
    defaultCurrency,
    defaultSymbol,
    title,
    id,
    isElectronicSignatureActive,
    customTitleForMeetYourCoach,
    customWaiverId,
    isCouponAvailable,
    customWaiverTemplateName,
    isInvoiced,
    isPriceHidden,
  } = contribution;
  const [timeZoneArray, setTimeZoneArray] = useState([]);
  const [disableBtn, setDisableBtn] = useState(true);
  const [disableJoinBtn, setDisableJoinBtn] = useState(true);
  const { user, currentRole } = useAccount();
  const [step, setStep] = useState(() => {
    if (clientPreviewMode) {
      return PURCHASE_MODAL_STEPS.init;
    }
    if (!isEmpty(user)) {
      return PURCHASE_MODAL_STEPS.loggedIn;
    }
    if (!isNil(isInviteToJoin)) {
      return PURCHASE_MODAL_STEPS.join;
    }
    return PURCHASE_MODAL_STEPS.init;
  });
  const [isSigned, setIsSigned] = useState(null);
  const [splittedPrice, setSplittedPrice] = useState(null);
  const [ip, setIP] = useState('');
  const [newUserSignedIn, setNewUserSignedIn] = useState(false);
  const [paramsforEditUser, setParamsforEditUser] = useState({
    countryId: null,
    TimeZoneId: null,
    stateCode: null,
  });
  const usereError = useSelector(errorSelector);

  const [waiverData, setWaiverData] = useState(null);
  const [typeOfPayment, setTypeOfPayment] = useState(
    paymentOptions.includes(PAYMENT_OPTIONS.PER_SESSION)
      ? PAYMENT_OPTIONS.PER_SESSION
      : paymentOptions.filter(p => isInviteToJoin?.length > 0 || p !== PAYMENT_OPTIONS.FREE).sort()[0],
  );
  const [summary, setSummary] = useState(null);
  const summaryRef = useRef();
  const canvasRef = useRef();
  const formRef = useRef(null);
  const phoneInputRef = useRef(null);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { countries, loading: countriesLoading } = useSelector(state => state?.country);
  summaryRef.current = summary;
  const [isMonthlySessionSubscription, setIsMonthlySessionSubscription] = useState(false); // TODO: to be defined
  const activeTemplate = contribution?.activeTemplate || TemplateType.TemplateThree;
  /* PRICE SECTION START */
  const { request, loading } = useHttp();
  const [couponValue, setCouponValue] = useState('');
  const [error, setError] = useState(null);
  const [coachPhoneNumber, setCoachPhoneNumber] = useState('');
  const [coachPhoneNumberFocused, setCoachPhoneNumberFocused] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const closeModal = () => setShowOtpModal(false);
  const handleShowOtpPopup = () => {
    setShowOtpModal(true);
    const email = formRef?.current?.values[ACCOUNT_FORM_FIELDS.email].trim();
    getOTP(email)(dispatch);
  };

  const saveUserSignature = async values => {
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(values.clientSignature);
    setWaiverData({
      ...waiverData,
      clientName: values.clientName,
    });
    formData.append('file', sign);

    setIsShowWaiver(false);
    setLoadingPayment(false);
    submitUserSignature(values.clientSignature);
  };

  useEffect(() => {
    if (isEmpty(user) === false) {
      if (contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null) {
        checkProceedStatus();
      }
    }
    if (!isEmpty(user)) {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        checkPhoneNumber();
      } else {
        setshowPhoneNumberFields(false);
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }
    }
  }, []);

  const setDefaultCountryCode = async () => {
    const countryCode = await fetchUserCountryCode(formRef?.current?.values?.TimeZoneId, timeZones, countries);
    setDefaultCountry(countryCode);
  };

  useEffect(() => {
    if (timeZones?.length > 0 && countries?.length > 0) setDefaultCountryCode();
  }, [formRef?.current?.values?.TimeZoneId, timeZones, countries]);

  useEffect(() => {
    if (phoneInputRef.current && !phoneNumberField) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.addEventListener('focus', () => {
          setCoachPhoneNumberFocused(true);
        });
      }
      return () => {
        if (phoneInput) {
          phoneInput.removeEventListener('focus', () => {
            setCoachPhoneNumberFocused(false);
            setCoachPhoneNumber('');
          });
        }
      };
    }
    if (phoneNumberField) {
      setCoachPhoneNumberFocused(true);
      setCoachPhoneNumber(phoneNumberField);
    }
  }, [phoneInputRef.current, showPhoneNumberFields, smsReminderCheckBox, step]);

  useEffect(() => {
    if (coachPhoneNumberFocused && phoneInputRef.current) {
      const phoneInput = phoneInputRef.current.querySelector('.react-international-phone-input');
      if (phoneInput) {
        phoneInput.focus();
      }
    }
  }, [coachPhoneNumberFocused]);

  const getSummary = useCallback(
    coupon => {
      let paymentType = `${isPackage ? 'SessionsPackage' : typeOfPayment}`;
      paymentType = `${isMonthlySessionSubscription ? 'MonthlySessionSubscription' : paymentType}`;

      const REQUEST_MAPPING = {
        [ContributionType.contributionOneToOne]: purchaseService.getOnToOnePaymentInfo,
        [ContributionType.contributionCourse]: purchaseService.getCoursePaymentInfo,
        [ContributionType.contributionMembership]: purchaseService.getMembershipPaymentInfo,
        [ContributionType.contributionCommunity]: purchaseService.getCommunityPaymentInfo,
      };

      const getPaymentData = REQUEST_MAPPING[contribution.type];
      const paymentFetch =
        paymentType == 'EntireCourse' ||
        paymentType == 'SplitPayments' ||
        paymentType == 'PerSession' ||
        paymentType == 'SessionsPackage' ||
        paymentType == 'MonthlyMembership' ||
        paymentType == 'YearlyMembership'
          ? priceId?.includes('p_')
            ? priceId
            : null
          : coupon?.id;
      if (coupon) {
        getPaymentData(contribution.id, paymentType, paymentFetch, coupon?.id)
          .then(data => {
            setSummary({ ...data, coupon });
          })
          .catch(responseError => {
            setError(responseError);
          });
      } else {
        getPaymentData(contribution.id, paymentType, paymentFetch, null)
          .then(data => {
            setSummary({ ...data, coupon });
          })
          .catch(responseError => {
            setError(responseError);
          });
      }
    },
    [contribution.id, contribution.type, isMonthlySessionSubscription, isPackage, typeOfPayment],
  );
  const isSignSaved = async signature => {
    let isSignedSubmitted = null;
    if (contribution?.customWaiverId != null) {
      isSignedSubmitted = await submitUserSignature(signature);
    } else {
      isSignedSubmitted = await saveSignature(signature);
    }
    return isSignedSubmitted;
  };
  const submitUserSignature = async val => {
    let isSignedSubmitted = false;
    setLoadingPayment(true);
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    const details = {
      contributionId: contribution.id,
      contractId: waiverData.id,
      ipAddress: userIP,
    };
    try {
      setSignedContract(formData, details);
      isSignedSubmitted = true;
    } catch (e) {
      isSignedSubmitted = false;
    }
    setIsShowWaiver(false);
    setLoadingPayment(false);
    return isSignedSubmitted;
  };
  const fetchJoindedContribution = () => {
    dispatch(
      fetchContributionActions.success({
        ...contribution,
        isPurchased: true,
        purchaseStatus: 'succeeded',
        subscriptionStatus: { status: 'active' },
      }),
    );
    onJoin(true);
  };
  const handleOnChangeCoupon = useCallback(
    event => {
      const { value } = event.target;

      setCouponValue(value.trim());
    },
    [setCouponValue],
  );

  const getWaiverForm = async () => {
    setLoadingPayment(true);
    try {
      getSingleContract(contribution?.id).then(data => {
        setWaiverData({
          ...waiverData,
          formName: data?.formName,
          formDescription: data?.formDescription,
          formText: data?.formText,
          description: data?.formDescription,
          templateId: data.id,
          id: data.id,
          completeDescription: data?.formText,
        });
        setIsShowWaiver(true);
        setLoadingPayment(false);
      });
    } catch (e) {
      setLoadingPayment(false);
    }
    setLoadingPayment(false);
  };
  const saveSignature = async val => {
    let returnval = false;
    const formData = new FormData();
    const sign = dataURLtoFile(val);
    formData.append('file', sign);
    const userIP = await getIpGlobal();
    await saveSignoffData(formData, id, userIP)
      .then(res => {
        if (res === 'Signoff data succesfully saved.') {
          returnval = true;
        }
      })
      .catch(err => {});
    return returnval;
  };

  const clearSign = () => {
    if (canvasRef?.current) {
      canvasRef.current.clear();
      setDisableBtn(true);
    }
  };

  const handleRedeem = useCallback(() => {
    if (couponValue.length > 0 && !loading) {
      setCouponError(false);
      const endpoint = `/Coupons/ValidateByName/${couponValue}/${contribution.id}/${typeOfPayment}`;

      request(endpoint, 'GET')
        .then(response => {
          if (response?.id === undefined) {
            setCouponError(true);
          }

          dispatch(setCouponCode(response.id));
          if (response?.percentAmount > 0 || response?.discountAmount > 0) {
            getSummary(response);
          }
        })
        .catch(() => {
          setCouponError(true);
        });
    }
  }, [contribution.id, couponValue, getSummary, loading, request, typeOfPayment]);

  useEffect(() => {
    setError(null);
    getSummary();
  }, [getSummary, typeOfPayment]);

  useEffect(() => {
    (async () => {
      const res = await fetch('https://api.ipify.org/?format=json');
      const data = await res.json();
      setIP(data.ip);
    })();
  }, []);

  /* PRICE SECTION END */

  const dispatch = useDispatch();

  const { states } = useSelector(state => state?.states);

  const { error: errorMessage } = useSelector(state => state.account);
  const countrypopup = getCountrypopup(user);
  const [isOpenPopUp, setIsOpenPopUp] = useState(false);
  const userNotLoggedIn = isEmpty(user);

  function isCountryNameAvailable(countryName, arrayOfObjects) {
    return arrayOfObjects.some(obj => obj.countryName === countryName);
  }

  const [lastStep, setLastStep] = useState(step);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const isShowInput = (currentStep, input) => {
    const visible = {
      init: [ACCOUNT_FORM_FIELDS.email],
      join: [ACCOUNT_FORM_FIELDS.email],
      loggedIn: [
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
        // ACCOUNT_FORM_FIELDS.timeZoneId,
        // ACCOUNT_FORM_FIELDS.country,
        // ACCOUNT_FORM_FIELDS.state,
      ],
      joinLogin:
        contribution.paymentType === 'Simple' && contribution.taxType != 'No' && isInviteToJoin === null
          ? [
              ACCOUNT_FORM_FIELDS.email,
              ACCOUNT_FORM_FIELDS.password,
              ACCOUNT_FORM_FIELDS.signature,
              RESTORE_PASS,
              ACCOUNT_FORM_FIELDS.phone,
              ACCOUNT_FORM_FIELDS.sessionReminder,
              ACCOUNT_FORM_FIELDS.timeZoneId,
              ACCOUNT_FORM_FIELDS.country,
              ACCOUNT_FORM_FIELDS.state,
            ]
          : [
              ACCOUNT_FORM_FIELDS.email,
              ACCOUNT_FORM_FIELDS.password,
              ACCOUNT_FORM_FIELDS.signature,
              RESTORE_PASS,
              ACCOUNT_FORM_FIELDS.phone,
              ACCOUNT_FORM_FIELDS.sessionReminder,
              // ACCOUNT_FORM_FIELDS.timeZoneId,
              // ACCOUNT_FORM_FIELDS.country,
              // ACCOUNT_FORM_FIELDS.state,
            ],
      login: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.signature,
        RESTORE_PASS,
        ACCOUNT_FORM_FIELDS.phone,
      ],
      joinCreate: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
      create: [
        ACCOUNT_FORM_FIELDS.email,
        ACCOUNT_FORM_FIELDS.confirmEmail,
        // ACCOUNT_FORM_FIELDS.password,
        ACCOUNT_FORM_FIELDS.birthDate,
        ACCOUNT_FORM_FIELDS.firstName,
        ACCOUNT_FORM_FIELDS.lastName,
        ACCOUNT_FORM_FIELDS.timeZoneId,
        ACCOUNT_FORM_FIELDS.country,
        ACCOUNT_FORM_FIELDS.state,
        ACCOUNT_FORM_FIELDS.signature,
        ACCOUNT_FORM_FIELDS.phone,
        ACCOUNT_FORM_FIELDS.sessionReminder,
      ],
    };

    return (isEmpty(user) || clientPreviewMode) && visible[currentStep]?.includes(input);
  };
  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
    if (!countries || (!countries?.length && !countriesLoading)) {
      dispatch(countryActions.fetchCountries());
    }
    if (!states || !states.length) {
      dispatch(countryActions.fetchStates());
    }
    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step, timeZones, timeZoneLoading]);
  const confirmEmailRef = useRef(null);
  const emailRef = useRef(null);

  const isCoach = currentRole === UserRoles.cohealer;
  const {
    applicationRequiredButNotApproved,
    isApplicationNeedsToBeSubmitted,
    isApplicationResponsePending,
  } = useApplicationStatus({
    contribution,
    user,
  });

  const shouldHidePriceSection = () => {
    if (priceId?.includes('p_') === true) {
      setHide(false);
    } else if (isInviteToJoin) {
      setHide(true);
    }
  };

  let joinButtonText =
    isApplyContributionView && isApplicationNeedsToBeSubmitted ? 'Application Pending' : `Join ${hide ? 'Now' : ''}`;

  if (!isCoach && applicationRequiredButNotApproved) {
    joinButtonText = 'Apply Now';
  }

  const isApplyButton = joinButtonText?.includes('Apply');

  useEffect(() => {
    const disablePaste = e => {
      e.preventDefault();
    };

    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
    if (!countries || !countries.length) {
      dispatch(countryActions.fetchCountries());
    }
    const confirmEmailInput = confirmEmailRef.current;
    if (!confirmEmailInput) return;

    confirmEmailInput.addEventListener('paste', disablePaste);
    return () => {
      confirmEmailInput.removeEventListener('paste', disablePaste);
    };
  }, [step, timeZones, timeZoneLoading]);

  const { checkEmail, isLoadingEmail } = useIsEmailExist();

  const handleProceedPurchaseStatus = useCallback(async () => {
    const userIP = await getIpGlobal();
    // const action = await fetchClientContribution(id)(dispatch);
    await dispatch(fetchClientContribution(id, userIP, priceId)).then(() => {
      dispatch(
        fetchContributionActions.success({
          ...contribution,
          isPurchased: true,
          purchaseStatus: 'succeeded',
          subscriptionStatus: { status: 'active' },
        }),
      );
    });
    dispatch(setInviteCode(null));
    if (contribution?.clientRedirectLink) {
      return history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
    } else {
      return history.push(`/contribution-view/${id}/sessions`);
    }
    // history.push(`/contribution-view/${id}/sessions`);
    setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 1000);
    if (!loading) document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [dispatch, history, id, loading]);

  const stepVerifier = useCallback(
    async values => {
      let { Signature, phoneNo, countryId, stateCode, TimeZoneId } = values;
      if (isSigned !== null) {
        Signature = isSigned;
      }
      if (!isEmpty(user)) {
        if (user?.id === contribution?.userId) {
          history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
          // setshowUnableJoin(true);
          return false;
        }
        if (contribution?.type === ContributionType.contributionOneToOne) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.loggedIn);
          return false;
        }
        if (isElectronicSignatureActive && Signature && step === PURCHASE_MODAL_STEPS.loggedIn) {
          const signSaved = await isSignSaved(Signature);
          if (signSaved) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }

      if (step === PURCHASE_MODAL_STEPS.create) {
        setNewUserSignedIn(true);
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
          PhoneNo: values.phoneNo,
          countryId,
          stateCode,
          TimeZoneId,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved && !isInvoiced) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          }
          if (!isInvoiced) {
            return formRef.current?.handleSubmit();
          }
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinCreate) {
        setNewUserSignedIn(true);
        dispatch({
          type: 'PURCHASE_DUMMY',
          payload: false,
        });
        const returnedRegisterAction = await completeRegister({
          ...values,
          userView: UserRoles.client,
          PhoneNo: values.phoneNo,
          countryId,
          stateCode,
          TimeZoneId,
        })(dispatch);
        if (
          returnedRegisterAction?.type !== REGISTER_FAILURE &&
          returnedRegisterAction?.type !== CONTINUE_REGISTER_FAILURE
        ) {
          if (customWaiverId != null && isElectronicSignatureActive) {
            getWaiverForm();
          } else if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              joinContribution({
                id,
                accessCode: isInviteToJoin,
              })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                  dispatch({
                    type: 'PURCHASE_DUMMY',
                    payload: true,
                  });
                })
                .then(() => {
                  onJoin(true);
                });
            } else {
              return false;
            }
          }
          if (customWaiverId === null && isElectronicSignatureActive === false) {
            joinContribution({
              id,
              accessCode: isInviteToJoin,
            })
              .then(() => {
                dispatch(
                  fetchContributionActions.success({
                    ...contribution,
                    isPurchased: true,
                    purchaseStatus: 'succeeded',
                    subscriptionStatus: { status: 'active' },
                  }),
                );
                dispatch({
                  type: 'PURCHASE_DUMMY',
                  payload: true,
                });
              })
              .then(() => {
                onJoin(true);
              });
          }
        }
        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.login) {
        const { Email, Password, phoneNo, otp } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
          null,
          null,
          phoneNo,
          null,
          null,
          contribution?.userId,
          otp,
        )(dispatch);
        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          // we shouldn't redirect in the login process, redirect only after purchasing
          // the return url for successful and unsuccessful payment is set in the api when creating stripe checkout
          // await handleProceedPurchaseStatus();
          if (isElectronicSignatureActive && Signature) {
            const signSaved = await isSignSaved(Signature);
            if (signSaved) {
              return formRef.current?.handleSubmit();
            } else {
              return false;
            }
          } else {
            const { payload } = returnedLoginAction || {};

            setLoginErrorMessage(payload?.message);
          }

          return formRef.current?.handleSubmit();
        }

        return false;
      }
      if (step === PURCHASE_MODAL_STEPS.joinLogin) {
        const { Email, Password, phoneNo, otp } = values;
        const returnedLoginAction = await login(
          Email,
          Password,
          null,
          null,
          phoneNo,
          true,
          null,
          contribution?.userId,
          otp,
        )(dispatch);

        if (returnedLoginAction?.type !== LOG_IN_FAILURE) {
          if (returnedLoginAction.user?.id === contribution?.userId) {
            history.push(ROUTES.ROLE_SWITCH, { path: location.pathname, domain: domain });
            // setshowUnableJoin(true);
            return false;
          } else {
            if (isElectronicSignatureActive && Signature) {
              const signSaved = await isSignSaved(Signature);
              if (signSaved) {
                joinContribution({
                  id,
                  accessCode: isInviteToJoin,
                })
                  .then(() => {
                    dispatch(
                      fetchContributionActions.success({
                        ...contribution,
                        isPurchased: true,
                        purchaseStatus: 'succeeded',
                        subscriptionStatus: { status: 'active' },
                      }),
                    );
                  })
                  .then(() => {
                    handleProceedPurchaseStatus();
                    return formRef.current?.handleSubmit();
                  });
              } else {
                return false;
              }
            }
          }
          if (customWaiverId != null && isElectronicSignatureActive) {
            if (step != PURCHASE_MODAL_STEPS.init && step != PURCHASE_MODAL_STEPS.join) {
              if (formRef.current.isValid) {
                getWaiverForm();
              }
            } else {
              stepVerifier(formRef.current.values);
            }
          } else {
            if (
              contribution?.smsPermissionsPerContribution?.SessionReminder === false ||
              contribution?.smsPermissionsPerContribution?.SessionReminder === undefined
            ) {
              joinContribution({
                id,
                accessCode: isInviteToJoin,
              })
                .then(() => {
                  dispatch(
                    fetchContributionActions.success({
                      ...contribution,
                      isPurchased: true,
                      purchaseStatus: 'succeeded',
                      subscriptionStatus: { status: 'active' },
                    }),
                  );
                })
                .then(() => {
                  handleProceedPurchaseStatus();
                  return formRef.current?.handleSubmit();
                });
            } else {
              setshowPhoneNumberFields(true);
            }
          }
        }
        return false;
      }

      const isExistEmail = await checkEmail(values[ACCOUNT_FORM_FIELDS.email].trim());
      setallowpassword(isExistEmail);

      if (isExistEmail) {
        if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
          setshowPhoneNumberFields(false);
        }
        if (isInviteToJoin) {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.joinLogin);
        } else {
          setLastStep(step);
          setStep(PURCHASE_MODAL_STEPS.login);
        }
      } else if (isInviteToJoin) {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.joinCreate);
      } else {
        setLastStep(step);
        setStep(PURCHASE_MODAL_STEPS.create);
      }
      return false;
    },
    [
      user,
      step,
      checkEmail,
      isInviteToJoin,
      dispatch,
      id,
      query.code,
      contribution,
      onJoin,
      handleProceedPurchaseStatus,
      showPhoneNumberFields,
    ],
  );

  const stripe = useStripe();
  const elements = useElements();
  const [isAlreadyPurchased, setIsAlreadyPurchased] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);

  const [purchaseSessionId, setPurchaseSessionId] = useState('');
  const [isInvoicePaid, setIsInvoicePaid] = useState(false);

  useEffect(() => {
    const sessionsRefresher = setInterval(async () => {
      if (!clientPreviewMode) {
        const userIP = await getIpGlobal();
        dispatch(fetchClientContributionAfterInterval(id, userIP, priceId));
      }
      setIsInvoicePaid(isPurchased);
    }, 10000);
    return () => {
      clearInterval(sessionsRefresher);
    };
  }, [isInvoicePaid, dispatch, id]);

  // buy contribution after login/signup check
  useEffect(() => {
    if (!loadingPayment && !isPurchased && purchaseSessionId) {
      const userHasAccessCode = isInviteToJoin;
      if (userHasAccessCode) {
        joinContribution({
          id,
          accessCode: isInviteToJoin,
        }).then(() => {
          dispatch(
            fetchContributionActions.success({
              ...contribution,
              isPurchased: true,
              purchaseStatus: 'succeeded',
              subscriptionStatus: { status: 'active' },
            }),
          );
          onJoin(true);
        });
      } else {
        if (paymentType === 'Advance' && !isInvoiced) {
          window.location.href = purchaseSessionId;
        } else if (isInvoiced) {
          if (contribution?.clientRedirectLink) {
            return history.push(`/contribution-view/${id}/${contribution?.clientRedirectLink?.toLowerCase()}`);
          } else {
            return history.push(`/contribution-view/${id}/sessions`);
          }
          // history.push(`/contribution-view/${id}/sessions`);
        } else {
          if (purchaseSessionId != 'Free session joined successfully') {
            setLoadingPayment(true);
            return stripe
              .redirectToCheckout({ sessionId: purchaseSessionId })
              .catch(responseError => {
                console.dir(responseError);

                if (responseError?.response?.status === 400) {
                  if (isInviteToJoin || query.code) {
                    window.location.reload();
                  } else {
                    setIsAlreadyPurchased(true);
                  }
                }
              })
              .finally(() => {
                setLoadingPayment(false);
                window.location.reload();
              });
          } else {
            window.location.reload();
          }
        }
      }
    }

    // redirect to sessions tab if accessCode and isPurchased
    if (!loadingPayment && isPurchased && purchaseSessionId && queryCode) {
      setLoadingPayment(true);
      onJoin(true);
    }
  }, [isInviteToJoin, isPurchased, loadingPayment, onJoin, purchaseSessionId, query.code, stripe, priceId]);

  const prepareEntire = useCallback(
    async data => {
      setLoadingPayment(true);
      const userHasAccessCode = isInviteToJoin;

      let checkOutResult;
      if (!userHasAccessCode) {
        if (!isInvoiced) {
          checkOutResult = await request(
            '/api/purchase/course/checkout',
            'POST',
            location?.search?.length === 0
              ? data
              : { ...data, affiliateTrackingLinkId: location?.search?.substring(1) },
          );
        } else if (isInvoiced && countrypopup != null) {
          checkOutResult = await request('/api/purchase/course/invoice', 'POST', data);
          if (checkOutResult != 'Country is not saved.') {
            setIsOpenPopUp(true);
          }
        }
      }
      try {
        if (userHasAccessCode) {
          await joinContribution({
            id,
            accessCode: isInviteToJoin || priceId,
          }).then(() => {
            dispatch(
              fetchContributionActions.success({
                ...contribution,
                isPurchased: true,
                purchaseStatus: 'succeeded',
                subscriptionStatus: { status: 'active' },
              }),
            );
            onJoin(true);
          });
        } else if (checkOutResult === '100discount') {
          if (contribution?.thankYouPageRedirectUrl?.length > 0) {
            // commented since it can be blocked on mobile that treats it like a pop up
            // window.open(contribution.thankYouPageRedirectUrl);
            window.location.href = contribution.thankYouPageRedirectUrl;
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        } else {
          if (!isInvoiced) {
            setPurchaseSessionId(checkOutResult);
          }
        }
      } catch (checkoutError) {
        console.dir(checkoutError);
        setError(checkoutError);
      } finally {
        setLoadingPayment(false);
      }
    },
    [contribution, dispatch, id, isInviteToJoin, onJoin, query.code, request],
  );
  const handlePayResponse = useCallback(
    async response => {
      setLoadingPayment(false);
      const userIP = await getIpGlobal();
      if (response.error) {
        setError(response.error.message);
      } else {
        dispatch(fetchClientContribution(id, userIP, priceId));
        dispatch(setInviteCode(null));
        history.push({
          pathname: `/contribution-view/${id}/sessions`,
        });
        setTimeout(() => window.scrollTo(0, 0), 1000);
      }
    },
    [dispatch, id],
  );
  const payWith3DSecure = useCallback(
    (...args) => {
      setLoadingPayment(true);

      stripe
        .confirmCardPayment(...args)
        .then(handlePayResponse)
        .catch(console.dir);
    },
    [stripe, handlePayResponse],
  );
  const subscribe = useCallback(
    ({ paymentMethodId }) => {
      const data = {
        paymentMethodId,
        paymentOptions: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        paymentOption: isMonthlySessionSubscription ? 'MonthlySessionSubscription' : 'SplitPayments',
        contributionId: contribution.id,
        couponId: summaryRef?.current?.coupon?.id,
      };

      const purchaseType = ContributionPurchaseType.course;
      const paymentOption = isMonthlySessionSubscription ? '/monthly-session-subscription' : '';
      const paymentUrl = `/api/purchase/${purchaseType}${paymentOption}`;

      request(paymentUrl, 'POST', data)
        .then(res => {
          if (isMonthlySessionSubscription) {
            payWith3DSecure(res.clientSecret);
          }
        })
        .catch(console.dir);
    },
    [request, contribution.id, payWith3DSecure, isMonthlySessionSubscription],
  );
  const attachPaymentMethodByToken = useCallback(
    cardToken => {
      request('/api/payment/attach-customer-payment-method-token', 'POST', {
        cardToken,
        contributionId: contribution.id,
      })
        .then(subscribe)
        .catch(console.dir);
    },
    [request, subscribe],
  );
  const createTokenForSplitPayments = useCallback(() => {
    const card = elements.getElement(CardNumberElement);
    stripe.createToken(card).then(res => {
      if (!res.error) {
        attachPaymentMethodByToken(res.token.id);
      }
    });
  }, [stripe, elements, attachPaymentMethodByToken]);

  const checkCountry = async values => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);

    if (currentCountry.id != formRef.current.values.countryId) {
      if (currentCountry.id === '60b8ddb57205057e7ce2b861') {
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
        setStatesArray(filteredStates);
        setTimeZoneArray(timeZones);
        if (
          formRef?.current?.values?.TimeZoneId?.length > 0 &&
          formRef?.current?.values?.TimeZoneId != null &&
          formRef?.current?.values?.stateCode?.length > 0 &&
          formRef?.current?.values?.stateCode != null
        ) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, values.stateCode);
        } else {
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    } else {
      if (formRef.current.values.countryId === '60b8ddb57205057e7ce2b861') {
        formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values.countryId);
        const filteredStates = states.filter(obj => obj.countryId === formRef.current.values.countryId);
        setStatesArray(filteredStates);
        if (formRef?.current?.values?.stateCode?.length > 0 && formRef?.current?.values?.stateCode != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, formRef.current.values.stateCode);
        } else {
          setLoadingPayment(false);
          return false;
        }
        if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
        } else {
          setLoadingPayment(false);
          return false;
        }
        return true;
      } else {
        const filteredTimezone = timeZones.filter(obj => obj?.countryId === formRef.current.values.countryId);
        if (filteredTimezone.length === 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0]?.countryName);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setLoadingPayment(false);
          setParamsforEditUser({
            countryId: formRef.current.values.countryId,
            TimeZoneId: filteredTimezone[0].countryName,
            stateCode: null,
          });
          return true;
        } else if (filteredTimezone.length > 1) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, formRef.current.values?.countryId);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(filteredTimezone);
          setParamsforEditUser({ countryId: values?.countryId, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);

          if (formRef.current.values.TimeZoneId.length > 0 && formRef.current.values.TimeZoneId != null) {
            let checkTimeZone = isCountryNameAvailable(formRef.current.values.TimeZoneId, filteredTimezone);
            if (checkTimeZone) {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values?.TimeZoneId);
              return true;
            } else if (values.TimeZoneId.length > 0 && values.TimeZoneId != null) {
              let checkTimeZoneValue = isCountryNameAvailable(values.TimeZoneId, filteredTimezone);
              if (checkTimeZoneValue) {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, values.TimeZoneId);
                return true;
              } else {
                formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                return false;
              }
            } else {
              formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
              return false;
            }
          } else {
            return false;
          }
          return true;
        } else if (filteredTimezone.length === 0) {
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
          formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
          setTimeZoneArray(timeZones);
          setParamsforEditUser({ countryId: currentCountry.id, TimeZoneId: null, stateCode: null });
          setLoadingPayment(false);
          if (formRef?.current?.values?.TimeZoneId?.length > 0 && formRef?.current?.values?.TimeZoneId != null) {
            formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, formRef.current.values.TimeZoneId);
          } else {
            return false;
          }
          return true;
        }
      }
    }
  };

  const checkPhoneNumber = () => {
    if (!isEmpty(user)) {
      if (!isEmpty(user.globalSmsPermissions) && user.globalSmsPermissions.SessionReminder === true) {
        if (
          !isEmpty(user.subCategorySmsPermissions) &&
          (user.subCategorySmsPermissions.OneHourSession === true ||
            user.subCategorySmsPermissions.TwentyFourHourSession === true)
        ) {
          if (step === 'login' && loginStep === false) {
            setshowPhoneNumberFields(true);
            setloginStep(true);
            return false;
          }
          if (smsReminderCheckBox === true) {
            if (user?.phoneNo === null || user.phoneNo?.length === 0) {
              if (phoneNumberField.length === 0) {
                setshowPhoneNumberFields(true);
                return false;
              } else {
                if (user?.phoneNo != phoneNumberField) {
                  updatePhoneNumber();
                }
                setshowPhoneNumberFields(false);
                return true;
              }
            } else {
              if (user?.phoneNo != phoneNumberField) {
                updatePhoneNumber();
              }
              setshowPhoneNumberFields(false);
              return true;
            }
          } else {
            return true;
          }
        } else {
          setshowPhoneNumberFields(false);
          return true;
        }
      } else {
        setshowPhoneNumberFields(false);
        return true;
      }
    } else {
      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        setshowPhoneNumberFields(true);
      } else {
        setshowPhoneNumberFields(false);
      }

      return true;
    }
  };
  const updatePhoneNumber = async () => {
    await request('/Account/UpdatePhoneNo', 'POST', {
      phoneNumber: phoneNumberField,
    }).then(res => {
      let u = {
        ...user,
        phoneNo: phoneNumberField,
      };
      dispatch(updateUser(u));
    });
  };
  const checkCountryField = async () => {
    const res = await fetch('https://geolocation-db.com/json/');
    const data = await res.json();
    const currentCountry = countries.find(obj => obj.name === data.country_name);
    const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
    const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
    setStatesArray(filteredObjects);
    formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
    if (filteredTimezone.length === 1) {
      setshowThreeFields(false);
    } else {
      if (currentCountry?.id === user.countryId && filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        setshowThreeFields(true);
      }
    }
  };

  const checkProceedStatus = async () => {
    if (!isEmpty(user)) {
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, user.countryId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, user.timeZoneId);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.state, user.stateCode);
      const res = await fetch('https://geolocation-db.com/json/');
      const data = await res.json();
      const currentCountry = countries.find(obj => obj.name === data.country_name);
      const filteredTimezone = timeZones.filter(obj => obj?.countryId === currentCountry.id);
      const filteredObjects = states.filter(obj => obj.countryId === currentCountry.id);
      setStatesArray(filteredObjects);
      formRef.current.setFieldValue(ACCOUNT_FORM_FIELDS.country, currentCountry.id);
      if (filteredTimezone.length === 1) {
        setshowThreeFields(false);
      } else {
        if (currentCountry?.id === user.countryId && filteredTimezone.length === 1) {
          setshowThreeFields(false);
        } else {
          setshowThreeFields(true);
        }
      }
    }
  };
  const updateUserCall = async () => {
    const updatedUser = {
      ...user,
      id: user.id,
      stateCode: formRef.current.values.stateCode,
      timeZoneId: formRef.current.values.TimeZoneId,
      countryId: formRef.current.values.countryId,
    };
    await editUserProfile(user.id, updatedUser).then(async res => {
      let u = {
        ...user,
        countryId: res.user.countryId,
        timeZoneId: res.user.timeZoneId,
        stateCode: res.user.stateCode,
      };
      dispatch(updateUser(u));
    });
  };
  const handleSubmit = useCallback(
    async values => {
      if (isApplyButton) {
        redirectTo(`/application-form/${id}${code ? `/${code}` : ''}`);
        return;
      }

      setLoadingPayment(true);

      formRef.current.setTouched({});
      const isCanProceed = await stepVerifier(values);
      if (!isCanProceed) {
        setLoadingPayment(false);
        return;
      }

      if (contribution?.smsPermissionsPerContribution?.SessionReminder === true) {
        const phoneNumberAdded = checkPhoneNumber();
        if (!phoneNumberAdded) {
          setshowPhoneNumberFields(true);
          setLoadingPayment(false);
          return;
        }
      }

      if (
        contribution.paymentType === 'Simple' &&
        contribution.taxType != 'No' &&
        isInviteToJoin === null &&
        newUserSignedIn === false &&
        (contribution.paymentInfo.paymentOptions.length === 1 &&
          contribution.paymentInfo.paymentOptions.includes('Free')) === false
      ) {
        const isCheckCountry = await checkCountry(values);
        if (!isCheckCountry) {
          setLoadingPayment(false);
          return;
        } else {
          await updateUserCall();
        }
      }

      if (customWaiverId != null && isElectronicSignatureActive) {
        if (step != PURCHASE_MODAL_STEPS.init && step != PURCHASE_MODAL_STEPS.join) {
          if (formRef.current.isValid) {
            getWaiverForm();
          }
        } else {
          stepVerifier(formRef.current.values);
        }
      } else {
        if (membershipPaymentOptions.includes(typeOfPayment)) {
          let data;
          if (location?.search?.length === 0) {
            data = {
              paymentOption: typeOfPayment,
              contributionId: id,
              couponId: summaryRef?.current?.coupon?.id,
              priceId: priceId,
            };
          } else {
            data = {
              paymentOption: typeOfPayment,
              contributionId: id,
              couponId: summaryRef?.current?.coupon?.id,
              affiliateTrackingLinkId: location?.search?.substring(1),
              priceId: priceId,
            };
          }

          const response =
            type === ContributionType.contributionCommunity
              ? await purchaseService.purchaseCommunitySubscription(data)
              : await purchaseService.purchaseMembershipSubscription(data);
          setLoadingPayment(false);
          if (response === '100discount') {
            // quick solution:redirect; todo: update state with purchased service and hide purchase modal
            window.location.reload();
          } else {
            setPurchaseSessionId(response);
          }

          return;
        }
        If(
          typeOfPayment === 'EntireCourse' ||
            typeOfPayment === 'SplitPayments' ||
            type === ContributionType.contributionCourse ||
            type === ContributionType.contributionMembership ||
            type === ContributionType.contributionCommunity ||
            (type === ContributionType.contributionOneToOne && typeOfPayment === 'Free'),
        )
          .then(
            mapTo({
              contributionId: contribution.id,
              // paymentOptions: typeOfPayment,
              paymentOption: typeOfPayment,
              couponId: summaryRef?.current?.coupon?.id,
              priceId: priceId?.includes('p_') ? priceId : null,
            }),
          )
          .then(prepareEntire)
          .else(createTokenForSplitPayments);
      }
    },
    [
      id,
      stepVerifier,
      typeOfPayment,
      contribution.id,
      prepareEntire,
      createTokenForSplitPayments,
      isApplyButton,
      // payEntireCourse,
    ],
  );

  const currencyCode = defaultCurrency.toUpperCase();
  let colorToUse = determineColorToUse(contribution);
  let textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const agrementText = useMemo(
    () =>
      isElectronicSignatureActive &&
      (isShowInput(step, ACCOUNT_FORM_FIELDS.signature) || step == PURCHASE_MODAL_STEPS.loggedIn)
        ? 'By proceeding'
        : 'clicking',
    [step, isElectronicSignatureActive],
  );

  useEffect(() => {
    shouldHidePriceSection();
  }, []);

  useEffect(() => {
    if (user?.phoneNo?.length > 0 && !isEmpty(user)) {
      setPhoneNumberField(user?.phoneNo);
    }
  }, [user]);

  useEffect(() => {
    if (!isEmpty(user)) {
      checkCountryField();
    } else {
      setshowThreeFields(true);
    }
  }, [user, countries, timeZones]);
  return (
    <Grid className={classNames.wrapper} container spacing={2}>
      {!hide && (
        <Grid item md={6} xs={12}>
          <CardUI
            style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}
            className={classNames.sectionWrapper}
          >
            <CardHeaderUI>
              <PageTitleSecond style={{ color: themedColor }}>Price</PageTitleSecond>
              {countrypopup == null && !userNotLoggedIn && isInvoiced ? (
                <CountryModal isOpen={true} forInvoiced={true} />
              ) : null}
              {/* Create password modal */}

              {countrypopup != null && isOpenPopUp && (
                <Modal
                  title="Pay the Invoice to get Access"
                  isOpen={isOpenPopUp}
                  submitTitle="Close"
                  hiddenCancel
                  onCancel={() => {
                    setIsOpenPopUp(false);
                  }}
                  dontCancelOnSideClick
                  onSubmit={() => {
                    setIsOpenPopUp(false);
                  }}
                  style={{ zIndex: '2000' }}
                >
                  <p>An Invoice has been sent to your email. Please pay your invoice to get access to your service.</p>
                </Modal>
              )}
            </CardHeaderUI>
            <CardContent>
              <RadioGroup value={typeOfPayment} onChange={e => setTypeOfPayment(e.target.value)}>
                {paymentOptions.includes(PAYMENT_OPTIONS.ENTIRE_COURSE) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.ENTIRE_COURSE}
                    control={<Radio color="primary" />}
                    label="Pay in Full"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.SPLIT_PAYMENTS) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.SPLIT_PAYMENTS}
                    control={<Radio color="primary" />}
                    label={`Split: ${splitNumbers} Payments, Billed ${splitPeriod}`}
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_DAY) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.PER_DAY}
                    control={<Radio color="primary" />}
                    label="Daily subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_WEEK) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.PER_WEEK}
                    control={<Radio color="primary" />}
                    label="Weekly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_MONTH) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.PER_MONTH}
                    control={<Radio color="primary" />}
                    label="Monthly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PER_YEAR) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.PER_YEAR}
                    control={<Radio color="primary" />}
                    label="Yearly subscription"
                  />
                )}
                {paymentOptions.includes(PAYMENT_OPTIONS.PACKAGE) && (
                  <FormControlLabel
                    value={PAYMENT_OPTIONS.PACKAGE}
                    control={<Radio color="primary" />}
                    label={`${membershipInfo.membershipPackage.duration} months subscription with ${membershipInfo.membershipPackage.period} payment`}
                  />
                )}
              </RadioGroup>
              {error && <CommonErrorMessage message={error} />}

              {!isPriceHidden && summary && !isMonthlySessionSubscription && (
                <>
                  {/* PRICE */}
                  <PriceContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                    <Grid container justify="space-between">
                      <Grid item>
                        <Typography style={{ lineHeight: 2.1 }}>Price:</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                          {defaultSymbol}
                          {formatMoney(summary.price)} {currencyCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  </PriceContainer>
                  {/* FEE */}
                  {summary.platformFee != 0 && (
                    <PriceContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography style={{ lineHeight: 2.1 }}>Processing Fee:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                            {defaultSymbol}
                            {formatMoney(summary.platformFee)} {currencyCode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </PriceContainer>
                  )}
                  {summary.platformFee != 0 && typeOfPayment != 'SplitPayments' && (
                    <PriceContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                      <Grid container justify="space-between">
                        <Grid item>
                          <Typography style={{ lineHeight: 2.1 }}>Total Price:</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                            {defaultSymbol}
                            {formatMoney(summary.price + summary.platformFee)} {currencyCode}
                          </Typography>
                        </Grid>
                      </Grid>
                    </PriceContainer>
                  )}
                  {/* DUE */}
                  {typeOfPayment === 'SplitPayments' && (
                    <>
                      <PriceContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                        <Grid container justify="space-between">
                          <Grid item>
                            <Typography style={{ lineHeight: 2.1 }}>Due Now:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                              {defaultSymbol}
                              {formatMoney(summary.dueNowWithCouponDiscountAmount)} {currencyCode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </PriceContainer>
                      <PriceContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                        <Grid container justify="space-between">
                          <Grid item>
                            <Typography style={{ lineHeight: 2.1 }}>Total Price:</Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: themedColor }}>
                              {defaultSymbol}
                              {formatMoney(summary.price + summary.platformFee)} {currencyCode}
                            </Typography>
                          </Grid>
                        </Grid>
                      </PriceContainer>
                    </>
                  )}
                </>
              )}

              {isCouponAvailable && (
                <ApplyCouponContainer style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}>
                  <Grid alignItems="center" container justify="space-between" wrap="nowrap">
                    <Grid container md={9} xs={9}>
                      <TextField
                        fullWidth
                        style={{ backgroundColor: themedBackgroundColor }}
                        placeholder="Promotion Code"
                        variant="outlined"
                        onChange={handleOnChangeCoupon}
                        className={classNames.themedTextField}
                        value={couponValue}
                      />
                    </Grid>
                    <Grid container justify="flex-end" md={2} xs={2}>
                      <Button
                        style={{
                          backgroundColor: colorToUse?.PrimaryColorCode,
                          color: textColor,
                          marginRight: '10px',
                        }}
                        className={classNames.redeemButton}
                        onClick={handleRedeem}
                        size={downSm ? 'medium' : 'large'}
                        variant="text"
                      >
                        Redeem
                      </Button>
                    </Grid>
                  </Grid>
                  {couponError && <CommonErrorMessage align="left" message={'This code is invalid.'} />}
                </ApplyCouponContainer>
              )}
            </CardContent>
          </CardUI>
        </Grid>
      )}
      <Grid item md={6} xs={12}>
        <Card
          style={{ color: themedColor, backgroundColor: themedCardBackgroundColor }}
          className={classNames.sectionWrapper}
        >
          <CardContent>
            {joinButtonText?.includes('Apply') ? (
              <Typography style={{ color: themedColor }} variant="h6">
                Complete Your Application
              </Typography>
            ) : (
              <Typography style={{ color: themedColor }} variant="h6">
                Complete Your {hide ? 'Free' : ''} Registration
              </Typography>
            )}
            <Formik
              initialValues={{
                [ACCOUNT_FORM_FIELDS.confirmEmail]: '',
                [ACCOUNT_FORM_FIELDS.email]: '',
                [ACCOUNT_FORM_FIELDS.password]: '',
                [ACCOUNT_FORM_FIELDS.firstName]: '',
                [ACCOUNT_FORM_FIELDS.lastName]: '',
                [ACCOUNT_FORM_FIELDS.timeZoneId]: '',
                [DISCOUNT_CODE_FIELDS.coupon]: '',
                [ACCOUNT_FORM_FIELDS.signature]: '',
                [ACCOUNT_FORM_FIELDS.phone]: query?.phone_number || '',
                [ACCOUNT_FORM_FIELDS.country]: '',
                [ACCOUNT_FORM_FIELDS.state]: '',
                [ACCOUNT_FORM_FIELDS.sessionReminder]: false,
              }}
              validationSchema={
                !applicationRequiredButNotApproved &&
                getPurchaseValidationSchema(step, isPackage || isMonthlySessionSubscription)
              }
              innerRef={formRef}
              onSubmit={handleSubmit}
            >
              {({ handleChange, setFieldValue }) => (
                <>
                  <FormikScrollToError>
                    <Form>
                      {!applicationRequiredButNotApproved && isShowInput(step, ACCOUNT_FORM_FIELDS.email) && (
                        <FormControl fullWidth>
                          <Input
                            fullWidth
                            label={ACCOUNT_FORM_FIELDS_LABELS.email}
                            name={ACCOUNT_FORM_FIELDS.email}
                            InputLabelProps={{
                              style: { color: themedColor },
                            }}
                            onChange={handleChange}
                            ref={emailRef}
                            className={classNames.themedTextField}
                            type="text"
                            whiteError={isDarkThemeEnabled}
                          />
                        </FormControl>
                      )}
                      {!applicationRequiredButNotApproved && isShowInput(step, ACCOUNT_FORM_FIELDS.confirmEmail) && (
                        <FormControl fullWidth>
                          <Input
                            fullWidth
                            label={ACCOUNT_FORM_FIELDS_LABELS.confirmEmail}
                            name={ACCOUNT_FORM_FIELDS.confirmEmail}
                            onChange={handleChange}
                            ref={confirmEmailRef}
                            type="email"
                            whiteError={isDarkThemeEnabled}
                            className={classNames.themedTextField}
                            InputLabelProps={{
                              style: { color: themedColor },
                            }}
                          />
                        </FormControl>
                      )}
                      {!applicationRequiredButNotApproved && isShowInput(step, ACCOUNT_FORM_FIELDS.password) && (
                        <FormControl fullWidth style={{ display: 'flex', alignItems: 'flex-end' }}>
                          <Input
                            fullWidth
                            label={allowpassword ? ACCOUNT_FORM_FIELDS_LABELS.password : 'Create a Password'}
                            name={ACCOUNT_FORM_FIELDS.password}
                            onChange={handleChange}
                            type="password"
                            InputLabelProps={{
                              style: { color: themedColor },
                            }}
                            className={classNames.themedTextField}
                            error={Boolean(loginErrorMessage)}
                            helperText={loginErrorMessage != 'Sign in failed. Please check your email or password'}
                          />
                          {isShowInput(step, RESTORE_PASS) && (
                            <SendOtpLinkContainer>
                              <SendOtpLink
                                isDarkModeEnabled={contribution.isDarkModeEnabled}
                                onClick={handleShowOtpPopup}
                              >
                                Sign in with email one-time code
                              </SendOtpLink>
                              <ForgotPasswordLink color={themedColor} to="/auth/restore-pass">
                                Forgot Password?
                              </ForgotPasswordLink>
                            </SendOtpLinkContainer>
                          )}
                          {errorMessage &&
                            errorMessage?.message != 'You have entered the wrong login code, try again' && (
                              <StyledError template={activeTemplate}>{errorMessage.message}</StyledError>
                            )}
                        </FormControl>
                      )}
                      {!applicationRequiredButNotApproved && isShowInput(step, ACCOUNT_FORM_FIELDS.firstName) && (
                        <FormControl fullWidth>
                          <Input
                            fullWidth
                            label={ACCOUNT_FORM_FIELDS_LABELS.firstName}
                            name={ACCOUNT_FORM_FIELDS.firstName}
                            onChange={handleChange}
                            InputLabelProps={{
                              style: { color: themedColor },
                            }}
                            className={classNames.themedTextField}
                            type="text"
                            whiteError={isDarkThemeEnabled}
                          />
                        </FormControl>
                      )}
                      {!applicationRequiredButNotApproved && isShowInput(step, ACCOUNT_FORM_FIELDS.lastName) && (
                        <FormControl fullWidth>
                          <Input
                            fullWidth
                            label={ACCOUNT_FORM_FIELDS_LABELS.lastName}
                            name={ACCOUNT_FORM_FIELDS.lastName}
                            onChange={handleChange}
                            InputLabelProps={{
                              style: { color: themedColor },
                            }}
                            className={classNames.themedTextField}
                            type="text"
                            whiteError={isDarkThemeEnabled}
                          />
                        </FormControl>
                      )}

                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.sessionReminder) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.joinLogin) &&
                        showPhoneNumberFields === true && (
                          <Grid
                            item
                            alignItems="flex-start"
                            md={12}
                            xs={12}
                            style={{
                              padding: '5px 15px',
                              flexDirection: 'row',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Checkbox
                              style={{ color: themedColor }}
                              id="create-contribution-agreement"
                              color="primary"
                              checked={smsReminderCheckBox}
                              onChange={() => {
                                if (smsReminderCheckBox === true) {
                                  setSmsReminderCheckBox(false);
                                } else {
                                  setSmsReminderCheckBox(true);
                                }
                              }}
                            />
                            <div style={{ fontFamily: 'Avenir', fontWeight: '300', fontSize: '14px' }}>
                              Receive SMS session reminders
                            </div>
                          </Grid>
                        )}
                      {(isShowInput(step, ACCOUNT_FORM_FIELDS.phone) ||
                        step == PURCHASE_MODAL_STEPS.loggedIn ||
                        step == PURCHASE_MODAL_STEPS.login ||
                        step == PURCHASE_MODAL_STEPS.joinLogin) &&
                        smsReminderCheckBox === true &&
                        showPhoneNumberFields === true && (
                          <Grid
                            container
                            direction="column"
                            alignItems="flex-start"
                            md={12}
                            xs={12}
                            style={{ padding: '5px 15px' }}
                          >
                            <div style={{ width: '100%' }} ref={phoneInputRef}>
                              {defaultCountry && (
                                <PhoneInput
                                  key={coachPhoneNumberFocused}
                                  value={coachPhoneNumber}
                                  defaultCountry={defaultCountry}
                                  {...(!coachPhoneNumberFocused && { disableDialCodePrefill: true })}
                                  {...(coachPhoneNumberFocused && { forceDialCode: true })}
                                  disableCountryGuess
                                  placeholder="Phone Number"
                                  onChange={phone => {
                                    setFieldValue(ACCOUNT_FORM_FIELDS?.phone, phone);
                                    setPhoneNumberField(phone);
                                    setCoachPhoneNumber(phone);
                                  }}
                                />
                              )}
                              {/* {errors && errors[ACCOUNT_FORM_FIELDS?.phone] && touched[ACCOUNT_FORM_FIELDS?.phone] && (
                                      <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                        {errors[ACCOUNT_FORM_FIELDS?.phone]}
                                      </StyledError>
                              )} */}
                            </div>
                          </Grid>
                        )}
                      {contribution.paymentType === 'Simple' &&
                        contribution.taxType != 'No' &&
                        isInviteToJoin === null &&
                        showThreeFields &&
                        (isShowInput(step, ACCOUNT_FORM_FIELDS.country) ||
                          (formRef?.current?.values?.countryId?.length > 0 &&
                            formRef?.current?.values?.countryId != null)) && (
                          <>
                            <Typography variant="h6" style={{ color: themedColor, fontSize: '20px' }}>
                              Where are you located?
                            </Typography>

                            <Select
                              label="Country of Residence"
                              name={ACCOUNT_FORM_FIELDS.country}
                              fullWidth
                              style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                              required
                              native
                              InputLabelProps={{
                                style: { color: 'white' },
                              }}
                              className={classNames.themedTextField}
                              labelId="country"
                              value={formRef?.current?.values?.countryId}
                              onChange={e => {
                                const filteredObjects = states.filter(obj => obj.countryId === e.target.value);
                                const filteredCountry = countries.find(obj => obj.id === e.target.value);
                                const filteredTimezone = timeZones.filter(obj => obj.countryId === e.target.value);
                                if (filteredObjects.length >= 1) {
                                  setStatesArray(filteredObjects);
                                  if (e.target.value != '60b8ddb57205057e7ce2b861') {
                                    setTimeZoneArray(filteredTimezone);
                                  }
                                } else if (filteredObjects.length === 0) {
                                  setStatesArray(filteredObjects);
                                  if (filteredTimezone.length === 0) {
                                    setTimeZoneArray(timeZones);
                                  } else if (
                                    filteredTimezone.length === 1 &&
                                    filteredCountry.id != '60b8ddb57205057e7ce2b861'
                                  ) {
                                    setTimeZoneArray(filteredTimezone);
                                    setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredTimezone[0].countryName);
                                  } else {
                                    setTimeZoneArray(filteredTimezone);
                                  }
                                }
                                if (filteredCountry.id === '60b8ddb57205057e7ce2b861') {
                                  setTimeZoneArray(filteredTimezone);
                                  setFieldValue(ACCOUNT_FORM_FIELDS.state, null);
                                  setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                                  setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, null);
                                }
                                setFieldValue(ACCOUNT_FORM_FIELDS.country, filteredCountry.id);
                              }}
                            >
                              <option aria-label="Time Zone" value={null}>
                                Select Country
                              </option>
                              {countries != null &&
                                countries?.map(timeZone => <option value={timeZone.id}>{timeZone.name}</option>)}
                            </Select>
                            {/* {errorLocal &&
                                      formRef?.current?.values?.countryId === null &&
                                      formRef?.current?.values?.countryId?.length === 0 && (
                                        <StyledError PrimaryColor={PrimaryColor} template={activeTemplate}>
                                          Please select country
                                        </StyledError>
                                      )} */}
                          </>
                        )}
                      {contribution.taxType != 'No' &&
                        isInviteToJoin === null &&
                        formRef?.current?.values?.countryId === '60b8ddb57205057e7ce2b861' &&
                        statesArray.length > 1 && (
                          <Select
                            label="State"
                            name={ACCOUNT_FORM_FIELDS.state}
                            fullWidth
                            required
                            native
                            labelId="stateCode"
                            value={formRef?.current?.values?.stateCode}
                            style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                            InputLabelProps={{
                              style: { color: 'white' },
                            }}
                            className={classNames.themedTextField}
                            onChange={e => {
                              const filteredState = states.find(obj => obj.alpha2Code === e.target.value);
                              const filteredObjects = timeZones.filter(obj => obj.statesIds.includes(filteredState.id));
                              if (filteredObjects.length >= 1) {
                                if (filteredObjects.length === 1) {
                                  setTimeZoneArray(filteredObjects);
                                  setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                } else {
                                  setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, filteredObjects[0].countryName);
                                  setTimeZoneArray(filteredObjects);
                                }
                              } else {
                                setTimeZoneArray(timeZones);
                              }
                              setFieldValue(ACCOUNT_FORM_FIELDS.state, filteredState.alpha2Code);
                            }}
                          >
                            <option aria-label="Time Zone" value={null}>
                              Select State
                            </option>
                            {statesArray?.length > 0 &&
                              statesArray?.map(timeZone => (
                                <option value={timeZone.alpha2Code}>{timeZone.name}</option>
                              ))}
                          </Select>
                        )}
                      {contribution.paymentType === 'Simple' &&
                        contribution.taxType != 'No' &&
                        isInviteToJoin === null &&
                        timeZoneArray.length > 1 &&
                        formRef?.current?.values?.countryId != null && (
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="timezones" style={{ color: isDarkThemeEnabled ? 'white' : '' }}>
                                {ACCOUNT_FORM_FIELDS_LABELS.timezone}
                              </InputLabel>

                              <Select
                                style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                                required
                                native
                                InputLabelProps={{
                                  style: { color: 'white' },
                                }}
                                className={classNames.themedTextField}
                                labelId="timezones"
                                name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                onChange={e => {
                                  setFieldValue(ACCOUNT_FORM_FIELDS.timeZoneId, e.target.value);
                                  const color = e.target.value === 'Select Timezone' ? 'darkgrey' : 'black';
                                  // setColorForSelect(color);
                                }}
                                value={formRef?.current?.values?.TimeZoneId}
                                // onBlur={handleBlur}
                              >
                                <option aria-label="None" value={null} />
                                {timeZoneArray != null &&
                                  timeZoneArray?.map(timeZone => (
                                    <option value={timeZone.countryName}>{timeZone.name}</option>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      {((contribution.paymentType === 'Simple' && contribution.taxType === 'No') ||
                        contribution.paymentType === 'Advance' ||
                        isInviteToJoin != null) &&
                        timeZones.length > 1 &&
                        !applicationRequiredButNotApproved &&
                        isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) &&
                        timeZoneArray.length > 1 && (
                          <Grid style={{ marginTop: '10px' }} item xs={12}>
                            <FormControl fullWidth>
                              <Typography variant="h6">Save Your Time Zone Preference</Typography>
                            </FormControl>
                          </Grid>
                        )}
                      {((contribution.paymentType === 'Simple' && contribution.taxType === 'No') ||
                        contribution.paymentType === 'Advance' ||
                        isInviteToJoin != null) &&
                        !applicationRequiredButNotApproved &&
                        isShowInput(step, ACCOUNT_FORM_FIELDS.timeZoneId) &&
                        timeZones.length > 1 && (
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <InputLabel id="timezones" style={{ color: isDarkThemeEnabled ? 'white' : '' }}>
                                {ACCOUNT_FORM_FIELDS_LABELS.timezone}
                              </InputLabel>
                              <Select
                                style={{ color: isDarkThemeEnabled ? 'white' : '' }}
                                required
                                native
                                InputLabelProps={{
                                  style: { color: 'white' },
                                }}
                                className={classNames.themedTextField}
                                labelId="timezones"
                                name={ACCOUNT_FORM_FIELDS.timeZoneId}
                                onChange={handleChange}
                              >
                                <option aria-label="None" value={null} />
                                {timeZones?.length > 0 &&
                                  timeZones.map(timeZone => (
                                    <option value={timeZone.countryName}>{timeZone.name}</option>
                                  ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                      {!applicationRequiredButNotApproved &&
                        isElectronicSignatureActive &&
                        customWaiverId === null &&
                        (isShowInput(step, ACCOUNT_FORM_FIELDS.signature) || step == PURCHASE_MODAL_STEPS.loggedIn) && (
                          <FormControl fullWidth>
                            <div style={{ position: 'relative' }}>
                              <div
                                type="button"
                                onClick={clearSign}
                                style={{
                                  cursor: 'pointer',
                                  position: 'absolute',
                                  right: '10px',
                                  top: '8px',
                                  // color: colorToUse.PrimaryColorCode,
                                  fontWeight: 'bold',
                                }}
                              >
                                Clear
                              </div>
                              <SignatureCanvas
                                ref={canvasRef}
                                clearOnResize={false}
                                penColor="black"
                                clearOnResize={false}
                                canvasProps={{ borderRadius: '0px', height: '270px', className: classNames.inputField }}
                                onEnd={() => {
                                  setDisableBtn(false);
                                  if (canvasRef?.current) {
                                    const val = canvasRef?.current?.getTrimmedCanvas().toDataURL('image/png');
                                    setFieldValue(ACCOUNT_FORM_FIELDS.signature, val);
                                  }
                                }}
                              />
                              <p
                                style={{
                                  color: textColor,
                                  fontWeight: 'bold',
                                }}
                              >
                                Sign here to Join
                              </p>
                            </div>
                          </FormControl>
                        )}
                      {showOtpModal && (
                        <SignInWithOtpModal
                          isOpen={showOtpModal}
                          email={formRef.current.values.Email}
                          isDarkModeEnabled={contribution?.isDarkModeEnabled}
                          formRef={formRef}
                          onClose={closeModal}
                          accentColorCode={colorToUse?.AccentColorCode}
                          primaryColor={colorToUse?.PrimaryColorCode}
                          textColor={textColor}
                          activeTemplate={activeTemplate}
                          errorMsg={usereError?.message}
                        />
                      )}
                      <StyledButton
                        textColor={textColor}
                        backgroundColor={colorToUse?.PrimaryColorCode}
                        disabled={
                          (isApplyContributionView && isApplicationResponsePending) ||
                          (isElectronicSignatureActive &&
                            customWaiverId === null &&
                            (isShowInput(step, ACCOUNT_FORM_FIELDS.signature) ||
                              step == PURCHASE_MODAL_STEPS.loggedIn) &&
                            disableBtn) ||
                          clientPreviewMode
                        }
                        marginTop
                        type="submit"
                      >
                        {joinButtonText}
                      </StyledButton>
                      {!applicationRequiredButNotApproved &&
                        step !== PURCHASE_MODAL_STEPS.join &&
                        step !== PURCHASE_MODAL_STEPS.init &&
                        !isMonthlySessionSubscription &&
                        (customToS ? (
                          isElectronicSignatureActive && customWaiverId === null ? (
                            <Typography variant="caption">
                              By proceeding, I agree to the{' '}
                              <StyledLink
                                href="/"
                                color={colorToUse?.PrimaryColorCode}
                                onClick={e => {
                                  e.preventDefault();
                                  setShowTerms(true);
                                }}
                              >
                                Terms and Conditions
                              </StyledLink>
                              , I also agree to {serviceProviderName}
                              {"'"}s{' '}
                              <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                                Terms and Conditions
                              </StyledLink>
                              , and I'm at least 18 years old.
                            </Typography>
                          ) : (
                            <Typography variant="caption">
                              By proceeding, I agree to the{' '}
                              <StyledLink
                                href="/"
                                color={colorToUse?.PrimaryColorCode}
                                onClick={e => {
                                  e.preventDefault();
                                  setShowTerms(true);
                                }}
                              >
                                Terms and Conditions
                              </StyledLink>
                              , I also agree to {serviceProviderName}
                              {"'"}s{' '}
                              <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                                Terms and Conditions
                              </StyledLink>
                              , and I'm at least 18 years old.
                            </Typography>
                          )
                        ) : (
                          customWaiverId != null && (
                            <>
                              <Typography variant="caption">
                                By {agrementText} join, I agree to the{' '}
                                <StyledLink
                                  color={colorToUse?.PrimaryColorCode}
                                  href="/"
                                  onClick={e => {
                                    e.preventDefault();
                                    setShowTerms(true);
                                  }}
                                >
                                  Terms and Conditions
                                </StyledLink>
                                {/* , I also agree to {serviceProviderName}
                                {"'"}s{' '}
                                <StyledDiv color={colorToUse?.PrimaryColorCode}>
                                  {customWaiverTemplateName.trim()}
                                </StyledDiv> */}
                                , and I'm at least 18 years old.
                              </Typography>
                              <br />
                              {customToS && (
                                <Typography variant="caption">
                                  By {agrementText} Reserve, I also agree to {serviceProviderName}
                                  {"'"}s{' '}
                                  <StyledLink color={colorToUse?.PrimaryColorCode} href={customToS}>
                                    Terms and Conditions
                                  </StyledLink>
                                  , and I'm at least 18 years old.
                                </Typography>
                              )}
                            </>
                          )
                        ))}
                      {usereError?.message &&
                        usereError?.message != 'Sign in failed. Please check your email or password' &&
                        usereError?.message != 'You have entered the wrong login code, try again' && (
                          <CommonErrorMessage message={usereError.message} />
                        )}
                    </Form>
                  </FormikScrollToError>
                </>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
      <ModalTermsAndConditions showTerms={showTerms} onCancel={() => setShowTerms(false)} />
      {isShowWaiver && (
        <CreateCustomWaiver
          isOpen={isShowWaiver}
          onSubmit={values =>
            saveUserSignature(values).then(async () => {
              isSignSaved(formRef.current.values.Signature);
              if (isInviteToJoin === null) {
                if (membershipPaymentOptions.includes(typeOfPayment)) {
                  const data = {
                    paymentOption: typeOfPayment,
                    contributionId: id,
                    couponId: summaryRef?.current?.coupon?.id,
                  };

                  const response =
                    type === ContributionType.contributionCommunity
                      ? await purchaseService.purchaseCommunitySubscription(data)
                      : await purchaseService.purchaseMembershipSubscription(data);
                  setTimeout(() => {
                    setLoadingPayment(false);
                  }, 4000);
                  if (response === '100discount' || response === 'Free session joined successfully') {
                    // quick solution:redirect; todo: update state with purchased contribution and hide purchase modal
                    fetchJoindedContribution();
                  } else {
                    setPurchaseSessionId(response);
                  }

                  return;
                }
                If(
                  typeOfPayment === 'EntireCourse' ||
                    typeOfPayment === 'SplitPayments' ||
                    type === ContributionType.contributionCourse ||
                    type === ContributionType.contributionMembership ||
                    type === ContributionType.contributionCommunity ||
                    (type === ContributionType.contributionOneToOne && typeOfPayment === 'Free'),
                )
                  .then(
                    mapTo({
                      contributionId: contribution.id,
                      paymentOption: typeOfPayment,
                      priceId: priceId,
                      couponId: summaryRef?.current?.coupon?.id,
                    }),
                  )
                  .then(prepareEntire)
                  .else(createTokenForSplitPayments);
              } else {
                joinContribution({
                  id,
                  accessCode: isInviteToJoin,
                })
                  .then(() => {
                    dispatch(
                      fetchContributionActions.success({
                        ...contribution,
                        isPurchased: true,
                        purchaseStatus: 'succeeded',
                        subscriptionStatus: { status: 'active' },
                      }),
                    );
                  })
                  .then(() => {
                    handleProceedPurchaseStatus();
                    return formRef.current?.handleSubmit();
                  });
              }
            })
          }
          contribution={contribution}
          waiverData={waiverData}
          isDarkModeAllowed={true}
          onCancel={() => setIsShowWaiver(false)}
          isCreateContribution={false}
        />
      )}
      {loadingPayment && (
        <Grid className={classNames.loaderWrapper} container justify="center">
          <Loader relative />
        </Grid>
      )}
      {showUnableJoin && (
        <Modal
          title="Unable to join"
          isOpen={showUnableJoin}
          submitTitle="Close"
          hiddenCancel
          onCancel={() => {
            setshowUnableJoin(false);
          }}
          dontCancelOnSideClick
          onSubmit={() => {
            setshowUnableJoin(false);
          }}
          style={{ zIndex: '2000' }}
        >
          <p>Please use another email - you entered the account holder email of this service.</p>
        </Modal>
      )}
    </Grid>
  );
}

PurchaseBlock.defaultProps = {
  isPackage: false,
  onJoin: () => {},
};

PurchaseBlock.propTypes = {
  isPackage: PropTypes.bool,
  onJoin: PropTypes.func,
};

export default PurchaseBlock;
