import React from 'react';

const CommunityLikeFilledSvg = ({ width = '24px', height = '24px', color = '#215C73' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.9418 13.245L12.0063 21L5.07247 13.2468C2.39333 10.4473 2.50655 6.94235 4.30099 4.81065C5.19657 3.74675 6.50884 3.04097 8.03356 3.00212C9.33205 2.96904 10.693 3.42062 12.0041 4.4002C13.3111 3.42057 14.6691 2.96865 15.9659 3.00169C17.4888 3.04049 18.7997 3.7468 19.695 4.81006C21.4884 6.93997 21.6103 10.4444 18.9418 13.245Z"
        fill={color}
      />
    </svg>
  );
};

export default CommunityLikeFilledSvg;
