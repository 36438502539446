/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import UserMentionSuggestion from 'components/UI/mentions/UserMentionSuggestion';
import useContribution from 'pages/ContributionView/hooks/useContribution';
import { determineDarkThemedColorToUse, getThemedColors } from 'services/contributions.service';
import './UserMentionInput.scss';
import HashTagSuggestion from './HashTagSuggestion';
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
  ${({ minHeight }) =>
    minHeight &&
    `
      .mentions {
        min-height: ${minHeight};
      }
    `};

  ${({ isComment }) =>
    isComment &&
    `
        .mentions__highlighter {
          padding: 0px;
        }
      `};
`;

const UserMentionInput = ({
  users,
  onInputChange,
  defaultValue = '',
  placeholder = 'Type a message',
  name,
  resetInputValue,
  onResetInputValueCallback,
  hashTags,
  inputRef,
  minHeight = 0,
  isCreate,
  type = 'post',
  addEmoji = false,
  emoji = null,
  setAddEmoji = null,
}) => {
  const [messageText, setMessageText] = useState(defaultValue);

  if (resetInputValue === true) {
    onResetInputValueCallback();
    setTimeout(() => setMessageText(''), 100);
  }

  useEffect(() => {
    if (type !== 'post' && addEmoji) {
      setMessageText(messageText + emoji);
      setAddEmoji(false);
    }
  }, [addEmoji, emoji]);

  const contribution = useContribution();

  const { themedColor } = getThemedColors(contribution);
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);

  const handleOnChange = (e, newValue, newPlainTextValue, mentions) => {
    setMessageText(newValue);

    onInputChange && onInputChange(newPlainTextValue, mentions);
  };

  const theme = useTheme();
  const downXs = useMediaQuery(theme.breakpoints.down('xs'));

  const renderHashtags = () => {
    const updatedHashTags = hashTags?.map(x => ({
      display: x.toString(),
      id: x.toString(),
    }));

    return (
      <Mention
        renderSuggestion={entry => <HashTagSuggestion user={entry} />}
        trigger="#"
        className={`mentions__mention ${isDarkThemeEnabled ? 'mentions__mention_dark' : ''}`}
        data={updatedHashTags}
        displayTransform={(id, display) => `${display}`}
      />
    );
  };

  return (
    <StyledGrid
      style={{ color: themedColor }}
      className={(downXs ? 'width-limiter' : '', isCreate ? 'is-create' : '')}
      minHeight={minHeight}
      isComment={type !== 'post'}
    >
      <MentionsInput
        name={name}
        className="mentions"
        value={messageText?.trimStart()}
        placeholder={placeholder}
        allowSuggestionsAboveCursor
        forceSuggestionsAboveCursor
        onChange={handleOnChange}
        allowSpaceInQuery
        inputRef={inputRef}
      >
        <Mention
          renderSuggestion={entry => <UserMentionSuggestion user={entry} />}
          className={`mentions__mention ${isDarkThemeEnabled ? 'mentions__mention_dark' : ''}`}
          trigger="@"
          data={search => users?.filter(user => user?.display?.toLowerCase().includes(search?.toLowerCase()))}
          displayTransform={(id, display) => `${display}`}
        />
        {renderHashtags()}
      </MentionsInput>
    </StyledGrid>
  );
};

UserMentionInput.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onInputChange: PropTypes.func.isRequired,
  onResetInputValueCallback: PropTypes.func.isRequired,
  resetInputValue: PropTypes.bool.isRequired,
  defaultValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  isCreate: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default UserMentionInput;
