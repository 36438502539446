import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LiveVideoProvider } from 'constants.js';
import { ContributionType } from 'helpers/constants';
import Button from 'components/FormUI/Button';
import { useHttp, useVideoChatActions } from 'hooks';
import { useRecordModal } from 'hooks/useSessionRecordModal';
import { determineColorToUse } from 'services/contributions.service';
import { lightOrDark } from 'utils/utils';
import Modal from 'components/UI/Modal';
import { DarkEnabledPrimaryFilledButton } from 'pages/ContributionView/ContributionSessions/component/styled';
import { Tooltip } from '@material-ui/core';

const LaunchSessionTime = ({
  user,
  contribution: { id, type, chat, liveVideoServiceProvider, isDarkModeEnabled },
  session,
  contribution,
  sessionTime,
  participantName,
  isPartnerCoach = false,
  mobileView = false,
  isOneOnOneExpansion = false,
  mobileViewCoachLaunch = false,
}) => {
  const { request, loading } = useHttp();
  const { startVideoChat } = useVideoChatActions();
  const [launchModal, setLaunchModal] = useState(false);
  let colorToUse = determineColorToUse(contribution);
  const textColor =
    colorToUse?.TextColorCode === 'Auto'
      ? lightOrDark(colorToUse?.PrimaryColorCode)
      : colorToUse?.TextColorCode === '#000000'
      ? '#000000'
      : '#FFFFFF';
  const chatId =
    type === ContributionType.contributionOneToOne && chat?.cohealerPeerChatSids
      ? chat?.cohealerPeerChatSids[sessionTime.participantId]
      : chat?.sid;

  const onSubmit = () => {};
  const onCancel = () => {};

  const modalOptions = {
    onSubmit,
    onCancel,
    classId: sessionTime.id,
    contributionType: type,
    contributionId: id,
    liveVideoServiceProvider,
  };
  const { launchRecodModal } = useRecordModal(modalOptions);

  const startZoom = ({}) => {
    window.open(sessionTime.zoomMeetingData.startUrl, '_blank');
  };
  const onLaunchSession = ({}) => {
    request('/Video/CreateRoomAndGetToken', 'POST', {
      contributionId: id,
      classId: sessionTime.id,
      identityName: `${user.firstName} ${user.lastName}`,
    }).then(({ room: { sid: roomId }, token }) =>
      startVideoChat({
        contributionId: id,
        type,
        sessionId: session?.id,
        classId: sessionTime.id,
        title: session?.title || sessionTime?.title,
        roomId,
        chatId,
        token,
        deleteRoomOnVideoEnd: true,
      }),
    );
  };

  const isCustomProvider =
    (liveVideoServiceProvider?.providerName === LiveVideoProvider.Custom.value ||
      liveVideoServiceProvider?.providerName === LiveVideoProvider.InPersonSession.value) &&
    liveVideoServiceProvider?.customLink;

  const launchSession = event => {
    if (isCustomProvider) {
      launchRecodModal(event);
    } else if (liveVideoServiceProvider.providerName === LiveVideoProvider.Zoom.value) {
      startZoom(event);
    } else if (sessionTime.isPhoneNumberEnabledSession) {
      setLaunchModal(true);
    } else {
      onLaunchSession(event);
    }
  };
  const onJoinSession = event => {
    event.stopPropagation();
    if (liveVideoServiceProvider?.providerName === 'Zoom') {
      window.open(sessionTime.zoomMeetingData.joinUrl, '_blank');
    }
  };
  const isBtnDisabled =
    loading ||
    sessionTime.isCompleted ||
    (sessionTime?.isPhoneNumberEnabledSession && sessionTime?.isPhoneNumberAdded && sessionTime?.isCoachNumberAdded);

  return (
    <>
      {isPartnerCoach && liveVideoServiceProvider?.providerName === 'Zoom' && (
        <DarkEnabledPrimaryFilledButton
          style={{ marginRight: '10px' }}
          autoWidth
          backgroundColor={
            isBtnDisabled && contribution?.isDarkModeEnabled ? 'transparent' : colorToUse?.PrimaryColorCode
          }
          textColor={textColor}
          borderColor={isBtnDisabled && contribution?.isDarkModeEnabled && textColor}
          onClick={onJoinSession}
          disabled={isBtnDisabled}
          {...{ isDarkModeEnabled }}
        >
          {'Join Session'}
        </DarkEnabledPrimaryFilledButton>
      )}
      {sessionTime.isPurchaseConfirmed === true && contribution.type === ContributionType.contributionOneToOne ? (
        <DarkEnabledPrimaryFilledButton
          isOneOnOneExpansion={isOneOnOneExpansion}
          mobileViewCoachLaunch={mobileViewCoachLaunch}
          mobileView={mobileView}
          autoWidth
          backgroundColor={
            isBtnDisabled && contribution?.isDarkModeEnabled ? 'transparent' : colorToUse?.PrimaryColorCode
          }
          textColor={textColor}
          borderColor={isBtnDisabled && contribution?.isDarkModeEnabled && textColor}
          onClick={e => launchSession(e)}
          disabled={isBtnDisabled}
          {...{ isDarkModeEnabled }}
        >
          {sessionTime?.isPhoneNumberEnabledSession && !sessionTime?.isCoachNumberAdded
            ? `Call ${sessionTime?.clientPhoneNumber}`
            : 'Launch Session'}
        </DarkEnabledPrimaryFilledButton>
      ) : sessionTime.isPurchaseConfirmed === false && contribution.type === ContributionType.contributionOneToOne ? (
        <Tooltip title="The session cannot be launched until the client has accepted the session invitation." arrow>
          <div
            style={{
              backgroundColor: '#ededed',
              color: '#9b9b9b',
              fill: '#9b9b9b',
              cursor: 'not-allowed',
              border: 'none',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              padding: '10px 16px',
              borderRadius: '4px',
              fontFamily: 'Avenir',
              fontWeight: '900',
              fontSize: '0.875rem',
            }}
          >
            {sessionTime?.isPhoneNumberEnabledSession && !sessionTime?.isCoachNumberAdded
              ? `Call ${sessionTime?.clientPhoneNumber}`
              : 'Launch Session'}
          </div>
        </Tooltip>
      ) : (
        <DarkEnabledPrimaryFilledButton
          mobileView={mobileView}
          autoWidth
          backgroundColor={
            isBtnDisabled && contribution?.isDarkModeEnabled ? 'transparent' : colorToUse?.PrimaryColorCode
          }
          textColor={textColor}
          borderColor={isBtnDisabled && contribution?.isDarkModeEnabled && textColor}
          onClick={e => launchSession(e)}
          disabled={isBtnDisabled}
          {...{ isDarkModeEnabled }}
        >
          {sessionTime?.isPhoneNumberEnabledSession && !sessionTime?.isCoachNumberAdded
            ? `Call ${sessionTime?.clientPhoneNumber}`
            : 'Launch Session'}
        </DarkEnabledPrimaryFilledButton>
      )}
      {launchModal && (
        <Modal
          title={`Call ${participantName}`}
          isOpen={launchModal}
          onCancel={() => setLaunchModal(false)}
          onBack={() => setLaunchModal(false)}
          submitTitle="Ok"
          onSubmit={() => setLaunchModal(false)}
          hiddenCancel
          applyTheming
        >
          Call {participantName}, {sessionTime?.clientPhoneNumber}
        </Modal>
      )}
    </>
  );
};

LaunchSessionTime.defaultProps = {
  session: {},
};

LaunchSessionTime.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  contribution: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    chat: PropTypes.shape({
      sid: PropTypes.string,
    }),
    liveVideoServiceProvider: {
      providerName: PropTypes.string,
      customLink: PropTypes.string,
    },
  }).isRequired,
  session: PropTypes.shape({
    id: PropTypes.string,
  }),
  sessionTime: PropTypes.shape({
    id: PropTypes.string,
    isCompleted: PropTypes.bool,
    isPhoneNumberEnabledSession: PropTypes.bool.isRequired,
    isPhoneNumberAdded: PropTypes.bool,
    isCoachNumberAdded: PropTypes.bool,
    clientPhoneNumber: PropTypes.string,
  }).isRequired,
  participantName: PropTypes.string.isRequired,
};

export default LaunchSessionTime;
