import React, { useState, useEffect, useRef, Fragment, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import * as userActions from 'actions/user';
import * as Yup from 'yup';
import { useAccount, useHttp } from 'hooks';
import * as calendarsActions from 'actions/calendars';
import * as contributionActions from 'actions/contributions';
import ContributionForm from 'pages/ContributionView/components/CalendarContributionView/ContributionForm';
import * as oneToOneDataActions from 'actions/oneToOneData';
import { Formik, FieldArray } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  TextField,
  useMediaQuery,
  useTheme,
  RadioGroup,
  SvgIcon,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import TimePickerField from 'components/FormUI/TimePickerField';
import { useDispatch, useSelector, connect } from 'react-redux';
// import Select from '@material-ui/core/Select';
import Select from 'components/FormUI/Select';
import { fetchTimeZones } from 'actions/timeZone';
import { Checkbox } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MenuItem, OutlinedInput } from '@material-ui/core';
import CommonErrorMessage from 'components/FormUI/CommonErrorMessage';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { TOOLTIP } from '../../../constants';
import { colors } from 'utils/styles';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import CustomSelect from 'components/FormUI/Select';
import moment from 'moment';
import DateTimePicker from 'components/FormUI/DateTimePicker';
import CustomExpansionPanel from 'pages/CreateContribution/components/ExpansionPanel';
import { mdiPlus, mdiMinusCircleOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import { BsCheckLg, BsDash } from 'react-icons/bs';
import WeekdayPicker from 'pages/CreateContribution/components/WeekdayPicker';
import { Calendar } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { setOneToOneRecurringAvailability } from 'actions/contributions';
import { AvailabilityRecurring } from 'pages/CreateContribution/components/AvailabilityRecurring/AvailabilityRecurring';
import { DATE_FORMATS } from 'utils/datesAndMoney';
import { masterCalendarUpdateOneToOneAvailabilities } from 'services/contributions.service';
import Loader from 'components/UI/Loader';
import Notification from 'pages/CreateCampaign/Components/Notification/Notification';
import { isEmpty } from 'lodash';
import { setPreSelectedMasterCalendarDate } from 'actions/masterCalendarSidebar.actions';

const StyledFormControlLabel = styled(FormControlLabel)`
  text-align: right;
`;
const StyledFormControl = styled(FormControl)`
  width: 100%;
`;
const StyledCustomSelect = styled(CustomSelect)`
  width: calc(100% - 33px);
`;
const StyledModalBody = styled.div`
  // height: 435px;
  // overflow-y: auto;
  padding: 0px 10px 0px 0px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgb(223, 227, 228) !important;
  }
`;
const useStyles = makeStyles({
  checkboxLabel: {
    fontSize: 14,
    fontWeight: 900,
    fontFamily: 'Avenir',
    padding: '0 9px',
    letterSpacing: '1.25px',
  },
  root: {
    marginLeft: '-30px',
  },
});
const StyledClientBook = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1;
  margin-bottom: 0px;
`;
const CustomRadio = styled(Radio)`
  .MuiIconButton-label {
    color: #213649;
  }
`;
const StyledDivContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
  margin-bottom: ${({ mobileView }) => (mobileView ? '16px' : '0')};
  justify-content: space-between;
  align-items: center;
`;
const StyledBsDash = styled(BsDash)``;
const StyledLink = styled.span`
  max-width: 240px;
  display: flex;
  font-family: Avenir;
  align-items: center;
  font-size: 14px;
  font-weight: 800;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: ${colors.darkOceanBlue};
  cursor: pointer;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
  justify-content: center;
  padding: 2px 0px;

  &:hover {
    text-decoration: underline;
  }
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 -5px;
`;
const StyledLabel = styled.div`
  width: 100px;
  font-family: 'Brandon Text';
`;
export const SelectStyled = styled(Select)``;

const durationUnitsValues = [
  { title: 'Hour(s)', value: 'Hours' },
  { title: 'Day(s)', value: 'Days' },
];
export const startIncrementDurationsOneToOne = [
  { title: 'Default Increment', value: 0 },
  { title: '15 min', value: 15 },
  { title: '30 min', value: 30 },
  { title: '1 hour', value: 60 },
];
const durationValues = {
  tenDays: 10,
  thirtyDays: 30,
  sixtyDays: 60,
  ninetyDays: 90,
  customDaysValue: 'custom',
};
const dateRangeOneToOne = [
  { title: '10 days', value: durationValues.tenDays },
  { title: '30 days', value: durationValues.thirtyDays },
  { title: '60 days', value: durationValues.sixtyDays },
  { title: '90 days', value: durationValues.ninetyDays },
  { title: 'Custom', value: durationValues.customDaysValue },
];
const bufferTimeValues = [
  { title: '15 min', value: 15 },
  { title: '30 min', value: 30 },
  { title: '1 hour', value: 60 },
];

const orderMap = { M: 1, T: 2, W: 3, Th: 4, F: 5, S: 6, Su: 7 };
const MasterCalendarAvailabilityModalComponent = ({
  showTerms,
  onCancel,
  selectedDate = null,
  selectedContribution = null,
  startTime = null,
  oneToDateRange,
  oneToOneEndDate,
  oneToOneStartDate,
  setSessionDuration,
  sessionDuration,
  setStartTimeIncrementDuration,
  startTimeIncrementDuration,
  setOneToDateRange,
  setStartDate,
  setEndDate,
  setOneToOneTime,
  removeBookingTime,
  addBookingTime,
  setBlockedDates,
  setDefaultWeeklyData,
  setshowAvailabilityPopUp = () => {},
  selectedWeeks,
  blockedDates,
  loadingTimes,
}) => {
  const { manageAvailabilitySelection, selectedMasterCalendarContribution, selectedMasterCalendarDate } = useSelector(
    state => state.masterCalendar,
  );
  const [isWeeklyPanelExpanded, setIsWeeklyPanelExpanded] = useState(false);
  const oneToOneData = useSelector(state => state.oneToOneData);
  const masterCalendarArray = useSelector(state => state?.contributions?.masterCalendarArray);
  const campaignNotification = useSelector(state => state?.campaigns?.campaignNotification);
  const [isDateRangeExpanded, setIsDateRangeExpanded] = useState(false);
  const [allOccurencesModal, setallOccurencesModal] = useState(false);
  const [minNoticeExpand, setMinNoticeExpand] = useState(false);
  const [loader, setLoader] = useState(false);
  const { customContributionTimes } = useSelector(state => state.contributions);
  const [openApplySettingsModal, set0penApplySettingsModal] = useState(false);
  const [openApplySettingsErrorModal, set0penApplySettingsErrorModal] = useState(false);
  const formRef = useRef(null);
  const { user } = useAccount();
  const dispatch = useDispatch();
  const [isAvailabilityPanelExpanded, setIsAvailabilityPanelExpanded] = useState(true);
  const [isOneToOneAvailabilityExpanded, setIsOneToOneAvailabilityExpanded] = useState(false);
  const { timeZones, loading: timeZoneLoading } = useSelector(state => state.timeZone);
  const { activeContribution, editContributionTimes } = useSelector(state => state.contributions);
  const [isBeforeBufferActive, setIsBeforeBufferActive] = useState(false);
  const [isAfterBufferActive, setIsAfterBufferActive] = useState(false);
  const [newBlockedDates, setNewBlockedDates] = useState([]);
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const smView = useMediaQuery(theme.breakpoints.down('sm'));
  const bookingval = oneToDateRange === 'custom' ? 'within-the-range' : 'into-the-future';
  const [bookingRange, setBookingRange] = useState(bookingval);
  const [isExpansionDisabled, setIsExpansionDisabled] = useState(false);
  const [isExpansionDisabledAvailability, setIsExpansionDisabledAvailability] = useState(false);
  const [oneToOneAvailability, setOneToOneAvailability] = useState(null);
  const [isBlockSingleDayExpanded, setIsBlockSingleDayExpanded] = useState(false);

  useEffect(() => {
    if (selectedMasterCalendarDate === null) {
      setIsWeeklyPanelExpanded(true);
    } else {
      setIsAvailabilityPanelExpanded(true);
      setIsWeeklyPanelExpanded(false);
      setOneToOneAvailability(selectedMasterCalendarDate);
    }
  }, [oneToOneAvailability]);

  const togglePopup = () => {
    set0penApplySettingsModal(!openApplySettingsModal);
  };

  const togglePopupError = () => {
    set0penApplySettingsErrorModal(!openApplySettingsErrorModal);
  };

  useEffect(() => {
    if (blockedDates?.length > 0) {
      setNewBlockedDates(
        blockedDates?.map(date => {
          return new Date(date);
        }),
      );
    }
  }, [blockedDates]);
  const DisplayArray = ({ data }) => {
    if (!data || data.length === 0) return null;

    const firstItem = data[0];
    const remainingCount = data.length - 1;

    return (
      <div
        style={{
          fontFamily: 'Avenir',
          fontSize: '16px',
          fontWeight: '350',
          lineHeight: '21px',
          letterSpacing: '0.1',
          marginBottom: '0px',
          marginLeft: '8px',
        }}
      >
        {remainingCount > 0
          ? `${moment(firstItem.startTime).format('MM/DD/YYYY, hh:mm a')} + ${remainingCount} more`
          : `${moment(firstItem.startTime).format('MM/DD/YYYY, hh:mm a')} `}
      </div>
    );
  };

  const DisplayArrayBlockedDates = ({ data }) => {
    if (!data || data.length === 0) return null;
    const firstThreeItems = data.slice(0, 3);
    const remainingCount = data.length - 3;

    return (
      <div
        style={{
          fontFamily: 'Avenir',
          fontSize: '16px',
          fontWeight: '350',
          lineHeight: '21px',
          letterSpacing: '0.1',
          marginBottom: '0px',
          marginLeft: '8px',
        }}
      >
        {firstThreeItems.map((item, index) => (
          <span key={index}>
            {moment(item).format('MM/DD/YYYY')}
            {index < firstThreeItems.length - 1 && ', '}
          </span>
        ))}
        {remainingCount > 0 && ` + ${remainingCount} more`}
      </div>
    );
  };
  // useEffect(() => {
  //   if (oneToDateRange === 'custom') {
  //     setBookingRange('within-the-range');
  //   } else {
  //     setBookingRange('into-the-future');
  //   }
  // }, [oneToDateRange]);

  useEffect(() => {
    if (activeContribution != null) {
      dispatch(oneToOneDataActions.setOneOnOneDataMasterCalendar(activeContribution.oneToOneSessionDataUi));
      if (activeContribution.bufferTimeAfter > 0) {
        setIsAfterBufferActive(true);
      }
      if (activeContribution.bufferTimeBefore > 0) {
        setIsBeforeBufferActive(true);
      }
      if (activeContribution.bufferTimeBefore > 0) {
        setIsBeforeBufferActive(true);
      }
      if (activeContribution.sessionIncrements.length > 0) {
        setStartTimeIncrementDuration(activeContribution.sessionIncrements);
      }
    } else {
      dispatch(setDefaultWeeklyData());
      setNewBlockedDates([]);
      setIsBeforeBufferActive(false);
      setIsAfterBufferActive(false);
    }
  }, [activeContribution]);

  useEffect(() => {
    if (selectedDate != null && startTime === null) {
      setTimeout(() => {
        handleDatePick(moment(selectedDate));
      }, [2000]);
    }
    if (selectedDate != null && startTime != null) {
      setTimeout(() => {
        handleDatePick(moment(selectedDate));
        handleStartTimePick(startTime);
      }, [2000]);
    }
    if (selectedContribution != null) {
      setTimeout(() => {
        handleSelectedContribution();
      }, [2000]);
    }
  }, [selectedDate, formRef.current, startTime]);

  useEffect(() => {
    if (timeZones?.length > 0) {
      return;
    }
    if ((!timeZones || !timeZones.length) && !timeZoneLoading) {
      dispatch(fetchTimeZones());
    }
  }, [timeZones, timeZoneLoading]);

  useEffect(() => {
    const diff = moment(oneToOneEndDate).diff(moment(oneToOneStartDate), 'days');
    if (
      !(
        diff === durationValues.tenDays ||
        diff === durationValues.thirtyDays ||
        diff === durationValues.sixtyDays ||
        diff === durationValues.ninetyDays
      )
    ) {
      setOneToDateRange(durationValues.customDaysValue);
    }
  }, [oneToOneStartDate, oneToOneEndDate]);
  const onChangeStartTimeIncrementDuration = e => {
    const { value } = e.target;
    setStartTimeIncrementDuration(value);
  };
  const handleDatePick = selectedMomentDate => {
    if (formRef.current) {
      const selectedDate = selectedMomentDate.format();
      formRef.current.setFieldValue('date', selectedDate);
      const selectedMoment = moment(selectedDate);

      const newDate = selectedMoment.add(1, 'year');
      formRef.current.setFieldValue('endDate', newDate);
    }
  };

  const handleStartTimePick = selectedMomentDate => {
    if (formRef.current) {
      const selectedTime = moment(selectedMomentDate);
      formRef.current.setFieldValue('startTime', selectedTime);
    }
  };
  const handleSelectedContribution = () => {
    if (formRef.current) {
      formRef.current.setFieldValue('contributions', [
        { id: selectedContribution.id, title: selectedContribution.title },
      ]);
    }
  };
  const handleTimeChange = (value, sessionTimeIdx, timeType) => {
    if (value) {
      const currentDate = moment(oneToOneStartDate).format('YYYY-MM-DD');
      const currentTime = moment(`${currentDate} ${value.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setOneToOneTime(sessionTimeIdx, currentTime, timeType);

      if (timeType === 'startTime') {
        const endTimeValue = value.add(sessionDuration, 'minutes');
        const newEndTime = moment(`${currentDate} ${endTimeValue.format('HH:mm:ss')}`).format('YYYY-MM-DDTHH:mm:ss[Z]');
        setOneToOneTime(sessionTimeIdx, newEndTime, 'endTime');
      }
    }
  };
  const removeTimesHandler = useCallback(i => () => removeBookingTime(i), [removeBookingTime]);
  const addTimesHandler = () => {
    addBookingTime(sessionDuration);
  };

  const handleAvailabilityDelete = () => {
    setOneToOneAvailability(null);
  };

  const giveTitle = (bufferTimeBefore, bufferTimeAfter) => {
    if (bufferTimeBefore > 0 && bufferTimeAfter > 0) {
      return `${bufferTimeBefore} min Before, ${bufferTimeAfter} min After`;
    } else if (bufferTimeBefore > 0) {
      return `${bufferTimeBefore} min Before`;
    } else if (bufferTimeAfter > 0) {
      return `${bufferTimeAfter} min After`;
    } else {
      return `None`;
    }
  };
  const hasEmptyDays = () => {
    if (!Array.isArray(selectedWeeks)) {
      throw new Error('Input must be an array');
    }

    return (
      selectedWeeks.some(week => Array.isArray(week.days) && week.days.length === 0) || oneToOneAvailability != null
    );
  };
  return (
    <>
      <Modal
        applyTheming={false}
        isDarkModeSelected={false}
        isCreatingContribution
        isOpen={showTerms}
        onCancel={() => {
          onCancel();
          // dispatch(contributionActions.setMasterCalendarArray([]));
          // dispatch(contributionActions.fetchContributionTimesActions.success([]));
        }}
        shrinkCancelButton={true}
        title="Update 1:1 Availability"
        cancelTitle={'Cancel'}
        submitTitle={'Apply'}
        isCreatingContrinbution
        widthRequired
        brandingColor={false}
        cancelBtnStyle={{ padding: '10px 20px !important', border: '1px solid black' }}
        disableSubmitClick={hasEmptyDays()}
        onSubmit={() => {
          if (masterCalendarArray.length >= 1) {
            setLoader(true);
            const params = {
              contributionIds: masterCalendarArray.map(item => item.id).join(', '),
              ...formRef.current.values,
              ...oneToOneData,
              duration: oneToOneData.duration === 'custom' ? oneToOneData.duration : `${oneToOneData.duration}`,
              minimumNotice: formRef.current.values.minimumNotice,
              endDay: oneToOneData.endDay,
              startDay: oneToOneData.startDay,
              bufferTimeBefore: formRef.current.values.bufferTimeBefore,
              bufferTimeAfter: formRef.current.values.bufferTimeAfter,
              sessionIncrements: Array.isArray(startTimeIncrementDuration)
                ? startTimeIncrementDuration
                : [startTimeIncrementDuration],
            };
            delete params.sessionDuration;
            delete params.contributions;
            delete params.startDate;
            delete params.multipleSessionDuration;
            delete params.startTimeIncrementSlots;
            delete params.startTimeIncrementDuration;
            delete params.endDate;
            delete params.preparedSlots;
            masterCalendarUpdateOneToOneAvailabilities(params)
              .then(() => {
                setLoader(false);
                setshowAvailabilityPopUp(false);
                dispatch(contributionActions.fetchContributionTimes(masterCalendarArray[0].id, true));
                dispatch(contributionActions.fetchCohealerContribution(masterCalendarArray[0].id));
                set0penApplySettingsModal(!openApplySettingsModal);
              })
              .catch(() => {
                setLoader(false);
                setshowAvailabilityPopUp(false);
                dispatch(contributionActions.fetchContributionActions.success(null));
                set0penApplySettingsModal(!openApplySettingsModal);
              });
          } else if (masterCalendarArray.length === 0 && activeContribution === null) {
            togglePopupError();
          }
        }}
      >
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            contributions: [],
            minimumNotice: {
              type:
                activeContribution != null ? activeContribution?.oneToOneSessionDataUi?.minimumNotice?.type : 'Hours',
              value: activeContribution != null ? activeContribution?.oneToOneSessionDataUi?.minimumNotice?.value : '4',
            },
            bufferTimeBefore: activeContribution != null ? activeContribution?.bufferTimeBefore : 0,
            bufferTimeAfter: activeContribution != null ? activeContribution?.bufferTimeAfter : 0,
          }}
          validationSchema={Yup.object().shape({
            interval: Yup.number().min(1, 'it must be 1 or more').required('This is a required field').nullable(),
            recurrencePattern: Yup.string().required('This is a required field'),
            contributions: Yup.array().required('This is a required field'),
            date: Yup.string().required('This is a required field').nullable(),
            startTime: Yup.string().required('This is a required field').nullable(),
            endTime: Yup.string().required('This is a required field').nullable(),
            daysOfWeek: Yup.array().when('recurrencePattern', {
              is: v => v === 'weekly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            repeatOn: Yup.array().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.array().required('This is a required field'),
              otherwise: Yup.array(),
            }),
            dayOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            dayOfWeek: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            weekOfMonth: Yup.string().when('recurrencePattern', {
              is: v => v === 'monthly',
              then: Yup.string().required('This is a required field'),
              otherwise: Yup.string(),
            }),
            // endDate: Yup.date().when('endson', {
            //   is: true,
            //   then: Yup.date().required('This is a required field'),
            //   // otherwise: Yup.date().nullable(),
            // }),
            occurrences: Yup.number().when('occurrence', {
              is: true,
              then: Yup.number()
                .integer()
                .required('This is a required field')
                .min(1, 'it must be 1 or more')
                .nullable(),
              otherwise: Yup.number().nullable(),
            }),
          })}
          onSubmit={() => {}}
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => {
            return (
              <StyledModalBody>
                {!isEmpty(campaignNotification) && allOccurencesModal === false && (
                  <Notification
                    notification={{
                      heading: mobileView ? 'Slot(s) Updated!' : 'Availability slot(s) updated successfully!',
                    }}
                    slotsUpdation={true}
                    component={true}
                    isModal={true}
                  />
                )}
                {loader && <Loader zIndex={'5000'} />}
                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                        }}
                      >
                        Date Range
                      </p>
                      <Tooltip
                        title="This allows your calendar availability to always have the next indicated number of days available for client booking. You can also select custom dates."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                      </Tooltip>
                    </div>
                  }
                  summarySubTitle={
                    mobileView
                      ? ''
                      : isDateRangeExpanded
                      ? ''
                      : bookingRange === 'into-the-future' && oneToDateRange != 'custom'
                      ? `${oneToDateRange} days`
                      : `${moment(oneToOneStartDate).format('MM/DD/YYYY')} - ${moment(oneToOneEndDate).format(
                          'MM/DD/YYYY',
                        )}`
                  }
                  mobileView={mobileView}
                  expand={isDateRangeExpanded}
                  noBorder
                >
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <StyledClientBook>Clients can book...</StyledClientBook>
                    <div style={{ marginTop: '15px' }}>
                      <RadioGroup
                        // row
                        name="booking_range"
                        onChange={({ target }) => {
                          setBookingRange(target.value);
                          if (target.value === 'within-the-range') {
                            setOneToDateRange('custom');
                          }
                        }}
                        value={bookingRange}
                      >
                        <div style={{ gap: '20px' }}>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              style={{ marginBottom: 0, marginRight: '10px' }}
                              value="into-the-future"
                              control={<CustomRadio />}
                              label={
                                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                  <Select
                                    style={{
                                      fontFamily: 'Avenir',
                                      border: '1px solid #DFE3E4',
                                      borderRadius: '4px',
                                      padding: '3px 10px',
                                      fontSize: '16px',
                                      fontWeight: '350',
                                      lineHeight: '24px',
                                      letterSpacing: '0.15px',
                                      marginRight: '10px',
                                    }}
                                    roundedBorder={true}
                                    labelTop={mobileView ? '32px' : '24px'}
                                    name="daterange"
                                    variant="Outlined"
                                    value={oneToDateRange}
                                    onChange={e => {
                                      if (e.target.value === 'custom') {
                                        setBookingRange('within-the-range');
                                      }
                                      setOneToDateRange(e.target.value);
                                    }}
                                    items={dateRangeOneToOne.map(({ title, value }) => ({
                                      title,
                                      value,
                                    }))}
                                    disabled={bookingRange !== 'into-the-future'}
                                  />
                                  into the future
                                </div>
                              }
                            />
                          </div>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              style={{ marginBottom: 0 }}
                              value="within-the-range"
                              control={<CustomRadio />}
                              label="Within the date range"
                              onChange={e => {
                                setFieldValue('duration', 'custom');
                              }}
                            />
                            <Tooltip
                              title="Your clients can only book appointments within this date range. Once it ends, you'll need to log back in to add more availability."
                              arrow
                              enterTouchDelay={TOOLTIP.ENTER_DELAY}
                              leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                            >
                              <InfoIcon
                                htmlColor={colors.lightBrown}
                                style={{ cursor: 'pointer', marginLeft: '-8px' }}
                              />
                            </Tooltip>
                          </div>
                          {(bookingRange === 'within-the-range' || oneToDateRange === 'custom') && (
                            <Grid
                              item
                              container
                              sm={12}
                              xs={12}
                              direction="row"
                              justifyContent="space-between"
                              style={{ marginTop: '10px', marginLeft: '0px' }}
                            >
                              <Grid sm={5} xs={5}>
                                <DateTimePicker
                                  disabled={oneToDateRange != 'custom'}
                                  // label={<div style={{ fontFamily: 'Brandon Text' }}>Start Day</div>}
                                  style={{
                                    fontFamily: 'Avenir',
                                    border: '1px solid #DFE3E4',
                                    borderRadius: '4px',
                                    padding: '3px 10px',
                                    fontSize: '16px',
                                    fontWeight: '350',
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                    marginBottom: mobileView ? '10px' : '',
                                  }}
                                  type="date"
                                  fullWidth
                                  name="startDate"
                                  value={oneToOneStartDate}
                                  onChange={momentDate => {
                                    setStartDate(momentDate);
                                  }}
                                  format={mobileView ? 'MM/DD/YYYY' : 'MMMM Do YYYY'}
                                  underline={false}
                                />
                              </Grid>
                              <Grid sm={5} xs={5}>
                                <DateTimePicker
                                  disabled={oneToDateRange != 'custom'}
                                  // label={<div style={{ fontFamily: 'Brandon Text' }}>End Day</div>}
                                  style={{
                                    fontFamily: 'Avenir',
                                    border: '1px solid #DFE3E4',
                                    borderRadius: '4px',
                                    padding: '3px 10px',
                                    fontSize: '16px',
                                    fontWeight: '350',
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px',
                                  }}
                                  type="date"
                                  fullWidth
                                  name="endDate"
                                  value={oneToOneEndDate}
                                  onChange={momentDate => {
                                    setEndDate(momentDate);
                                  }}
                                  format={mobileView ? 'MM/DD/YYYY' : 'MMMM Do YYYY'}
                                  underline={false}
                                />
                              </Grid>
                            </Grid>
                          )}
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </CustomExpansionPanel>

                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                        }}
                      >
                        Add Weekly Schedule: What Days And Times Can Be Booked?
                      </p>
                      <Tooltip
                        title="This allows you to add weekly schedule that can be booked on your calendar. You can always go back to edit this later."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                  }
                  expand={isWeeklyPanelExpanded}
                  disableCollapse={isExpansionDisabled}
                  mobileView={mobileView}
                  noBorder
                >
                  <FieldArray validateOnChange={false}>
                    {({ push, remove, form }) => {
                      return (
                        <div style={{ width: '100%' }}>
                          {selectedWeeks.length && (
                            <Grid container>
                              {selectedWeeks.map((week, i) => {
                                const momentStartTime = moment.utc(week.startTime);
                                const momentEndTime = moment.utc(week.endTime);
                                const key = i;
                                return (
                                  <Fragment key={key}>
                                    <Grid container>
                                      <StyledDivContainer mobileView={smView} smView={smView}>
                                        <Grid sm={smView ? 12 : 7} xs={smView ? 12 : 7} style={{ width: '100%' }}>
                                          <div className="d-flex" style={{ width: '100%' }}>
                                            <StyledFormControl>
                                              {/* <InputLabel
                                                      style={{ color: 'black', fontFamily: 'Brandon Text' }}
                                                      shrink
                                                      htmlFor={`available-time-${i}`}
                                                    >
                                                      Add available times: What days and times can be booked?
                                                    </InputLabel> */}
                                              <WeekdayPicker itemIndex={i} />
                                            </StyledFormControl>
                                          </div>
                                        </Grid>
                                        <Grid
                                          sm={smView ? 12 : 5}
                                          xs={smView ? 12 : 5}
                                          style={{ marginLeft: smView ? 0 : 8 }}
                                        >
                                          <Grid container direction="row" justify="space-around" alignItems="baseline">
                                            <Grid item sm={4} xs={4}>
                                              <div className="d-flex">
                                                <StyledFormControl>
                                                  {/* <InputLabel
                                                          htmlFor={`start-time-${i}`}
                                                          style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                                                        >
                                                          From
                                                        </InputLabel> */}
                                                  <TimePickerField
                                                    onChange={value => handleTimeChange(value, i, 'startTime')}
                                                    onOpen={e => {
                                                      setIsExpansionDisabled(true);
                                                    }}
                                                    onClose={e => {
                                                      setIsExpansionDisabled(false);
                                                    }}
                                                    style={{
                                                      fontFamily: 'Avenir',
                                                      border: '1px solid #e7e7e7',
                                                      borderRadius: '4px',
                                                      alignItems: 'center',
                                                    }}
                                                    className="custom-time-picker-nounderline"
                                                    name={`start-time-${i}`}
                                                    id={`tstart-time-${i}`}
                                                    classes={{
                                                      root: 'mt-4 mr-3',
                                                    }}
                                                    showSecond={false}
                                                    value={momentStartTime}
                                                    format="h:mm a"
                                                    use12Hours
                                                    minuteStep={15}
                                                  />
                                                </StyledFormControl>
                                              </div>
                                            </Grid>
                                            <Grid item sm={1} xs={1}>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                }}
                                              >
                                                <StyledBsDash />
                                              </div>
                                            </Grid>
                                            <Grid item sm={4} xs={4}>
                                              <div className="d-flex">
                                                <StyledFormControl>
                                                  {/* <InputLabel
                                                          htmlFor={`end-time-${i}`}
                                                          style={{ top: '-20px', fontFamily: 'Brandon Text' }}
                                                        >
                                                          To
                                                        </InputLabel> */}
                                                  <TimePickerField
                                                    style={{
                                                      fontFamily: 'Avenir',
                                                      border: '1px solid #e7e7e7',
                                                      borderRadius: '4px',
                                                      alignItems: 'center',
                                                    }}
                                                    className="custom-time-picker-nounderline"
                                                    onChange={value => handleTimeChange(value, i, 'endTime')}
                                                    onOpen={e => {
                                                      setIsExpansionDisabled(true);
                                                    }}
                                                    onClose={e => {
                                                      setIsExpansionDisabled(false);
                                                    }}
                                                    name={`end-time-${i}`}
                                                    id={`tend-time-${i}`}
                                                    classes={{
                                                      root: 'mt-4 mr-3',
                                                    }}
                                                    showSecond={false}
                                                    value={momentEndTime}
                                                    format="h:mm a"
                                                    use12Hours
                                                    minuteStep={15}
                                                  />
                                                </StyledFormControl>
                                              </div>
                                            </Grid>
                                            <Grid
                                              item
                                              sm={1}
                                              xs={1}
                                              style={{
                                                textAlign: 'right',
                                                justifyContent: 'flex-end',
                                              }}
                                              // classes={{
                                              //   root: 'd-flex align-items-center',
                                              // }}
                                            >
                                              &nbsp;
                                              {selectedWeeks.length > 1 && (
                                                <StyledIcon
                                                  onClick={removeTimesHandler(i)}
                                                  path={mdiMinusCircleOutline}
                                                  size={1}
                                                  style={{ color: colors.darkOceanBlue }}
                                                />
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </StyledDivContainer>
                                    </Grid>
                                  </Fragment>
                                );
                              })}
                            </Grid>
                          )}
                          <div style={{ marginTop: '15px' }}>
                            <StyledLink onClick={addTimesHandler}>
                              <Icon
                                path={mdiPlus}
                                style={{ width: '14px', height: '14px' }}
                                color={colors.darkOceanBlue}
                              />
                              Add Additional Booking Times
                            </StyledLink>
                          </div>
                        </div>
                      );
                    }}
                  </FieldArray>
                </CustomExpansionPanel>

                {
                  <CustomExpansionPanel
                    // setMainExpand={setIsOneToOneAvailabilityExpanded}
                    summaryTitle={
                      <div className="d-flex align-items-center">
                        <p
                          style={{
                            fontFamily: 'Avenir',
                            fontSize: mobileView ? '14px' : '16px',
                            fontWeight: '800',
                            lineHeight: '21px',
                            letterSpacing: '0.1',
                            marginBottom: '0px',
                            marginRight: '5px',
                          }}
                        >
                          Add One Time And/Or Recurring Availability
                        </p>
                        <Tooltip
                          title="This allows you to select single days or a recurring series of days that can be booked on your calendar. You can always go back to edit this later."
                          arrow
                          enterTouchDelay={TOOLTIP.ENTER_DELAY}
                          leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                        >
                          <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                        </Tooltip>
                      </div>
                    }
                    noBorder
                    expand={isAvailabilityPanelExpanded}
                    setExpand={setIsAvailabilityPanelExpanded}
                    mobileView={mobileView}
                    disableCollapse={isExpansionDisabledAvailability}
                    summarySubTitle={
                      mobileView ? (
                        ''
                      ) : isAvailabilityPanelExpanded === false && customContributionTimes?.length > 0 ? (
                        <DisplayArray data={customContributionTimes} />
                      ) : (
                        isAvailabilityPanelExpanded === false && (
                          <div
                            style={{
                              fontFamily: 'Avenir',
                              fontSize: '16px',
                              fontWeight: '350',
                              lineHeight: '21px',
                              letterSpacing: '0.1',
                              marginBottom: '0px',
                              marginLeft: '8px',
                            }}
                          >
                            None
                          </div>
                        )
                      )
                    }
                  >
                    <Grid container style={{ width: '100%' }}>
                      <div
                        id="blockSingleDayDiv"
                        style={{ display: 'flex', width: '100%', flexDirection: mobileView ? 'column' : 'row' }}
                      >
                        <div
                          style={{
                            width: mobileView ? '100%' : '45%',
                            borderRight: '1px solid #cfd8e2',
                            paddingRight: '10px',
                          }}
                        >
                          {mobileView && (
                            <span
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: 350,
                                lineHeight: '24.69px',
                                textAlign: 'left',
                                color: '#000000DE',
                                cursor: 'default', // Prevent the cursor from showing as clickable
                                pointerEvents: 'none', // Disable click interactions
                              }}
                            >
                              Select a date below
                            </span>
                          )}

                          <Calendar
                            className="custom-blockdaycalendar"
                            style={{ width: '100%', boxShadow: 'none' }}
                            value={oneToOneAvailability}
                            monthYearSeparator={' '}
                            headerOrder={['LEFT_BUTTON', 'MONTH_YEAR', 'RIGHT_BUTTON']}
                            onChange={value => {
                              dispatch(setPreSelectedMasterCalendarDate(null));
                              const selectedAvailabilityDate = new Date(value.unix * 1000);
                              setOneToOneAvailability(selectedAvailabilityDate);
                              setIsExpansionDisabledAvailability(true);
                            }}
                            minDate={moment().toDate()}
                            // maxDate={moment(oneToOneEndDate).toDate()}
                            renderButton={(direction, handleClick, disabled) =>
                              mobileView ? (
                                <div style={{ display: 'flex' }}>
                                  <div
                                    style={{
                                      cursor: disabled ? 'default' : 'pointer',
                                      color: disabled ? 'gray' : '',
                                      fontWeight: '600',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    onClick={handleClick}
                                  >
                                    {direction === 'left' && (
                                      <SvgIcon
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          marginLeft: '10px',
                                        }}
                                        viewBox="0 0 29 29"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    )}
                                    {direction === 'right' && (
                                      <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div style={{ display: 'flex' }}>
                                  {direction === 'left' && (
                                    <span
                                      style={{
                                        fontFamily: 'Avenir',
                                        fontSize: '16px',
                                        fontWeight: 350,
                                        lineHeight: '24.69px',
                                        textAlign: 'left',
                                        color: '#000000DE',
                                        cursor: 'default', // Prevent the cursor from showing as clickable
                                        pointerEvents: 'none', // Disable click interactions
                                      }}
                                    >
                                      Select a date below
                                    </span>
                                  )}
                                  <div
                                    style={{
                                      cursor: disabled ? 'default' : 'pointer',
                                      color: disabled ? 'gray' : '',
                                      fontWeight: '600',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                    onClick={handleClick}
                                  >
                                    {direction === 'left' && (
                                      <SvgIcon
                                        style={{
                                          width: '20px',
                                          height: '20px',
                                          marginLeft: '10px',
                                        }}
                                        viewBox="0 0 29 29"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    )}
                                    {direction === 'right' && (
                                      <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="29"
                                          height="29"
                                          viewBox="0 0 29 29"
                                          fill="none"
                                        >
                                          <path
                                            d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                                            fill={disabled ? 'gray' : colors.darkOceanBlue}
                                          />
                                        </svg>
                                      </SvgIcon>
                                    )}
                                  </div>
                                </div>
                              )
                            }
                            weekStartDayIndex={1}
                          />
                        </div>
                        <div style={{ width: mobileView ? '100%' : '55%', paddingLeft: mobileView ? '0px' : '20px' }}>
                          <AvailabilityRecurring
                            selectedDate={oneToOneAvailability}
                            oneToOneAvailability={oneToOneAvailability}
                            setOneToOneAvailability={setOneToOneAvailability}
                            deleteRecurring={handleAvailabilityDelete}
                            IsMasterCalendarModal={true}
                            contributions={values?.contributions}
                            setIsExpansionDisabledAvailability={setIsExpansionDisabledAvailability}
                            setallOccurencesModal={setallOccurencesModal}
                            allOccurencesModal={allOccurencesModal}
                          />
                        </div>
                      </div>
                    </Grid>
                  </CustomExpansionPanel>
                }
                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                          marginRight: '5px',
                        }}
                      >
                        Block Single Days?
                      </p>
                      <Tooltip
                        title="Now you can Block any specific days you want."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                      </Tooltip>
                    </div>
                  }
                  noBorder
                  expand={isBlockSingleDayExpanded}
                  // setMainExpand={setIsBlockSingleDayExpanded}
                  mobileView={mobileView}
                  summarySubTitle={
                    mobileView ? (
                      <></>
                    ) : isBlockSingleDayExpanded ? (
                      ''
                    ) : newBlockedDates.length === 0 ? (
                      'None'
                    ) : (
                      <DisplayArrayBlockedDates data={newBlockedDates.sort((a, b) => a - b)} />
                    )
                  }
                >
                  <Grid container style={{ width: '100%' }}>
                    <div id="blockSingleDayDiv" style={{ display: 'flex', width: '100%' }}>
                      {mobileView && (
                        <span
                          style={{
                            fontFamily: 'Avenir',
                            fontSize: '16px',
                            fontWeight: 350,
                            lineHeight: '24.69px',
                            textAlign: 'left',
                            color: '#000000DE',
                            cursor: 'default', // Prevent the cursor from showing as clickable
                            pointerEvents: 'none', // Disable click interactions
                          }}
                        >
                          Select a date below
                        </span>
                      )}
                      <div style={{ width: mobileView ? '100%' : '62%' }}>
                        <Calendar
                          className="custom-blockdaycalendar"
                          style={{ width: '100%', boxShadow: 'none' }}
                          value={newBlockedDates}
                          monthYearSeparator={' '}
                          onChange={val => {
                            const newBlockdates = val.map(date => new Date(date.unix * 1000));
                            const reversedDates = [...newBlockdates]?.reverse();
                            setNewBlockedDates(reversedDates);
                            setBlockedDates(reversedDates?.map(date => date.toISOString()));
                          }}
                          multiple
                          minDate={moment(oneToOneStartDate).toDate()}
                          maxDate={moment(oneToOneEndDate).toDate()}
                          headerOrder={['LEFT_BUTTON', 'MONTH_YEAR', 'RIGHT_BUTTON']}
                          renderButton={(direction, handleClick, disabled) =>
                            mobileView ? (
                              <div style={{ display: 'flex' }}>
                                <div
                                  style={{
                                    cursor: disabled ? 'default' : 'pointer',
                                    color: disabled ? 'gray' : '',
                                    fontWeight: '600',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={handleClick}
                                >
                                  {direction === 'left' && (
                                    <SvgIcon
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                      }}
                                      viewBox="0 0 29 29"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="29"
                                        height="29"
                                        viewBox="0 0 29 29"
                                        fill="none"
                                      >
                                        <path
                                          d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                                          fill={disabled ? 'gray' : colors.darkOceanBlue}
                                        />
                                      </svg>
                                    </SvgIcon>
                                  )}
                                  {direction === 'right' && (
                                    <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="29"
                                        height="29"
                                        viewBox="0 0 29 29"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                                          fill={disabled ? 'gray' : colors.darkOceanBlue}
                                        />
                                      </svg>
                                    </SvgIcon>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div style={{ display: 'flex' }}>
                                {direction === 'left' && (
                                  <span
                                    style={{
                                      fontFamily: 'Avenir',
                                      fontSize: '16px',
                                      fontWeight: 350,
                                      lineHeight: '24.69px',
                                      textAlign: 'left',
                                      color: '#000000DE',
                                      cursor: 'default', // Prevent the cursor from showing as clickable
                                      pointerEvents: 'none', // Disable click interactions
                                    }}
                                  >
                                    Select a date below
                                  </span>
                                )}
                                <div
                                  style={{
                                    cursor: disabled ? 'default' : 'pointer',
                                    color: disabled ? 'gray' : '',
                                    fontWeight: '600',
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                  onClick={handleClick}
                                >
                                  {direction === 'left' && (
                                    <SvgIcon
                                      style={{
                                        width: '20px',
                                        height: '20px',
                                        marginLeft: '10px',
                                      }}
                                      viewBox="0 0 29 29"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="29"
                                        height="29"
                                        viewBox="0 0 29 29"
                                        fill="none"
                                      >
                                        <path
                                          d="M20.333 6.92943L12.7632 14.5168L20.333 22.1019L18.0034 24.4334L8.08674 14.5168L18.0034 4.6001L20.333 6.92943Z"
                                          fill={disabled ? 'gray' : colors.darkOceanBlue}
                                        />
                                      </svg>
                                    </SvgIcon>
                                  )}
                                  {direction === 'right' && (
                                    <SvgIcon style={{ width: '20px', height: '20px' }} viewBox="0 0 29 29">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="29"
                                        height="29"
                                        viewBox="0 0 29 29"
                                        fill="none"
                                      >
                                        <path
                                          d="M9.16699 6.92943L16.7368 14.5168L9.16699 22.1019L11.4966 24.4334L21.4133 14.5168L11.4966 4.6001L9.16699 6.92943Z"
                                          fill={disabled ? 'gray' : colors.darkOceanBlue}
                                        />
                                      </svg>
                                    </SvgIcon>
                                  )}
                                </div>
                              </div>
                            )
                          }
                          weekStartDayIndex={1}
                          plugins={[
                            <DatePanel
                              header="Blocked Days"
                              formatFunction={({ format }) => {
                                return moment(format).format(DATE_FORMATS.DAY);
                              }}
                              position={mobileView ? 'bottom' : 'right'}
                              reverse
                            />,
                          ]}
                        />
                      </div>
                    </div>
                  </Grid>
                </CustomExpansionPanel>

                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                          marginRight: '10px',
                        }}
                      >
                        Add Buffer Time: Before Or After Sessions?
                      </p>
                      <Tooltip
                        title="If you add a buffer before or after your sessions, you will have a time break that clients can not reserve."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </div>
                  }
                  summarySubTitle={mobileView ? '' : giveTitle(values.bufferTimeBefore, values.bufferTimeAfter)}
                  expand={false}
                  mobileView={mobileView}
                  subTitleBelowTheTitle={true}
                  noBorder
                >
                  <Grid container xs={12} spacing={1}>
                    <Grid item direction="column" md={3} sm={5} xs={5}>
                      <FormControlLabel
                        style={{ fontFamily: 'Brandon Text' }}
                        checked={isBeforeBufferActive}
                        onChange={() => {
                          setIsBeforeBufferActive(!isBeforeBufferActive);
                          if (isBeforeBufferActive === true) {
                            setFieldValue('bufferTimeBefore', 0);
                          }
                        }}
                        control={<Checkbox color="primary" />}
                        label={<StyledLabel>Before event</StyledLabel>}
                        name="isBeforeBufferActive"
                        disabled={false}
                      />
                      <Select
                        labelTop="0px"
                        // name='name'
                        style={{
                          fontFamily: 'Avenir',
                          border: '1px solid #DFE3E4',
                          borderRadius: '4px',
                          padding: '3px 10px',
                          fontSize: '16px',
                          fontWeight: '350',
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                        }}
                        roundedBorder={true}
                        value={values.bufferTimeBefore}
                        onChange={e => {
                          setFieldValue('bufferTimeBefore', e.target.value);
                        }}
                        fullWidth
                        items={bufferTimeValues?.map(({ title, value }) => ({
                          title,
                          value,
                        }))}
                        disabled={!isBeforeBufferActive}
                        error=""
                      />
                    </Grid>
                    <Grid item direction="column" md={1} sm={1}></Grid>
                    <Grid item direction="column" md={3} sm={5} xs={5}>
                      <FormControlLabel
                        style={{ fontFamily: 'Brandon Text' }}
                        checked={isAfterBufferActive}
                        onChange={() => {
                          setIsAfterBufferActive(!isAfterBufferActive);
                          if (isAfterBufferActive === true) {
                            setFieldValue('bufferTimeAfter', 0);
                          }
                        }}
                        control={<Checkbox color="primary" />}
                        label={<StyledLabel>After event</StyledLabel>}
                        name="isAfterBufferActive"
                        disabled={false}
                      />
                      <Select
                        labelTop="0px"
                        // name={`sessions[${index}].daterange`}
                        style={{
                          fontFamily: 'Avenir',
                          border: '1px solid #DFE3E4',
                          borderRadius: '4px',
                          padding: '3px 10px',
                          fontSize: '16px',
                          fontWeight: '350',
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                        }}
                        roundedBorder={true}
                        value={values.bufferTimeAfter}
                        onChange={e => {
                          setFieldValue('bufferTimeAfter', e.target.value);
                        }}
                        fullWidth
                        items={bufferTimeValues?.map(({ title, value }) => ({
                          title,
                          value,
                        }))}
                        disabled={!isAfterBufferActive}
                        error=""
                      />
                    </Grid>
                    {/* <Grid
                            md={5}
                            sm={6}
                            xs={12}
                            style={{ padding: '12px', display: 'flex', alignItems: 'flex-end' }}
                          >
                            <StyledHeading GiveYourself mobileView={mobileView}>
                              Give yourself buffer time before or after your client bookings{' '}
                            </StyledHeading>
                          </Grid> */}
                  </Grid>
                </CustomExpansionPanel>

                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                          marginRight: '5px',
                        }}
                      >
                        Customize Start Time Increments?
                      </p>
                      <Tooltip
                        title="This value is used as interval for calendar slots"
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                      </Tooltip>
                    </div>
                  }
                  summarySubTitle={
                    mobileView ? (
                      <></>
                    ) : Array.isArray(startTimeIncrementDuration) ? (
                      startIncrementDurationsOneToOne
                        ?.find(d => d.value === startTimeIncrementDuration[0])
                        ?.title.includes('Default') &&
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration != undefined ? (
                        `${
                          startIncrementDurationsOneToOne?.find(d => d.value === startTimeIncrementDuration[0])?.title
                        } (${
                          activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60
                            ? '1 hour'
                            : activeContribution?.oneToOneSessionDataUi?.sessionDuration
                        }${activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60 ? '' : 'min'})`
                      ) : (
                        startIncrementDurationsOneToOne?.find(d => d.value === startTimeIncrementDuration[0])?.title
                      )
                    ) : startIncrementDurationsOneToOne
                        ?.find(d => d.value === startTimeIncrementDuration)
                        ?.title.includes('Default') &&
                      activeContribution?.oneToOneSessionDataUi?.sessionDuration != undefined ? (
                      `${startIncrementDurationsOneToOne?.find(d => d.value === startTimeIncrementDuration)?.title} (${
                        activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60
                          ? '1 hour'
                          : activeContribution?.oneToOneSessionDataUi?.sessionDuration
                      }${activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60 ? '' : 'min'})`
                    ) : (
                      startIncrementDurationsOneToOne?.find(d => d.value === startTimeIncrementDuration)?.title
                    )
                  }
                  expand={false}
                  mobileView={mobileView}
                  noBorder
                >
                  <Grid item md={4} sm={4} xs={12}>
                    <SelectStyled
                      style={{
                        fontFamily: 'Avenir',
                        border: '1px solid #DFE3E4',
                        borderRadius: '4px',
                        fontSize: '16px',
                        fontWeight: '350',
                        lineHeight: '24px',
                        letterSpacing: '0.15px',
                      }}
                      onChange={onChangeStartTimeIncrementDuration}
                      value={startTimeIncrementDuration}
                      fullWidth
                      items={startIncrementDurationsOneToOne.map(({ title, value }) =>
                        title === 'Default Increment' &&
                        activeContribution?.oneToOneSessionDataUi?.sessionDuration != undefined
                          ? {
                              value: value,
                              title: `${title} (${
                                activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60
                                  ? '1 hour'
                                  : activeContribution?.oneToOneSessionDataUi?.sessionDuration
                              }${activeContribution?.oneToOneSessionDataUi?.sessionDuration === 60 ? '' : 'min'})`,
                            }
                          : {
                              title: title,
                              value: value,
                            },
                      )}
                      disabled={false}
                      error={null}
                      errorHeight={null}
                      roundedBorder={true}
                    />
                  </Grid>
                </CustomExpansionPanel>

                <CustomExpansionPanel
                  summaryTitle={
                    <div className="d-flex align-items-center">
                      <p
                        style={{
                          fontFamily: 'Avenir',
                          fontSize: mobileView ? '14px' : '16px',
                          fontWeight: '800',
                          lineHeight: '21px',
                          letterSpacing: '0.1',
                          marginBottom: '0px',
                        }}
                      >
                        Minimum Notice
                      </p>
                      <Tooltip
                        title="Set the minimum amount of notice that is required. The clients can’t schedule within the minimum notice duration of a session start time."
                        arrow
                        enterTouchDelay={TOOLTIP.ENTER_DELAY}
                        leaveTouchDelay={TOOLTIP.LEAVE_DELAY}
                      >
                        <InfoIcon htmlColor={colors.lightBrown} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                      </Tooltip>
                      {mobileView
                        ? ''
                        : values?.minimumNotice?.value != undefined && (
                            <div
                              style={{
                                fontFamily: 'Avenir',
                                fontSize: '16px',
                                fontWeight: '350',
                                lineHeight: '21px',
                                letterSpacing: '0.1',
                                marginBottom: '0px',
                                marginLeft: '8px',
                              }}
                            >
                              {values.minimumNotice?.value == 0
                                ? `None`
                                : `${values.minimumNotice?.value} ${
                                    values.minimumNotice?.value == 1
                                      ? values.minimumNotice?.type.slice(0, -1)
                                      : values.minimumNotice?.type
                                  }`}
                            </div>
                          )}
                    </div>
                  }
                  expand={minNoticeExpand}
                  mobileView={mobileView}
                  noBorder
                >
                  {mobileView ? (
                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <div style={{ whiteSpace: 'nowrap' }}>The clients can’t schedule within</div>
                        <Grid
                          sm={1}
                          xs={1}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexShrink: 0,
                          }}
                        >
                          <StyledTextField
                            variant="outlined"
                            value={values.minimumNotice?.value}
                            InputProps={{
                              inputProps: {
                                style: {
                                  fontSize: '16px',
                                  fontFamily: 'Avenir',
                                  padding: '13px 8px',
                                  fontWeight: '350',
                                  lineHeight: '24px',
                                  maxWidth: '26px',
                                  color: '#000000DE',
                                  textAlign: 'center',
                                },
                              },
                            }}
                            onChange={e => {
                              setFieldValue('minimumNotice', {
                                ...values.minimumNotice,
                                value: e.target.value,
                              });
                            }}
                          />
                        </Grid>
                        <Grid
                          sm={3}
                          xs={3}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexShrink: 0,
                          }}
                        >
                          <Select
                            style={{
                              fontFamily: 'Avenir',
                              border: '1px solid #DFE3E4',
                              borderRadius: '4px',
                              padding: '3px 10px',
                              fontSize: '16px',
                              fontWeight: '350',
                              lineHeight: '24px',
                              letterSpacing: '0.15px',
                              marginTop: '3px',
                            }}
                            labelTop={mobileView ? '32px' : '24px'}
                            name="minimumNotice"
                            variant="Outlined"
                            value={values.minimumNotice?.type}
                            onChange={e => {
                              setFieldValue('minimumNotice', {
                                ...values.minimumNotice,
                                type: e.target.value,
                              });
                            }}
                            items={durationUnitsValues?.map(({ title, value }) => ({
                              title: values.minimumNotice?.type === 1 ? title.slice(0, -1) : title,
                              value: values.minimumNotice?.type === 1 ? value.slice(0, -1) : value,
                            }))}
                            roundedBorder={true}
                          />
                        </Grid>
                      </div>
                      <div style={{ whiteSpace: 'nowrap' }}>of a session start time</div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px',
                        marginBottom: '20px',
                      }}
                    >
                      <div>The clients can’t schedule within</div>
                      <StyledTextField
                        variant="outlined"
                        value={values.minimumNotice?.value}
                        InputProps={{
                          inputProps: {
                            style: {
                              fontSize: '16px',
                              fontFamily: 'Avenir',
                              padding: '13px 8px',
                              fontWeight: '350',
                              lineHeight: '24px',
                              maxWidth: '26px',
                              color: '#000000DE',
                              textAlign: 'center',
                            },
                          },
                        }}
                        onChange={e => {
                          setFieldValue('minimumNotice', { ...values.minimumNotice, value: e.target.value });
                        }}
                      />
                      <Select
                        style={{
                          fontFamily: 'Avenir',
                          border: '1px solid #DFE3E4',
                          borderRadius: '4px',
                          padding: '3px 10px',
                          fontSize: '16px',
                          fontWeight: '350',
                          lineHeight: '24px',
                          letterSpacing: '0.15px',
                          marginTop: '3px',
                        }}
                        labelTop={mobileView ? '32px' : '24px'}
                        name="minimumNotice"
                        variant="Outlined"
                        value={values.minimumNotice?.type}
                        onChange={e => {
                          setFieldValue('minimumNotice', { ...values.minimumNotice, type: e.target.value });
                        }}
                        items={durationUnitsValues?.map(({ title, value }) => ({
                          title: values.minimumNotice?.type === 1 ? title.slice(0, -1) : title,
                          value: values.minimumNotice?.type === 1 ? value.slice(0, -1) : value,
                        }))}
                        roundedBorder={true}
                      />
                      <div>of a session start time</div>
                    </div>
                  )}
                </CustomExpansionPanel>
              </StyledModalBody>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        disableOverlayClick={true}
        title="Confirm Availability Update"
        isOpen={openApplySettingsModal}
        isCreatingContribution={true}
        brandingColor={false}
        submitTitle="Confirm & Update"
        cancelTitle="Cancel"
        shrinkCancelButton={true}
        cancelBtnStyle={{ padding: '10px 20px !important', border: '1px solid black' }}
        headerBorder={true}
        onSubmit={() => {
          setLoader(true);
          const params = {
            contributionIds: masterCalendarArray.map(item => item.id).join(', '),
            ...formRef.current.values,
            ...oneToOneData,
            duration: oneToOneData.duration === 'custom' ? oneToOneData.duration : `${oneToOneData.duration}`,
            minimumNotice: formRef.current.values.minimumNotice,
            endDay: oneToOneData.endDay,
            startDay: oneToOneData.startDay,
            bufferTimeBefore: formRef.current.values.bufferTimeBefore,
            bufferTimeAfter: formRef.current.values.bufferTimeAfter,
            sessionIncrements: Array.isArray(startTimeIncrementDuration)
              ? startTimeIncrementDuration
              : [startTimeIncrementDuration],
          };
          delete params.sessionDuration;
          delete params.contributions;
          delete params.startDate;
          delete params.multipleSessionDuration;
          delete params.startTimeIncrementSlots;
          delete params.startTimeIncrementDuration;
          delete params.endDate;
          delete params.preparedSlots;
          masterCalendarUpdateOneToOneAvailabilities(params)
            .then(() => {
              setLoader(false);
              setshowAvailabilityPopUp(false);
              dispatch(contributionActions.fetchContributionTimes(masterCalendarArray[0].id, true));
              dispatch(contributionActions.fetchCohealerContribution(masterCalendarArray[0].id));
              set0penApplySettingsModal(!openApplySettingsModal);
            })
            .catch(() => {
              setLoader(false);
              setshowAvailabilityPopUp(false);
              dispatch(contributionActions.fetchContributionActions.success(null));
              set0penApplySettingsModal(!openApplySettingsModal);
            });
        }}
        onCancel={togglePopup}
      >
        <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginTop: '10px' }}>
          You are about to update your availability for the following service(s):
        </div>
        <ul>
          {masterCalendarArray?.length > 0 &&
            masterCalendarArray?.map(item => {
              return (
                <li key={item?.id} style={{ listStyleType: 'disc', marginLeft: '20px' }}>
                  <div
                    style={{
                      fontFamily: 'Avenir',
                      fontWeight: '400',
                      fontSize: '16px',
                      alignContent: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {item?.label}
                  </div>
                </li>
              );
            })}
        </ul>
        <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginBottom: '10px' }}>
          Please note that this action will override your previously set availability for the selected service(s).
        </div>
      </Modal>
      <Modal
        disableOverlayClick={true}
        title="No Service Selected"
        isOpen={openApplySettingsErrorModal}
        isCreatingContribution={true}
        brandingColor={false}
        submitTitle="Ok"
        hiddenCancel
        onSubmit={() => {
          togglePopupError();
        }}
        onCancel={togglePopupError}
      >
        <div style={{ fontFamily: 'Avenir', fontWeight: '400', fontSize: '18px', marginBottom: '10px' }}>
          Please select services to continue.
        </div>
      </Modal>
    </>
  );
};

MasterCalendarAvailabilityModalComponent.propTypes = {
  showTerms: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  applyTheming: PropTypes.bool,
};
MasterCalendarAvailabilityModalComponent.defaultProps = {
  applyTheming: false,
};

const mapStateToProps = ({ oneToOneData, contributions, calendars }) => ({
  oneToOneData,
  calendars: calendars.calendarsAccounts,
  lastAddedMail: calendars.lastAddedEmail,
  loadingLastEmail: calendars.loadingLastEmail,
  accountsForConflictsCheck: calendars.accountsForConflictsCheck,
  contribution: contributions.activeContribution,
  loadingTimes: contributions.loadingTimes,
  sessionDuration: oneToOneData?.sessionDuration,
  sessionDurationError: oneToOneData?.sessionDurationError,
  startTimeIncrementDuration: oneToOneData?.startTimeIncrementDuration,
  startTimeIncrementSlots: oneToOneData?.startTimeIncrementSlots,
  startTimeIncrementDurationError: oneToOneData?.startTimeIncrementDurationError,
  oneToDateRange: oneToOneData?.duration,
  oneToOneStartDate: oneToOneData?.startDay,
  oneToOneEndDate: oneToOneData?.endDay,
  blockedDates: oneToOneData?.blockedDates,
  selectedWeeks: oneToOneData?.selectedWeeks,
  calendarActiveView: calendars.currentView,
});
const actions = {
  fetchCalendars: calendarsActions.fetchCalendars,
  addCalendar: calendarsActions.addCalendar,
  getAcсountsForConflictsCheck: calendarsActions.getAcсountsForConflictsCheck,
  getAcсountForSendReminders: calendarsActions.getAcсountForSendReminders,
  removeCalendarFromConflictsCheck: calendarsActions.removeCalendarFromConflictsCheck,
  removeCalendar: calendarsActions.removeCalendar,
  getEditContributionTimes: contributionActions.fetchEditContributionTimes,
  calculateSlots: contributionActions.calculateCreateSlots,
  setSessionDuration: oneToOneDataActions.setSessionDuration,
  setStartTimeIncrementDuration: oneToOneDataActions.setStartTimeIncrementDuration,
  setOneToDateRange: oneToOneDataActions.setDuration,
  setStartDate: oneToOneDataActions.setStartDate,
  setEndDate: oneToOneDataActions.setEndDate,
  setOneToOneTime: oneToOneDataActions?.setOneToOneTime,
  removeBookingTime: oneToOneDataActions?.removeBookingTime,
  addBookingTime: oneToOneDataActions.addBookingTime,
  setBlockedDates: oneToOneDataActions.setBlockedDates,
  setBlockedDates: oneToOneDataActions.setBlockedDates,
  setDefaultWeeklyData: oneToOneDataActions.setDefaultWeeklyData,
};

export const MasterCalendarAvailabilityModal = connect(
  mapStateToProps,
  actions,
)(MasterCalendarAvailabilityModalComponent);
