import { createAction } from 'redux-actions';
import { get, pipe } from 'lodash/fp';
import {
  getAllPostsForContributionAsync,
  getAllCommunityPostsForCoachAsync,
  getAllCommunityPostsForClientAsync,
  deletePost,
  removeComment,
  getPostForKeywords,
  searchByKeyword,
  getHashTagByContributionId,
  getCommunitiesByType,
} from 'services/community.service';

export const COMMUNITIES_PAGE_LIMIT = 15;

export const ALL_POSTS_CONTRIBUTION_REQUEST = 'ALL_POSTS_CONTRIBUTION_REQUEST';
export const ALL_POSTS_CONTRIBUTION_SUCCESS = 'ALL_POSTS_CONTRIBUTION_SUCCESS';
export const ALL_POSTS_CONTRIBUTION_ERROR = 'ALL_POSTS_CONTRIBUTION_ERROR';

export const ALL_SEARCHED_POSTS_CONTRIBUTION_REQUEST = 'ALL_SEARCHED_POSTS_CONTRIBUTION_REQUEST';
export const ALL_SEARCHED_POSTS_CONTRIBUTION_SUCCESS = 'ALL_SEARCHED_POSTS_CONTRIBUTION_SUCCESS';
export const ALL_SEARCHED_POSTS_CONTRIBUTION_ERROR = 'ALL_SEARCHED_POSTS_CONTRIBUTION_ERROR';

export const BY_PAGE_POSTS_CONTRIBUTION_REQUEST = 'BY_PAGE_POSTS_CONTRIBUTION_REQUEST';
export const BY_PAGE_POSTS_CONTRIBUTION_SUCCESS = 'BY_PAGE_POSTS_CONTRIBUTION_SUCCESS';
export const BY_PAGE_POSTS_CONTRIBUTION_ERROR = 'BY_PAGE_POSTS_CONTRIBUTION_ERROR';

export const FETCH_COMMUNITIES_RESET = 'FETCH_COMMUNITIES_RESET';
export const FETCH_COMMUNITIES_REQUEST = 'FETCH_COMMUNITIES_REQUEST';
export const FETCH_COMMUNITIES_SUCCESS = 'FETCH_COMMUNITIES_SUCCESS';
export const FETCH_COMMUNITIES_ERROR = 'FETCH_COMMUNITIES_ERROR';

export const TOGGLE_COMMUNITY_SIDEBAR = 'TOGGLE_COMMUNITY_SIDEBAR';
export const UPDATE_COMMUNITY_UNREAD_COUNT = 'UPDATE_COMMUNITY_UNREAD_COUNT';
export const UPDATE_COMMUNITIES_UNREAD_COUNT = 'UPDATE_COMMUNITIES_UNREAD_COUNT';

export const TOGGLE_CONFIRMATION_MODAL = 'TOGGLE_CONFIRMATION_MODAL';

export const toggleConfirmationModal = payload => {
  return {
    type: TOGGLE_CONFIRMATION_MODAL,
    payload,
  };
};

export const toggleCommunitySidebar = payload => {
  return {
    type: TOGGLE_COMMUNITY_SIDEBAR,
    payload,
  };
};

export const updateCommunitiesUnreadCount = payload => {
  return {
    type: UPDATE_COMMUNITIES_UNREAD_COUNT,
    payload,
  };
};

export const updateCommunityUnreadCount = payload => {
  return {
    type: UPDATE_COMMUNITY_UNREAD_COUNT,
    payload,
  };
};

export const fetchCommunitiesActions = {
  reset: createAction(FETCH_COMMUNITIES_RESET),
  request: createAction(FETCH_COMMUNITIES_REQUEST),
  success: createAction(FETCH_COMMUNITIES_SUCCESS),
  error: createAction(FETCH_COMMUNITIES_ERROR),
};

export const fetchAllPostsForContributionActions = {
  request: createAction(ALL_POSTS_CONTRIBUTION_REQUEST),
  success: createAction(ALL_POSTS_CONTRIBUTION_SUCCESS),
  error: createAction(ALL_POSTS_CONTRIBUTION_ERROR),
};

export const fetchAllPostsForKeywordActions = {
  request: createAction(ALL_SEARCHED_POSTS_CONTRIBUTION_REQUEST),
  success: createAction(ALL_SEARCHED_POSTS_CONTRIBUTION_SUCCESS),
  error: createAction(ALL_SEARCHED_POSTS_CONTRIBUTION_ERROR),
};

export const fetchByPagePostsForContributionActions = {
  request: createAction(BY_PAGE_POSTS_CONTRIBUTION_REQUEST),
  success: createAction(BY_PAGE_POSTS_CONTRIBUTION_SUCCESS),
  error: createAction(BY_PAGE_POSTS_CONTRIBUTION_ERROR),
};

export const getAllCommunityPostsForCoach = data => async dispatch => {
  dispatch(fetchAllPostsForContributionActions.request());
  try {
    const response = await getAllCommunityPostsForCoachAsync(data);
    if (data?.skip > 0) {
      dispatch(fetchAllPostsForKeywordActions.success(response));
    } else {
      dispatch(fetchAllPostsForContributionActions.success(response));
    }
  } catch (error) {
    pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error);
  }
};

export const getAllCommunityPostsForClient = data => async dispatch => {
  dispatch(fetchAllPostsForContributionActions.request());
  try {
    const response = await getAllCommunityPostsForClientAsync(data);
    if (data?.skip > 0) {
      dispatch(fetchAllPostsForKeywordActions.success(response));
    } else {
      dispatch(fetchAllPostsForContributionActions.success(response));
    }
  } catch (error) {
    pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error);
  }
};

export const getAllPostsForContribution = data => async dispatch => {
  dispatch(fetchAllPostsForContributionActions.request());
  try {
    const response = await getAllPostsForContributionAsync(data);
    dispatch(fetchAllPostsForContributionActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error);
  }
};

export const getAllPostsForKeyword = data => async dispatch => {
  dispatch(fetchAllPostsForContributionActions.request());
  try {
    const response = await getPostForKeywords(data);
    if (data?.skip > 0) {
      dispatch(fetchAllPostsForKeywordActions.success(response));
    } else {
      dispatch(fetchAllPostsForContributionActions.success(response));
    }
  } catch (error) {
    pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error);
  }
};

export const getByPagePostsForKeyword = data => async dispatch => {
  dispatch(fetchByPagePostsForContributionActions.request());
  try {
    const response = await getPostForKeywords(data);
    dispatch(fetchByPagePostsForContributionActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchByPagePostsForContributionActions.error, dispatch)(error);
  }
};

export const getByPagePostsForContribution = data => async dispatch => {
  dispatch(fetchByPagePostsForContributionActions.request());
  try {
    const response = await getAllPostsForContributionAsync(data);
    dispatch(fetchByPagePostsForContributionActions.success(response));
  } catch (error) {
    pipe(get('response'), get('data'), fetchByPagePostsForContributionActions.error, dispatch)(error);
  }
};
export const deletePostById = id => async dispatch => {
  // dispatch(fetchAllPostsForContributionActions.request())
  try {
    const response = await deletePost(id);
    // dispatch(fetchAllPostsForContributionActions.success(response))
  } catch (error) {
    // pipe(get('response'), get('data'), fetchAllPostsForContributionActions.error, dispatch)(error)
  }
};
export const deleteCommentById = id => async dispatch => {
  try {
    const response = await removeComment(id);
  } catch (error) {}
};

export const fetchCommunities = ({ communityType, number, shouldRetain }) => async dispatch => {
  dispatch(fetchCommunitiesActions.request());
  try {
    if (number === 0) {
      dispatch(fetchCommunitiesActions.reset());
    }
    const response = await getCommunitiesByType(communityType, number);

    let { communities, ...rest } = response;

    if (communityType !== 'All' && shouldRetain) {
      rest = {};
    }

    dispatch(
      fetchCommunitiesActions.success({
        communities: response.communities,
        limitReached: response?.communities?.length === 0 || response?.communities?.length < COMMUNITIES_PAGE_LIMIT,
        rest,
      }),
    );
  } catch (error) {
    pipe(get('response'), get('data'), fetchCommunitiesActions.error, dispatch)(error);
  }
};
