import axiosInstance from 'utils/axiosInstance';
import { get } from 'lodash/fp';
import { downloadFileByLink, getIpGlobal, wrapLink } from '../utils/utils';
import { CONTRIBUTION_COLORS } from '../constants';
import { ContributionThemedColors } from 'helpers/constants';
import { isInteger, isUndefined } from 'lodash';

const getContributions = id => axiosInstance.get(`/Contribution/GetAllCreatedByUserId/${id}`).then(get('data'));

const getUpcomingCreated = (id, number) =>
  axiosInstance
    .get(
      `/Contribution/GetUpcomingCreatedByUserId/${id}${number >= 0 ? `?skip=${number * 10}&take=10&orderBy=Desc` : ''}`,
    )
    .then(get('data'));

const getUpcomingCreatedWithType = (id, type) =>
  axiosInstance.get(`/Contribution/GetUpcomingCreatedByUserId/${id}/${type}`).then(get('data'));

const getArchived = id => axiosInstance.get(`/Contribution/GetArchivedCreatedByUserId/${id}`).then(get('data'));

const getContribution = id => axiosInstance.get(`/Contribution/GetContribById/${id}`).then(get('data'));

const getCohealerContribution = id => axiosInstance.get(`/Contribution/GetCohealerContribById/${id}`).then(get('data'));

const getOneToOneContributionsWithBookedSession = () =>
  axiosInstance.get(`/Contribution/GetOneToOneContributionsWithBookedSession`).then(get('data'));

const contributionParticipants = id =>
  axiosInstance.get(`/Contribution/GetContribParticipantsById/${id}`).then(get('data'));
const getAllParticipants = id =>
  axiosInstance.get(`/Contribution/GetAllParticipantsInContribution/${id}`).then(get('data'));
const getClientContribution = async (id, ipAddress, priceId) => {
  const userIp = await getIpGlobal();
  return axiosInstance
    .get(`/Contribution/GetClientContribById/${id}/${userIp}${priceId ? `/${priceId}` : ''}`)
    .then(get('data'));
};

const getClientContributionApi = async (id, ipAddress, priceId) => {
  const userIp = await getIpGlobal();
  return axiosInstance
    .get(`/Contribution/GetClientContribById/${id}/${userIp}${priceId ? `/${priceId}` : ''}`)
    .then(get('data'));
};
const getVideoUrl = videoKey => axiosInstance.get(`/Video/GetVideoUrl?videoKey=${videoKey}`).then(get('data'));

const getCoachCustomSlots = (id, offset = 0, sessionDuration) =>
  axiosInstance
    .get(
      `/Contribution/GetCustomSlots?contributionId=${id}&offSet=${offset}&sessionDuration=${sessionDuration}`,
      null,
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(get('data'));
const getCoachContributionTimes = (id, offset = 0, startDay, timeZoneId = null) =>
  axiosInstance
    .post(`/Contribution/${id}/GetSlots`, null, {
      params: { offset, slotsStartDate: startDay, slotsEndDate: startDay, timeZoneId },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getSingleDaySlotsForScheduleClient = (id, offset = 0, startDay, timeZoneId = null) =>
  axiosInstance
    .post(`/Contribution/${id}/GetSingleDaySlotsForScheduleClient`, null, {
      params: { offset, date: startDay, timeZoneId },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));
const getClientContributionTimes = (id, offset = 0, startDay) =>
  axiosInstance
    .post(`/Contribution/${id}/GetClientSlots`, null, {
      params: { offset, slotsStartDate: startDay, slotsEndDate: startDay },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));
const getClientSlotsWithTimezone = (id, offset = 0, timeZoneId, startDay, endDay, duration) => {
  return axiosInstance
    .post(`/Contribution/${id}/GetClientSlotsWithTimezone`, null, {
      params: { offset, timeZoneId, sessionDuration: duration },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));
};
const getCoachEditContributionTimes = (id, oneToOneSessionDataUi, params = {}) =>
  axiosInstance.post(`/Contribution/${id}/GetSlots`, oneToOneSessionDataUi, { params }).then(get('data'));

const calculateSlots = oneToOneSessionDataUi =>
  axiosInstance.post('/Contribution/CalculateSlots', oneToOneSessionDataUi).then(get('data'));

const createContribution = data => axiosInstance.post(`/contribution`, data).then(get('data'));

const updateContribution = (id, data) => axiosInstance.put(`/contribution/${id}`, data).then(get('data'));

const getContributionsAdmin = () => axiosInstance.get('/Contribution/GetForAdmin').then(get('data'));
const getLatestApproved = () => axiosInstance.get('/Contribution/GetLatestApprovedContribution').then(get('data'));

const purchaseCourse = data => axiosInstance.post('/api/purchase', data).then(get('data'));

const rescheduleSession = data => axiosInstance.post(`/Contribution/RescheduleOneToOneBooking`, data).then(get('data'));

const rescheduleSessionForClient = data =>
  axiosInstance.post(`/Contribution/ClientOneToOneRescheduling`, data).then(get('data'));

const getLastDraftContribution = data =>
  axiosInstance
    .get(`/Contribution/Draft`, data)
    .then(get('data'))
    .catch(() => ({}));

const createDraftContribution = data => axiosInstance.post(`/Contribution/Draft`, data).then(get('data'));

const createDuplciateContribution = data =>
  axiosInstance.post(`Contribution/DuplicateContribution`, data).then(get('data'));
const updateDraftContribution = (id, data) =>
  axiosInstance
    .put(`/Contribution/Draft`, {
      ...data,
      isApplicationFormBannerPreviewed: 'false',
    })
    .then(get('data'));

const deleteDraftContribution = (id, data) => axiosInstance.delete(`/Contribution/Draft/${id}`, data).then(get('data'));

const getChatChannelDetails = chatsid =>
  axiosInstance.get(`/Chat/GetContributionDataByChatId?chatSid=${chatsid}`).then(get('data'));

const submitDraftContribution = ({ timeZoneId, ...body }) =>
  updateDraftContribution(null, {
    ...body,
    instagramUrl: body.instagramUrl ? wrapLink(body.instagramUrl) : '',
    linkedInUrl: body.linkedInUrl ? wrapLink(body.linkedInUrl) : '',
    youtubeUrl: body.youtubeUrl ? wrapLink(body.youtubeUrl) : '',
    facebookUrl: body.facebookUrl ? wrapLink(body.facebookUrl) : '',
    websiteUrl: body.websiteUrl ? wrapLink(body.websiteUrl) : '',
  })
    .then(() => axiosInstance.post(`/Contribution/Draft/${body.id}/Submit`))
    .then(get('data'));

const shareViaEmail = data => axiosInstance.post(`/Contribution/ShareViaEmail`, data).then(get('data'));

const getAllBoughtByUserId = userId =>
  axiosInstance.get(`/Contribution/GetAllBoughtByUserIdUpdated/${userId}`).then(get('data'));

const GetAllBoughtByUserIdUpdated = userId =>
  axiosInstance.get(`/Contribution/GetAllBoughtByUserIdUpdated/${userId}`).then(get('data'));

const getClientPurchases = (clientUserId, count) =>
  axiosInstance
    .get(`/Contribution/GetClientPurchases/${clientUserId}${count === false ? `/${count}` : ''}`)
    .then(get('data'));

const bookSessionTime = data => axiosInstance.post(`/Contribution/BookSessionTime`, data).then(get('data'));

const unbookSessionTime = data => axiosInstance.post(`/Contribution/RevokeBookSessionTime`, data).then(get('data'));

const saveThankYouPage = data =>
  axiosInstance.post(`/Contribution/SaveThankyouPageInContribution`, data).then(get('data'));

const updateTimeforSelfBook = data =>
  axiosInstance.put(`/Contribution/UpdateTimeSoltForSelfBook`, data).then(get('data'));

const saveCustomWaiver = data =>
  axiosInstance.post(`/Contribution/SaveThankyouPageInContribution`, data).then(get('data'));

const markThankYouPageViewed = contributionId =>
  axiosInstance.post(`/Contribution/MarkThankyouPageViewed?contributionId=${contributionId}`).then(get('data'));

const getThankYouPage = data =>
  axiosInstance.get(`/Contribution/GetThankyouPageInContribution/${data}`).then(get('data'));

const getBoughtByType = type => axiosInstance.get(`/Contribution/GetBoughtByType/${type}`).then(get('data'));

const joinContribution = data =>
  axiosInstance.post(`/ContributionAccess/${data.id}/join`, { accessCode: data.accessCode }).then(get('data'));

const saveSignoffData = (formData, id, ip) =>
  axiosInstance.post(`Contribution/save-signoff-data?contributionId=${id}&ipAddress=${ip}`, formData).then(get('data'));

const deleteClient = (contributionId, userId) =>
  axiosInstance.delete(`/ContributionAccess/${contributionId}/${userId}`).then(get('data'));

const createAccessCode = data => axiosInstance.post(`/ContributionAccess/${data}/CreateAccessCode`).then(get('data'));
const getFreeAccessCode = id =>
  axiosInstance.get(`/ContributionAccess/GetFreeAccessCode?contributionId=${id}`).then(get('data'));

const getAllContracts = data => axiosInstance.get(`/Contract/AllTemplate`, { params: data }).then(get('data'));

const getSingleContract = (contributionId, email = ' ') =>
  axiosInstance.get(`/Contract/GetContract/${contributionId}/${email}`).then(get('data'));

const createContract = data => axiosInstance.post(`/Contract/Create`, data).then(get('data'));
const deleteOneToOneSession = data =>
  axiosInstance.post(`/Contribution/DeleteOneToOneSingleSession`, data).then(get('data'));
const bookeOneToOneSession = data =>
  axiosInstance.post(`Contribution/BookOneToOneCreditedSession`, data).then(get('data'));
const getContributionFromList = (data, contributionId) =>
  axiosInstance
    .get(
      `/Contribution/GetAllContributionForCoachByType?type=${data}${
        contributionId ? `&contributionId=${contributionId}` : ''
      }`,
    )
    .then(get('data'));

const getOneToOneContributions = () => axiosInstance.get(`/Contribution/GetOneToOneContributions`).then(get('data'));
const getAllApprovedContributionsForCoach = () =>
  axiosInstance.get('/Contribution/GetAllApprovedContributionsForCoach').then(get('data'));

const saveCustomAvailablilityTime = data =>
  axiosInstance.post('/Contribution/SaveCustomAvailablilityTime', data).then(get('data'));

// const updateOnetoOneAvailabilities = data =>
//   axiosInstance.post('/Contribution/UpdateOneToOneAvailabilities', data).then(get('data'));

const updateCustomAvailabilityEntireSequence = data =>
  axiosInstance.post('/Contribution/UpdateCustomAvailabilityEntireSequence', data).then(get('data'));

const deleteCustomAvailabilityEntireSequence = data =>
  axiosInstance.post('/Contribution/DeleteCustomAvailabilityEntireSequence', data).then(get('data'));

const updateCustomAvailability = data =>
  axiosInstance.put('/Contribution/UpdateCustomAvailability', data).then(get('data'));

const updateContract = data => axiosInstance.put(`/Contract/Update`, data).then(get('data'));

const setSignedContract = (file, query) => {
  axiosInstance.post(`/Contract/SignedOffContract`, file, { params: query }).then(get('data'));
};

const calculateSlotsheckIfPurchaseExistsForClient = (email, contributionId) => {
  return axiosInstance
    .get(`/Contribution/CheckIfPurchaseExistsForClient?email=${email}&contribId=${contributionId}`)
    .then(get('data'));
};

const getIsAnyContributionCreatedOrJoined = isCoach => {
  return axiosInstance.get(`/Contribution/IsAnyContributionCreatedOrJoined?isCoach=${isCoach}`).then(get('data'));
};

const completeSelfPacedSessionTime = async data => {
  const endpoint = '/Contribution/SetSelfPacedClassAsCompleted';
  const body = {
    classId: data?.sessionTimeId,
    contributionId: data?.contributionId,
  };

  const response = await axiosInstance.post(endpoint, body);

  return response.data;
};

const determineColorToUse = contribution => {
  if (contribution?.coachSelectedBrandingColors?.PrimaryColorCode) {
    return contribution?.coachSelectedBrandingColors;
  } else {
    return CONTRIBUTION_COLORS;
  }
};

const determineDarkThemedColorToUse = contribution => {
  return Boolean(contribution?.isDarkModeEnabled);
};

const getThemedColors = (contribution, isCoach = true) => {
  const isDarkThemeEnabled = determineDarkThemedColorToUse(contribution);
  const themedColor = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedColor
    : ContributionThemedColors.LightThemedColor;
  let themedBackgroundColor = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedBackgroundColor
    : ContributionThemedColors.LightThemedBackgroundColor;

  const themedColorBtn = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedColorBtn
    : ContributionThemedColors.LightThemedColorBtn;
  let themedBackgroundColorBtn = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedBackgroundColorBtn
    : ContributionThemedColors.LightThemedBackgroundColorBtn;

  let themedCardBackgroundColor = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedCardBackgroundColor
    : ContributionThemedColors.LightThemedCardBackgroundColor;
  const themedCardOutlineColor = isDarkThemeEnabled
    ? ContributionThemedColors.DarkThemedCardOutlineColor
    : ContributionThemedColors.LightThemedCardOutlineColor;
  const newThemedBackgroundColor = isDarkThemeEnabled
    ? ContributionThemedColors.NewDarkThemedBackgroundColor
    : ContributionThemedColors.NewLightThemedBackgroundColor;
  const newThemedTextColor = isDarkThemeEnabled
    ? ContributionThemedColors.NewDarkThemedTextColor
    : ContributionThemedColors.NewLightThemedTextColor;
  const newThemedCardColor = isDarkThemeEnabled
    ? ContributionThemedColors.NewDarkThemedCardColor
    : ContributionThemedColors.NewLightThemedCardColor;

  if (!isCoach) {
    [themedBackgroundColor, themedCardBackgroundColor] = [themedCardBackgroundColor, themedBackgroundColor];
  }
  return {
    themedColor,
    themedColorBtn,
    themedCardBackgroundColor,
    themedBackgroundColor,
    themedBackgroundColorBtn,
    themedCardOutlineColor,
    newThemedBackgroundColor,
    newThemedTextColor,
    newThemedCardColor,
  };
};
const getAllResources = id =>
  axiosInstance.get(`/Contribution/GetAllResourcessByContributionId/${id}`).then(get('data'));

const deleteSpecificResources = resourceDataUi =>
  axiosInstance.post('/Contribution/DeleteFileFromContributionAndResourceFolder', resourceDataUi).then(get('data'));

const shareSpecificResource = resourceDataUi =>
  axiosInstance.post('/Contribution/ShareContributionResourceWithUser', resourceDataUi).then(get('data'));

const renameSpecificResources = resourceDataUi =>
  axiosInstance.post('/Contribution/RenameFileFromContributionAndResourceFolder', resourceDataUi).then(get('data'));

const downloadSpecificResources = resourceDataUi =>
  axiosInstance.post('/Contribution/DownloadFileFromContributionAndResourceFolder', resourceDataUi).then(({ data }) => {
    downloadFileByLink(data, resourceDataUi?.fileName || 'cohereFile');
  });

const getContributionSessionNotes = (contributionId, classId, subClassId, isPrerecorded) => {
  return axiosInstance
    .get(`/api/Note/${contributionId}/${classId}${isPrerecorded ? `?subClassId=${subClassId}` : ''}`)
    .then(get('data'));
};

const updateApplicationFormPreviewStatus = id =>
  axiosInstance
    .put(`/ApplicationForm/SetApplicationFormBannerPreviewed/${id}?status=true`)
    .then(get('data'))
    .catch(error => {});

const getCoachClientsWithCredits = id =>
  axiosInstance
    .get(`/Contribution/GetClientListOfCoachWithPkgCreditRemaining?contributionId=${id}`)
    .then(get('data'))
    .catch(error => {});

const getOneOnOneContributons = () =>
  axiosInstance
    .get(`/Contribution/GetOneToOneContributions`)
    .then(get('data'))
    .catch(error => {});

const saveOneToOneSelfBookClient = data =>
  axiosInstance
    .post(`/api/Purchase/SaveOneToOneSelfBookClientData`, data)
    .then(get('data'))
    .catch(error => {});

const sendScheduleSessionEmailToClient = id =>
  axiosInstance
    .post(`/Contribution/SendScheduleSessionEmailToClient?Id=${id}`, null, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const masterCalendarUpdateOneToOneAvailabilities = params =>
  axiosInstance
    .post(`/Contribution/UpdateOneToOneAvailabilities`, params, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getScheduleSessionLink = sessionId =>
  axiosInstance
    .post(`/Contribution/GetScheduleSessionLink`, null, {
      params: { sessionId },
      headers: { 'Content-Type': 'application/json' },
    })
    .then(get('data'));

const getOneToOneSelfBookClient = contributionId =>
  axiosInstance.get(`/Contribution/GetOneToOneSelfBookClientData?Id=${contributionId}`).then(get('data'));

const addToGoogleCalender = data =>
  axiosInstance.get(`/Contribution/GetGoogleInviteURL/`, { params: data }).then(get('data'));

const getAllOneToOneSessionBookings = data =>
  axiosInstance.get(`/Contribution/GetAllOneToOneSessionBookings`, { params: data }).then(get('data'));

const getSessionBookingsPerContribution = data =>
  axiosInstance.get(`/Contribution/GetSessionBookingsPerContribution`, { params: data }).then(get('data'));

const submitEnrollmentForm = data => axiosInstance.post(`/Contribution/SubmitEnrollmentForm/`, data).then(get('data'));

const getContributionConversionMetrics = contributionId => {
  return axiosInstance.get(`/Contribution/${contributionId}/GetConversionMetrics`).then(get('data'));
};

export {
  getClientPurchases,
  getContributions,
  getUpcomingCreated,
  getUpcomingCreatedWithType,
  getArchived,
  getContribution,
  updateContribution,
  createContribution,
  getContributionsAdmin,
  purchaseCourse,
  getCohealerContribution,
  getClientContribution,
  rescheduleSession,
  rescheduleSessionForClient,
  getCoachContributionTimes,
  getClientContributionTimes,
  getCoachEditContributionTimes,
  calculateSlots,
  getLastDraftContribution,
  createDraftContribution,
  updateDraftContribution,
  deleteDraftContribution,
  submitDraftContribution,
  shareViaEmail,
  shareSpecificResource,
  getAllBoughtByUserId,
  GetAllBoughtByUserIdUpdated,
  contributionParticipants,
  bookSessionTime,
  unbookSessionTime,
  saveThankYouPage,
  getThankYouPage,
  getBoughtByType,
  joinContribution,
  saveSignoffData,
  deleteClient,
  createAccessCode,
  getFreeAccessCode,
  completeSelfPacedSessionTime,
  determineColorToUse,
  determineDarkThemedColorToUse,
  getThemedColors,
  getLatestApproved,
  getVideoUrl,
  getAllResources,
  deleteOneToOneSession,
  bookeOneToOneSession,
  deleteSpecificResources,
  renameSpecificResources,
  getAllParticipants,
  downloadSpecificResources,
  createDuplciateContribution,
  markThankYouPageViewed,
  getChatChannelDetails,
  saveCustomWaiver,
  updateApplicationFormPreviewStatus,
  getCoachClientsWithCredits,
  saveOneToOneSelfBookClient,
  sendScheduleSessionEmailToClient,
  getScheduleSessionLink,
  getOneToOneSelfBookClient,
  getClientContributionApi,
  updateTimeforSelfBook,
  getContributionSessionNotes,
  getAllContracts,
  getSingleContract,
  setSignedContract,
  createContract,
  updateContract,
  addToGoogleCalender,
  getAllOneToOneSessionBookings,
  submitEnrollmentForm,
  calculateSlotsheckIfPurchaseExistsForClient,
  getContributionFromList,
  getAllApprovedContributionsForCoach,
  saveCustomAvailablilityTime,
  getSessionBookingsPerContribution,
  getSingleDaySlotsForScheduleClient,
  getOneOnOneContributons,
  getContributionConversionMetrics,
  getClientSlotsWithTimezone,
  updateCustomAvailabilityEntireSequence,
  deleteCustomAvailabilityEntireSequence,
  // updateOnetoOneAvailabilities,
  updateCustomAvailability,
  getOneToOneContributions,
  masterCalendarUpdateOneToOneAvailabilities,
  getIsAnyContributionCreatedOrJoined,
  getCoachCustomSlots,
  getOneToOneContributionsWithBookedSession,
};
