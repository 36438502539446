import React, { useEffect } from 'react';
import CalendarSvg from 'pages/ContributionView/ContributionSessions/OneToOneSessions/EasyBooking/icons/CalendarIcon';
import CircleSvg from './CircleIcon';
import moment from 'moment';

const CustomEvent = ({
  title,
  event: { sessionTitle, isExternalEvent, color, start },
  view,
  component = '',
  setShowLoader = () => {},
}) => {
  useEffect(() => {
    setShowLoader(false);
  }, []);

  return (
    <div>
      {view === 'month' && <CircleSvg fill={color} />}
      <div className="d-flex event-title" style={{ gap: '10px' }}>
        <span style={{ color: color }}>{title}</span>
        {view !== 'month' && isExternalEvent && <CalendarSvg color={color} />}
      </div>
    </div>
  );
};

export default CustomEvent;
